import React from 'react'
import { Component } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { ApplicationPaths, QueryParameterNames } from './ApiAuthorizationConstants'
import authService from './AuthorizeService'

export default class AuthorizeRoute extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ready: false,
            authenticated: false,
            newUserResponse: "",
            isAdmin: false
        };
    }

    _isMounted = false;

    componentDidMount() {
        this._subscription = authService.subscribe(() => this.authenticationChanged());
        this.populateAuthenticationState();
        this._isMounted = true;
    }

    componentWillUnmount() {
        authService.unsubscribe(this._subscription);
        this._isMounted = false;
    }

    render() {
        const { ready, authenticated, newUserResponse, isAdmin } = this.state;
        const redirectUrl = `${ApplicationPaths.Login}?${QueryParameterNames.ReturnUrl}=${encodeURI(window.location.href)}`
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const location = urlParams.get('feature')
        if (!ready && !this._isMounted) {
            return <div></div>;
        } else {
            const { component: Component, ...rest } = this.props;
            if (ready && this._isMounted){
            return <Route {...rest}
                render={(props) => {
                    if(location === "TrackRental"){
                        return <Redirect to="/TrackRental" />
                    }
                    if(location === "TrackRentalAdmin"){
                        return <Redirect to="/TrackRentalAdmin" />
                    }
                    if(location === "TrackRentalAdminPriceSheet"){
                        return <Redirect to="/TrackRentalAdminPriceSheet" />
                    }
                    //redirect a newUser to the entrant form
                    if (authenticated && newUserResponse === "No Entrant under this user") {
                        if(window.location.pathname.toLowerCase() === "/newuser"){
                            return <Component {...props} />
                        }else{
                            return <Redirect to="/NewUser" />
                        }
                    } else if (authenticated && newUserResponse === "User exists") {
                        if(window.location.pathname.toLowerCase() === "/admindashboard"){
                            if(isAdmin){
                                return <Component {...props} />
                            }
                            return <Redirect to="/Dashboard" />
                        }
                        else if(window.location.pathname.toLowerCase() === "/dashboard" || window.location.pathname === "/"){
                            if(isAdmin){
                                return <Redirect to="/AdminDashboard" />
                            }
                            return <Component {...props} />
                        }
                        else if(window.location.pathname.toLowerCase() === "/checkin" || window.location.pathname === "/"){
                            if(isAdmin){
                                return <Component {...props} />
                            }
                            return <Redirect to="/Dashboard" />
                        }
                        else if(window.location.pathname.toLowerCase() === "/adminprivilege"){
                            if(isAdmin){
                                return <Component {...props} />
                            }
                            return <Redirect to="/Dashboard" />
                        }
                        else if(window.location.pathname.toLowerCase() === "/newuser"){
                            return <Redirect to="/Dashboard" />
                        }
                        else if(window.location.pathname.toLowerCase() === "/trackrental"){
                            if(isAdmin){
                                return <Redirect to="/TrackRentalAdmin" />
                            }
                            return <Component {...props} />
                        }
                        else if(window.location.pathname.toLowerCase() === "/trackrentaladmin"){
                            if(isAdmin){
                                return <Component {...props} />
                            }
                            return <Redirect to="/TrackRental" />
                        }
                        else if(window.location.pathname.toLowerCase() === "/trackrentaladminpricesheet"){
                            if(isAdmin){
                                return <Component {...props} />
                            }
                            return <Redirect to="/TrackRental" />
                        }
                        else{
                            return <Component {...props} />
                        }
                        
                    }
                    else{
                        return <Redirect to={redirectUrl} />
                    }
                }} />}
                else{
                    return <div></div>;
                }
        }
    }

    async populateAuthenticationState() {
        const authenticated = await authService.isAuthenticated();
        const resultAdminFunc = await authService.checkIfAdmin();
        var isAdmin = true;
        if(resultAdminFunc != true){
            isAdmin = false;
        }
        await authService.isNewUser().then((newUserResponse)=>{
            this.setState({authenticated});
            this.setState({newUserResponse});
            this.setState({isAdmin});
            this.setState({ready: true});}
        );
        
    }

    async authenticationChanged() {
        if(this._isMounted){
            this.setState({ ready: false, authenticated: false });
            await this.populateAuthenticationState()
            }
        }
    }
