import React from 'react'
import { Component } from 'react';
import authService from './AuthorizeService';
import { AuthenticationResultStatus } from './AuthorizeService';
import { QueryParameterNames, LogoutActions, ApplicationPaths } from './ApiAuthorizationConstants';
import { Route, Redirect } from 'react-router-dom'
import {LinearProgress, AppBar, Typography, Toolbar } from '@material-ui/core';
import { withStyles, createStyles, Theme, ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import logo from '../../img/weatherTech.png';

// The main responsibility of this component is to handle the user's logout process.
// This is the starting point for the logout process, which is usually initiated when a
// user clicks on the logout button on the LoginMenu component.
export class Logout extends Component {
    constructor(props) {
        super(props);

        this.state = {
            message: undefined,
            isReady: false,
            authenticated: false
        };
        this.topBar = this.topBar.bind(this);
    }

    componentDidMount() {
        const action = this.props.action;
        switch (action) {
            case LogoutActions.Logout:
                if (!!window.history.state.state.local) {
                    this.logout(this.getReturnUrl());
                } else {
                    // This prevents regular links to <app>/authentication/logout from triggering a logout
                    this.setState({ isReady: true, message: "The logout was not initiated from within the page." });
                }
                break;
            case LogoutActions.LogoutCallback:
                this.processLogoutCallback();
                break;
            case LogoutActions.LoggedOut:
                this.setState({ isReady: true, message: "You successfully logged out!" });
                break;
            default:
                throw new Error(`Invalid action '${action}'`);
        }

        this.populateAuthenticationState();
    }
    topBar(){
        const classes = this.props;
        return(
            <React.Fragment>
            <AppBar
            position="fixed"
            >
            <Toolbar>
            <img src={logo}/>          
            <Typography variant="h6" >
            Race Registration
            </Typography>
            </Toolbar>
            </AppBar>
            <LinearProgress color="secondary"/></React.Fragment>)
    }
    render() {
        const { isReady, message } = this.state;
        const classes = this.props;
        if (!isReady) {
            return <div></div>
        }
        if (!!message) {
            return (<div><this.topBar/>{message}</div>);
        } else {
            const action = this.props.action;
            switch (action) {
                case LogoutActions.Logout:
                    return (<div><this.topBar/><Typography variant="h4" className={classes.center} color="secondary">Logging Out...</Typography></div>);
                case LogoutActions.LogoutCallback:
                    return (<div><this.topBar/><Typography variant="h4" className={classes.center} color="secondary">Log Out Successful</Typography></div>);
                case LogoutActions.LoggedOut:
                    window.location.replace('./Identity/Pages/Account/Login');
                    return (<div><this.topBar/>{message}</div>);
                default:
                    throw new Error(`Invalid action '${action}'`);
            }
        }
    }

    async logout(returnUrl) {
        const state = { returnUrl };
        const isauthenticated = await authService.isAuthenticated();
        if (isauthenticated) {
            const result = await authService.signOut(state);
            switch (result.status) {
                case AuthenticationResultStatus.Redirect:
                    break;
                case AuthenticationResultStatus.Success:
                    await this.navigateToReturnUrl(returnUrl);
                    break;
                case AuthenticationResultStatus.Fail:
                    this.setState({ message: result.message });
                    break;
                default:
                    throw new Error("Invalid authentication result status.");
            }
        } else {
            this.setState({ message: "You successfully logged out!" });
        }
    }

    async processLogoutCallback() {
        const url = window.location.href;
        const result = await authService.completeSignOut(url);
        switch (result.status) {
            case AuthenticationResultStatus.Redirect:
                // There should not be any redirects as the only time completeAuthentication finishes
                // is when we are doing a redirect sign in flow.
                throw new Error('Should not redirect.');
            case AuthenticationResultStatus.Success:
                await this.navigateToReturnUrl(this.getReturnUrl(result.state));
                break;
            case AuthenticationResultStatus.Fail:
                this.setState({ message: result.message });
                break;
            default:
                throw new Error("Invalid authentication result status.");
        }
    }

    async populateAuthenticationState() {
        const authenticated = await authService.isAuthenticated();
        this.setState({ isReady: true, authenticated });
    }

    getReturnUrl(state) {
        const params = new URLSearchParams(window.location.search);
        const fromQuery = params.get(QueryParameterNames.ReturnUrl);
        if (fromQuery && !fromQuery.startsWith(`${window.location.origin}/`)) {
            // This is an extra check to prevent open redirects.
            throw new Error("Invalid return url. The return url needs to have the same origin as the current page.")
        }
        return (state && state.returnUrl) ||
            fromQuery ||
            `${window.location.origin}${ApplicationPaths.LoggedOut}`;
    }

    navigateToReturnUrl(returnUrl) {
        return window.location.replace('./Identity/Pages/Account/Login');
        //return window.location.replace(returnUrl);
    }
}


const styles = createStyles((theme) => ({
    logo:{
        height: '50px',
        paddingRight: theme.spacing(2)
      },
      toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
      },      
    loadingBar:{
        marginTop: theme.spacing(8)+ 1,
        marginRight: theme.spacing(-1),
        marginLeft: theme.spacing(-1),
        zIndex: 10
    },
    center:{
        textAlign: "center"
    }
  }));

  
export default withStyles(styles)(Logout);

