import React, { Component } from 'react';
import { withStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Dialog, DialogTitle, DialogContent, DialogActions, withWidth, Button, Grid } from '@material-ui/core';
import CrewForm from './CrewForm';
import Car from '../interfaces/ICar';
import Crew from '../interfaces/ICrew';
import { green, red } from '@material-ui/core/colors';
import APIService from '../services/APIService';
import RegistrationEvent from '../interfaces/IRegistrationEvent';
import Race from '../interfaces/IRace';
import Entry from '../interfaces/IEntry';

export interface IModifyCrewProps {
    classes: any;
    theme: any;
    width: any;
    handleCancel: any;
    car: Car;
    event: RegistrationEvent;
    show: boolean;
    setMessage: any;
    setLoading: any;
    loading: boolean;
    entry: Entry;
}

export interface IModifyCrewState {
    crew: Crew[];
    //loungeMembers: LoungeMember[];
}

export class ModifyCrew extends Component<IModifyCrewProps, IModifyCrewState> {
    private apiService: APIService = new APIService;
    public constructor(props: Readonly<IModifyCrewProps>) {
        super(props);
        this.state = {
            crew: [],
            //loungeMembers: [],
        }
        this.updateCrew = this.updateCrew.bind(this);
        this.apiService = new APIService;
    }

    private updateCrew = (crewMembers: Crew[], crew: string): void => {
        const newState = { [crew]: crewMembers } as any;
        this.setState(newState);
    };

    private submitCrew = async () => {
        var crewList: Crew[] = [];
        this.props.car.carRace.forEach(carRace => {
            this.state.crew.filter(x => x.race?.id === carRace.race.id).forEach(crew =>
                {
                    crewList.push(crew);
                })
        });

        await this.apiService.updateCrew(this.props.car.id, crewList).then((result) => {
            if(result.ok){
                this.props.setMessage("Crew Successfully Updated.","success")
                this.props.handleCancel();
            }else{
                this.props.setMessage("Error completing operation. Please try again. (" + result.status + ")", "error");
            }
        })
    }

    public componentDidMount() {
        if (this.props.car.crew !== undefined && this.props.car.crew.length > 0) {
            let crew: Crew[] = [];
            this.props.car.crew.map((value) => {
                crew.push(value);
            });
            this.setState({crew})
        }
        // if(this.props.car.lounge !== undefined && this.props.car.lounge.length > 0){
        //     let loungeMembers : LoungeMember[] = [];
        //     this.props.car.lounge.map((value) =>{
        //         loungeMembers.push(value);
        //     });
        //     this.setState({loungeMembers})
        // }
    }

    public render(): React.ReactElement {
        const { classes, width } = this.props;
        let selectedRaces : Race[] = [];
        this.props.car.carRace.forEach(carRace => {
            selectedRaces.push(carRace.race)
        });
        const validCarsInEntry = this.props.entry.carModel.filter(x => x.status === 'Pending' || x.status === 'Accepted' || x.status === 'Waitlisted' || x.status === 'Draft');
        const selectedCar = validCarsInEntry.find(x => this.props.car !== undefined && x.id === this.props.car.id);
        const maxCrew = selectedCar === undefined ? 
        (validCarsInEntry.length === 0 ? 4 : validCarsInEntry.length === 1 ? 3 : 2) : 
        (validCarsInEntry.indexOf(selectedCar) === 0 ? 4 : validCarsInEntry.indexOf(selectedCar) === 1 ? 3 : 2);

        return (
            <Dialog
                maxWidth={"lg"}
                fullWidth
                fullScreen={width !== "lg" && width !== "xl" && width !== "md"}
                open={this.props.show}
                onClose={this.props.handleCancel}
                aria-labelledby="dialog-title"
                aria-describedby="dialog-description"
                scroll="paper"
            >
                <DialogTitle >
                    Modify Crew for {this.props.car.year + " " + this.props.car.make + " " + this.props.car.model}
                </DialogTitle>
                <DialogContent dividers>
                    <CrewForm
                        setMessage={this.props.setMessage}
                        updateCrew={this.updateCrew}
                        selectedRaces={selectedRaces}
                        event={this.props.event}
                        crew={this.state.crew}
                        maxCrew={maxCrew}
                    />
                </DialogContent>
                <DialogActions>
                    <Grid container spacing={1} className={classes.actions} justify="space-around" alignItems="center">
                        <Grid item xs={6}>
                            <Button className={classes.cancel} onClick={this.props.handleCancel} disabled={this.props.loading} variant="contained" autoFocus>Cancel</Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button className={classes.submit} onClick={this.submitCrew} disabled={this.props.loading} variant="contained" autoFocus>Submit</Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        );
    }
}

const styles = createStyles((theme: Theme) => ({
    center: {
        textAlign: "center"
    },
    actions: {
        textAlign: "center",
        paddingLeft: "0px",
        paddingRight: "0px"
    },
    submit: {
        backgroundColor: green[500],
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        color: 'white',
        '&:hover': {
            backgroundColor: green[700],
        }
    },
    cancel: {
        backgroundColor: red[500],
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        color: 'white',
        '&:hover': {
            backgroundColor: red[700],
        }
    },
}))

export default withWidth()(withStyles(styles, { withTheme: true })(ModifyCrew));