import React, { Component } from 'react';
import deburr from 'lodash/deburr';
import Autosuggest from 'react-autosuggest';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import OptionType from '../interfaces/IOptionType'
import { withStyles, createStyles, Theme } from '@material-ui/core/styles';

export interface AutosuggestProps {
    options: OptionType[];
    label: string;
    name: string;
    disabled?: boolean;
    placeholder?:string;
    onChange:any;
    defaultValue?:string;
    required?: boolean;
    error?:boolean;
    helperText?:string;    
    value?: string;
    classes:any;
    fullWidth?:boolean;
  }

export interface AutosuggestState {
    suggestions: OptionType[],
    renderInputComponent : any,
    onSuggestionsFetchRequested: any,
    onSuggestionsClearRequested: any,
    getSuggestionValue: any,
    renderSuggestion: any,
    anchorEl: HTMLElement | null,
    value?: string,
}

export class IntegrationAutosuggest extends Component<AutosuggestProps, AutosuggestState> {
    public constructor(props: Readonly<AutosuggestProps>) {
        super(props);    
        this.state = {
            suggestions: props.options,
            renderInputComponent : this.renderInputComponent,
            onSuggestionsFetchRequested: this.handleSuggestionsFetchRequested,
            onSuggestionsClearRequested: this.handleSuggestionsClearRequested,
            getSuggestionValue: this.getSuggestionValue,
            renderSuggestion: this.renderSuggestion,
            anchorEl: null,
            value: this.props.value
        }
        this.renderInputComponent = this.renderInputComponent.bind(this);
        this.renderSuggestion = this.renderSuggestion.bind(this);
        this.getSuggestions = this.getSuggestions.bind(this);
        this.getSuggestionValue = this.getSuggestionValue.bind(this);
        this.handleSuggestionsFetchRequested = this.handleSuggestionsFetchRequested.bind(this);
        this.handleSuggestionsClearRequested = this.handleSuggestionsClearRequested.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }    
    
    private renderInputComponent(inputProps: any) : React.ReactElement {
        const { classes, inputRef = () => {}, ref, ...other } = inputProps;      
        return (
          <TextField
            fullWidth={false}
            className={classes.suggestion}
            InputProps={{
              inputRef: node => {
                ref(node);
                inputRef(node);
              },
              classes: {
                input: classes.input,
              },
            }}
            {...other}
          />
        );
      }
      
    private renderSuggestion(suggestion: OptionType,{ query, isHighlighted }: Autosuggest.RenderSuggestionParams,) : React.ReactElement{
        const matches = match(suggestion.label, query);
        const parts = parse(suggestion.label, matches);      
        return (
            <MenuItem selected={isHighlighted} component="div">
            <div>
                {parts.map(part => (
                <span key={part.text} style={{ fontWeight: part.highlight ? "bold" : 400 }}>
                    {part.text}
                </span>
                ))}
            </div>
            </MenuItem>
        );
    }
    
    private getSuggestions(value: string) {
        const inputValue = deburr(value.trim()).toLowerCase();
        const inputLength = inputValue.length;
        let count = 0;      
        return inputLength === 0 ? [] : this.props.options.filter(suggestion => {
                const keep = count < 5 && suggestion.label.slice(0, inputLength).toLowerCase() === inputValue;      
                if (keep) {
                count += 1;
                }      
                return keep;
        });
    }
    
    private getSuggestionValue(suggestion: OptionType) : string {
        return suggestion.label;
    }

    private handleSuggestionsFetchRequested = ({ value }: any) => {
        this.setState({suggestions: this.getSuggestions(value)});
    };
    
    private handleSuggestionsClearRequested = () => {
        this.setState({suggestions: []});
    };

    private handleChange = (event: React.ChangeEvent<HTMLInputElement>,{ newValue }: Autosuggest.ChangeEvent) : void => {
        this.setState({
            ...this.state,
            value: newValue,
          });
          this.props.onChange(this.props.name, newValue);
    };

    public render() : React.ReactElement {        
    const {classes} = this.props;
    const inputProps: any = {
      classes: classes,
      name: this.props.name,
      label: this.props.label,
      placeholder: this.props.placeholder,
      value: (this.props.value === undefined)? "" : this.props.value,
      onChange: this.handleChange,
      disabled: this.props.disabled,
      required: this.props.required,
      error: this.props.error,
      helperText: this.props.helperText,
      fullWidth : this.props.fullWidth
    }
        return (
            <div className={classes.suggestion}>
            <Autosuggest
            {...this.state}
            inputProps={inputProps}
            theme={{
                container: classes.container,
                suggestionsContainerOpen: classes.suggestionsContainerOpen,
                suggestionsList: classes.suggestionsList,
                suggestion: classes.suggestion,
            }}
            renderSuggestionsContainer={options => (
                <Paper {...options.containerProps} square>
                {options.children}
                </Paper>
            )}
            />
            
        </div>);
  }
}
const styles = createStyles((theme: Theme) => ({
    root: {
      height: 250,
      flexGrow: 1,
    },
    container: {
      marginTop: theme.spacing(2),
      position: 'relative',
      width: '100%'
    },
    suggestionsContainerOpen: {
      position: 'absolute',
      zIndex: 1,
      marginTop: theme.spacing(1),
      left: 0,
      right: 0,
      width: 'fit-content'
    },
    suggestionsList: {
      margin: 0,
      padding: 0,
      listStyleType: 'none',
    },
    divider: {
      height: theme.spacing(2),
    },
}))

export default withStyles(styles)(IntegrationAutosuggest);