import React, { Component, ReactNode } from 'react';
import { withStyles, createStyles, Theme } from '@material-ui/core/styles';
import { green, red } from '@material-ui/core/colors';
import { Accordion, AccordionDetails, AccordionSummary, Checkbox, Divider, FormControlLabel, Grid, IconButton, Link, MenuItem, Switch, TextField, Typography } from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import ITrackRental from '../interfaces/ITrackRental';
import EditIcon from '@material-ui/icons/Edit';
import moment from 'moment';
import ITrackCaterer from '../interfaces/ITrackCaterer';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DateRangeIcon from '@material-ui/icons/DateRange';
import ITrackSoundLevel from '../interfaces/ITrackSoundLevel';
import ITrackService from '../interfaces/ITrackService';

export interface ITrackRentalWorksheetFormAdminProps {
    classes: any;
    numberOfVehicles : number;
    ALS : boolean;
    ALSQty : number;
    towTruck : boolean;
    towTruckQty : number;
    rescueTeam2 : boolean;
    rescueTeam3 : boolean;
    fireEngine3 : boolean;
    trackRentalSupervisor : boolean;
    trackRentalSupervisorDays : number;
    starter : boolean;
    starterDays : number;
    marshal : boolean;
    marshalDays : number;
    marshalQty : number;
    soundTester : boolean;
    soundTesterDays : number;
    soundTesterQty : number;
    gridWorker : boolean;
    gridWorkerDays : number;
    gridWorkerQty : number;
    gasAttendant : boolean;
    gasAttendantDays : number;
    gasAttendantQty : number;
    daytimeGateAttendant : boolean;
    daytimeGateAttendantDays : number;
    daytimeGateAttendantQty : number;
    nighttimeGateAttendant : boolean;
    nighttimeGateAttendantDays : number;
    nighttimeGateAttendantQty : number;
    earlyLoadInLateLoadOut : boolean;
    earlyLoadInLateLoadOutDays : number;
    earlyLoadInLateLoadOutQty : number;
    paddockCamping : boolean;
    paddockCampingNights : number;
    paddockCampingQty : number;
    COVID19AdminFee : boolean;
    COVID19AdminFeeDays : number;
    caterer : string;
    estimatedCost : number;
    handleChange: any;
    handleToggle: any;
    selectedRental: ITrackRental;
    editItemPrice: any;
    ALSPrice : number;
    towTruckPrice : number;
    rescueTeam2Price : number;
    rescueTeam3Price : number;
    fireEngine3Price : number;
    trackRentalSupervisorPrice : number;
    starterPrice : number;
    marshalPrice : number;
    soundTesterPrice : number;
    gridWorkerPrice : number;
    gasAttendantPrice : number;
    daytimeGateAttendantPrice : number;
    nighttimeGateAttendantPrice : number;
    earlyLoadInLateLoadOutPrice : number;
    paddockCampingPrice : number;
    COVID19AdminFeePrice : number;
    caterers : ITrackCaterer[];
    garagesRequested : number[];
    garage1to20StartDate: Date;
    garage1to20EndDate: Date;
    garage21to24StartDate: Date;
    garage21to24EndDate: Date;    
    newmanBuilding4Bay: boolean;
    newmanBuilding2Bay: boolean;
    newmanBuilding4BayStartDate: Date;
    newmanBuilding4BayEndDate: Date;
    newmanBuilding2BayStartDate: Date;
    newmanBuilding2BayEndDate: Date;
    stewardsRoom: boolean;
    stewardsRoomStartDate: Date;
    stewardsRoomEndDate: Date;
    timingAndScoring: boolean;
    timingAndScoringStartDate: Date;
    timingAndScoringEndDate: Date;
    garage1to20Price: number;
    garage21to24Price: number;
    newmanBuilding4BayPrice: number;
    newmanBuilding2BayPrice: number;
    stewardsRoomPrice: number;
    timingAndScoringPrice: number;
    handleGarageToggle: any;
    editDates: any;
    garage1to20: boolean;
    garage21to24: boolean;
    soundLevels: ITrackSoundLevel[];
    trackServices: ITrackService[];
}

export interface ITrackRentalWorksheetFormAdminState {
    _isMounted: boolean;    
    estimatedCost: number;
    g1price: number;
    g2price: number;
}

export class TrackRentalWorksheetFormAdmin extends Component<ITrackRentalWorksheetFormAdminProps, ITrackRentalWorksheetFormAdminState> {
    static displayName = TrackRentalWorksheetFormAdmin.name;
    public constructor(props: Readonly<ITrackRentalWorksheetFormAdminProps>) {
        super(props);
        this.state = {
            _isMounted: false,
            estimatedCost: 0,
            g1price: 0,
            g2price: 0
        };
    }

    private estimateCost() {
        var estimatedCost : number;
        estimatedCost = (this.props.ALSQty * this.props.ALSPrice) + 
        (this.props.towTruckQty * this.props.towTruckPrice) +
        (this.props.trackRentalSupervisorDays * this.props.trackRentalSupervisorPrice) +
        (this.props.starterDays * this.props.starterPrice) +
        (this.props.marshalDays * this.props.marshalQty * this.props.marshalPrice) +
        (this.props.soundTesterDays * this.props.soundTesterQty * this.props.soundTesterPrice) +
        (this.props.gridWorkerDays * this.props.gridWorkerQty * this.props.gridWorkerPrice) +
        (this.props.gasAttendantDays * this.props.gasAttendantQty * this.props.gasAttendantPrice) +
        (this.props.daytimeGateAttendantDays * this.props.daytimeGateAttendantQty * this.props.daytimeGateAttendantPrice) +
        (this.props.nighttimeGateAttendantDays * this.props.nighttimeGateAttendantQty * this.props.nighttimeGateAttendantPrice) +
        (this.props.earlyLoadInLateLoadOutQty * this.props.earlyLoadInLateLoadOutPrice * this.props.earlyLoadInLateLoadOutQty) +
        (this.props.paddockCampingNights * this.props.paddockCampingQty * this.props.paddockCampingPrice) +
        (this.props.COVID19AdminFeeDays * this.props.COVID19AdminFeePrice);
        estimatedCost += this.props.selectedRental.soundLevelPrice;
        if(this.props.rescueTeam2){
            estimatedCost += Number(this.props.rescueTeam2Price);
        }
        if(this.props.rescueTeam3){
            estimatedCost += Number(this.props.rescueTeam3Price);
        }
        if(this.props.fireEngine3){
            estimatedCost += Number(this.props.fireEngine3Price);
        }
        
        let price = 0;
        let group1 : number[] = [];
        let group2 : number[] = [];
        for (var i = 1; i <= 24; i++) {
            if(this.props.garagesRequested.includes(i)){
                if(i <=20){
                    if(this.props.garage1to20){
                        group1.push(i);
                    }
                }
                else if (i <= 24){
                    if(this.props.garage21to24){
                        group2.push(i);
                    }
                }
            }
        }
        price = 0;
        if(this.props.garage1to20){
            if(this.props.garage1to20StartDate !== null && this.props.garage1to20EndDate !== null){
                let a = moment(this.props.garage1to20StartDate);
                let b = moment(this.props.garage1to20EndDate);
                if (group1.length > 9){
                    price = group1.length * (this.props.garage1to20Price - 50) * (b.diff(a, 'days') + 1);
                } else{
                    price = group1.length * (this.props.garage1to20Price) * (b.diff(a, 'days') + 1);
                }

            }
        }
        estimatedCost += price;
        this.setState({g1price:price});
        price = 0;
        if(this.props.garage21to24){
            if (this.props.garage21to24StartDate !== null && this.props.garage21to24EndDate !== null){
                let a = moment(this.props.garage21to24StartDate);
                let b = moment(this.props.garage21to24EndDate);
                price = group2.length * (this.props.garage21to24Price) * (b.diff(a, 'days') + 1);
            }
        }
        estimatedCost += price;

        this.setState({g2price:price});
        if(this.props.newmanBuilding4Bay){
            estimatedCost += (this.props.newmanBuilding4BayPrice) * (moment(this.props.newmanBuilding4BayEndDate).diff(moment(this.props.newmanBuilding4BayStartDate), 'days') + 1);
        }
        if(this.props.newmanBuilding2Bay){
            estimatedCost += (this.props.newmanBuilding2BayPrice) * (moment(this.props.newmanBuilding2BayEndDate).diff(moment(this.props.newmanBuilding2BayStartDate), 'days') + 1);
        }
        if(this.props.stewardsRoom){
            estimatedCost += (this.props.stewardsRoomPrice) * (moment(this.props.stewardsRoomEndDate).diff(moment(this.props.stewardsRoomStartDate), 'days') + 1);
        }
        if(this.props.timingAndScoring){
            estimatedCost += (this.props.timingAndScoringPrice) * (moment(this.props.timingAndScoringEndDate).diff(moment(this.props.timingAndScoringStartDate), 'days') + 1);
        }
        this.setState({estimatedCost});
    };

    public renderCheckboxes(start : number, end : number){
        var elements : ReactNode[] = [];
        for (var i = start; i <= end; i++) {
            elements.push(
                <Grid item xs={3} key={i}>
                    <FormControlLabel
                        control={<Checkbox checked={this.props.garagesRequested.includes(i)} onClick={this.props.handleGarageToggle} name={`${i}`} />}
                        label={`#${i}`}
                    />
                </Grid>
            )
        }
        return elements;
    };

    public componentDidMount() {
        this.estimateCost();
    }
    public componentDidUpdate(prevProps: ITrackRentalWorksheetFormAdminProps) {
        if(prevProps !== this.props){
            this.estimateCost();
        }
    }
    public render(): React.ReactElement<ITrackRentalWorksheetFormAdminProps> {
        const { classes } = this.props;
        return (
            <React.Fragment>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h5">Rental Information</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography>Track Rental Date(s): {moment(this.props.selectedRental.startDate).format('MMMM Do YYYY')} - {moment(this.props.selectedRental.endDate).format('MMMM Do YYYY')}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography>Description of Track Activity: {this.props.selectedRental.description}</Typography>
                </Grid>
                <Grid item xs={10}>
                    <Typography>Track Rental Fee ({this.props.soundLevels.filter(x => {return (x.id === this.props.selectedRental.soundLevel)})[0].name})</Typography>
                </Grid>
                <Grid item xs={2}>
                    <Typography>${this.props.selectedRental.soundLevelPrice}</Typography>
                </Grid>
                <Grid item xs={8}>
                    <Typography>Number of Vehicles per Day</Typography>
                    <Typography variant="caption">(maximum 35 on track at one time)</Typography>
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        name="numberOfVehicles"
                        label="Quantity"
                        required
                        className={classes.textField}
                        value={this.props.numberOfVehicles}
                        onChange={this.props.handleChange}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Typography>Gate Hours: 6:30AM – 6:00PM</Typography>
                    <Typography variant="caption">Gate Attendant is required when gates are open</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography>Track Hours: 9:00AM – 5:00PM</Typography>
                    <Typography variant="caption">No engines may be started before 7:30am or after 6:00pm</Typography>
                </Grid>
                <Grid item xs={12}>
                </Grid>
                <Grid item xs={12} className={classes.center}>
                    <Link href="https://www.co.monterey.ca.us/Home/ShowDocument?id=98744" target="_new">View Track Rental Information Packet<OpenInNewIcon fontSize="small"/></Link>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h5">Services</Typography>
                    <Typography variant="caption">Market Rate applies to the following services. Per day/overtime rate applies for anything in excess of 8 hours.</Typography>
                </Grid>
                {(!this.props.trackServices.find(x => {return x.name === "ALS"})?.disabled || this.props.ALS) && 
                    <>
                        <Grid item xs={6}>
                            <FormControlLabel
                                control={<Switch name="ALS" disabled checked={this.props.ALS} value={this.props.ALS} onChange={this.props.handleToggle}/>}
                                label={<>ALS (Paramedic) Ambulance Service<sup>1</sup></>}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Typography>${this.props.ALSPrice}     
                            <IconButton aria-label="edit" className={classes.margin} onClick={() => this.props.editItemPrice("ALSPrice")}>
                                <EditIcon />
                            </IconButton>
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                name="ALSQty"
                                label="Quantity"
                                required
                                className={classes.textField}
                                value={this.props.ALSQty}
                                onChange={this.props.handleChange}
                                disabled={!this.props.ALS}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Typography>${this.props.ALSQty * this.props.ALSPrice}</Typography>
                        </Grid>
                    </>
                }
                {(!this.props.trackServices.find(x => {return x.name === "TowTruck"})?.disabled || this.props.towTruck) && 
                    <>
                        <Grid item xs={6}>                    
                            <FormControlLabel
                                control={<Switch name="towTruck" checked={this.props.towTruck}  value={this.props.towTruck} onChange={this.props.handleToggle}/>}
                                label={<>Tow Truck (Flatbed)<sup>2</sup></>}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Typography>${this.props.towTruckPrice}   
                            <IconButton aria-label="edit" className={classes.margin} onClick={() => this.props.editItemPrice("towTruckPrice")}>
                                <EditIcon />
                            </IconButton>
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                name="towTruckQty"
                                label="Quantity"
                                required
                                className={classes.textField}
                                value={this.props.towTruckQty}
                                onChange={this.props.handleChange}
                                disabled={!this.props.towTruck}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Typography>${this.props.towTruckQty * this.props.towTruckPrice}</Typography>
                        </Grid>
                    </>
                }
                <Grid item xs={12}>
                <Typography variant="caption"><sup>1</sup>Mandatory for all rentals.</Typography>
                <Typography variant="caption"><sup>2</sup>Mandatory for all rentals except Motorcycles and exempted Media.</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6">Emergency Response Rescue – Fire / Jaws</Typography>
                </Grid>
                {(!this.props.trackServices.find(x => {return x.name === "RescueTruck2"})?.disabled || this.props.rescueTeam2) && 
                    <>
                        <Grid item xs={6}>                    
                            <FormControlLabel
                                control={<Switch name="rescueTeam2" checked={this.props.rescueTeam2} value={this.props.rescueTeam2} onChange={this.props.handleToggle}/>}
                                label="Rescue Truck with 2-man crew"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Typography>${this.props.rescueTeam2Price} 
                            <IconButton aria-label="edit" className={classes.margin} onClick={() => this.props.editItemPrice("rescueTeam2Price")}>
                                <EditIcon />
                            </IconButton>
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography>${this.props.rescueTeam2 ?  this.props.rescueTeam2Price : 0}</Typography>
                        </Grid>
                    </>
                }
                {(!this.props.trackServices.find(x => {return x.name === "RescueTruck3"})?.disabled || this.props.rescueTeam3) && 
                    <>
                        <Grid item xs={6}>                    
                            <FormControlLabel
                                control={<Switch name="rescueTeam3" checked={this.props.rescueTeam3} value={this.props.rescueTeam3} onChange={this.props.handleToggle}/>}
                                label="Rescue Truck with 3-man crew"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Typography>${this.props.rescueTeam3Price}    
                            <IconButton aria-label="edit" className={classes.margin} onClick={() => this.props.editItemPrice("rescueTeam3Price")}>
                                <EditIcon />
                            </IconButton>
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography>${this.props.rescueTeam3 ?  this.props.rescueTeam3Price : 0}</Typography>
                        </Grid>
                    </>
                }
                {(!this.props.trackServices.find(x => {return x.name === "FireEngine3"})?.disabled || this.props.fireEngine3) && 
                    <>
                        <Grid item xs={6}>                    
                            <FormControlLabel
                                control={<Switch name="fireEngine3" checked={this.props.fireEngine3} value={this.props.fireEngine3} onChange={this.props.handleToggle}/>}
                                label="Fire Engine with 3-man crew "
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Typography>${this.props.fireEngine3Price}       
                            <IconButton aria-label="edit" className={classes.margin} onClick={() => this.props.editItemPrice("fireEngine3Price")}>
                                <EditIcon />
                            </IconButton>
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography>${this.props.fireEngine3 ?  this.props.fireEngine3Price : 0}</Typography>
                        </Grid>
                    </>
                }
                <Grid item xs={12}>
                    <Typography variant="subtitle1"><strong>Additional Items</strong></Typography>
                    <Typography variant="body1">Fee information for rental radios (in addition to the six (6) provided), I.T. services / connectivity, electrical services / connectivity, facility building rental, etc., is available upon request.</Typography>
                    <Typography variant="subtitle1"><strong>Insurance Carrier</strong></Typography>                    
                    <Typography variant="body1">Customer insurance coverage must meet limits and include a Certificate of Insurance per Track Fee sheet, submitted two (2) weeks in advance of your track day and paid for by the renter directly.</Typography>
                    <Typography variant="subtitle1"><strong>Water Truck</strong></Typography>
                    <Typography variant="body1">A water truck is mandatory for use in the Skid Pad in the Paddock, except with the use of specialized drift equipment, or if specialized tires are utilized to eliminate surface damage.</Typography>
                    <Typography variant="subtitle1"><strong>Waste/Recycling Services</strong></Typography>
                    <Typography variant="body1">For groups in excess of 60 participants, you will be required to contract with Waste/Recycling Services to maintain the Paddock as required by COVID-19 protocols. Please contact Matt Fedorko at 469-964-3147, or by email at matt@bluestrikeenvironmental.com.</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h5">Track Safety Personnel</Typography>
                    <Typography variant="caption">All Track Safety Personnel require a 1/2 hour lunch break and anything over eight (8) hours will be charged at overtime rate</Typography>
                </Grid>
                {(!this.props.trackServices.find(x => {return x.name === "TrackRentalSupervisor"})?.disabled || this.props.trackRentalSupervisor) && 
                    <>
                        <Grid item xs={6}>                    
                            <FormControlLabel
                                control={<Switch name="trackRentalSupervisor" disabled checked={this.props.trackRentalSupervisor} value={this.props.trackRentalSupervisor} onChange={this.props.handleToggle}/>}
                                label="Track Rental Supervisor"
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Typography>${this.props.trackRentalSupervisorPrice}/day      
                            <IconButton aria-label="edit" className={classes.margin} onClick={() => this.props.editItemPrice("trackRentalSupervisorPrice")}>
                                <EditIcon />
                            </IconButton>
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                name="trackRentalSupervisorDays"
                                label="Days"
                                required
                                className={classes.textField}
                                value={this.props.trackRentalSupervisorDays}
                                onChange={this.props.handleChange}
                                disabled={!this.props.trackRentalSupervisor}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Typography>${this.props.trackRentalSupervisorDays * this.props.trackRentalSupervisorPrice}</Typography>
                        </Grid>
                    </>
                }
                {(!this.props.trackServices.find(x => {return x.name === "Starter"})?.disabled || this.props.starter) && 
                    <>
                        <Grid item xs={6}>                    
                            <FormControlLabel
                                control={<Switch name="starter" checked={this.props.starter} value={this.props.starter} onChange={this.props.handleToggle}/>}
                                label="Starter"
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Typography>${this.props.starterPrice}/day    
                            <IconButton aria-label="edit" className={classes.margin} onClick={() => this.props.editItemPrice("starterPrice")}>
                                <EditIcon />
                            </IconButton>
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                name="starterDays"
                                label="Days"
                                required
                                className={classes.textField}
                                value={this.props.starterDays}
                                onChange={this.props.handleChange}
                                disabled={!this.props.starter}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Typography>${this.props.starterDays * this.props.starterPrice}</Typography>
                        </Grid>
                    </>
                }
                {(!this.props.trackServices.find(x => {return x.name === "Marshal"})?.disabled || this.props.marshal) && 
                    <>
                        <Grid item xs={6}>                    
                            <FormControlLabel
                                control={<Switch name="marshal" disabled checked={this.props.marshal} value={this.props.marshal} onChange={this.props.handleToggle}/>}
                                label={<><Typography>Course Marshal</Typography>
                                    <Typography variant="caption">LSRA Course Marshals are required. No outside Marshals allowed.</Typography><br/>
                                    <Typography variant="caption">A full complement of 10 corner workers is recommended, with 6 being required.</Typography><br/>
                                    <Typography variant="caption">If renter chooses not to have the full complement of corners covered, the renter is required to designate which turns they will leave unmanned and accepts full liability.</Typography> </>}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Typography>${this.props.marshalPrice}/day      
                            <IconButton aria-label="edit" className={classes.margin} onClick={() => this.props.editItemPrice("marshalPrice")}>
                                <EditIcon />
                            </IconButton>
                            </Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <TextField
                                name="marshalDays"
                                label="Days"
                                required
                                className={classes.textField}
                                value={this.props.marshalDays}
                                onChange={this.props.handleChange}
                                disabled={!this.props.marshal}
                            />
                        </Grid>
                        <Grid item xs={1}>
                            <TextField
                                name="marshalQty"
                                label="Quantity"
                                required
                                className={classes.textField}
                                value={this.props.marshalQty}
                                onChange={this.props.handleChange}
                                disabled={!this.props.marshal}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Typography>${this.props.marshalDays * this.props.marshalQty * this.props.marshalPrice}</Typography>
                        </Grid>
                    </>
                }
                {(!this.props.trackServices.find(x => {return x.name === "SoundTester"})?.disabled || this.props.soundTester) && 
                    <>
                        <Grid item xs={6}>                    
                            <FormControlLabel
                                control={<Switch name="soundTester" checked={this.props.soundTester} value={this.props.soundTester} onChange={this.props.handleToggle}/>}
                                label={<><Typography>Sound Tester</Typography> 
                                    <Typography variant="caption">If renter is motorcycles or more than 35 cars per session, two (2) Sound Testers are required.</Typography></>}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Typography>${this.props.soundTesterPrice}/day
                            <IconButton aria-label="edit" className={classes.margin} onClick={() => this.props.editItemPrice("soundTesterPrice")}>
                                <EditIcon />
                            </IconButton>
                            </Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <TextField
                                name="soundTesterDays"
                                label="Days"
                                required
                                className={classes.textField}
                                value={this.props.soundTesterDays}
                                onChange={this.props.handleChange}
                                disabled={!this.props.soundTester}
                            />
                        </Grid>
                        <Grid item xs={1}>
                            <TextField
                                name="soundTesterQty"
                                label="Quantity"
                                required
                                className={classes.textField}
                                value={this.props.soundTesterQty}
                                onChange={this.props.handleChange}
                                disabled={!this.props.soundTester}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Typography>${this.props.soundTesterDays * this.props.soundTesterQty * this.props.soundTesterPrice}</Typography>
                        </Grid>
                    </>
                }
                {(!this.props.trackServices.find(x => {return x.name === "GridWorker"})?.disabled || this.props.gridWorker) && 
                    <>
                        <Grid item xs={6}>                    
                            <FormControlLabel
                                control={<Switch name="gridWorker" checked={this.props.gridWorker} value={this.props.gridWorker} onChange={this.props.handleToggle}/>}
                                label="Grid Worker"
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Typography>${this.props.gridWorkerPrice}/day      
                            <IconButton aria-label="edit" className={classes.margin} onClick={() => this.props.editItemPrice("gridWorkerPrice")}>
                                <EditIcon />
                            </IconButton>
                            </Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <TextField
                                name="gridWorkerDays"
                                label="Days"
                                required
                                className={classes.textField}
                                value={this.props.gridWorkerDays}
                                onChange={this.props.handleChange}
                                disabled={!this.props.gridWorker}
                            />
                        </Grid>
                        <Grid item xs={1}>
                            <TextField
                                name="gridWorkerQty"
                                label="Quantity"
                                required
                                className={classes.textField}
                                value={this.props.gridWorkerQty}
                                onChange={this.props.handleChange}
                                disabled={!this.props.gridWorker}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Typography>${this.props.gridWorkerDays * this.props.gridWorkerQty * this.props.gridWorkerPrice}</Typography>
                        </Grid>
                    </>
                }
                {(!this.props.trackServices.find(x => {return x.name === "GasAttendant"})?.disabled || this.props.gasAttendant) && 
                    <>
                        <Grid item xs={6}>                    
                            <FormControlLabel
                                control={<Switch name="gasAttendant" checked={this.props.gasAttendant} value={this.props.gasAttendant} onChange={this.props.handleToggle}/>}
                                label="Gas Attendant"
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Typography>${this.props.gasAttendantPrice}/day      
                            <IconButton aria-label="edit" className={classes.margin} onClick={() => this.props.editItemPrice("gasAttendantPrice")}>
                                <EditIcon />
                            </IconButton>
                            </Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <TextField
                                name="gasAttendantDays"
                                label="Days"
                                required
                                className={classes.textField}
                                value={this.props.gasAttendantDays}
                                onChange={this.props.handleChange}
                                disabled={!this.props.gasAttendant}
                            />
                        </Grid>
                        <Grid item xs={1}>
                            <TextField
                                name="gasAttendantQty"
                                label="Quantity"
                                required
                                className={classes.textField}
                                value={this.props.gasAttendantQty}
                                onChange={this.props.handleChange}
                                disabled={!this.props.gasAttendant}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Typography>${this.props.gasAttendantDays * this.props.gasAttendantQty * this.props.gasAttendantPrice}</Typography>
                        </Grid>
                    </>
                }
                {(!this.props.trackServices.find(x => {return x.name === "DaytimeGateAttendant"})?.disabled || this.props.daytimeGateAttendant) && 
                    <>
                        <Grid item xs={6}>                    
                            <FormControlLabel
                                control={<Switch name="daytimeGateAttendant" checked={this.props.daytimeGateAttendant} value={this.props.daytimeGateAttendant} onChange={this.props.handleToggle}/>}
                                label="Daytime Gate Attendant (6:30am-6:00pm)"
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Typography>${this.props.daytimeGateAttendantPrice}/day      
                            <IconButton aria-label="edit" className={classes.margin} onClick={() => this.props.editItemPrice("daytimeGateAttendantPrice")}>
                                <EditIcon />
                            </IconButton>
                            </Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <TextField
                                name="daytimeGateAttendantDays"
                                label="Days"
                                required
                                className={classes.textField}
                                value={this.props.daytimeGateAttendantDays}
                                onChange={this.props.handleChange}
                                disabled={!this.props.daytimeGateAttendant}
                            />
                        </Grid>
                        <Grid item xs={1}>
                            <TextField
                                name="daytimeGateAttendantQty"
                                label="Quantity"
                                required
                                className={classes.textField}
                                value={this.props.daytimeGateAttendantQty}
                                onChange={this.props.handleChange}
                                disabled={!this.props.daytimeGateAttendant}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Typography>${this.props.daytimeGateAttendantDays * this.props.daytimeGateAttendantQty * this.props.daytimeGateAttendantPrice}</Typography>
                        </Grid>
                    </>
                }
                {(!this.props.trackServices.find(x => {return x.name === "NighttimeGateAttendant"})?.disabled || this.props.nighttimeGateAttendant) && 
                    <>
                        <Grid item xs={6}>
                            <FormControlLabel
                                control={<Switch name="nighttimeGateAttendant" checked={this.props.nighttimeGateAttendant} value={this.props.nighttimeGateAttendant} onChange={this.props.handleToggle}/>}
                                label="Nighttime Gate Attendant (6:00pm-6:30am)"
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Typography>${this.props.nighttimeGateAttendantPrice}/day        
                            <IconButton aria-label="edit" className={classes.margin} onClick={() => this.props.editItemPrice("nighttimeGateAttendantPrice")}>
                                <EditIcon />
                            </IconButton>
                            </Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <TextField
                                name="nighttimeGateAttendantDays"
                                label="Days"
                                required
                                className={classes.textField}
                                value={this.props.nighttimeGateAttendantDays}
                                onChange={this.props.handleChange}
                                disabled={!this.props.nighttimeGateAttendant}
                            />
                        </Grid>
                        <Grid item xs={1}>
                            <TextField
                                name="nighttimeGateAttendantQty"
                                label="Quantity"
                                required
                                className={classes.textField}
                                value={this.props.nighttimeGateAttendantQty}
                                onChange={this.props.handleChange}
                                disabled={!this.props.nighttimeGateAttendant}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Typography>${this.props.nighttimeGateAttendantDays * this.props.nighttimeGateAttendantQty * this.props.nighttimeGateAttendantPrice}</Typography>
                        </Grid>
                    </>
                }
                {(!this.props.trackServices.find(x => {return x.name === "EarlyLoadInLateLoadOut"})?.disabled || this.props.earlyLoadInLateLoadOut) && 
                    <>
                        <Grid item xs={6}>
                            <FormControlLabel
                                control={<Switch name="earlyLoadInLateLoadOut" checked={this.props.earlyLoadInLateLoadOut} value={this.props.earlyLoadInLateLoadOut} onChange={this.props.handleToggle}/>}
                                label="Early Load-In and Late Load-Outs"
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Typography>${this.props.earlyLoadInLateLoadOutPrice}/hour     
                            <IconButton aria-label="edit" className={classes.margin} onClick={() => this.props.editItemPrice("earlyLoadInLateLoadOutPrice")}>
                                <EditIcon />
                            </IconButton>
                            </Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <TextField
                                name="earlyLoadInLateLoadOutDays"
                                label="Days"
                                required
                                className={classes.textField}
                                value={this.props.earlyLoadInLateLoadOutDays}
                                onChange={this.props.handleChange}
                                disabled={!this.props.earlyLoadInLateLoadOut}
                            />
                        </Grid>
                        <Grid item xs={1}>
                            <TextField
                                name="earlyLoadInLateLoadOutQty"
                                label="Hours"
                                required
                                className={classes.textField}
                                value={this.props.earlyLoadInLateLoadOutQty}
                                onChange={this.props.handleChange}
                                disabled={!this.props.earlyLoadInLateLoadOut}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Typography>${this.props.earlyLoadInLateLoadOutDays * this.props.earlyLoadInLateLoadOutQty * this.props.earlyLoadInLateLoadOutPrice}</Typography>
                        </Grid>
                    </>
                }
                {(!this.props.trackServices.find(x => {return x.name === "PaddockCamping"})?.disabled || this.props.paddockCamping) && 
                    <>
                        <Grid item xs={6}>
                            <FormControlLabel
                                control={<Switch name="paddockCamping" checked={this.props.paddockCamping} value={this.props.paddockCamping} onChange={this.props.handleToggle}/>}
                                label="Paddock Camping"
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Typography>${this.props.paddockCampingPrice}/night       
                            <IconButton aria-label="edit" className={classes.margin} onClick={() => this.props.editItemPrice("paddockCampingPrice")}>
                                <EditIcon />
                            </IconButton>
                            </Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <TextField
                                name="paddockCampingNights"
                                label="Days"
                                required
                                className={classes.textField}
                                value={this.props.paddockCampingNights}
                                onChange={this.props.handleChange}
                                disabled={!this.props.paddockCamping}
                            />
                        </Grid>
                        <Grid item xs={1}>
                            <TextField
                                name="paddockCampingQty"
                                label="Quantity"
                                required
                                className={classes.textField}
                                value={this.props.paddockCampingQty}
                                onChange={this.props.handleChange}
                                disabled={!this.props.paddockCamping}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Typography>${this.props.paddockCampingNights * this.props.paddockCampingQty * this.props.paddockCampingPrice}</Typography>
                        </Grid>
                    </>
                }
                {(!this.props.trackServices.find(x => {return x.name === "COVID19AdminFee"})?.disabled || this.props.COVID19AdminFee) && 
                    <>
                        <Grid item xs={6}>                    
                            <FormControlLabel
                                control={<Switch name="COVID19AdminFee" disabled checked={this.props.COVID19AdminFee} value={this.props.COVID19AdminFee} onChange={this.props.handleToggle}/>}
                                label={<><Typography>COVID-19 Administrative Fee</Typography> 
                                <Typography variant="caption">Due to the protocols required given the COVID-19 challenges, renters with groups larger than 60 participants per day will be charged an Administrative Fee of $450 per day 
                                for 8 hours of security patrol and additional support at Paddock check-in.</Typography></>}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Typography>${this.props.COVID19AdminFeePrice}/day        
                            <IconButton aria-label="edit" className={classes.margin} onClick={() => this.props.editItemPrice("COVID19AdminFeePrice")}>
                                <EditIcon />
                            </IconButton>
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                name="COVID19AdminFeeDays"
                                label="Days"
                                required
                                className={classes.textField}
                                value={this.props.COVID19AdminFeeDays}
                                onChange={this.props.handleChange}
                                disabled={!this.props.COVID19AdminFee}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Typography>${this.props.COVID19AdminFeeDays * this.props.COVID19AdminFeePrice}</Typography>
                        </Grid>
                    </>
                }
                <Grid item xs={8}>
                    <Typography>What Track Approved Caterer are you using?</Typography>
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        name="caterer"
                        required
                        select
                        fullWidth
                        label="Selected Caterer"
                        className={classes.textField}
                        value={(this.props.caterer === null) ? "" : this.props.caterer}
                        onChange={this.props.handleChange}
                        SelectProps={{
                        MenuProps: {
                            className: classes.menu,
                        },
                        }}
                        margin="normal"
                        >   
                        {this.props.caterers.map((option : ITrackCaterer) => (
                            <MenuItem key={option.id} value={option.name}>
                                {option.name}
                            </MenuItem>
                        ))}
                        <MenuItem key={0} value={0}>None</MenuItem>
                    </TextField>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h5">Additional Forms</Typography>
                    <Accordion>
                        <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        >
                        <Typography variant="h6">Garage Rental</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={<Switch name="garage1to20" checked={this.props.garage1to20} value={this.props.garage1to20} onChange={this.props.handleToggle}/>}
                                    label={<>
                                        <Typography variant="h6">Garages #1 - #20</Typography>
                                        <Typography>Each garage bay is 16’ wide x 32’ deep. $250 per garage bay per day, or $2,000 for 10 bays, per day, or $4,000 for 20 bays, per day.</Typography>
                                        </>}
                                />
                            </Grid>
                            {this.props.garage1to20 && 
                            <>
                            <Grid item xs={12}>
                            <Typography>Dates requested: {(this.props.garage1to20StartDate === null || this.props.garage1to20EndDate === null) ? "No Dates Selected" :
                                moment(this.props.garage1to20StartDate).format('MMMM Do YYYY') + "-" + moment(this.props.garage1to20EndDate).format('MMMM Do YYYY')}
                            <IconButton aria-label="edit" className={classes.margin} onClick={() => this.props.editDates("garage1to20")}>
                                <DateRangeIcon />
                            </IconButton>
                            </Typography>
                            </Grid>
                            <Grid item xs={12} className={classes.center} >
                            <Typography variant="h6">Garage Bay(s) Requested</Typography>
                            </Grid>
                            {this.renderCheckboxes(1, 20)}
                            <Grid item xs={10}>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography>${this.state.g1price}</Typography>
                            </Grid>
                            </>
                            }
                            <Grid item xs={12}>
                                <Divider/>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={<Switch name="garage21to24" checked={this.props.garage21to24} value={this.props.garage21to24} onChange={this.props.handleToggle}/>}
                                    label={<><Typography variant="h6">Garages #21 - #24</Typography>
                                        <Typography>$250 for (1) or $1,000 for (4) per day</Typography></>}
                                />
                            </Grid>
                            {this.props.garage21to24 && 
                                <>
                                <Grid item xs={12}>
                                <Typography>Dates requested: {(this.props.garage21to24StartDate === null || this.props.garage21to24EndDate === null) ? "No Dates Selected" :
                                    moment(this.props.garage21to24StartDate).format('MMMM Do YYYY') + "-" + moment(this.props.garage21to24EndDate).format('MMMM Do YYYY')}
                                <IconButton aria-label="edit" className={classes.margin} onClick={() => this.props.editDates("garage21to24")}>
                                    <DateRangeIcon />
                                </IconButton>
                                </Typography>
                                </Grid>
                                <Grid item xs={12} className={classes.center} >
                                </Grid>
                                {this.renderCheckboxes(21, 24)}
                                <Grid item xs={10}>
                                </Grid>
                                <Grid item xs={2}>
                                <Typography>${this.state.g2price}</Typography>
                                </Grid>
                                </>
                            }
                            <Grid item xs={12}>
                                <Divider/>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={<Switch name="newmanBuilding4Bay" checked={this.props.newmanBuilding4Bay} value={this.props.newmanBuilding4Bay} onChange={this.props.handleToggle}/>}
                                    label={<>
                                    <Typography variant="h6">Newman Building 4-Bay Garage</Typography>
                                    <Typography>${this.props.newmanBuilding4BayPrice}/day</Typography>
                                    </>}
                                />
                            </Grid>
                            {this.props.newmanBuilding4Bay && 
                            <>
                                <Grid item xs={10}>
                                    <Typography>Dates requested: {(this.props.newmanBuilding4BayStartDate === null || this.props.newmanBuilding4BayEndDate === null) ? "No Dates Selected" :
                                        moment(this.props.newmanBuilding4BayStartDate).format('MMMM Do YYYY') + "-" + moment(this.props.newmanBuilding4BayEndDate).format('MMMM Do YYYY')}
                                    <IconButton aria-label="edit" className={classes.margin} onClick={() => this.props.editDates("newmanBuilding4Bay")} disabled={!this.props.newmanBuilding4Bay}>
                                        <DateRangeIcon />
                                    </IconButton>
                                    </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography>${(this.props.newmanBuilding4BayStartDate !== null || this.props.newmanBuilding4BayEndDate !== null) ? 
                                    (this.props.newmanBuilding4BayPrice) * (moment(this.props.newmanBuilding4BayEndDate).diff(moment(this.props.newmanBuilding4BayStartDate), 'days') + 1) : 0
                                    }</Typography>
                                </Grid>
                            </>
                            }
                            <Grid item xs={12}>
                                <Divider/>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={<Switch name="newmanBuilding2Bay" checked={this.props.newmanBuilding2Bay} value={this.props.newmanBuilding2Bay} onChange={this.props.handleToggle}/>}
                                    label={<>
                                        <Typography variant="h6">Newman Building 2-Bay Garage</Typography>
                                        <Typography>${this.props.newmanBuilding2BayPrice}/day</Typography>
                                        </>}
                                />
                            </Grid>
                            {this.props.newmanBuilding2Bay && 
                            <>
                                <Grid item xs={10}>
                                <Typography>Dates requested: {(this.props.newmanBuilding2BayStartDate === null || this.props.newmanBuilding2BayEndDate === null) ? "No Dates Selected" :
                                    moment(this.props.newmanBuilding2BayStartDate).format('MMMM Do YYYY') + "-" + moment(this.props.newmanBuilding2BayEndDate).format('MMMM Do YYYY')}
                                <IconButton aria-label="edit" className={classes.margin} onClick={() => this.props.editDates("newmanBuilding2Bay")} disabled={!this.props.newmanBuilding2Bay}>
                                    <DateRangeIcon />
                                </IconButton>
                                </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography>${(this.props.newmanBuilding2BayStartDate !== null || this.props.newmanBuilding2BayEndDate !== null) ? 
                                    (this.props.newmanBuilding2BayPrice) * (moment(this.props.newmanBuilding2BayEndDate).diff(moment(this.props.newmanBuilding2BayStartDate), 'days') + 1) : 0
                                    }</Typography>
                                </Grid>
                                </>
                            }
                            <Grid item xs={12}>
                                <Divider/>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={<Switch name="stewardsRoom" checked={this.props.stewardsRoom} value={this.props.stewardsRoom} onChange={this.props.handleToggle}/>}
                                    label={<>
                                        <Typography variant="h6">Stewards Room</Typography>
                                        <Typography>${this.props.stewardsRoomPrice}/day</Typography>
                                        </>}
                                />
                            </Grid>
                            {this.props.stewardsRoom && 
                                <>
                                    <Grid item xs={10}>
                                    <Typography>Dates requested: {(this.props.stewardsRoomStartDate === null || this.props.stewardsRoomEndDate === null) ? "No Dates Selected" :
                                        moment(this.props.stewardsRoomStartDate).format('MMMM Do YYYY') + "-" + moment(this.props.stewardsRoomEndDate).format('MMMM Do YYYY')}
                                    <IconButton aria-label="edit" className={classes.margin} onClick={() => this.props.editDates("stewardsRoom")} disabled={!this.props.stewardsRoom}>
                                        <DateRangeIcon />
                                    </IconButton>
                                    </Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                    <Typography>${(this.props.stewardsRoomStartDate !== null || this.props.stewardsRoomEndDate !== null) ? 
                                    (this.props.stewardsRoomPrice) * (moment(this.props.stewardsRoomEndDate).diff(moment(this.props.stewardsRoomStartDate), 'days') + 1) : 0
                                    }</Typography>
                                    </Grid>
                                </>
                            }
                            <Grid item xs={12}>
                                <Divider/>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={<Switch name="timingAndScoring" checked={this.props.timingAndScoring} value={this.props.timingAndScoring} onChange={this.props.handleToggle}/>}
                                    label={<>
                                        <Typography variant="h6">Timing &amp; Scoring Building</Typography>
                                        <Typography>${this.props.timingAndScoringPrice}/day</Typography>
                                        </>}
                                />
                            </Grid>
                            {this.props.timingAndScoring && 
                                <>
                                    <Grid item xs={10}>
                                    <Typography>Dates requested: {(this.props.timingAndScoringStartDate === null || this.props.timingAndScoringEndDate === null) ? "No Dates Selected" :
                                        moment(this.props.timingAndScoringStartDate).format('MMMM Do YYYY') + "-" + moment(this.props.timingAndScoringEndDate).format('MMMM Do YYYY')}
                                    <IconButton aria-label="edit" className={classes.margin} onClick={() => this.props.editDates("timingAndScoring")} disabled={!this.props.timingAndScoring}>
                                        <DateRangeIcon />
                                    </IconButton>
                                    </Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                    <Typography>${(this.props.timingAndScoringStartDate !== null || this.props.timingAndScoringEndDate !== null) ? 
                                    (this.props.timingAndScoringPrice) * (moment(this.props.timingAndScoringEndDate).diff(moment(this.props.timingAndScoringStartDate), 'days') + 1) : 0
                                    }</Typography>
                                    </Grid>
                                </>
                            }
                            <Grid item xs={12}>
                                <Divider/>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant={"h6"}>Hours of Operation</Typography>
                                <Typography variant={"body2"}>
                                    Facilities will be open at 6:30AM each morning and closed at 6:00PM each evening. Early load-in and late load-out will
                                    only be allowed if pre-scheduled on an individual basis and approved by WeatherTech Raceway Laguna Seca
                                    management.
                                </Typography>
                                <Typography variant={"h6"}>Rules and Regulations</Typography>
                                <Typography variant={"body2"}>
                                    As partial consideration of the covenants and agreements on the part of WeatherTech Raceway Laguna Seca set forth
                                    herein, Renter agrees to comply with the following requirements during all times that this agreement is in effect:<br/>
                                    • Garages shall not be sublet to others<br/>
                                    • No signage of any type shall be placed on the exterior of any building or doors<br/>
                                    • Fixtures, pictures and signage on or within the building may not be removed<br/>
                                    • Removable hanging putty is the only approved method for hanging additional signage on the walls. Do not tape,
                                    Velcro, glue, staple, or use any other method without prior approval from WeatherTech Raceway Laguna Seca
                                    management.<br/>
                                    • WeatherTech Raceway Laguna Seca is not responsible for cars or equipment in garages<br/>
                                    • No welding or open flames of any kind shall be permitted in garages, and no fuel shall be stored therein<br/>
                                    • Garage beams shall not be used for hoisting purposes<br/>
                                    • At no time shall Renter permit more than one (1) car to be kept in any one garage, nor more than two (2)
                                    motorcycles per garage, nor more than four (4) go-karts per garage. Further, Renter always agrees that it shall use
                                    said garage(s) in a careful and safe manner and keep the floor neat and clean and free of all debris.<br/>
                                    • Renter shall always be responsible for the conduct of all guests, employees, and agents and will take all precautions
                                    in order to avoid accidents and ensure the safety of others.<br/>
                                    • Renter shall be responsible for the removal cost of any waste, hazardous or otherwise, including, but not limited to,
                                    fuel, oil, or tires left on the premises by the Renter or any participants.<br/>
                                    • Renter shall fully comply with all posted rules and regulations. In the event of any dispute, the decision of
                                    WeatherTech Raceway Laguna Seca (or their designated agent) shall be final and binding.<br/>
                                    • Renter shall provide a certificate of insurance listing the following as additionally insured: County of Monterey, its
                                    officers, agents and employees; A &amp; D Narigi Consulting LLC, its officers, agents and employees; and
                                    WeatherTech, its parent subsidiary and affiliated companies, and their officers, directors and employees.<br/>
                                </Typography>
                                <Typography variant={"h6"}>Damage Responsibility</Typography>
                                <Typography variant={"body2"}>
                                    Any damage to any rented facility will be charged back to the renter after the fact. A walk-through will be scheduled with
                                    the client prior to and after use to determine the condition of the area and assess any damage.
                                    Renter shall be held responsible for any undue damages to the WeatherTech Raceway Laguna Seca facility and/or its
                                    garages. Renter agrees for itself, its employees, agents, drivers, crew members, guests, and invitees to fully protect,
                                    indemnify, and hold harmless County of Monterey, its officers, agents and employees; A &amp; D Narigi Consulting LLC, its
                                    officers, agents and employees; and WeatherTech, its parent subsidiary and affiliated companies, and their officers,
                                    directors and employees are named as additional insureds from any and all liability to third persons or property of third
                                    persons resulting from Renter’s use of said garage(s).
                                    Renter agrees to require all its employees, agents, drivers, crew members, guests, and invitees to sign the standard waiver
                                    and release form furnished by K &amp; K Insurance prior to admission of any such persons to WeatherTech Raceway Laguna
                                    Seca.
                                </Typography>
                            </Grid>
                        </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                <Grid item xs={8}>
                </Grid>
                <Grid item xs={4}>
                    <Typography>Total: ${this.state.estimatedCost}</Typography>
                </Grid>
            </Grid>
            </React.Fragment>
        )
    }
}

const styles = createStyles((theme: Theme) => ({
    paper: {
        padding: theme.spacing(3, 2),
        position: 'relative',
    },
    center:{
        textAlign: 'center',
    },
    tabContainer: {
        padding: 0,
    },
    reject: {
        backgroundColor: red[500],
        marginLeft: theme.spacing(2),
        color: 'white',
        '&:hover': {
            backgroundColor: red[700],
        }
    },
    submit: {
        backgroundColor: green[500],
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        color: 'white',
        '&:hover': {
            backgroundColor: green[700],
        }
    },
}))

export default withStyles(styles)(TrackRentalWorksheetFormAdmin)