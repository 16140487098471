
import React, { Component } from 'react';
import { withStyles, createStyles, Theme } from '@material-ui/core/styles';
import APIService from '../services/APIService';
import { Button, DialogActions, DialogContent, DialogTitle, withWidth, Dialog, Grid, Typography} from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import 'react-day-picker/lib/style.css';
import TrackRentalWorksheetForm from './TrackRentalWorksheetForm';
import ITrackRental from '../interfaces/ITrackRental';
import ITrackCaterer from '../interfaces/ITrackCaterer';
import DayPicker, { DateUtils, DayModifiers } from 'react-day-picker';
import moment from 'moment';
import ITrackSoundLevel from '../interfaces/ITrackSoundLevel';
import ITrackService from '../interfaces/ITrackService';

export interface ITrackRentalWorksheetDialogProps {
    classes: any;
    width: any;
    setMessage: any;
    setLoading: any;
    loading: boolean;
    closeDialog: any;
    selectedRental: ITrackRental;
}

export interface ITrackRentalWorksheetDialogState {
    _isMounted: boolean;
    showDialog: boolean;
    startDate: Date;
    endDate: Date;
    numberOfVehicles : number;
    ALS : boolean;
    ALSQty : number;
    towTruck : boolean;
    towTruckQty : number;
    rescueTeam2 : boolean;
    rescueTeam3 : boolean;
    fireEngine3 : boolean;
    trackRentalSupervisor : boolean;
    trackRentalSupervisorDays : number;
    starter : boolean;
    starterDays : number;
    marshal : boolean;
    marshalDays : number;
    marshalQty : number;
    soundTester : boolean;
    soundTesterDays : number;
    soundTesterQty : number;
    gridWorker : boolean;
    gridWorkerDays : number;
    gridWorkerQty : number;
    gasAttendant : boolean;
    gasAttendantDays : number;
    gasAttendantQty : number;
    daytimeGateAttendant : boolean;
    daytimeGateAttendantDays : number;
    daytimeGateAttendantQty : number;
    nighttimeGateAttendant : boolean;
    nighttimeGateAttendantDays : number;
    nighttimeGateAttendantQty : number;
    earlyLoadInLateLoadOut : boolean;
    earlyLoadInLateLoadOutDays : number;
    earlyLoadInLateLoadOutQty : number;
    paddockCamping : boolean;
    paddockCampingNights : number;
    paddockCampingQty : number;
    COVID19AdminFee : boolean;
    COVID19AdminFeeDays : number;
    caterer : string;
    estimatedCost : number;
    ALSPrice : number;
    towTruckPrice : number;
    rescueTeam2Price : number;
    rescueTeam3Price : number;
    fireEngine3Price : number;
    trackRentalSupervisorPrice : number;
    starterPrice : number;
    marshalPrice : number;
    soundTesterPrice : number;
    gridWorkerPrice : number;
    gasAttendantPrice : number;
    daytimeGateAttendantPrice : number;
    nighttimeGateAttendantPrice : number;
    earlyLoadInLateLoadOutPrice : number;
    paddockCampingPrice : number;
    COVID19AdminFeePrice : number;
    caterers : ITrackCaterer[];
    garagesRequested : number[];
    garage1to20StartDate: Date;
    garage1to20EndDate: Date;
    garage21to24StartDate: Date;
    garage21to24EndDate: Date;    
    newmanBuilding4Bay: boolean;
    newmanBuilding2Bay: boolean;
    newmanBuilding4BayStartDate: Date;
    newmanBuilding4BayEndDate: Date;
    newmanBuilding2BayStartDate: Date;
    newmanBuilding2BayEndDate: Date;
    stewardsRoom: boolean;
    stewardsRoomStartDate: Date;
    stewardsRoomEndDate: Date;
    timingAndScoring: boolean;
    timingAndScoringStartDate: Date;
    timingAndScoringEndDate: Date;
    garage1to20Price: number;
    garage21to24Price: number;
    newmanBuilding4BayPrice: number;
    newmanBuilding2BayPrice: number;
    stewardsRoomPrice: number;
    timingAndScoringPrice: number;
    editDate: string;
    garage1to20: boolean;
    garage21to24: boolean;
    soundLevels: ITrackSoundLevel[];
    trackServices: ITrackService[];
}

export class TrackRentalWorksheetDialog extends Component<ITrackRentalWorksheetDialogProps, ITrackRentalWorksheetDialogState> {
    static displayName = TrackRentalWorksheetDialog.name;
    private apiService: APIService = new APIService;
    public constructor(props: Readonly<ITrackRentalWorksheetDialogProps>) {
        super(props);
        this.state = {
            _isMounted: false,
            showDialog: false,
            editDate: "",
            startDate: new Date(),
            endDate: new Date(),
            numberOfVehicles : this.props.selectedRental.numberOfVehicles,
            ALS : true,
            ALSQty : this.props.selectedRental.alsQty,
            towTruck : this.props.selectedRental.towTruck,
            towTruckQty : this.props.selectedRental.towTruckQty,
            rescueTeam2 : this.props.selectedRental.rescueTeam2,
            rescueTeam3 : this.props.selectedRental.rescueTeam3,
            fireEngine3 : this.props.selectedRental.fireEngine3,
            trackRentalSupervisor : true,
            trackRentalSupervisorDays : this.props.selectedRental.trackRentalSupervisorDays,
            starter : this.props.selectedRental.starter,
            starterDays : this.props.selectedRental.starterDays,
            marshal : true,
            marshalDays : this.props.selectedRental.marshalDays,
            marshalQty : this.props.selectedRental.marshalQty,
            soundTester : this.props.selectedRental.soundTester,
            soundTesterDays : this.props.selectedRental.soundTesterDays,
            soundTesterQty : this.props.selectedRental.soundTesterQty,
            gridWorker : this.props.selectedRental.gridWorker,
            gridWorkerDays : this.props.selectedRental.gridWorkerDays,
            gridWorkerQty : this.props.selectedRental.gridWorkerQty,
            gasAttendant : this.props.selectedRental.gasAttendant,
            gasAttendantDays : this.props.selectedRental.gasAttendantDays,
            gasAttendantQty : this.props.selectedRental.gasAttendantQty,
            daytimeGateAttendant : this.props.selectedRental.daytimeGateAttendant,
            daytimeGateAttendantDays : this.props.selectedRental.daytimeGateAttendantDays,
            daytimeGateAttendantQty : this.props.selectedRental.daytimeGateAttendantQty,
            nighttimeGateAttendant : this.props.selectedRental.nighttimeGateAttendant,
            nighttimeGateAttendantDays : this.props.selectedRental.nighttimeGateAttendantDays,
            nighttimeGateAttendantQty : this.props.selectedRental.nighttimeGateAttendantQty,
            earlyLoadInLateLoadOut : this.props.selectedRental.earlyLoadInLateLoadOut,
            earlyLoadInLateLoadOutDays : this.props.selectedRental.earlyLoadInLateLoadOutDays,
            earlyLoadInLateLoadOutQty : this.props.selectedRental.earlyLoadInLateLoadOutQty,
            paddockCamping : this.props.selectedRental.paddockCamping,
            paddockCampingNights : this.props.selectedRental.paddockCampingNights,
            paddockCampingQty : this.props.selectedRental.paddockCampingQty,
            COVID19AdminFee : true,
            COVID19AdminFeeDays : this.props.selectedRental.coviD19AdminFeeDays,
            caterer : this.props.selectedRental.caterer,
            estimatedCost : this.props.selectedRental.estimatedCost,
            ALSPrice : this.props.selectedRental.alsPrice,
            towTruckPrice : this.props.selectedRental.towTruckPrice,
            rescueTeam2Price : this.props.selectedRental.rescueTeam2Price,
            rescueTeam3Price : this.props.selectedRental.rescueTeam3Price,
            fireEngine3Price : this.props.selectedRental.fireEngine3Price,
            trackRentalSupervisorPrice : this.props.selectedRental.trackRentalSupervisorPrice,
            starterPrice : this.props.selectedRental.starterPrice,
            marshalPrice : this.props.selectedRental.marshalPrice,
            soundTesterPrice : this.props.selectedRental.soundTesterPrice,
            gridWorkerPrice : this.props.selectedRental.gridWorkerPrice,
            gasAttendantPrice : this.props.selectedRental.gasAttendantPrice,
            daytimeGateAttendantPrice : this.props.selectedRental.daytimeGateAttendantPrice,
            nighttimeGateAttendantPrice : this.props.selectedRental.nighttimeGateAttendantPrice,
            earlyLoadInLateLoadOutPrice : this.props.selectedRental.earlyLoadInLateLoadOutPrice,
            paddockCampingPrice : this.props.selectedRental.paddockCampingPrice,
            COVID19AdminFeePrice : this.props.selectedRental.coviD19AdminFeePrice,
            caterers:[],
            garagesRequested : JSON.parse(this.props.selectedRental.garagesRequested),
            garage1to20StartDate: this.props.selectedRental.garage1to20StartDate,
            garage1to20EndDate: this.props.selectedRental.garage1to20EndDate,
            garage21to24StartDate: this.props.selectedRental.garage21to24StartDate,
            garage21to24EndDate: this.props.selectedRental.garage21to24EndDate,
            newmanBuilding4Bay: this.props.selectedRental.newmanBuilding4Bay,
            newmanBuilding2Bay: this.props.selectedRental.newmanBuilding2Bay,
            newmanBuilding4BayStartDate: this.props.selectedRental.newmanBuilding4BayStartDate,
            newmanBuilding4BayEndDate: this.props.selectedRental.newmanBuilding4BayEndDate,
            newmanBuilding2BayStartDate: this.props.selectedRental.newmanBuilding2BayStartDate,
            newmanBuilding2BayEndDate: this.props.selectedRental.newmanBuilding2BayEndDate,
            stewardsRoom: this.props.selectedRental.stewardsRoom,
            stewardsRoomStartDate: this.props.selectedRental.stewardsRoomStartDate,
            stewardsRoomEndDate: this.props.selectedRental.stewardsRoomEndDate,
            timingAndScoring: this.props.selectedRental.timingAndScoring,
            timingAndScoringStartDate: this.props.selectedRental.timingAndScoringStartDate,
            timingAndScoringEndDate: this.props.selectedRental.timingAndScoringEndDate,
            garage1to20Price: this.props.selectedRental.garage1to20Price,
            garage21to24Price: this.props.selectedRental.garage21to24Price,
            newmanBuilding4BayPrice: this.props.selectedRental.newmanBuilding4BayPrice,
            newmanBuilding2BayPrice: this.props.selectedRental.newmanBuilding2BayPrice,
            stewardsRoomPrice: this.props.selectedRental.stewardsRoomPrice,
            timingAndScoringPrice: this.props.selectedRental.timingAndScoringPrice,
            garage1to20: false,
            garage21to24: false,
            soundLevels: [],
            trackServices: [],
        };
        this.apiService = new APIService;
        this.onSubmit = this.onSubmit.bind(this);
    }

    private handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newState = { [event.target.name]: event.target.value } as any;
        this.setState(newState);
    };    

    private openDialog = (editDate : string) => {
        this.setState({showDialog: true, editDate});
    }

    private editDates = () =>{
        const newState = {[`${this.state.editDate}StartDate`]: this.state.startDate, [`${this.state.editDate}EndDate`]: this.state.endDate} as any;
        this.setState(newState);
        this.setState({startDate: new Date(), endDate: new Date()});
        this.closeDialog();
    }

    private handleGarageToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
        let garagesRequested = this.state.garagesRequested;
        garagesRequested.includes(Number(event.target.name)) ?
        garagesRequested.splice(garagesRequested.indexOf(Number(event.target.name)), 1) :
        garagesRequested.push(Number(event.target.name));
        this.setState({garagesRequested});
    };

    private closeDialog = () => {
        this.setState({ showDialog: false, editDate: ""});
    };

    private checkGarages= () => {        
        for (var i = 1; i <= 20; i++) {
            if(this.state.garagesRequested.includes(i)){
                this.setState({ garage1to20: true});
                break;
            }
        }
        for (var i = 21; i <= 24; i++) {
            if(this.state.garagesRequested.includes(i)){
                this.setState({garage21to24: true});
                break;
            }
        }
    };

    private handleDateClick = (date : Date, modifiers: DayModifiers) =>{
        if(moment(this.state.startDate).format('MMMM Do YYYY') === moment(new Date()).format('MMMM Do YYYY')){
            this.setState({startDate : date, endDate : date});
        }
        else{
            const range = DateUtils.addDayToRange(date, {from: this.state.startDate, to: this.state.endDate});            
            if(!modifiers.disabled){
                this.setState({startDate : range.from, endDate : range.to});
            }
        }
    };

    private handleToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newState = { [event.target.name]: !this.state[event.target.name as keyof ITrackRentalWorksheetDialogState] } as any;
        if(this.state[event.target.name as keyof ITrackRentalWorksheetDialogState]){
            switch (event.target.name) {
                case "ALS":
                    this.setState({ALSQty : 0});
                    break;
                case "towTruck":
                    this.setState({towTruckQty : 0});
                    break;
                case "trackRentalSupervisor":
                    this.setState({trackRentalSupervisorDays : 0});
                    break;
                case "starter":
                    this.setState({starterDays : 0});
                    break;
                case "marshal":
                    this.setState({marshalDays : 0, marshalQty : 0});
                    break;
                case "soundTester":
                    this.setState({soundTesterDays : 0, soundTesterQty : 0});
                    break;
                case "gridWorker":
                    this.setState({gridWorkerDays : 0, gridWorkerQty : 0});
                    break;
                case "gasAttendant":
                    this.setState({gasAttendantDays : 0, gasAttendantQty : 0});
                    break;
                case "daytimeGateAttendant":
                    this.setState({daytimeGateAttendantDays : 0, daytimeGateAttendantQty : 0});
                    break;
                case "nighttimeGateAttendant":
                    this.setState({nighttimeGateAttendantDays : 0, nighttimeGateAttendantQty : 0});
                    break;
                case "earlyLoadInLateLoadOut":
                    this.setState({earlyLoadInLateLoadOutDays : 0, earlyLoadInLateLoadOutQty : 0});
                    break;
                case "paddockCamping":
                    this.setState({paddockCampingNights : 0, paddockCampingQty : 0});
                    break;
                case "COVID19AdminFee":
                    this.setState({COVID19AdminFeeDays : 0});
                    break;
                default:
                    break;
            }
        }
        this.setState(newState);
    };

    private submitRequest = async () => {
        this.props.setLoading(true);
        if(this.props.selectedRental){
            const request : ITrackRental = {
                id : this.props.selectedRental.id,
                entrantId : this.props.selectedRental.entrantId,
                status: this.props.selectedRental.status,
                startDate: this.props.selectedRental.startDate,
                endDate: this.props.selectedRental.endDate,
                description: this.props.selectedRental.description,
                soundLevel: this.props.selectedRental.soundLevel,
                numberOfVehicles : this.state.numberOfVehicles,
                als : this.state.ALS,
                alsQty : this.state.ALSQty,
                towTruck : this.state.towTruck,
                towTruckQty : this.state.towTruckQty,
                rescueTeam2 : this.state.rescueTeam2,
                rescueTeam3 : this.state.rescueTeam3,
                fireEngine3 : this.state.fireEngine3,
                trackRentalSupervisor : this.state.trackRentalSupervisor,
                trackRentalSupervisorDays : this.state.trackRentalSupervisorDays,
                starter : this.state.starter,
                starterDays : this.state.starterDays,
                marshal : this.state.marshal,
                marshalDays : this.state.marshalDays,
                marshalQty : this.state.marshalQty,
                soundTester : this.state.soundTester,
                soundTesterDays : this.state.soundTesterDays,
                soundTesterQty : this.state.soundTesterQty,
                gridWorker : this.state.gridWorker,
                gridWorkerDays : this.state.gridWorkerDays,
                gridWorkerQty : this.state.gridWorkerQty,
                gasAttendant : this.state.gasAttendant,
                gasAttendantDays : this.state.gasAttendantDays,
                gasAttendantQty : this.state.gasAttendantQty,
                daytimeGateAttendant : this.state.daytimeGateAttendant,
                daytimeGateAttendantDays : this.state.daytimeGateAttendantDays,
                daytimeGateAttendantQty : this.state.daytimeGateAttendantQty,
                nighttimeGateAttendant : this.state.nighttimeGateAttendant,
                nighttimeGateAttendantDays : this.state.nighttimeGateAttendantDays,
                nighttimeGateAttendantQty : this.state.nighttimeGateAttendantQty,
                earlyLoadInLateLoadOut : this.state.earlyLoadInLateLoadOut,
                earlyLoadInLateLoadOutDays : this.state.earlyLoadInLateLoadOutDays,
                earlyLoadInLateLoadOutQty : this.state.earlyLoadInLateLoadOutQty,
                paddockCamping : this.state.paddockCamping,
                paddockCampingNights : this.state.paddockCampingNights,
                paddockCampingQty : this.state.paddockCampingQty,
                coviD19AdminFee : this.state.COVID19AdminFee,
                coviD19AdminFeeDays : this.state.COVID19AdminFeeDays,
                caterer : this.state.caterer,
                estimatedCost : this.state.estimatedCost,
                alsPrice : this.props.selectedRental.alsPrice,
                towTruckPrice : this.props.selectedRental.towTruckPrice,
                rescueTeam2Price : this.props.selectedRental.rescueTeam2Price,
                rescueTeam3Price : this.props.selectedRental.rescueTeam3Price,
                fireEngine3Price : this.props.selectedRental.fireEngine3Price,
                trackRentalSupervisorPrice : this.props.selectedRental.trackRentalSupervisorPrice,
                starterPrice : this.props.selectedRental.starterPrice,
                marshalPrice : this.props.selectedRental.marshalPrice,
                soundTesterPrice : this.props.selectedRental.soundTesterPrice,
                gridWorkerPrice : this.props.selectedRental.gridWorkerPrice,
                gasAttendantPrice : this.props.selectedRental.gasAttendantPrice,
                daytimeGateAttendantPrice : this.props.selectedRental.daytimeGateAttendantPrice,
                nighttimeGateAttendantPrice : this.props.selectedRental.nighttimeGateAttendantPrice,
                earlyLoadInLateLoadOutPrice : this.props.selectedRental.earlyLoadInLateLoadOutPrice,
                paddockCampingPrice : this.props.selectedRental.paddockCampingPrice,
                coviD19AdminFeePrice : this.props.selectedRental.coviD19AdminFeePrice,
                garagesRequested : JSON.stringify(this.state.garagesRequested),
                garage1to20StartDate: this.state.garage1to20StartDate,
                garage1to20EndDate: this.state.garage1to20EndDate,
                garage21to24StartDate: this.state.garage21to24StartDate,
                garage21to24EndDate: this.state.garage21to24EndDate,
                newmanBuilding4Bay: this.state.newmanBuilding4Bay,
                newmanBuilding2Bay: this.state.newmanBuilding2Bay,
                newmanBuilding4BayStartDate: this.state.newmanBuilding4BayStartDate,
                newmanBuilding4BayEndDate: this.state.newmanBuilding4BayEndDate,
                newmanBuilding2BayStartDate: this.state.newmanBuilding2BayStartDate,
                newmanBuilding2BayEndDate: this.state.newmanBuilding2BayEndDate,
                stewardsRoom: this.state.stewardsRoom,
                stewardsRoomStartDate: this.state.stewardsRoomStartDate,
                stewardsRoomEndDate: this.state.stewardsRoomEndDate,
                timingAndScoring: this.state.timingAndScoring,
                timingAndScoringStartDate: this.state.timingAndScoringStartDate,
                timingAndScoringEndDate: this.state.timingAndScoringEndDate,
                garage1to20Price: this.props.selectedRental.garage1to20Price,
                garage21to24Price: this.props.selectedRental.garage21to24Price,
                newmanBuilding4BayPrice: this.props.selectedRental.newmanBuilding4BayPrice,
                newmanBuilding2BayPrice: this.props.selectedRental.newmanBuilding2BayPrice,
                stewardsRoomPrice: this.props.selectedRental.stewardsRoomPrice,
                timingAndScoringPrice: this.props.selectedRental.timingAndScoringPrice,
                soundLevelPrice: this.props.selectedRental.soundLevelPrice
            }
            await this.apiService.addWorksheetToRentalRequest(request).then((result : any) => {
                this.props.setLoading(false);
                //SucessMessage
            });
        }
    }

    private getCaterers = async () => {
        let caterers : ITrackCaterer[] = [];
        await this.apiService.getActiveCaterers().then((result : any) => {
            result.map((item : any, index: number) => {
                if(item.active){
                    caterers.push({name: item.name, id : item.id, active: item.active});
                }
            });
            this.setState({caterers});
        });
    }

    private getSoundLevels = async () => {
        await this.apiService.getSoundLevels().then((result : any) => {
            this.setState({ soundLevels: result });
        });
    }

    private getServices = async () => {
        await this.apiService.getAllTrackServices().then((result : ITrackService[]) => {
            this.setState({trackServices : result});
            (result.find(x => {return x.name === "TrackRentalSupervisor"})?.mandatory) && this.setState({trackRentalSupervisor : true});
            (result.find(x => {return x.name === "Starter"})?.mandatory) && this.setState({starter : true});
            (result.find(x => {return x.name === "Marshal"})?.mandatory) && this.setState({marshal : true});
            (result.find(x => {return x.name === "SoundTester"})?.mandatory) && this.setState({soundTester : true});
            (result.find(x => {return x.name === "GridWorker"})?.mandatory) && this.setState({gridWorker : true});
            (result.find(x => {return x.name === "GasAttendant"})?.mandatory) && this.setState({gasAttendant : true});
            (result.find(x => {return x.name === "DaytimeGateAttendant"})?.mandatory) && this.setState({daytimeGateAttendant : true});
            (result.find(x => {return x.name === "NighttimeGateAttendant"})?.mandatory) && this.setState({nighttimeGateAttendant : true});
            (result.find(x => {return x.name === "EarlyLoadInLateLoadOut"})?.mandatory) && this.setState({earlyLoadInLateLoadOut : true});
            (result.find(x => {return x.name === "PaddockCamping"})?.mandatory) && this.setState({paddockCamping : true});
            (result.find(x => {return x.name === "COVID19AdminFee"})?.mandatory) && this.setState({COVID19AdminFee : true});
            (result.find(x => {return x.name === "ALS"})?.mandatory) && this.setState({ALS : true});
            (result.find(x => {return x.name === "TowTruck"})?.mandatory) && this.setState({towTruck : true});
            (result.find(x => {return x.name === "RescueTruck3"})?.mandatory) && this.setState({rescueTeam3 : true});
            (result.find(x => {return x.name === "RescueTruck2"})?.mandatory) && this.setState({rescueTeam2 : true});
            (result.find(x => {return x.name === "FireEngine3"})?.mandatory) && this.setState({fireEngine3 : true});
        });
    }

    private onSubmit(){
        let a = moment(this.props.selectedRental.startDate);
        let b = moment(this.props.selectedRental.endDate);
        const maxDays = (b.diff(a, 'days') + 1);
        if (this.state.trackRentalSupervisorDays > maxDays){
            this.props.setMessage("Track Rental Supervisor Days Exceeds Rental Length.", "error");
            return;
        }
        if (this.state.starterDays > maxDays){
            this.props.setMessage("Starter Days Exceeds Rental Length.", "error");
            return;
        }
        if (this.state.marshalDays > maxDays){
            this.props.setMessage("Course Marshal Days Exceeds Rental Length.", "error");
            return;
        }
        if (this.state.soundTesterDays > maxDays){
            this.props.setMessage("Sound Tester Days Exceeds Rental Length.", "error");
            return;
        }
        if (this.state.gridWorkerDays > maxDays){
            this.props.setMessage("Grid Worker Days Exceeds Rental Length.", "error");
            return;
        }
        if (this.state.gasAttendantDays > maxDays){
            this.props.setMessage("Gas Attendant Days Exceeds Rental Length.", "error");
            return;
        }
        if (this.state.daytimeGateAttendantDays > maxDays){
            this.props.setMessage("Daytime Gate Attendant Days Exceeds Rental Length.", "error");
            return;
        }
        if (this.state.nighttimeGateAttendantDays > maxDays){
            this.props.setMessage("Nighttime Gate Attendant Days Exceeds Rental Length.", "error");
            return;
        }
        if (this.state.earlyLoadInLateLoadOutDays > maxDays){
            this.props.setMessage("Early Load-In and Late Load-Outs Days Exceeds Rental Length.", "error");
            return;
        }
        if (this.state.paddockCampingNights > maxDays){
            this.props.setMessage("Camping Days Exceeds Rental Length.", "error");
            return;
        }
        if (this.state.COVID19AdminFeeDays > maxDays){
            this.props.setMessage("Covid19 Fee Days Exceeds Rental Length.", "error");
            return;
        }

        if (this.state.trackRentalSupervisorDays <= 0 && this.state.trackRentalSupervisor){
            this.props.setMessage("No Track Rental Supervisor Days Entered.", "error");
            return;
        }
        if (this.state.starterDays <= 0 && this.state.starter){
            this.props.setMessage("No Starter Days Entered.", "error");
            return;
        }
        if (this.state.marshalDays <= 0 && this.state.marshal){
            this.props.setMessage("No Course Marshal Days Entered.", "error");
            return;
        }
        if (this.state.soundTesterDays <= 0 && this.state.soundTester){
            this.props.setMessage("No Sound Tester Days Entered.", "error");
            return;
        }
        if (this.state.gridWorkerDays <= 0 && this.state.gridWorker){
            this.props.setMessage("No Grid Worker Days Entered.", "error");
            return;
        }
        if (this.state.gasAttendantDays <= 0 && this.state.gasAttendant){
            this.props.setMessage("No Gas Attendant Days Entered.", "error");
            return;
        }
        if (this.state.daytimeGateAttendantDays <= 0 && this.state.daytimeGateAttendant){
            this.props.setMessage("No Daytime Gate Attendant Days Entered.", "error");
            return;
        }
        if (this.state.nighttimeGateAttendantDays <= 0 && this.state.nighttimeGateAttendant){
            this.props.setMessage("No Nighttime Gate Attendant Days Entered.", "error");
            return;
        }
        if (this.state.earlyLoadInLateLoadOutDays <= 0 && this.state.earlyLoadInLateLoadOut){
            this.props.setMessage("No Early Load-In and Late Load-Outs Days Entered.", "error");
            return;
        }
        if (this.state.paddockCampingNights <= 0 && this.state.paddockCamping){
            this.props.setMessage("No Camping Nights Entered.", "error");
            return;
        }
        if (this.state.COVID19AdminFeeDays <= 0 && this.state.COVID19AdminFee){
            this.props.setMessage("No Covid19 Fee Days Entered.", "error");
            return;
        }


        if (this.state.ALSQty <= 0 && this.state.ALS){
            this.props.setMessage("No ALS Quantity Entered.", "error");
            return;
        }
        if (this.state.towTruckQty <= 0 && this.state.towTruck){
            this.props.setMessage("No Tow Truck Quantity Entered.", "error");
            return;
        }
        if (this.state.marshalQty <= 0 && this.state.marshal){
            this.props.setMessage("No Course Marshal Quantity Entered.", "error");
            return;
        }
        if (this.state.soundTesterQty <= 0 && this.state.soundTester){
            this.props.setMessage("No Sound Tester Quantity Entered.", "error");
            return;
        }
        if (this.state.gridWorkerQty <= 0 && this.state.gridWorker){
            this.props.setMessage("No Grid Worker Quantity Entered.", "error");
            return;
        }
        if (this.state.gasAttendantQty <= 0 && this.state.gasAttendant){
            this.props.setMessage("No Gas Attendant Quantity Entered.", "error");
            return;
        }
        if (this.state.daytimeGateAttendantQty <= 0 && this.state.daytimeGateAttendant){
            this.props.setMessage("No Daytime Gate Attendant Quantity Entered.", "error");
            return;
        }
        if (this.state.nighttimeGateAttendantQty <= 0 && this.state.nighttimeGateAttendant){
            this.props.setMessage("No Nighttime Gate Attendant Quantity Entered.", "error");
            return;
        }
        if (this.state.earlyLoadInLateLoadOutQty <= 0 && this.state.earlyLoadInLateLoadOut){
            this.props.setMessage("No Early Load-In and Late Load-Outs Quantity Entered.", "error");
            return;
        }
        if (this.state.paddockCampingQty <= 0 && this.state.earlyLoadInLateLoadOut){
            this.props.setMessage("No Paddock Capming Quantity Entered.", "error");
            return;
        }
        
        if(this.state.garage1to20 && (this.state.garage1to20StartDate === null || this.state.garage1to20EndDate === null)){
            this.props.setMessage("No Garage (1-20) Date Selected.", "error");
            return;
        }
        if(this.state.garage21to24 && (this.state.garage21to24StartDate === null || this.state.garage21to24EndDate === null)){
            this.props.setMessage("No Garage (21-24) Date Selected.", "error");
            return;
        }
        if(this.state.newmanBuilding4Bay && (this.state.newmanBuilding4BayStartDate === null || this.state.newmanBuilding4BayEndDate === null)){
            this.props.setMessage("No Newman Building 4 Bay Date Selected.", "error");
            return;
        }
        if(this.state.newmanBuilding2Bay && (this.state.newmanBuilding2BayStartDate === null || this.state.newmanBuilding2BayEndDate === null)){
            this.props.setMessage("No Newman Building 2 Bay Date Selected.", "error");
            return;
        }
        if(this.state.stewardsRoom && (this.state.stewardsRoomStartDate === null || this.state.stewardsRoomEndDate === null)){
            this.props.setMessage("No Stewards Room Date Selected.", "error");
            return;
        }
        if(this.state.timingAndScoring && (this.state.timingAndScoringStartDate === null || this.state.timingAndScoringEndDate === null)){
            this.props.setMessage("No Timing and Scoring Date Selected.", "error");
            return;
        }

        this.submitRequest().then(data => {
            this.props.closeDialog();
        });
    }
    
    public componentDidMount() {
        this.props.setLoading(true);
        Promise.all([this.getSoundLevels(), this.getCaterers(), this.getServices()]).then(() =>{
            this.checkGarages();
            this.setState({ _isMounted: true }); 
            this.props.setLoading(false);
        });
        // this.getSoundLevels().then(() => this.getCaterers()).then(() => {
        // });
    }

    public componentDidUpdate(prevProps: ITrackRentalWorksheetDialogProps, prevState: ITrackRentalWorksheetDialogState) {
    }

    public render(): React.ReactElement<ITrackRentalWorksheetDialogProps> {
        const { classes, width } = this.props;
        let disabledDates = [{ before: new Date(this.props.selectedRental.startDate)}, { after: new Date(this.props.selectedRental.endDate)}];
        const from = this.state.startDate;
        const to = this.state.endDate;
        const modifiers = { start: from, end: to };
        return (
            <React.Fragment>
            {(this.state._isMounted && !this.props.loading) &&
            <>
                <DialogTitle>New Track Rental Request </DialogTitle>
                <DialogContent dividers>
                    <TrackRentalWorksheetForm
                        {...this.state}
                        classes={this.props.classes}
                        width={this.props.width}
                        selectedRental={this.props.selectedRental}
                        handleChange={this.handleChange}
                        handleToggle={this.handleToggle}
                        editDates={this.openDialog}
                        handleGarageToggle={this.handleGarageToggle}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.props.closeDialog} variant="contained">Cancel</Button>
                    <Button onClick={this.onSubmit} variant="contained">Submit Request</Button>
                </DialogActions>
            </>
            }
            {this.state.showDialog && 
            <Dialog
                    open={this.state.showDialog}
                    onClose={this.closeDialog}
                    maxWidth={"lg"}
                    fullWidth
                    fullScreen={width !== "lg" && width !== "xl"}
                    scroll="paper">
                <DialogTitle>Edit Date</DialogTitle>
                <DialogContent dividers>
                <Grid container>
                    <Grid item xs={12} className={classes.center}>
                        <DayPicker 
                            className="Selectable"
                            modifiers={modifiers}
                            onDayClick={this.handleDateClick}
                            selectedDays={[from, { from, to }]}
                            disabledDays={disabledDates}
                            numberOfMonths={2}
                        />
                        <style>{`
                            .Selectable .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
                            background-color: #f0f8ff !important;
                            color: #4a90e2;
                            }
                            .Selectable .DayPicker-Day {
                            border-radius: 0 !important;
                            }
                            .Selectable .DayPicker-Day--start {
                            border-top-left-radius: 50% !important;
                            border-bottom-left-radius: 50% !important;
                            }
                            .Selectable .DayPicker-Day--end {
                            border-top-right-radius: 50% !important;
                            border-bottom-right-radius: 50% !important;
                            }
                        `}</style>
                    </Grid>
                    <Grid item xs={12} className={classes.center}>
                        {moment(this.state.startDate).format('MMMM Do YYYY') === moment(new Date()).format('MMMM Do YYYY') || moment(this.state.startDate).format('MMMM Do YYYY') === "Invalid date" ? <Typography>Select Start Date</Typography> : 
                        moment(this.state.endDate).format('MMMM Do YYYY') === moment(new Date()).format('MMMM Do YYYY') || moment(this.state.endDate).format('MMMM Do YYYY') === "Invalid date" ? <Typography>Select End Date</Typography> : 
                        <Typography>{moment(this.state.startDate).format('MMMM Do YYYY')} - {moment(this.state.endDate).format('MMMM Do YYYY')}</Typography>}
                    </Grid>
                </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.closeDialog} variant="contained">Cancel</Button>
                    <Button onClick={this.editDates} className={classes.submit} variant="contained">Save Date</Button>
                </DialogActions>
            </Dialog>}
            </React.Fragment>
        )
    }
}

const styles = createStyles((theme: Theme) => ({
    paper: {
        padding: theme.spacing(3, 2),
        position: 'relative',
    },
    center:{
        textAlign: 'center',
    },
    right:{
        textAlign: 'right',
    },
    submit: {
        backgroundColor: green[500],
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        color: 'white',
        '&:hover': {
            backgroundColor: green[700],
        }
    },
}))

export default withWidth()(withStyles(styles, { withTheme: true })(TrackRentalWorksheetDialog));