import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import ImageIcon from '@material-ui/icons/Image';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import InfoIcon from '@material-ui/icons/Info';
import PeopleIcon from '@material-ui/icons/People';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CloseIcon from '@material-ui/icons/Close';
import Car from '../interfaces/ICar';
import { withStyles, createStyles, Theme } from '@material-ui/core/styles';
import { orange, red, blue, green, grey, deepPurple, purple } from '@material-ui/core/colors';
import CarWizard from './CarWizard';
import Entry from '../interfaces/IEntry';
import { Tooltip, withWidth, Box, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import Grid from '@material-ui/core/Grid';
import authService from '../services/api-authorization/AuthorizeService';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import CarInfo from './CarInfo';
import ModifyCrew from './ModifyCrew';
import APIService from '../services/APIService';
import RegistrationEvent from '../interfaces/IRegistrationEvent';
import Race from '../interfaces/IRace';
import CarRace from '../interfaces/ICarRace';

export interface ICarCardProps {
    car: Car;
    entry: Entry;
    classes: any;
    width: any;
    editEnabled: boolean;
    setMessage: any;
    setLoading: any;
    loading: boolean;
    getEntryForms: any;
    regOpen: boolean;
    event: RegistrationEvent;
}
export interface ICarCardState {
    editOpen: boolean;
    infoOpen: boolean;
    crewOpen: boolean;
    deleteOpen: boolean;
    whithdrawOpen: boolean;
    showActions: boolean;
    startDate: Date;
}

export class CarCard extends Component<ICarCardProps, ICarCardState> {
    private apiService: APIService = new APIService;
    public constructor(props: Readonly<ICarCardProps>) {
        super(props);
        this.state = {
            editOpen: false,
            infoOpen: false,
            crewOpen: false,
            deleteOpen: false,
            whithdrawOpen: false,
            showActions: false,
            startDate: new Date()
        }
        this.handleCloseInfoDialog = this.handleCloseInfoDialog.bind(this);
        this.actionIcons = this.actionIcons.bind(this);
        this.apiService = new APIService;
    }

    private handleOpenInfoDialog = () => {
        this.setState({ infoOpen: true });
    };

    private handleCloseInfoDialog = () => {
        this.setState({ infoOpen: false });
    };

    private handleOpenEditDialog = () => {
        this.setState({ editOpen: true });
    };

    private handleCloseEditDialog = () => {
        this.setState({ editOpen: false });
        this.props.getEntryForms();
    };

    private handleOpenCrewDialog = () => {
        this.setState({ crewOpen: true });
    };

    private handleCloseCrewDialog = () => {
        this.setState({ crewOpen: false });
        this.props.getEntryForms();
    };

    private handleOpenDeleteDialog = () => {
        this.setState({ deleteOpen: true })
    };

    private handleCloseDeleteDialog = () => {
        this.setState({ deleteOpen: false });
    };

    private handleOpenWithdrawDialog = () => {
        this.setState({ whithdrawOpen: true })
    };

    private handleCloseWithdrawDialog = () => {
        this.setState({ whithdrawOpen: false });
    };

    private showActions = () => {
        this.setState({ showActions: true });
    }

    private hideActions = () => {
        this.setState({ showActions: false });
    }
    // A manual process is now used for withdrawing cars
    // private withdrawCar = async () => {
        // this.handleCloseDeleteDialog();
        // this.props.setLoading(true);
        // await this.apiService.deleteCar(this.props).then(() => {
        //     this.props.setLoading(false);
        // });
    // }

    private deleteCar = async () => {
        this.handleCloseDeleteDialog();
        this.props.setLoading(true);
        await this.apiService.deleteCar(this.props.car.id).then((result) => {
            if(result.ok){
                this.props.setMessage("Car Successfully Deleted.", "success")
                this.props.setLoading(false);
                this.props.getEntryForms();
        }else{
            this.props.setMessage("Error completing operation. Please try again. (" + result.status + ")", "error");
            this.props.setLoading(false);
        }
        });
    }

    private actionIcons() {
        const { width } = this.props;
        if (width === "xs"){
            return (
            <List component="nav" aria-label="main mailbox folders">
            {(this.props.editEnabled && this.props.car.status === "Draft") ?
            <React.Fragment>
                <ListItem button onClick={this.handleOpenInfoDialog} disabled={this.props.loading}>
                    <ListItemIcon>
                        <InfoIcon />
                    </ListItemIcon>
                    <ListItemText primary="View Info" />
                </ListItem>
                <ListItem button onClick={this.handleOpenEditDialog} disabled={this.props.loading}>
                    <ListItemIcon>
                        <EditIcon />
                    </ListItemIcon>
                    <ListItemText primary="Edit" />
                </ListItem>
                {this.props.car.status === "Draft" &&
                    <ListItem button onClick={this.handleOpenDeleteDialog} disabled={this.props.loading}>
                        <ListItemIcon>
                            <DeleteIcon />
                        </ListItemIcon>
                        <ListItemText primary="Delete" />
                    </ListItem>
                }
            </React.Fragment> : ((this.state.startDate > new Date()) && this.props.car.status !== "Draft") ?
                <React.Fragment>
                    <ListItem button onClick={this.handleOpenInfoDialog} disabled={this.props.loading}>
                        <ListItemIcon>
                            <InfoIcon />
                        </ListItemIcon>
                        <ListItemText primary="View Info" />
                    </ListItem>
                    {this.props.car.status !== "Withdrawn" &&
                        <React.Fragment>
                            <ListItem button onClick={this.handleOpenCrewDialog} disabled={this.props.loading}>
                                <ListItemIcon>
                                    <PeopleIcon />
                                </ListItemIcon>
                                <ListItemText primary="Modify Crew" />
                            </ListItem>
                            <ListItem button onClick={this.handleOpenWithdrawDialog} disabled={this.props.loading}>
                                <ListItemIcon>
                                    <SettingsBackupRestoreIcon />
                                </ListItemIcon>
                                <ListItemText primary="Withdraw Car" />
                            </ListItem>
                        </React.Fragment>
                    }
                </React.Fragment> :
                <React.Fragment>
                    <ListItem button onClick={this.handleOpenInfoDialog} disabled={this.props.loading}>
                        <ListItemIcon>
                            <InfoIcon />
                        </ListItemIcon>
                        <ListItemText primary="View Info" />
                    </ListItem>
                </React.Fragment>}
            </List>)
        }
        else{
            return ((this.props.editEnabled && this.props.car.status === "Draft") ?
            <React.Fragment>
                <Tooltip title="Car Info" placement="top" arrow>
                    <IconButton aria-label="Info" onClick={this.handleOpenInfoDialog} disabled={this.props.loading}><InfoIcon /></IconButton>
                </Tooltip>
                <Tooltip title="Edit Car" placement="top" arrow>
                    <IconButton aria-label="Edit" onClick={this.handleOpenEditDialog} disabled={this.props.loading}><EditIcon /></IconButton>
                </Tooltip>
                {this.props.car.status === "Draft" &&
                    <Tooltip title="Delete Car" placement="top" arrow>
                        <IconButton aria-label="Delete" onClick={this.handleOpenDeleteDialog} disabled={this.props.loading}><DeleteIcon /></IconButton>
                    </Tooltip>
                }
            </React.Fragment> : ((this.state.startDate > new Date()) && this.props.car.status !== "Draft") ?
                <React.Fragment>
                    <Tooltip title="Car Info" placement="top" arrow>
                        <IconButton aria-label="Info" onClick={this.handleOpenInfoDialog} disabled={this.props.loading}><InfoIcon /></IconButton>
                    </Tooltip>
                    {this.props.car.status !== "Withdrawn" &&
                        <React.Fragment>
                            <Tooltip title="Modify Crew" placement="top" arrow>
                                <IconButton aria-label="Modify Crew" onClick={this.handleOpenCrewDialog} disabled={this.props.loading}><PeopleIcon /></IconButton>
                            </Tooltip>
                            <Tooltip title="Withdraw Car" placement="top" arrow>
                                <IconButton aria-label="Withdraw" onClick={this.handleOpenWithdrawDialog} disabled={this.props.loading}><SettingsBackupRestoreIcon /></IconButton>
                            </Tooltip>
                        </React.Fragment>
                    }
                </React.Fragment> :
                <React.Fragment>
                    <Tooltip title="Car Info" placement="top" arrow>
                        <IconButton aria-label="Info" onClick={this.handleOpenInfoDialog} disabled={this.props.loading}><InfoIcon /></IconButton>
                    </Tooltip>
                </React.Fragment>)
        }    

    }

    private setStartDate(){
        let startDate : Date = this.props.car.carRace.sort((a: CarRace, b: CarRace) => new Date(a.race.startDate).getTime() - new Date(b.race.startDate).getTime())[0].race.startDate;
        this.setState({startDate: new Date(startDate)})
    }

    public componentDidMount(){        
        this.setStartDate()
    }
    public render(): React.ReactElement {
        const { classes, width } = this.props;  
        return (
            <Card className={classes.card}>
                <CardHeader className={classes.cardHeader}
                    title={<Typography gutterBottom variant="h6" className={classes.cardHeaderText} >{this.props.car.year + " " + this.props.car.make + " " + this.props.car.model}</Typography>}
                    action={(width === "xs") ? <IconButton aria-label="More Actions" onClick={this.showActions} disabled={this.props.loading}><MoreVertIcon /></IconButton> : <this.actionIcons />}
                />
                <CardContent>
                    <Typography gutterBottom variant="h6" className={classes[this.props.car.status]}>{this.props.car.status}</Typography>
                </CardContent>
                {(this.props.car.carImageToDisplay === undefined || this.props.car.carImageToDisplay === null) ?
                    <div className={classes.emptyImage}>
                        <ImageIcon /><br></br><Typography variant="subtitle1"> No Image Uploaded</Typography>
                    </div> :
                    <CardMedia
                        className={classes.media}
                        image={this.props.car.carImageToDisplay.toString()}
                        title="Car Image"
                    />
                }
                <Dialog
                    maxWidth={'sm'}
                    fullScreen={width === "xs"}
                    scroll="paper"
                    open={this.state.whithdrawOpen}
                    onClose={this.handleCloseWithdrawDialog}
                    aria-labelledby="dialog-title"
                    aria-describedby="dialog-description"
                >
                    <DialogTitle>
                        <Typography variant="h5"><b>Withdraw Car</b></Typography>
                    </DialogTitle>
                    <DialogContent dividers>
                        {/* <Typography>Are you sure you want to withdraw <b>{this.props.car.year + " " + this.props.car.make + " " + this.props.car.model}</b>?</Typography> */}
                        <Typography>To withdraw a vehicle please contact us at <a href="mailto:registration@laguna-seca.com">registration@laguna-seca.com</a></Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseWithdrawDialog} variant="contained">Close</Button>
                        {/* <Grid container spacing={1} className={classes.spacingButton}>
                        <Grid item xs={6}>
                            <Button onClick={this.handleCloseWithdrawDialog} variant="contained">Cancel</Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button onClick={this.withdrawCar} className={classes.withdrawButton} variant="contained" >WITHDRAW</Button>
                        </Grid>
                    </Grid> */}
                    </DialogActions>
                </Dialog>
                <Dialog
                    maxWidth={'sm'}
                    fullScreen={width === "xs"}
                    scroll="paper"
                    open={this.state.deleteOpen}
                    onClose={this.handleCloseDeleteDialog}
                    aria-labelledby="dialog-title"
                    aria-describedby="dialog-description"
                >
                    <DialogTitle>
                        <Typography variant="h5"><b>Delete Car</b></Typography>
                    </DialogTitle>
                    <DialogContent dividers>
                        <Typography>Are you sure you want to delete <b>{this.props.car.year + " " + this.props.car.make + " " + this.props.car.model}</b>?</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Grid container spacing={1} className={classes.spacingButton}>
                            <Grid item xs={6}>
                                <Button onClick={this.handleCloseDeleteDialog} variant="contained">Cancel</Button>
                            </Grid>
                            <Grid item xs={6}>
                                <Button onClick={this.deleteCar} color="secondary" variant="contained" >DELETE</Button>
                            </Grid>
                        </Grid>
                    </DialogActions>
                </Dialog>
                {width === "xs" && 
                    <Dialog maxWidth={'sm'} onClose={this.hideActions} open={this.state.showActions}>
                        <DialogTitle>Car Actions
                        <IconButton aria-label="close" className={classes.closeButton} onClick={this.hideActions}>
                            <CloseIcon />
                        </IconButton>
                        </DialogTitle>
                        <DialogContent dividers>
                            <this.actionIcons/>
                        </DialogContent>
                    </Dialog>
                }
                <CarInfo
                    car={this.props.car}
                    show={this.state.infoOpen}
                    handleCancel={this.handleCloseInfoDialog}
                />
                {(this.props.editEnabled && this.state.editOpen) &&
                    <CarWizard
                        {...this.state}
                        showCarDialog={this.state.editOpen}
                        entry={this.props.entry}
                        car={this.props.car}
                        setMessage={this.props.setMessage}
                        setLoading={this.props.setLoading}
                        loading={this.props.loading}
                        editMode={true}
                        handleCancel={this.handleCloseEditDialog}
                    />
                }
                {((this.props.editEnabled || this.state.startDate  > new Date()) && this.state.crewOpen) &&
                    <ModifyCrew
                        {...this.state}
                        entry={this.props.entry}
                        event={this.props.event}
                        show={this.state.crewOpen}
                        car={this.props.car}
                        setMessage={this.props.setMessage}
                        setLoading={this.props.setLoading}
                        loading={this.props.loading}
                        handleCancel={this.handleCloseCrewDialog}
                    />
                }
            </Card>
        );
    }
}

const styles = createStyles((theme: Theme) => ({
    // card: {
    //     maxWidth: 345,
    // },
    card: {
        height: '100%'
    },
    cardHeader: {
        whiteSpace: 'nowrap',
        overflow: "hidden",
    },
    cardHeaderText: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    center:{
        textAlign: 'center',
    },
    emptyImage: {
        textAlign: 'center',
        marginLeft: 'auto',
        marginRight: 'auto',
        backgroundColor: '#cacaca',
        height: 0,
        paddingBottom: '36%',
        paddingTop: '20%',
    },
    Pending: {
        textAlign: 'center',
        color: orange[500],
    },
    Accepted: {
        textAlign: 'center',
        color: green[500],
    },
    Draft: {
        textAlign: 'center',
        color: blue[500],
    },
    Declined: {
        textAlign: 'center',
        color: red[500],
    },
    Withdrawn: {
        textAlign: 'center',
        color: grey[500],
    },
    Waitlisted: {
        textAlign: 'center',
        color: deepPurple[500],
    },
    carHeader: {
        width: '60%'
    },
    spacingButton: {
        '& > *': {
            textAlign: "center",
        },
    },
    withdrawButton: {
        color: theme.palette.getContrastText(purple[500]),
        backgroundColor: purple[500],
        '&:hover': {
            backgroundColor: purple[700],
        },
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
}))

export default withWidth()(withStyles(styles)(CarCard));