import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import DateFnsUtils from '@date-io/date-fns';
import {MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import {countries} from '../lists/CountryList';
import { withStyles, createStyles, Theme } from '@material-ui/core/styles';
import { MenuItem } from '@material-ui/core';
// @ts-ignore
import MuiPhoneNumber from "material-ui-phone-number";

 
export interface IEntrantFormProps {
    // entrant: Entrant;
    setMessage: any;
    classes:any;
    handleChange:any;
    entrantFName: string;
    entrantMName: string | undefined;
    entrantLName: string;
    entrantPhone: string;
    entrantDOB: Date | null;
    entrantAdd1: string;
    entrantAdd2: string | undefined;
    entrantAdd3: string | undefined;
    entrantCity: string;
    entrantState: string;
    entrantCountry: string;
    entrantPostal: string;
    entrantEmail:string;
    handleAutoSuggestChange: any;
    validationAttempt: boolean;
    editMode: boolean;
    entrantId: string;
    handlePhoneChange: any;
    handleDateChange: any;
  }

export interface IEntrantFormState {
    dateValid:boolean;
    editMode:boolean;
}

export class EntrantForm extends Component<IEntrantFormProps, IEntrantFormState> {    
    static displayName = EntrantForm.name;
    public constructor(props: Readonly<IEntrantFormProps>) {
        super(props);
        this.state = {
            dateValid:true,
            editMode: true
        };
    }

    public validateDate(date: Date | null) : boolean{
        this.setState({dateValid : true})
        if (date === null || date > new Date())
        {
            this.setState({dateValid : false})
        }
        return this.state.dateValid;
    }
    public render() : React.ReactElement{
        const {classes} = this.props;
        return(  
            <React.Fragment>
                <Grid container spacing={4} className={classes.center}>
                    <Grid item xs={12} sm={4}>
                    <TextField
                        inputProps={{
                            readOnly: !this.state.editMode
                            }
                        }                            
                        name="entrantFName"
                        label="First Name"
                        className={classes.textField}
                        onChange={this.props.handleChange}
                        value={this.props.entrantFName}
                        margin="normal"
                        required
                        fullWidth
                        disabled={!this.state.editMode}
                        error={this.props.validationAttempt && this.props.entrantFName === ""}
                        helperText={(this.props.validationAttempt && this.props.entrantFName === "") ? "Required Field" : ""}
                    />
                    </Grid>
                    <Grid item xs={12} sm={4}>              
                        <TextField
                            inputProps={{
                                readOnly: !this.state.editMode
                                }
                            }                            
                            name="entrantMName"
                            label="Middle Name"
                            className={classes.textField}
                            onChange={this.props.handleChange}
                            value={this.props.entrantMName}
                            fullWidth
                            margin="normal"
                            disabled={!this.state.editMode}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>              
                        <TextField
                            inputProps={{
                                readOnly: !this.state.editMode
                                }
                            }                            
                            name="entrantLName"
                            label="Last Name"
                            className={classes.textField}
                            onChange={this.props.handleChange}
                            value={this.props.entrantLName}
                            margin="normal"
                            disabled={!this.state.editMode}
                            fullWidth
                            required
                            error={this.props.validationAttempt && this.props.entrantLName === ""}
                            helperText={(this.props.validationAttempt && this.props.entrantLName === "") ? "Required Field" : ""}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                    <MuiPhoneNumber 
                        defaultCountry={'us'}
                        name="entrantPhone"
                        label="Phone Number"
                        className={classes.textField}
                        onChange={this.props.handlePhoneChange}
                        value={this.props.entrantPhone}
                        disabled={!this.state.editMode}
                        fullWidth
                        required
                        margin="normal"                        
                        error={this.props.validationAttempt && this.props.entrantPhone.replace(/[\W\D\S]/,"") === ""}
                    />
                        {/* <TextField
                            name="entrantPhoneCode"
                            label="Calling Code"
                            required
                            className={classes.phoneCode}
                            onChange={this.props.handleChange}
                            value={this.props.entrantPhoneCode}
                            margin="normal"      
                            disabled={!this.state.editMode}                      
                            InputProps={{
                                startAdornment: <InputAdornment position="start">+</InputAdornment>,                                
                                inputComponent: TextMaskCode as any,
                                readOnly: !this.state.editMode
                            }}
                            InputLabelProps={{ shrink: true }}
                        />
                        <TextField
                            name="entrantPhone"
                            label="Phone Number"
                            className={classes.textField}
                            onChange={this.props.handleChange}
                            value={this.props.entrantPhone}
                            margin="normal"
                            disabled={!this.state.editMode}
                            required
                            error={this.props.validationAttempt && this.props.entrantPhone.replace(/[\W\D\S]/,"") === ""}
                            helperText={(this.props.validationAttempt && this.props.entrantPhone.replace(/[\W\D\S]/,"") === "") ? "Required Field" : ""}
                            InputProps={{
                                inputComponent: TextMaskPhone  as any,
                                readOnly: !this.state.editMode
                            }}
                        /> */}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker                            
                            disabled={!this.state.editMode}
                            required
                            disableToolbar
                            variant="inline"
                            format="MM/dd/yyyy"
                            margin="normal"
                            name="entrantDOB"
                            id="entrantDOB"
                            label="Date of Birth"
                            placeholder="MM/dd/yyyy"
                            autoOk
                            maxDate={new Date()}
                            value={this.props.entrantDOB}
                            error={(this.props.validationAttempt && this.props.entrantDOB === null) || !this.state.dateValid}
                            helperText={(this.props.validationAttempt && this.props.entrantDOB === null) ? "Required Field" : (!this.state.dateValid) ? "Invalid Date" : ""}
                            onChange={date => {
                                if(this.validateDate(date))this.props.handleDateChange(date, "entrantDOB");
                            }}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={12}>              
                        <TextField
                            inputProps={{
                                readOnly: !this.state.editMode
                                }
                            }
                            disabled={!this.state.editMode}
                            name="entrantAdd1"
                            label="Street Address 1"
                            className={classes.textField}
                            onChange={this.props.handleChange}
                            value={this.props.entrantAdd1}
                            margin="normal"
                            required
                            error={this.props.validationAttempt && this.props.entrantAdd1 === ""}
                            helperText={(this.props.validationAttempt && this.props.entrantAdd1 === "") ? "Required Field" : ""}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            inputProps={{
                                readOnly: !this.state.editMode
                                }
                            }        
                            disabled={!this.state.editMode}                    
                            name="entrantAdd2"
                            label="Street Address 2 (Optional)"
                            className={classes.textField}
                            onChange={this.props.handleChange}
                            value={this.props.entrantAdd2}
                            margin="normal"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            inputProps={{
                                readOnly: !this.state.editMode
                                }
                            }
                            disabled={!this.state.editMode}
                            name="entrantAdd3"
                            label="Street Address 3 (Optional)"
                            className={classes.textField}
                            onChange={this.props.handleChange}
                            value={this.props.entrantAdd3}
                            margin="normal"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <TextField
                        inputProps={{
                            readOnly: !this.state.editMode
                            }
                        }
                        disabled={!this.state.editMode}
                        select
                        required
                        label="Country"
                        name="entrantCountry"
                        onChange={this.props.handleChange}
                        value={this.props.entrantCountry}
                        className={classes.textField}
                        SelectProps={{
                            MenuProps: {
                                className: classes.menu,
                            },
                        }}
                        margin="normal"
                        fullWidth                        
                        error={this.props.validationAttempt && this.props.entrantCountry === ""}
                        helperText={(this.props.validationAttempt && this.props.entrantCountry === "") ? "Required Field" : ""}
                        >
                        {countries.map((option, index) => (
                            <MenuItem key={index} value={option.label}>
                                {option.label}
                            </MenuItem>
                        ))}
                        </TextField>
                    </Grid>
                    {(this.props.entrantCountry === "United States" || this.props.entrantCountry === "Canada") &&
                    <Grid item xs={12} sm={3}>              
                        <TextField
                            inputProps={{
                                readOnly: !this.state.editMode,
                                maxLength: 2
                                }
                            }
                            name="entrantState"
                            label="State/Province"
                            className={classes.textField}
                            onChange={this.props.handleChange}
                            value={this.props.entrantState.toUpperCase()}
                            disabled={(!this.state.editMode) || (this.props.entrantCountry !== "United States" && this.props.entrantCountry !== "Canada")}
                            margin="normal"
                            required
                            fullWidth
                            error={(this.props.entrantCountry === "United States" || this.props.entrantCountry === "Canada") &&this.props.validationAttempt && this.props.entrantState === ""}
                            helperText={(this.props.entrantCountry === "United States" || this.props.entrantCountry === "Canada") && (this.props.validationAttempt && this.props.entrantState === "") ? "Required Field" : ""}
                        />
                    </Grid>}
                    <Grid item xs={12} sm={3}>              
                        <TextField
                            inputProps={{
                                readOnly: !this.state.editMode
                                }
                            }
                            disabled={!this.state.editMode}
                            name="entrantCity"
                            label="City"
                            className={classes.textField}
                            onChange={this.props.handleChange}
                            value={this.props.entrantCity}
                            margin="normal"
                            required
                            fullWidth
                            error={this.props.validationAttempt && this.props.entrantCity === ""}
                            helperText={(this.props.validationAttempt && this.props.entrantCity === "") ? "Required Field" : ""}
                        />
                    </Grid>                 
                    <Grid item xs={12} sm={3}>              
                        <TextField
                            inputProps={{
                                readOnly: !this.state.editMode
                                }
                            }
                            disabled={!this.state.editMode}
                            name="entrantPostal"
                            label="Postal Code"
                            className={classes.textField}
                            value={this.props.entrantPostal}
                            onChange={this.props.handleChange}
                            margin="normal"
                            required
                            fullWidth
                            error={this.props.validationAttempt && this.props.entrantPostal === ""}
                            helperText={(this.props.validationAttempt && this.props.entrantPostal === "") ? "Required Field" : ""}
                        />
                    </Grid>
                </Grid>    
            </React.Fragment>
            
            
        );
    }
}

const styles = createStyles((theme: Theme) => ({
    button: {
      margin: theme.spacing(1),
    },
    paper:{    
      padding: theme.spacing(2, 2),
      marginLeft: '25%',
      marginRight: '25%',
    },  
    center:{
        textAlign: 'center'
    },
    backButton: {
      marginRight: theme.spacing(1),
    },
    completed: {
      display: 'inline-block',
    },
    wizardControl:{
        marginTop: 15,
        textAlign: 'center', 
    },
    regStep:{
        textAlign: 'center', 
    },
    phoneCode:{
        width: 50,
    },
    root:{
        width: '90%',
        disabled:{
            color: "rgba(0, 0, 0, .67);",
            fontStyle: 'italic',
            fontWeight: 'bold'
        }
    },
  }))
  
  export default withStyles(styles)(EntrantForm);