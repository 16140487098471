import React, { Component } from 'react';
import { withStyles, createStyles, Theme } from '@material-ui/core/styles';
import PersonIcon from '@material-ui/icons/Person';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import PeopleIcon from '@material-ui/icons/People';
import CarForm from './CarForm';
import DriverForm from './DriverForm';
import CrewForm from './CrewForm';
import LoungeForm from './LoungeForm';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import { Button, Box, Stepper, Step, StepLabel, Typography, StepButton, CircularProgress } from '@material-ui/core';
import { green, red, blue } from '@material-ui/core/colors';
import Crew from '../interfaces/ICrew';
import Class from '../interfaces/IClass';
import clsx from 'clsx';
import { StepIconProps } from '@material-ui/core/StepIcon';
import Car from '../interfaces/ICar';
import Entrant from '../interfaces/IEntrant';
import authService from '../services/api-authorization/AuthorizeService';
import Entry from '../interfaces/IEntry';
import StepConnector from '@material-ui/core/StepConnector';
import Container from '@material-ui/core/Container';
import FlagIcon from '@material-ui/icons/Flag';
import AdminRaceForm from './AdminRaceForm';
import LandscapeIcon from '@material-ui/icons/Landscape';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import AccountForm from './AccountInfoAdmin';
import PaddockInfo from './PaddockInfo';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PrepShop from '../interfaces/IPrepShop';
import { PhoneNumberUtil, PhoneNumber } from 'google-libphonenumber';
import LoungeMember from '../interfaces/ILoungeMember';
import Race from '../interfaces/IRace';
import CarRace from '../interfaces/ICarRace';
import APIService from '../services/APIService';
import convert from '../util/UnitConversion';
//                     ____ 
//                   .'* *.'
//                __/_*_*(_
//               / _______ \
//              _\_)/___\(_/_ 
//            /_(  u     u ))_\
//            \ \())  w  (()/ /
//              ' \(((())))/ '
//             / ' \)).))/ ' \
//            / _ \ - | - /_  \
//           (   ( .;''';. .'  )
//           _\"__ /    )\ __"/_
//             \/  \   ' /  \/
//              .'  '...' ' )
//               / /  |  \ \
//              / .   .   . \
//             /   .     .   \
//            /   /   |   \   \
//          .'   /    b    '.  '.
//      _.-'    /     Bb     '-. '-._ 
//  _.-'       |      BBb       '-.  '-. 
// (________mrf\____.dBBBb.________)____)
interface ICarWizardAdminProps {
    classes: any;
    imperial: boolean;
    handleCancel: any;
    setMessage: any;
    editMode: boolean;
    setLoading: any;
    entry: Entry;
    entrant: Entrant;
    car?: Car;
    loading: boolean;
    isAdmin: boolean;
}
interface ICarWizardAdminState {
    _isMounted: boolean;
    steps: number[];
    completedSteps: number[];
    activeStep: number;
    skippedSteps: number[];
    prevActiveStep: number;
    steplabels: string[];
    selectedRaces: Race[];
    raceOptions: Race[];
    runGroups: CarRace[];
    status: string;
    isPaid:boolean;

    carYear?: number;
    carMake: string;
    carModel: string;
    carChassis: string;
    carClass: any;
    carColor: string;
    transponderRental: boolean;
    transponderNumber?: number;
    displacement?: number;
    horsepower?: number;
    induction: string;
    lubeSys: string;
    brakeSys: string;
    originalBrakes: boolean;
    modded: boolean;
    modifications: string;
    tireMake: string;
    tireModel: string;
    tireFrontSize: string;
    tireRearSize: string;
    wheelFrontWidth: number | null;
    wheelRearWidth: number | null;
    wheelFrontDia: number | null;
    wheelRearDia: number | null;
    prefCarNum?: string;
    altCarNum?: string;
    altCarNum2?: string;
    carHistory: string;
    imperial: boolean;
    carImage: File | null;
    adminNotes: string;

    ClassesList: Class[];
    sameAsEntrant: boolean
    driverEmail: string;
    driverEmailConfirm: string;
    driverFName: string;
    driverMName?: string;
    driverLName: string;
    driverPhone: string;
    driverDOB: Date | null;
    driverAdd1: string;
    driverAdd2?: string;
    driverAdd3?: string;
    driverCity: string;
    driverState: string;
    driverCountry: string;
    driverPostal: string;
    ICEName: string;
    ICENumber: string;
    driverLicenseIssuer: string;
    driverLicenseExpiry: Date | null;
    driverMedicalIssuer: string;
    driverMedicalExpiry: Date | null;
    driverYears?: number;
    driverNumRaces?: number;
    driverRacesList: string;
    driverIncidents: string;
    driverSuspensions: string;
    blackFlagged: boolean;
    blackFlaggedReason: string;
    driverId?: string;
    driverPeopleId?: string;
    crew: Crew[];
    loungeMembers: LoungeMember[];
    tshirtSize: string;
    setMessage: any;
    handleChange: any;
    handleAutoSuggestChange: any;
    handleToggle: any;
    handleChangeUnit: any;
    handleDateChange: any;
    updateCrew: any;
    handleImageUpload: any;
    deleteImage: any;
    handleClassSelect: any;
    handlePhoneChange: any;
    addHistoricalCar: any;
    showDialog: boolean;
    historicalImage: boolean;
    historicalId: string;
    validationAttempt: boolean;
    editMode: boolean;

    dialogContent: string;
    scrollAtBottom?: boolean;

    //ENTRANT INFO
    entrantFName: string;
    entrantMName: string | undefined;
    entrantLName: string;
    entrantPhone: string;
    entrantDOB: Date | null;
    entrantAdd1: string;
    entrantAdd2: string | undefined;
    entrantAdd3: string | undefined;
    entrantCity: string;
    entrantState: string;
    entrantCountry: string;
    entrantPostal: string;
    entrantEmail: string;
    entrantId: string;

    //PADDOCK INFO
    handlePrepShopSelect: any;
    removableTrailer: boolean;
    removableTrailerLength: number | null;
    transporterType: string;
    transporterLength: number | null;
    transporterWidth: number | null;
    awning: boolean;
    awningWidth: number | null;
    awningLength: number | null;
    sharePitWith: string;
    pitNextTo: string;
    prepShop: PrepShop | null;
    customPrepShopTeam: string;
    customPrepShopNumber: string;
    customPrepShopEmail: string;
    isInterestedInTOP: boolean;
    customPrepShop: boolean;
    awningOrientation: string;
    updateLoungeMembers: any;
    handleRaceSelect: any;
    changeRungroup: any;
}

export class CarWizardAdmin extends Component<ICarWizardAdminProps, ICarWizardAdminState> {
    private apiService: APIService = new APIService;
    public constructor(props: Readonly<ICarWizardAdminProps>) {
        super(props);
        this.state = {
            _isMounted: false,
            steps: [0, 1, 2, 4, 5, 6],
            completedSteps: [],
            activeStep: 0,
            skippedSteps: [],
            prevActiveStep: -1,
            steplabels: ["Car Information", "Driver Information", "Crew Information", "Paddock Information", "Entrant Information", "Race Information"],

            status: "",
            carMake: "",
            carImage: null,
            transponderRental: false,
            modded: false,
            originalBrakes: false,
            isPaid: false,
            runGroups:[],
            raceOptions:[],
            selectedRaces: [],
            tireFrontSize: "",
            tireRearSize: "",
            wheelFrontDia: null,
            wheelRearDia: null,
            wheelFrontWidth: null,
            wheelRearWidth: null,
            carYear: undefined,
            carModel: "",
            carChassis: "",
            carClass: "",
            carColor: "",
            transponderNumber: undefined,
            displacement: undefined,
            horsepower: undefined,
            induction: "",
            lubeSys: "",
            brakeSys: "",
            modifications: "",
            tireMake: "",
            tireModel: "",
            prefCarNum: "",
            altCarNum: "",
            altCarNum2: "",
            carHistory: "",

            ClassesList: [],
            sameAsEntrant: false,

            driverEmail: "",
            driverEmailConfirm: "",
            driverFName: "",
            driverMName: "",
            driverLName: "",
            driverPhone: "",
            driverDOB: null,
            driverAdd1: "",
            driverAdd2: "",
            driverAdd3: "",
            driverCity: "",
            driverState: "",
            driverPostal: "",
            ICEName: "",
            ICENumber: "",
            tshirtSize: "M",
            driverLicenseIssuer: "",
            driverLicenseExpiry: null,
            driverMedicalIssuer: "",
            driverMedicalExpiry: null,
            driverYears: undefined,
            driverNumRaces: undefined,
            driverRacesList: "",
            driverIncidents: "",
            driverSuspensions: "",
            blackFlaggedReason: "",
            blackFlagged: false,
            driverCountry: "",
            historicalImage: false,
            showDialog: false,
            historicalId: "",
            dialogContent: "",
            adminNotes: "",
            crew: [],
            loungeMembers: [],
            handlePhoneChange: this.handlePhoneChange.bind(this),
            handleChange: this.handleChange,
            handleDateChange: this.handleDateChange,
            handleAutoSuggestChange: this.handleAutoSuggestChange,
            handleToggle: this.handleToggle,
            handleChangeUnit: this.handleChangeUnit,
            updateCrew: this.updateCrew,
            handleImageUpload: this.handleImageUpload.bind(this),
            deleteImage: this.deleteImage.bind(this),
            handleClassSelect: this.handleClassSelect.bind(this),
            setMessage: this.props.setMessage,
            addHistoricalCar: this.addHistoricalCar.bind(this),
            updateLoungeMembers : this.updateLoungeMembers,
            validationAttempt: false,
            editMode: this.props.editMode,

            //ENTRANT
            entrantFName: this.props.entrant.fname,
            entrantMName: this.props.entrant.mname,
            entrantLName: this.props.entrant.lname,
            entrantPhone: this.props.entrant.phoneNumber,
            entrantDOB: this.props.entrant.dob,
            entrantAdd1: this.props.entrant.address1,
            entrantAdd2: this.props.entrant.address2,
            entrantAdd3: this.props.entrant.address3,
            entrantCity: this.props.entrant.city,
            entrantState: this.props.entrant.state,
            entrantCountry: this.props.entrant.country,
            entrantPostal: this.props.entrant.zipCode,
            entrantEmail: this.props.entrant.email,
            entrantId: this.props.entrant.id,
            imperial: true,

            //PADDOCK
            // entry: this.props.entry
            handlePrepShopSelect: this.handlePrepShopSelect,
            removableTrailer: this.props.entry.isRemovableTrailer,
            removableTrailerLength: this.props.entry.trailerLength,
            transporterType: this.props.entry.transporterType,
            transporterLength: this.props.entry.transporterLength,
            transporterWidth: this.props.entry.transporterWidth,
            awning: this.props.entry.hasTrasporterAwning,
            awningWidth: this.props.entry.awningWidth,
            awningLength: this.props.entry.awningLength,
            sharePitWith: this.props.entry.sharedSpaceParticipants,
            pitNextTo: this.props.entry.adjacentParticipants,
            prepShop: this.props.entry.prepShop,
            customPrepShopTeam: this.props.entry.prepShopName,
            customPrepShopNumber: this.props.entry.prepShopContactNumber,
            customPrepShopEmail: this.props.entry.prepShopEmailAddress,
            isInterestedInTOP: this.props.entry.isInterestedInTop,
            customPrepShop: false,
            awningOrientation: this.props.entry.awningOrientation,
            handleRaceSelect: this.handleRaceSelect,
            changeRungroup: this.changeRungroup
        }
        this.changeRungroup = this.changeRungroup.bind(this);
        this.getStepContent = this.getStepContent.bind(this);
        this.handleNext = this.handleNext.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
        this.updateCrew = this.updateCrew.bind(this);
        this.handleAutoSuggestChange = this.handleAutoSuggestChange.bind(this);
        this.handleImageUpload = this.handleImageUpload.bind(this);
        this.deleteImage = this.deleteImage.bind(this)
        this.ColorlibStepIcon = this.ColorlibStepIcon.bind(this);
        this.handleClassSelect = this.handleClassSelect.bind(this);
        this.handlePhoneChange = this.handlePhoneChange.bind(this);
        this.addHistoricalCar = this.addHistoricalCar.bind(this);
        this.closeDialog = this.closeDialog.bind(this);
        this.populateCarFromHistorical = this.populateCarFromHistorical.bind(this);
        this.showConfirmationDialog = this.showConfirmationDialog.bind(this);
        this.getEntrantDriverInfo = this.getEntrantDriverInfo.bind(this);
        this.ScrollChevron = this.ScrollChevron.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
        this.updateLoungeMembers = this.updateLoungeMembers.bind(this);
        this.apiService = new APIService;
        console.log(this.props.entry)
    }

    private addHistoricalCar(): void {
        this.setState({ showDialog: true })
    }
    private showConfirmationDialog(): void {
        this.setState({ dialogContent: "confirmation" })
        this.setState({ showDialog: true })
    }
    private closeDialog(): void {
        this.setState({ showDialog: false })
    }
    private handleImageUpload(file: File): void {
        this.setState({ historicalImage: false })
        this.setState({ carImage: file })
    };
    private deleteImage(): void {
        this.setState({ carImage: null })
    }
    private populateCarFromHistorical = (car: Car): void => {

        this.setState({
            carMake: car.make,
            carImage: car.carImageToDisplay,
            transponderRental: car.isTransponderRentalNeeded,
            modded: car.isModified,
            originalBrakes: car.isBrakeSystemOriginal,
            tireFrontSize: car.tireSizeFront,
            tireRearSize: car.tireSizeRear,
            wheelFrontDia: car.frontWheelDiameter,
            wheelRearDia: car.rearWheelDiameter,
            wheelFrontWidth: car.frontWheelWidth,
            wheelRearWidth: car.rearWheelWidth,
            carYear: car.year,
            carModel: car.model,
            carChassis: car.chassisNumber,
            carClass: "",
            carColor: car.color,
            transponderNumber: car.transponderNumber,
            displacement: car.displacement,
            horsepower: car.horsePower,
            induction: car.inductionType,
            lubeSys: car.lubricationSystem,
            brakeSys: car.brakeType,
            modifications: car.modificationReason,
            tireMake: car.tireMake,
            tireModel: car.tireModel,
            prefCarNum: car.preferredCarNumber,
            altCarNum: car.alternateCarNumber1,
            altCarNum2: car.alternateCarNumber2,
            carHistory: car.historyOfCar,
            adminNotes: car.adminNotes,
            historicalImage: (car.carImageToDisplay !== null) ? true : false,
            historicalId: car.id
        });
        this.closeDialog();
    }

    private handleClassSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newClass: Class = this.state.ClassesList.filter((item: any) => item.id === event.target.value)[0];
        this.setState({ carClass: newClass.name });
    }
    private handlePhoneChange = (phone: string, field: any) => {
        if(typeof field !== "string" ){
            field = "entrantPhone";
        }
        const newState = { [field]: phone } as any;
        console.log(newState)
        this.setState(newState);
    }
    private handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newState = { [event.target.name]: event.target.value } as any;
        this.setState(newState);
    };

    private handleAutoSuggestChange = (field: string, value: string) => {
        const newState = { [field]: value } as any;
        this.setState(newState);
    };

    private updateLoungeMembers = (loungeMembers: LoungeMember[]): void => {
        this.setState({ loungeMembers });
    };

    private handleDateChange = (date: Date | null, field: string) => {
        const newState = { [field]: date } as any;
        this.setState(newState);
    };
    
    private handlePrepShopSelect = (customPrepShop: PrepShop) => {
        this.setState({ prepShop: customPrepShop });
    }

    private handleRaceSelect = (race : Race) => {
        let current : Race[] = this.state.selectedRaces;
        if(current.some(x => x.id === race.id)){
            current.splice(current.indexOf(current.filter(x => x.id === race.id)[0]), 1);
        }else{
            current.push(race);
        }
        current.sort((a: Race, b: Race) => {
            let nameA = a.name.toUpperCase(); 
            let nameB = b.name.toUpperCase(); 
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            return 0;
         });
        this.setState({selectedRaces : current});
        let currentRunGroups = this.state.runGroups;
        if(!currentRunGroups.some(x => x.raceId === race.id)){
            if(this.props.car !== undefined){
                currentRunGroups.push({id: "", race : race, runGroup : "", raceId : race.id, carId : this.props.car.id})
            }
        } else{            
            currentRunGroups.splice(currentRunGroups.indexOf(currentRunGroups.filter(x => x.raceId === race.id)[0]), 1);
        }
        this.setState({runGroups : currentRunGroups});
    };

    private changeRungroup = (event: React.ChangeEvent<HTMLInputElement>) =>{
        let runGroups : CarRace[] = this.state.runGroups;
        runGroups.filter(x => x.raceId === event.target.name)[0].runGroup = event.target.value;
        this.setState({runGroups});
        
    }

    private getClassesList = async () => {
        const token = await authService.getAccessToken();
        const response = await fetch("/api/list/Classes", {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });

        await response.json().then((data) => {
            var arr: Class[] = [];
            if (data != null) {
                data.classes.forEach(function (value: any) {
                    arr.push(value)
                })
            }
            this.setState({ ClassesList: arr })
        });
    }

    private getEntrantDriverInfo = async () => {
        this.props.setLoading(true);
        const token = await authService.getAccessToken();
        const response = await fetch("/api/driver/" + this.state.entrantId, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        await response.json().then((data) => {
            console.log(data)
            if (data.result !== null) {
                this.setState({
                    driverIncidents: data.result.incidents, driverLicenseExpiry: data.result.licenseExpiry,
                    driverLicenseIssuer: data.result.licenseIssuer, driverMedicalExpiry: data.result.medicalExpiry,
                    driverMedicalIssuer: data.result.medicalIssuer, driverSuspensions: data.result.suspensions,
                    driverNumRaces: data.result.numOfVintageEvents, driverRacesList: data.result.racesList,
                    driverYears: data.result.yearsDriving, driverId: data.result.id,
                    driverPeopleId: data.result.peopleId, blackFlagged: data.result.isBlackFlagged,
                    blackFlaggedReason: data.result.blackFlaggedReason, ICEName: data.result.icename,
                    ICENumber: data.result.icenumber
                })
            }
            this.props.setLoading(false);
        }).catch((e) => {
            console.error(e)
            this.props.setLoading(false);
        })
    }
    private handleEntrant = async () => {
        this.setState({ validationAttempt: true });
        try {
            // const phoneUtil: PhoneNumberUtil = PhoneNumberUtil.getInstance();
            // const phoneNumberProto: PhoneNumber = phoneUtil.parse(this.state.entrantPhone);

            if ((this.state.entrantFName === "" || this.state.entrantFName === null) || (this.state.entrantLName === "" || this.state.entrantLName === null) ||
                (this.state.entrantPhone === "" || this.state.entrantPhone === null) || (this.state.entrantDOB === null) || (this.state.entrantAdd1 === "" || this.state.entrantAdd1 === null) ||
                (this.state.entrantCity === "" || this.state.entrantCity === null) || ((this.state.entrantCountry === "United States" || this.state.entrantCountry === "Canada") && (this.state.entrantState === "" || this.state.entrantState === null)) ||
                (this.state.entrantCountry === "" || this.state.entrantCountry === null) || (this.state.entrantPostal === "" || this.state.entrantPostal === null) || this.state.entrantDOB > new Date()) {
                this.props.setMessage("Missing Required Fields", "error");
                return;
            }
            // if (!phoneUtil.isValidNumber(phoneNumberProto)) {
            //     this.props.setMessage("Invalid Phone Format", "error");
            //     return;
            // }
        } catch (e) {
            console.error(e)
            this.props.setMessage("Not A Valid Phone Number", "error");
            return;
        }
        const token = await authService.getAccessToken();
        const response = await fetch("/api/entrant/", {
            method: 'PUT',
            headers: !token ? {} : {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(
                {
                    Fname: this.state.entrantFName,
                    Lname: this.state.entrantLName,
                    Mname: this.state.entrantMName,
                    Address1: this.state.entrantAdd1,
                    Address2: this.state.entrantAdd2,
                    Address3: this.state.entrantAdd3,
                    City: this.state.entrantCity,
                    State: (this.state.entrantCountry === "United States" || this.state.entrantCountry === "Canada") ? this.state.entrantState.toUpperCase() : "",
                    Dob: this.state.entrantDOB,
                    Zipcode: this.state.entrantPostal,
                    Country: this.state.entrantCountry,
                    PhoneNumber: this.state.entrantPhone,
                    id: this.state.entrantId
                }
            )
        });
        await response.json().then((data) => {
            this.props.setMessage("Information Successfully Updated.", "success")
            this.setState({ editMode: false })
        }).catch((e) => {
            this.props.setMessage("Error  processing entrant info. Please try again", "error")
        })
        this.setState({ validationAttempt: false });
    }
    private handleEntry = async () => {
        let customPrepShop;
        if (this.state.customPrepShop) {
            customPrepShop = { id: "00000000-0000-0000-0000-000000000000", name: this.state.customPrepShopTeam, phone: (this.state.customPrepShopNumber), email: this.state.customPrepShopEmail }
        }
        const entryToken = await authService.getAccessToken();
        const entryResponse = await fetch("/api/entryform/" + this.props.entry.id, {
            method: 'PUT',
            headers: !entryToken ? {} : {
                'Authorization': `Bearer ${entryToken}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(
                {
                    id: this.props.entry.id,
                    entrantId: this.props.entrant.id,
                    entrantFName: this.props.entrant.fname,
                    entrantMName: this.props.entrant.mname,
                    entrantLName: this.props.entrant.lname,
                    entrantEmail: this.props.entrant.email,
                    entrantPhoneNumber: this.props.entrant.phoneNumber,
                    isRemovableTrailer: this.state.removableTrailer,
                    trailerLength: this.state.imperial ? this.state.removableTrailerLength : convert(this.state.removableTrailerLength, "imperial", "feet"),
                    transporterLength: this.state.imperial ? this.state.transporterLength : convert(this.state.transporterLength, "imperial", "feet"),
                    transporterWidth: this.state.imperial ? this.state.transporterWidth : convert(this.state.transporterWidth, "imperial", "feet"),
                    transporterType: this.state.transporterType,
                    hasTrasporterAwning: this.state.awning,
                    awningWidth: this.state.imperial ? this.state.awningWidth : convert(this.state.awningWidth, "imperial", "feet"),
                    awningLength: this.state.imperial ? this.state.awningLength : convert(this.state.awningLength, "imperial", "feet"),
                    hasSharedSpace: (this.state.sharePitWith === "" || this.state.sharePitWith === null) ? false : true,
                    sharedSpaceParticipants: this.state.sharePitWith,
                    adjacentParticipants: this.state.pitNextTo,
                    sharePitWith: this.props.entry.sharedSpaceParticipants,
                    participatingYear: this.props.entry.participatingYear,
                    event: this.props.entry.event,
                    race: this.state.selectedRaces,
                    isInterestedInTop: this.state.isInterestedInTOP,
                    prepShop: (this.state.customPrepShop) ? customPrepShop : this.state.prepShop,
                    awningOrientation: this.state.awningOrientation,
                    // prepShopName: this.state.customPrepShopTeam,
                    // prepShopContactNumber: this.state.customPrepShopNumber,
                    // prepShopEmailAddress: this.state.customPrepShopEmail,
                }
            )
        });
        await entryResponse.json().then((data) => {
            this.props.setMessage("Information Successfully Updated.", "success")
            this.setState({ editMode: false })
        }).catch((e) => {
            this.props.setMessage("Error processing paddock info. Please try again", "error")
            console.error(e)
        })
        await fetch(`api/entrant/ChangeMeasurementSystemPreference/${this.state.imperial}/false`, {
            method: 'PUT',
            headers: !entryToken ? {} : {
                'Authorization': `Bearer ${entryToken}`,
                'Content-Type': 'application/json'
            },
        });
    }
    private handleCar = async () => {
        if (this.state.carYear === null || this.state.carMake === null || this.state.carModel === null ||
            this.state.carYear === undefined || this.state.carMake === "" || this.state.carModel === "") {
            this.props.setMessage("Car Year, Make, and Model are required to save as draft.", "error");
            this.props.setLoading(false);
            return;
        }
        if (this.state.driverYears !== undefined && (this.state.driverYears > 999 || this.state.driverYears < 0)) {
            this.setState({ driverYears: 0 })
        }
        if (this.state.driverNumRaces !== undefined && (this.state.driverNumRaces > 999 || this.state.driverNumRaces < 0)) {
            this.setState({ driverNumRaces: 0 })
        }
        var driverList: any = [{
            Fname: this.state.driverFName,
            Mname: this.state.driverMName,
            Lname: this.state.driverLName,
            Email: this.state.driverEmail,
            Address1: this.state.driverAdd1,
            Address2: this.state.driverAdd2,
            Address3: this.state.driverAdd3,
            PhoneNumber: this.state.driverPhone,
            City: this.state.driverCity,
            State: this.state.driverState,
            ZipCode: this.state.driverPostal,
            Country: this.state.driverCountry,
            UsesMetricSystem: !this.state.imperial,
            Icename: this.state.ICEName,
            Icenumber: this.state.ICENumber,
            LicenseIssuer: this.state.driverLicenseIssuer,
            LicenseExpiry: this.state.driverLicenseExpiry,
            MedicalIssuer: this.state.driverMedicalIssuer,
            MedicalExpiry: this.state.driverMedicalExpiry,
            YearsDriving: this.state.driverYears,
            RacesList: this.state.driverRacesList,
            Incidents: this.state.driverIncidents,
            Suspensions: this.state.driverSuspensions,
            IsBlackFlagged: this.state.blackFlagged,
            BlackFlaggedReason: this.state.blackFlaggedReason,
            NumOfVintageEvents: this.state.driverNumRaces,
            PeopleId: this.state.sameAsEntrant ? this.state.entrantId : "00000000-0000-0000-0000-000000000000"
        }]

        if (this.state.driverDOB !== null) {
            driverList[0].Dob = this.state.driverDOB
        }
        if (this.state.driverPeopleId !== undefined && this.state.driverPeopleId !== "") {
            driverList[0].PeopleId = this.state.driverPeopleId
        }
        if (this.state.driverId !== undefined && this.state.driverId !== "") {
            driverList[0].DriverId = this.state.driverId
        }
        // if( this.state.driverEmail === ""){
        //   DriverList = null;
        // }
        var crewList: Crew[] = [];
        this.state.selectedRaces.forEach(race => {
            this.state.crew.filter(x => x.race?.id === race.id).forEach(crew =>
                {
                    crewList.push(crew);
                })
        });

        var loungeList: LoungeMember[] = [];
        if(this.state.selectedRaces.some(x => x.hasLounge === true)){
            loungeList = this.state.loungeMembers;
        }
        let method = '';
        let uri = '';
        var car: any = {
            RegistrationFormId: this.props.entry.id,
            Model: this.state.carModel,
            Make: this.state.carMake,
            Year: this.state.carYear,
            ChassisNumber: this.state.carChassis,
            Class: this.state.carClass,
            Color: this.state.carColor,
            TransponderNumber: this.state.transponderNumber,
            IsTransponderRentalNeeded: this.state.transponderRental,
            Displacement: this.state.displacement,
            HorsePower: this.state.horsepower,
            InductionType: this.state.induction,
            LubricationSystem: this.state.lubeSys,
            BrakeType: this.state.brakeSys,
            IsBrakeSystemOriginal: this.state.originalBrakes,
            IsModified: this.state.modded,
            ModificationReason: this.state.modifications,
            TireMake: this.state.tireMake,
            TireModel: this.state.tireModel,
            TireSizeFront: this.state.tireFrontSize,
            TireSizeRear: this.state.tireRearSize,
            PreferredCarNumber: this.state.prefCarNum,
            AlternateCarNumber1: this.state.altCarNum,
            AlternateCarNumber2: this.state.altCarNum2,
            HistoryOfCar: this.state.carHistory,
            Race: this.state.selectedRaces,
            ParticipatingYear: this.props.entry.participatingYear,
            Status: this.state.status,
            IsPaid: false,
            DriverModel: driverList,
            Crew: crewList,
            Lounge: loungeList,
            adminNotes: this.state.adminNotes,
            CarRace: [],
            TshirtSize: this.state.tshirtSize,
            isPaid: this.state.isPaid,
            FrontWheelWidth: (this.state.imperial) ? this.state.wheelFrontWidth : convert(this.state.wheelFrontWidth, "imperial", "inches"),
            FrontWheelDiameter: (this.state.imperial) ? this.state.wheelFrontDia : convert(this.state.wheelFrontDia, "imperial", "inches"),
            RearWheelWidth: (this.state.imperial) ? this.state.wheelRearWidth : convert(this.state.wheelRearWidth, "imperial", "inches"),
            RearWheelDiameter: (this.state.imperial) ? this.state.wheelRearDia : convert(this.state.wheelRearDia, "imperial", "inches")
        };
        this.state.runGroups.forEach(race => {
            car.CarRace.push({RaceId: race.raceId, RunGroup: race.runGroup});
        });
        if (this.props.car !== undefined) {
            car.Id = this.props.car.id
        }
        const carToken = await authService.getAccessToken();
        if (this.props.car !== undefined) {
            method = 'PUT';
            uri = `/api/entryform/${this.props.entry.id}/car/${this.props.car.id}`;
        } else {
            method = 'POST';
            uri = `/api/entryform/${this.props.entry.id}/car`;
        }
        const carResponse = await fetch(uri, {
            method: method,
            headers: !carToken ? {} : {
                'Authorization': `Bearer ${carToken}`
                , 'Content-Type': 'application/json'
            },
            body: JSON.stringify(car)
        });

        await carResponse.json().then(async (data) => {
            await fetch(`api/entrant/ChangeMeasurementSystemPreference/${this.state.imperial}/false`, {
                method: 'PUT',
                headers: !carToken ? {} : {
                    'Authorization': `Bearer ${carToken}`,
                    'Content-Type': 'application/json'
                },
            });

            if (carToken != null && data != null && data.result.result != null && data.result.result.id != null) {
                //copy over image from past car
                await this.processImage(data, carToken);
            }
            else if (carToken != null && data != null && data.result != null && data.result.id != null) {
                if (this.state.historicalImage) {
                    const response = await fetch(`/api/entryform/${this.props.entry.id}/car/${data.result.id}/copyImage/${this.state.historicalId}`, {
                        method: 'PUT',
                        headers: !carToken ? {} : {
                            'Authorization': `Bearer ${carToken}`
                            , 'Content-Type': 'application/json'
                        },
                    });
                    response.json().catch((e) => {
                        console.error(e)
                        this.props.setMessage("Error processing car info. Please try again", "error")
                    })
                }
                else if (this.state.carImage != null && this.state.carImage.type !== undefined) {
                    var oReq = new XMLHttpRequest();
                    const content_: FormData = new FormData();
                    content_.append("CarImageFile", this.state.carImage as Blob, this.state.carImage.name);
                    oReq.open("POST", `/api/entryform/${data.result.registrationFormId}/car/${data.result.id}/uploadCarImage`, true);
                    oReq.setRequestHeader("Authorization", "Bearer " + carToken);
                    oReq.onload = function () { }
                    oReq.send(content_);
                }
            }
            this.props.setMessage("Information Successfully Added.", "success")

        }).then(() => { this.props.handleCancel(); }).catch((e) => {
            console.error(e)
            this.props.setMessage("Error processing car info. Please try again", "error")
        }).finally(() => {
            this.closeDialog();
        })
    }
    private handleSubmit = async (action: string) => {

        this.props.setLoading(true);
        // HANDLING ENTRY START
        this.handleEntry().then(() =>{
            this.handleEntrant().then(() =>{
                this.handleCar();
            });
        })
    };

    private handleToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.name === "sameAsEntrant" && !this.state[event.target.name as keyof ICarWizardAdminState]) {
            this.setState({ driverPhone: this.state.entrantPhone });
            this.setState({
                driverAdd1: this.state.entrantAdd1, driverAdd2: this.state.entrantAdd2, driverAdd3: this.state.entrantAdd3, driverCity: this.state.entrantCity,
                driverCountry: this.state.entrantCountry, driverDOB: this.state.entrantDOB, driverEmail: this.state.entrantEmail, driverEmailConfirm: this.state.entrantEmail,
                driverFName: this.state.entrantFName, driverLName: this.state.entrantLName, driverMName: this.state.entrantMName, driverPostal: this.state.entrantPostal,
                driverState: this.state.entrantState
            })
            this.getEntrantDriverInfo();
        }
        if (event.target.name === "sameAsEntrant" && this.state[event.target.name as keyof ICarWizardAdminState]) {
            this.setState({ driverPhone: "" });
            this.setState({
                driverAdd1: "", driverAdd2: "", driverAdd3: "", driverCity: "",
                driverCountry: "", driverDOB: null, driverEmail: "", driverEmailConfirm: "",
                driverFName: "", driverLName: "", driverMName: "", driverPostal: "",
                driverState: ""
            })
            
            this.setState({
                driverIncidents: "", ICENumber: "",
                driverLicenseExpiry: null, driverLicenseIssuer: "",
                driverMedicalExpiry: null, driverMedicalIssuer: "",
                driverSuspensions: "", driverNumRaces: undefined,
                driverRacesList: "", driverYears: undefined,
                driverId: "", driverPeopleId: "", blackFlagged: false,
                blackFlaggedReason: "", ICEName: ""
            })
        }
        const newState = { [event.target.name]: !this.state[event.target.name as keyof ICarWizardAdminState] } as any;
        this.setState(newState);
    }

    private updateCrew = (crewMembers: Crew[], crew: string): void => {
        const newState = { [crew]: crewMembers } as any;
        this.setState(newState);
    };

    private processImage = async (data: any, token: string) => {
        var oReq;
        if (this.state.carImage != null && this.state.carImage.type !== undefined) {
            oReq = new XMLHttpRequest();
            const content_: FormData = new FormData();
            content_.append("CarImageFile", this.state.carImage as Blob, this.state.carImage.name);
            oReq.open("POST", `/api/entryform/${data.result.result.registrationFormId}/car/${data.result.result.id}/uploadCarImage`, true);
            oReq.setRequestHeader("Authorization", "Bearer " + token);
            oReq.onload = function () { };
            oReq.send(content_);
        }
        else
            //Remove from DB if image is null
            if (this.state.carImage === null) {
                oReq = new XMLHttpRequest();
                const content_: FormData = new FormData();
                content_.append("CarImageFile", new Blob(), "");
                oReq.open("POST", `/api/entryform/${data.result.result.registrationFormId}/car/${data.result.result.id}/uploadCarImage`, true);
                oReq.setRequestHeader("Authorization", "Bearer " + token);
                oReq.onload = function () { };
                oReq.send(content_);
            }
        return oReq;
    }

    private convertCar(value: number | null, targetMeasurementSystem: string): number | null {
        if (value !== null) {
            if (targetMeasurementSystem === "imperial") {
                return (Math.round(value / 25.4 * 100) / 100)
            } else {
                return (Math.round(value * 25.4 * 100) / 100)
            }
        }
        else {
            return null;
        }
    }
    private convertPaddock(value: number | null, targetMeasurementSystem: string): number | null {
        if (value !== null) {
            if (targetMeasurementSystem === "imperial") {
                return (Math.round(value / 3.281 * 100) / 100)
            } else {
                return (Math.round(value * 3.281 * 100) / 100)
            }
        }
        else {
            return null;
        }
    }

    private handleChangeUnit = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
        if (event.target.value === "imperial" && this.state.imperial === false) {
            this.setState({
                awningLength: convert(this.state.awningLength, "imperial", "feet"),
                awningWidth: convert(this.state.awningWidth, "imperial", "feet"),
                transporterLength: convert(this.state.transporterLength, "imperial", "feet"),
                transporterWidth: convert(this.state.transporterWidth, "imperial", "feet"),
                removableTrailerLength: convert(this.state.removableTrailerLength, "imperial", "feet"),
                wheelFrontDia: convert(this.state.wheelFrontDia, "imperial", "in"),
                wheelRearDia: convert(this.state.wheelRearDia, "imperial", "in"),
                wheelFrontWidth: convert(this.state.wheelFrontWidth, "imperial", "in"),
                wheelRearWidth: convert(this.state.wheelRearWidth, "imperial", "in"), 
                imperial: true
            });
        }
        if (event.target.value === "metric" && this.state.imperial === true) {
            this.setState({
                awningLength: convert(this.state.awningLength, "metric", "meters"),
                awningWidth: convert(this.state.awningWidth, "metric", "meters"),
                transporterLength: convert(this.state.transporterLength, "metric", "meters"),
                transporterWidth: convert(this.state.transporterWidth, "metric", "meters"),
                removableTrailerLength: convert(this.state.removableTrailerLength, "metric", "meters"),
                wheelFrontDia: convert(this.state.wheelFrontDia, "metric", "mm"),
                wheelRearDia: convert(this.state.wheelRearDia, "metric", "mm"),
                wheelFrontWidth: convert(this.state.wheelFrontWidth, "metric", "mm"),
                wheelRearWidth: convert(this.state.wheelRearWidth, "metric", "mm"), 
                imperial: false
            });
        }
    }
    
    private handleNext(): void {
        this.setState({ validationAttempt: true });
        switch (this.state.activeStep) {
            case 0:
                if ((this.state.carYear === null || this.state.carYear === undefined) || (this.state.induction === null || this.state.induction === "") ||
                    (this.state.carMake === null || this.state.carMake === "") || (this.state.carModel === null || this.state.carModel === "") ||
                    (this.state.selectedRaces.length === 0) ||
                    (this.state.carChassis === null || this.state.carChassis === "") || (this.state.wheelRearDia === null || this.state.wheelRearDia === undefined) ||
                    (this.state.wheelFrontDia === null || this.state.wheelFrontDia === undefined) || (this.state.wheelRearWidth === null || this.state.wheelRearWidth === undefined) ||
                    (this.state.wheelFrontWidth == null || this.state.wheelFrontWidth === undefined) || (this.state.carColor === null || this.state.carColor === "") ||
                    (this.state.displacement === null || this.state.displacement === undefined) || (this.state.horsepower === null || this.state.horsepower === undefined) ||
                    (this.state.lubeSys === null || this.state.lubeSys === "") || (this.state.brakeSys === null || this.state.brakeSys === "") ||
                    ((this.state.modded && this.state.modifications === null) || (this.state.modded && this.state.modifications === "")) ||
                    (this.state.tireMake === null || this.state.tireMake === "") || (this.state.tireModel === null || this.state.tireModel === "") ||
                    (this.state.tireFrontSize === null || this.state.tireFrontSize === "") || (this.state.tireRearSize === null || this.state.tireRearSize === "") ||
                    (this.state.altCarNum === null || this.state.altCarNum === "") || (this.state.altCarNum2 === null || this.state.altCarNum2 === "") ||
                    (this.state.prefCarNum === null || this.state.prefCarNum === "") || (this.state.carHistory === null || this.state.carHistory === "")) {
                    this.props.setMessage("Missing Required Fields", "error");
                    return;
                }
                if (this.state.carYear !== undefined && (this.state.carYear > (new Date().getFullYear() + 1) || this.state.carYear < 1900)) {
                    this.props.setMessage("Invalid Information", "error");
                    return;
                }
                if(this.state.carImage?.size === 0){
                    this.props.setMessage("Image cannot be 0KB.", "error")
                }
                if (isNaN(this.state.carYear) || this.state.carYear as any === "" || isNaN(this.state.wheelFrontDia) || this.state.wheelFrontDia as any === "" ||
                    isNaN(this.state.wheelFrontWidth) || this.state.wheelFrontWidth as any === "" || isNaN(this.state.wheelRearWidth) || this.state.wheelRearWidth as any === "" ||
                    isNaN(this.state.wheelRearDia) || this.state.wheelRearDia as any === "" || isNaN(this.state.displacement) || this.state.displacement as any === "" ||
                    isNaN(this.state.horsepower) || this.state.horsepower as any === "") {
                    this.props.setMessage("Value Must Be a Number", "error");
                    return;
                }
                break;
            case 1:
                if (this.state.driverFName === null || this.state.driverLName === null || this.state.driverEmail === null || this.state.driverEmailConfirm === null || this.state.driverPhone === null ||
                    this.state.driverDOB === null || this.state.driverCity === null || this.state.driverPostal === null ||
                    this.state.driverNumRaces === null || (this.state.blackFlagged && this.state.blackFlaggedReason === null) || this.state.driverRacesList === null || this.state.driverIncidents === null ||
                    this.state.driverCountry === null || this.state.driverLicenseExpiry === null || this.state.driverLicenseIssuer === null || this.state.driverMedicalExpiry === null ||
                    this.state.driverMedicalIssuer === null || this.state.driverYears === null || this.state.driverSuspensions === null ||
                    this.state.driverFName === "" || this.state.driverLName === "" || this.state.driverEmail === "" || this.state.driverEmailConfirm === "" || this.state.driverPhone === "" ||
                    this.state.driverCity === "" || ((this.state.driverCountry === "United States" || this.state.driverCountry === "Canada") && (this.state.driverState === "" || this.state.driverState === null)) || this.state.driverPostal === "" ||
                    this.state.driverNumRaces === undefined || (this.state.blackFlagged && this.state.blackFlaggedReason === "") || this.state.driverRacesList === "" || this.state.driverIncidents === "" ||
                    this.state.driverCountry === "" || this.state.driverLicenseIssuer === "" || this.state.driverMedicalIssuer === "" || this.state.driverYears === undefined || this.state.driverSuspensions === "") {
                    this.props.setMessage("Missing Required Fields", "error");
                    return;
                }
                if (this.state.driverEmail.toLowerCase() !== this.state.driverEmailConfirm.toLowerCase()) {
                    this.props.setMessage("Emails Do Not Match", "error");
                    return;
                }
                if (this.state.driverDOB > new Date() || new Date(this.state.driverDOB).toString() === "Invalid Date" ||
                    new Date(this.state.driverMedicalExpiry).toString() === "Invalid Date" || new Date(this.state.driverLicenseExpiry).toString() === "Invalid Date") {
                    this.props.setMessage("Invalid date", "error");
                    return;
                }
                if (this.state.driverYears > 999 || this.state.driverYears < 0) {
                    this.setState({ driverYears: 0 })
                }
                if (this.state.driverNumRaces > 999 || this.state.driverNumRaces < 0) {
                    this.setState({ driverNumRaces: 0 })
                }
                if (isNaN(this.state.driverYears) || this.state.driverYears as any === "" || isNaN(this.state.driverNumRaces) || this.state.driverNumRaces as any === "") {
                    this.props.setMessage("Value Must Be a Number", "error");
                    return;
                }
                break;
            case 2:
                break;
            case 3:
                break;
            default:
        }
        const stepper = document.getElementById("stepper");
        if (stepper !== null) {
            stepper.scrollTop = 0;
        }
        this.setState({ scrollAtBottom: false });
        this.setState({ prevActiveStep: this.state.activeStep });
        this.setState({ activeStep: this.state.activeStep + 1 });
        this.setState({ validationAttempt: false });
    };

    private handleEdit(step: number): void {
        this.setState({ validationAttempt: true });
        switch (this.state.activeStep) {
            case 0:
                if ((this.state.carYear === null || this.state.carYear === undefined) || (this.state.induction === null || this.state.induction === "") ||
                    (this.state.carMake === null || this.state.carMake === "") || (this.state.carModel === null || this.state.carModel === "") ||
                    (this.state.selectedRaces.length === 0) ||
                    (this.state.carChassis === null || this.state.carChassis === "") || (this.state.wheelRearDia === null || this.state.wheelRearDia === undefined) ||
                    (this.state.wheelFrontDia === null || this.state.wheelFrontDia === undefined) || (this.state.wheelRearWidth === null || this.state.wheelRearWidth === undefined) ||
                    (this.state.wheelFrontWidth == null || this.state.wheelFrontWidth === undefined) || (this.state.carColor === null || this.state.carColor === "") ||
                    (this.state.displacement === null || this.state.displacement === undefined) || (this.state.horsepower === null || this.state.horsepower === undefined) ||
                    (this.state.lubeSys === null || this.state.lubeSys === "") || (this.state.brakeSys === null || this.state.brakeSys === "") ||
                    ((this.state.modded && this.state.modifications === null) || (this.state.modded && this.state.modifications === "")) ||
                    (this.state.tireMake === null || this.state.tireMake === "") || (this.state.tireModel === null || this.state.tireModel === "") ||
                    (this.state.tireFrontSize === null || this.state.tireFrontSize === "") || (this.state.tireRearSize === null || this.state.tireRearSize === "") ||
                    (this.state.altCarNum === null || this.state.altCarNum === "") || (this.state.altCarNum2 === null || this.state.altCarNum2 === "") ||
                    (this.state.prefCarNum === null || this.state.prefCarNum === "") || (this.state.carHistory === null || this.state.carHistory === "")) {
                    this.props.setMessage("Missing Required Fields", "error");
                    return;
                }
                if (this.state.carYear !== undefined && (this.state.carYear > (new Date().getFullYear() + 1) || this.state.carYear < 1900)) {
                    this.props.setMessage("Invalid Information", "error");
                    return;
                }
                if(this.state.carImage?.size === 0){
                    this.props.setMessage("Image cannot be 0KB.", "error")
                }
                if (isNaN(this.state.carYear) || this.state.carYear as any === "" || isNaN(this.state.wheelFrontDia) || this.state.wheelFrontDia as any === "" ||
                    isNaN(this.state.wheelFrontWidth) || this.state.wheelFrontWidth as any === "" || isNaN(this.state.wheelRearWidth) || this.state.wheelRearWidth as any === "" ||
                    isNaN(this.state.wheelRearDia) || this.state.wheelRearDia as any === "" || isNaN(this.state.displacement) || this.state.displacement as any === "" ||
                    isNaN(this.state.horsepower) || this.state.horsepower as any === "") {
                    this.props.setMessage("Value Must Be a Number", "error");
                    return;
                }
                break;
            case 1:
                if (this.state.driverFName === null || this.state.driverLName === null || this.state.driverEmail === null || this.state.driverEmailConfirm === null || this.state.driverPhone === null ||
                    this.state.driverDOB === null || this.state.driverCity === null || this.state.driverPostal === null ||
                    this.state.driverNumRaces === null || (this.state.blackFlagged && this.state.blackFlaggedReason === null) || this.state.driverRacesList === null || this.state.driverIncidents === null ||
                    this.state.driverCountry === null || this.state.driverLicenseExpiry === null || this.state.driverLicenseIssuer === null || this.state.driverMedicalExpiry === null ||
                    this.state.driverMedicalIssuer === null || this.state.driverYears === null || this.state.driverSuspensions === null ||
                    this.state.driverFName === "" || this.state.driverLName === "" || this.state.driverEmail === "" || this.state.driverEmailConfirm === "" || this.state.driverPhone === "" ||
                    this.state.driverCity === "" || ((this.state.driverCountry === "United States" || this.state.driverCountry === "Canada") && (this.state.driverState === "" || this.state.driverState === null)) || this.state.driverPostal === "" ||
                    this.state.driverNumRaces === undefined || (this.state.blackFlagged && this.state.blackFlaggedReason === "") || this.state.driverRacesList === "" || this.state.driverIncidents === "" ||
                    this.state.driverCountry === "" || this.state.driverLicenseIssuer === "" || this.state.driverMedicalIssuer === "" || this.state.driverYears === undefined || this.state.driverSuspensions === "") {
                    this.props.setMessage("Missing Required Fields", "error");
                    return;
                }
                if (this.state.driverEmail.toLowerCase() !== this.state.driverEmailConfirm.toLowerCase()) {
                    this.props.setMessage("Emails Do Not Match", "error");
                    return;
                }
                if (this.state.driverDOB > new Date() || new Date(this.state.driverDOB).toString() === "Invalid Date" ||
                    new Date(this.state.driverMedicalExpiry).toString() === "Invalid Date" || new Date(this.state.driverLicenseExpiry).toString() === "Invalid Date") {
                    this.props.setMessage("Invalid date", "error");
                    return;
                }
                if (this.state.driverYears > 999 || this.state.driverYears < 0) {
                    this.setState({ driverYears: 0 })
                }
                if (this.state.driverNumRaces > 999 || this.state.driverNumRaces < 0) {
                    this.setState({ driverNumRaces: 0 })
                }
                if (isNaN(this.state.driverYears) || this.state.driverYears as any === "" || isNaN(this.state.driverNumRaces) || this.state.driverNumRaces as any === "") {
                    this.props.setMessage("Value Must Be a Number", "error");
                    return;
                }
                break;
            case 2:
                break;
            case 3:
                break;
            default:
        }
        const stepper = document.getElementById("stepper");
        if (stepper !== null) {
            stepper.scrollTop = 0;
        }
        this.setState({ scrollAtBottom: false });
        this.setState({ prevActiveStep: this.state.activeStep });
        this.setState({ activeStep: step });
        this.setState({ validationAttempt: false });
    };
    // private handleEdit(step: number): void {
    //     const { classes } = this.props;
    //     const stepper = document.getElementsByClassName(classes.form);
    //     if (stepper !== null) {
    //         Array.prototype.forEach.call(stepper, element => {
    //             element.scrollTop = 0;
    //         });
    //     }
    //     this.setState({ prevActiveStep: this.state.activeStep });
    //     this.setState({ activeStep: step });
    // };
    private getRaces(){
        this.apiService.getRaces(this.props.entry.event.id).then(result => { 
            result.sort((a: Race, b: Race) => {
               let nameA = a.name.toUpperCase(); 
               let nameB = b.name.toUpperCase(); 
               if (nameA < nameB) {
                 return -1;
               }
               if (nameA > nameB) {
                 return 1;
               }
               return 0;
            });
            this.setState({raceOptions : result})
        })
    };
    public componentDidMount() {
        // this.getEntrant();
        this.getRaces();
        this.getClassesList().then(() => this.setState({ _isMounted: true }));
        if (this.state.customPrepShopEmail !== null && this.state.prepShop === null) {
            this.setState({ customPrepShop: true })
        }
        if (this.props.editMode && this.props.car !== undefined) {
            this.setState({
                carChassis: this.props.car.chassisNumber, carModel: this.props.car.model, carYear: this.props.car.year,
                carColor: this.props.car.color, carClass: this.props.car.class, carHistory: this.props.car.historyOfCar,
                carMake: this.props.car.make, carImage: this.props.car.carImageToDisplay,
                transponderRental: this.props.car.isTransponderRentalNeeded, transponderNumber: this.props.car.transponderNumber, displacement: this.props.car.displacement, isPaid: this.props.car.isPaid,
                horsepower: this.props.car.horsePower, induction: this.props.car.inductionType, lubeSys: this.props.car.lubricationSystem,
                brakeSys: this.props.car.brakeType, originalBrakes: this.props.car.isBrakeSystemOriginal, modded: this.props.car.isModified,
                modifications: this.props.car.modificationReason, tireMake: this.props.car.tireMake, tireModel: this.props.car.tireModel,
                tireFrontSize: this.props.car.tireSizeFront, tireRearSize: this.props.car.tireSizeRear, wheelFrontDia: this.props.car.frontWheelDiameter,
                wheelFrontWidth: this.props.car.frontWheelWidth, wheelRearDia: this.props.car.rearWheelDiameter, wheelRearWidth: this.props.car.rearWheelWidth,
                prefCarNum: this.props.car.preferredCarNumber, altCarNum: this.props.car.alternateCarNumber1, altCarNum2: this.props.car.alternateCarNumber2, tshirtSize: this.props.car.tshirtSize,
                runGroups: this.props.car.carRace, status: this.props.car.status, adminNotes: this.props.car.adminNotes,
            });
            let selectedRaces : Race[] = [];
            this.props.car.carRace.forEach(carRace => {
                selectedRaces.push(carRace.race);
            });
            this.setState({selectedRaces});
            if (this.props.car.driverModel.length > 0) {
                this.setState({ sameAsEntrant: (this.props.entry.entrantId === this.props.car.driverModel[0].peopleId) });
                this.setState({ ICENumber: this.props.car.driverModel[0].icenumber })
                this.setState({ driverPhone: this.props.car.driverModel[0].phoneNumber })
                this.setState({
                    driverAdd1: this.props.car.driverModel[0].address1, driverAdd2: this.props.car.driverModel[0].address2, driverAdd3: this.props.car.driverModel[0].address3,
                    driverCity: this.props.car.driverModel[0].city, driverMName: this.props.car.driverModel[0].mname, driverCountry: this.props.car.driverModel[0].country,
                    driverDOB: (this.props.car.driverModel[0].dob !== null && this.props.car.driverModel[0].dob.toString() === "0001-01-01T00:00:00") ? null : this.props.car.driverModel[0].dob, driverEmail: this.props.car.driverModel[0].email, driverIncidents: this.props.car.driverModel[0].incidents,
                    driverLName: this.props.car.driverModel[0].lname, driverLicenseExpiry: this.props.car.driverModel[0].licenseExpiry, driverLicenseIssuer: this.props.car.driverModel[0].licenseIssuer,
                    driverMedicalExpiry: this.props.car.driverModel[0].medicalExpiry, driverMedicalIssuer: this.props.car.driverModel[0].medicalIssuer, driverState: this.props.car.driverModel[0].state,
                    driverPostal: this.props.car.driverModel[0].zipCode, driverSuspensions: this.props.car.driverModel[0].suspensions, driverEmailConfirm: this.props.car.driverModel[0].email,
                    driverFName: this.props.car.driverModel[0].fname, driverNumRaces: this.props.car.driverModel[0].numOfVintageEvents, driverRacesList: this.props.car.driverModel[0].racesList,
                    driverYears: this.props.car.driverModel[0].yearsDriving, driverId: this.props.car.driverModel[0].driverId,
                    blackFlagged: this.props.car.driverModel[0].isBlackFlagged, blackFlaggedReason: this.props.car.driverModel[0].blackFlaggedReason, ICEName: this.props.car.driverModel[0].icename
                })
            }
            if (this.props.car.crew !== undefined && this.props.car.crew.length > 0) {
                let crew: Crew[] = [];
                this.props.car.crew.map((value) => {
                    crew.push(value);
                });
                this.setState({crew})
            }
            if (this.props.car.lounge !== undefined && this.props.car.lounge.length > 0) {
                let loungeMembers: LoungeMember[] = [];
                this.props.car.lounge.map((value) => {
                    loungeMembers.push(value);
                });
                this.setState({ loungeMembers })
            }
        }
    }
    private getStepContent(step: number): React.ReactElement {
        const { classes } = this.props;
        const validCarsInEntry = this.props.entry.carModel.filter(x => x.status === 'Pending' || x.status === 'Accepted' || x.status === 'Waitlisted' || x.status === 'Draft');
        const selectedCar = validCarsInEntry.find(x => this.props.car !== undefined && x.id === this.props.car.id);
        const maxCrew = selectedCar === undefined ? 
        (validCarsInEntry.length === 0 ? 4 : validCarsInEntry.length === 1 ? 3 : 2) : 
        (validCarsInEntry.indexOf(selectedCar) === 0 ? 4 : validCarsInEntry.indexOf(selectedCar) === 1 ? 3 : 2);
        switch (step) {
            case 0:
                return (<React.Fragment>
                    {this.state._isMounted &&
                        <CarForm
                            isAdmin={this.props.isAdmin}
                            {...this.state}
                            upcomingYear={undefined}
                        />}
                    {!this.state._isMounted &&
                        <Box className={classes.center}>
                            <CircularProgress color="secondary" /></Box>}
                </React.Fragment>);
            case 1:
                return (
                    <DriverForm
                        {...this.state}
                    />);
            case 2:
                return (<React.Fragment>
                    <Grid item xs={12}>
                        <Typography className={classes.subheading}><b>Crew Information</b></Typography>
                        <Divider />
                        <CrewForm
                            maxCrew={maxCrew}
                            event={this.props.entry.event}
                            {...this.state}
                        />
                    </Grid>
                    {this.state.selectedRaces.some(e => e.hasLounge) &&
                    <Grid item xs={12}>
                        <Typography className={classes.subheading}><b>Lounge Information</b></Typography>
                        <Divider />
                        <LoungeForm
                            event={this.props.entry.event}
                            {...this.state}
                        />
                    </Grid>
                    }
                </React.Fragment>);
            case 3:
                return (<PaddockInfo {...this.state} editMode={true} />)
            case 4:
                return (
                    <AccountForm 
                        {...this.state}
                        editMode={true}
                    />);
            case 5:
                return (<React.Fragment>
                    {this.state._isMounted &&
                        <AdminRaceForm
                            car={this.props.car}
                            {...this.state}
                        />}
                    {!this.state._isMounted &&
                        <Box className={classes.center}>
                            <CircularProgress color="secondary" /></Box>}
                </React.Fragment>);
            default:
                return <h1>Error !</h1>;
        }
    };
    private getEntrant = async () => {
        this.props.setLoading(true);
        const token = await authService.getAccessToken();
        const response = await fetch("/api/entrant/", {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        await response.json().then((data) => {
            this.props.setLoading(false);
            this.setState({ imperial: !data.usesMetricSystem });
            if (!data.usesMetricSystem) {
                this.setState({
                    imperial: true
                });
            }
            else {
                this.setState({
                    wheelFrontDia: this.convertCar(this.state.wheelFrontDia, "metric"),
                    wheelRearDia: this.convertCar(this.state.wheelRearDia, "metric"),
                    wheelFrontWidth: this.convertCar(this.state.wheelFrontWidth, "metric"),
                    wheelRearWidth: this.convertCar(this.state.wheelRearWidth, "metric"),
                    awningLength: this.convertPaddock(this.state.awningLength, "metric"),
                    awningWidth: this.convertPaddock(this.state.awningWidth, "metric"),
                    transporterLength: this.convertPaddock(this.state.transporterLength, "metric"),
                    transporterWidth: this.convertPaddock(this.state.transporterWidth, "metric"),
                    removableTrailerLength: this.convertPaddock(this.state.removableTrailerLength, "metric"),
                    imperial: false
                });
            }
        }).catch((e) => {
            this.props.setLoading(false);
        })
    }
    private ScrollChevron(): React.ReactElement {
        const { classes } = this.props;
        return (<ExpandMoreIcon className={classes.scrollChevron} color="secondary" />)
    }
    private ColorlibStepIcon(props: StepIconProps) {
        const { classes } = this.props;
        const { active, completed } = props;

        const icons: { [index: string]: React.ReactElement } = {
            1: <DriveEtaIcon />,
            2: <PersonIcon />,
            3: <PeopleIcon />,
            4: <LandscapeIcon />,
            5: <AssignmentIndIcon />,
            6: <FlagIcon />,
            // 7: <CheckCircleIcon />,
        };
        return (
            <div
                className={clsx(classes.stepIcon, {
                    [classes.active]: active,
                    [classes.completed]: completed,
                })}
            >
                {icons[String(props.icon)]}
            </div>
        );
    }
    //hides assistive chevron, after user has reached the end of page
    private handleScroll() {
        const stepper = document.getElementById("stepper");
        if (stepper !== null && stepper.scrollTop / (stepper.scrollHeight - stepper.clientHeight) === 1) {
            this.setState({ scrollAtBottom: true });
        }
    }
    public render(): React.ReactElement {
        const { classes } = this.props;
        return (<React.Fragment>
            <Container maxWidth="xl" >
                <Stepper nonLinear alternativeLabel activeStep={this.state.activeStep} connector={<QontoConnector />} >
                    {this.state.steplabels.map((label, index) => (
                        <Step key={label}>
                            <StepButton aria-label="Info" onClick={() => this.handleEdit(index)}>
                                <StepLabel StepIconComponent={this.ColorlibStepIcon}>
                                    {label}</StepLabel>
                            </StepButton>
                        </Step>
                    ))}
                </Stepper>
                <div>
                    {this.state.activeStep === this.state.steps.length ? (
                        <div>
                            <Typography className={classes.instructions}>
                                All steps completed - you&apos;re finished
                            </Typography>
                        </div>
                    ) : (
                            <Box className={classes.form}>
                                {this.getStepContent(this.state.activeStep)}
                            </Box>
                        )}
                </div>
                <Box p={3} className={classes.header}>
                    <Button className={classes.cancel} onClick={this.props.handleCancel} variant="contained" disabled={this.props.loading}> 
                        Cancel
                    </Button>
                    {/* <Button className={classes.button} onClick={() => this.handleSubmit("draft")}  variant="contained" disabled={this.props.loading}>
            Save As Draft
            </Button> */}
                    {this.state.activeStep === this.state.steps.length - 1 ?
                        <Button variant="contained" onClick={() => this.handleSubmit("submit")} className={classes.submit} disabled={this.props.loading}>
                            Submit
            </Button> :
                        <Button variant="contained" onClick={this.handleNext} className={classes.submit} disabled={this.props.loading}>
                            Next Step
            </Button>}
                </Box>
            </Container>

        </React.Fragment>
        );
    }
}
const QontoConnector = withStyles({
    alternativeLabel: {
        top: 10,
        left: 'calc(-50% + 16px)',
        right: 'calc(50% + 16px)',
    },
    line: {
        borderColor: '#eaeaf0',
        borderTopWidth: 3,
        borderRadius: 1,
    },
})(StepConnector);

const styles = createStyles((theme: Theme) => ({
    header: {
        textAlign: "center"
    },
    center: {
        textAlign: "center"
    },
    form: {
        overflowY: 'auto',
        overflowX: 'hidden',
        height: '70vh',
        width: 'auto',
        padding: theme.spacing(2),
    },
    submit: {
        backgroundColor: green[500],
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        color: 'white',
        '&:hover': {
            backgroundColor: green[700],
        }
    },
    button: {
        backgroundColor: blue[500],
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        color: 'white',
        '&:hover': {
            backgroundColor: blue[700],
        }
    },
    cancel: {
        backgroundColor: red[500],
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        color: 'white',
        '&:hover': {
            backgroundColor: red[700],
        }
    },
    dialog: {
        maxWidth: '900px'
    },
    stepIcon: {
        backgroundColor: '#ccc',
        zIndex: 1,
        color: '#fff',
        width: 50,
        height: 50,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    active: {
        backgroundImage:
            'linear-gradient( 136deg, rgb(255, 205, 210) 0%, rgb(244, 67, 54) 50%, rgb(183, 28, 28) 100%)',
        boxShadow: '4px 1px 5px rgba(0,0,0,.25)',
    },
    completed: {
        backgroundImage:
            'linear-gradient( 136deg, rgb(255, 205, 210) 0%, rgb(244, 67, 54) 50%, rgb(183, 28, 28) 100%)',
    },
}))

export default withStyles(styles)(CarWizardAdmin);