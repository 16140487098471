import React, { Component } from 'react';
import { Container } from '@material-ui/core';
import { withStyles, createStyles, Theme } from '@material-ui/core/styles';

import NavMenu from './NavMenu';
import Entrant from '../interfaces/IEntrant';

interface ILayoutProps {
    message: string;
    setMessage: any;
    classes: any;
    setLoading: any;
    loading: boolean;
    currentEntrant?: Entrant;
    isAdmin: boolean;
    featureMode: string;
    setFeature: any;
}

interface ILayoutState {
    showMenu: boolean;
}

export class Layout extends Component<ILayoutProps, ILayoutState>  {
    static displayName = Layout.name;
    public constructor(props: Readonly<ILayoutProps>) {
        super(props);
        this.state = {
            showMenu: false
        };
    };
    private handleDrawerToggle = (open: boolean) => {
        this.setState({ showMenu: open })
    };
    public render(): React.ReactElement {
        const { classes } = this.props;
        return (<React.Fragment>
            <NavMenu
                handleDrawerToggle={this.handleDrawerToggle}
                {...this.state}
                {...this.props}
            />
            <Container className={classes.root}>
                <>
                    {this.props.children}
                </>
            </Container>
        </React.Fragment>
        );
    }
}

const styles = createStyles((theme: Theme) => ({
    root: {
        paddingLeft: '57px',
        flexGrow: 1,
        marginTop: (theme.spacing(4)),
        [theme.breakpoints.down('sm')]: {
            marginTop: (theme.spacing(3)),
            paddingLeft: (theme.spacing(2)),
        }
    },
}))

export default withStyles(styles)(Layout)