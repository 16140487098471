import React, { Component } from 'react';
import { withStyles, createStyles, Theme } from '@material-ui/core/styles';
import APIService from '../services/APIService';
import { Paper, Grid, Typography, Tabs, Tab} from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import CheckInPerson from './CheckInPerson';
import CheckInVehicle from './CheckInVehicle';

export interface ICheckInModuleProps {
    classes: any;
    setMessage: any;
    setLoading: any;
    loading: boolean;
}

export interface ICheckInModuleState {
    checkInMode: number;
    _isMounted: boolean;
}

export class CheckInModule extends Component<ICheckInModuleProps, ICheckInModuleState> {
    static displayName = CheckInModule.name;
    private apiService: APIService = new APIService;
    public constructor(props: Readonly<ICheckInModuleProps>) {
        super(props);
        this.state = {
            checkInMode:0,
            _isMounted: false,
        };
        this.apiService = new APIService;
    }
    private changeMode = (event: React.ChangeEvent<{}>, newValue: number) =>  {
        this.setState({checkInMode: newValue})
    }

    public componentDidMount() {
    }

    public componentDidUpdate(prevProps: ICheckInModuleProps) {
    }
    public render(): React.ReactElement<ICheckInModuleProps> {
        const { classes } = this.props;
        return (
            <React.Fragment>
            <Paper className={classes.paper}>
                <Grid container spacing={2}>
                    <Grid item xs={12} className={classes.center}>
                        <Typography variant={'h6'}>Event Check In</Typography>
                    </Grid>  
                    <Grid item xs={12}>
                        <Tabs
                            value={this.state.checkInMode}
                            onChange={this.changeMode}
                            indicatorColor="secondary"
                            textColor="secondary"
                            centered
                        >
                            <Tab label="Name" />
                            <Tab label="Vehicle" />
                        </Tabs>
                    </Grid>
                </Grid>
                {this.state.checkInMode === 0 ? 
                    <React.Fragment>
                        <CheckInPerson
                        {...this.props}
                        />
                    </React.Fragment>
                    :
                    <React.Fragment>
                        <CheckInVehicle
                        {...this.props}
                        />
                    </React.Fragment>
                }
            </Paper>      
            </React.Fragment>
        )
    }
}

const styles = createStyles((theme: Theme) => ({
    paper: {
        padding: theme.spacing(3, 2),
        position: 'relative',
    },
    center:{
        textAlign: 'center',
    },
    submit: {
        backgroundColor: green[500],
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        color: 'white',
        '&:hover': {
            backgroundColor: green[700],
        }
    },
}))

export default withStyles(styles)(CheckInModule)