import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import { withStyles, createStyles, Theme, responsiveFontSizes, createMuiTheme, ThemeProvider  } from '@material-ui/core/styles';
import { Toolbar , Typography, Divider, Tooltip, Paper, Card, Button, Fab, Dialog, AppBar, IconButton, DialogTitle, DialogContent, TextField, MenuItem } from '@material-ui/core';
import { lightBlue, cyan, teal, red, green, grey } from '@material-ui/core/colors';
import MUIDataTable from 'mui-datatables';
import CloseIcon from '@material-ui/icons/Close';
import DateOpenRange from '../interfaces/IDateOpenRange'
import authService from '../services/api-authorization/AuthorizeService';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import EditIcon from '@material-ui/icons/Edit';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import Entrant from '../interfaces/IEntrant';
import Class from '../interfaces/IClass';

export interface IAdminPrivilegeProps {
    classes:any;
    setMessage:any;
    setLoading:any;
    loading:boolean;
  }

export interface IAdminPrivilegeState {
    dateValid:boolean;
    dialogOpen:boolean;
    dialogExport:boolean;
    dialogSubject:string;
    dialogDateOpenRange: DateOpenRange[];
    dialogUsers: Entrant[];
    dialogCarClasses: Class[];
    dialogParticipatingYear: string[];
    dialogChosenYear: string;
    dateOpenRangeID: string;
    editDateRangeMode:boolean;
}

let theme = createMuiTheme();
theme = responsiveFontSizes(theme);

export class AdminPrivilege extends Component<IAdminPrivilegeProps, IAdminPrivilegeState> {    
    // static displayName = EntrantForm.name;
    public constructor(props: Readonly<IAdminPrivilegeProps>) {
        super(props);
        this.state = {
            dateValid:true,
            dialogOpen: false,
            dialogExport: false,
            dialogSubject: "",
            dialogDateOpenRange: [],
            dialogUsers: [],
            dialogCarClasses: [],
            dialogParticipatingYear: [],
            dialogChosenYear: "",
            dateOpenRangeID: "",
            editDateRangeMode: false,
        };
    }
    // private participatingYears : { [text: string] : string; } = {};
    private adminPrivilegeList = ["Open/Close Registration", "Car Classes", "Manage Admins"];
    private addList = ["Add Registration", "Add Car Class", "Export"];
    private manageAdminColumns = [
        {
         name: "fullName",
         label: "Name",
         options: {
          filter: true,
          sort: true,
         }
        },
        {
         name: "email",
         label: "Email",
         options: {
          filter: true,
          sort: true
         }
        },
        {
         name: "phoneNumber",
         label: "Phone Number",
         options: {
          filter: true,
          sort: true,
         }
        },
        {
         name: "dob",
         label: "Date of Birth",
         options: {
          filter: true,
          sort: true,
         }
        },
        {
          name: "address",
          label: "Address",
          options: {
           filter: true,
           sort: true,
          }
         },
         {
           name: "admin",
           label: "Admin",
            options: {
            filter: true,
            sort: true,
            }
         },
       ];

       private registrationDatesColumns = [
        {
         name: "participatingYear",
         label: "Year",
         options: {
          filter: true,
          sort: true,
         }
        },
        {
         name: "openDate",
         label: "Open Date",
         options: {
          filter: true,
          sort: true,
          customBodyRender: (value : any, tableMeta : any, updateValue : any) => {
            //console.log("ID: ", tableMeta.rowData[3]);
            return (
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            // disabled={!this.props.editMode}
                            required
                            disableToolbar
                            variant="inline"
                            format="MM/dd/yyyy"
                            margin="normal"
                            name=""
                            id=""
                            // label="Open Date"
                            placeholder="MM/dd/yyyy"
                            autoOk
                            // maxDate={new Date()}
                            value={value}
                            // error={(this.props.validationAttempt && this.props.entrantDOB === null) || !this.state.dateValid}
                            // helperText={(this.props.validationAttempt && this.props.entrantDOB === null) ? "Required Field" : (!this.state.dateValid) ? "Invalid Date" : ""}
                            onChange={date => {
                                this.handleDateChange(tableMeta, date, "dateOpenRangeID");
                                //console.log(tableMeta);
                            }}
                            KeyboardButtonProps={{
                                'aria-label': 'change open date',
                            }}
                        />
                    </MuiPickersUtilsProvider>
                );
            }
         }
        },
        {
         name: "closeDate",
         label: "Close Date",
         options: {
          filter: true,
          sort: true,
          customBodyRender: (value : any, tableMeta : any, updateValue : any) => {
            return (
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            // disabled={!this.props.editMode}
                            required
                            disableToolbar
                            variant="inline"
                            format="MM/dd/yyyy"
                            margin="normal"
                            name="entrantDOB"
                            id="entrantDOB"
                            // label="Date of Birth"
                            placeholder="MM/dd/yyyy"
                            autoOk
                            // maxDate={new Date()}
                            value={value}
                            // error={(this.props.validationAttempt && this.props.entrantDOB === null) || !this.state.dateValid}
                            // helperText={(this.props.validationAttempt && this.props.entrantDOB === null) ? "Required Field" : (!this.state.dateValid) ? "Invalid Date" : ""}
                            onChange={date => {
                                this.handleDateChange(tableMeta.id, date, "dateOpenRangeID");
                                //console.log(tableMeta);
                            }}
                            KeyboardButtonProps={{
                                'aria-label': 'change close date',
                            }}
                        />
                    </MuiPickersUtilsProvider>
                );
            }
         }
        },
        {
            name: "id",
            label: "Edit",
            options: {
             filter: true,
             sort: true,
             customBodyRender: (value : any, tableMeta : any, updateValue : any) => {
                return (
                   <React.Fragment>
                        <Fab color="primary" variant="extended" aria-label="Update Date">
                        <SaveIcon /> Update
                       </Fab>
                   </React.Fragment>
                    );
                }
            }
           },
       ];
       private carClassesColumns = [
        {
         name: "name",
         label: "Class Name",
         options: {
          filter: true,
          sort: true,
         }
        },
        {
            name: "id",
            label: "Edit",
            options: {
             filter: false,
             sort: false,
             customBodyRender: (value : any, tableMeta : any, updateValue : any) => {
                return (
                   <React.Fragment>
                       <EditIcon/>
                       <CheckCircleIcon/>
                       <CancelIcon/>
                   </React.Fragment>
                    // <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    //         <KeyboardDatePicker
                    //             // disabled={!this.props.editMode}
                    //             required
                    //             disableToolbar
                    //             variant="inline"
                    //             format="MM/dd/yyyy"
                    //             margin="normal"
                    //             name="entrantDOB"
                    //             id="entrantDOB"
                    //             label="Date of Birth"
                    //             placeholder="MM/dd/yyyy"
                    //             autoOk
                    //             maxDate={new Date()}
                    //             value={value}
                    //             // error={(this.props.validationAttempt && this.props.entrantDOB === null) || !this.state.dateValid}
                    //             // helperText={(this.props.validationAttempt && this.props.entrantDOB === null) ? "Required Field" : (!this.state.dateValid) ? "Invalid Date" : ""}
                    //             onChange={date => {
                    //                 this.handleDateChange(tableMeta.id, date, "dateOpenRangeID");
                    //                 console.log(tableMeta);
                    //             }}
                    //             KeyboardButtonProps={{
                    //                 'aria-label': 'change date',
                    //             }}
                    //         />
                    //     </MuiPickersUtilsProvider>
                    );
                }
            }
         },
         {
           name: "delete",
           label: "Delete",
           options: {
            filter: false,
            sort: false,
            customBodyRender: (value : any, tableMeta : any, updateValue : any) => {
                return (
                   <React.Fragment>
                       <DeleteIcon/>
                   </React.Fragment>
                    );
                }
            },
        }
       ];
       private options = {
        filterType: 'checkbox'
        };
    private handleParticipatingYearChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({dialogChosenYear: event.target.value});
    }
    private handleOpenDialog = (dialogSubject: string) => {
        this.setState({dialogSubject}); 
        
        if(dialogSubject === "Open/Close Registration"){
            this.GetDateOpenRange();
            this.setState({dialogOpen : true}); 
        }
        if(dialogSubject === "Manage Admins"){
            this.GetAllUsers();
            this.setState({dialogOpen : true}); 
        }
        if(dialogSubject === "Car Classes"){
            this.GetAllCarClasses();
            this.setState({dialogOpen : true}); 
        }
        if(dialogSubject === "Export"){
            this.GetParticipatingYears();
            this.setState({dialogExport : true}); 
        }
    }
    // private Edit
    private GetDateOpenRange = async () => {
        const token = await authService.getAccessToken();
        // const year = this.upcomingRaceYear();
        if(token != null){
          const headers = new Headers();
          headers.append("Accept", "application/json");
          headers.append("Content-Type", "application/json");
          headers.append( "Authorization", `Bearer ${token}` );
          const response = await fetch(`/api/list/DateOpenRange/`, {
            headers: headers,
            method: "GET"
          })
          .then(res => res.json())
          .then(data => {
              // //console.log(data.result);
              this.setState({dialogDateOpenRange: data.dateRange})
          })
          .catch((error) => {
              //console.log(error);
          });
        }
    }
    private GetAllUsers = async () => {
        const token = await authService.getAccessToken();
        // const year = this.upcomingRaceYear();
        if(token != null){
          const headers = new Headers();
          headers.append("Accept", "application/json");
          headers.append("Content-Type", "application/json");
          headers.append( "Authorization", `Bearer ${token}` );
          const response = await fetch(`/api/admin/AllUsers/`, {
            headers: headers,
            method: "GET"
          })
          .then(res => res.json())
          .then(data => {
              // //console.log(data.result);
              this.setState({dialogUsers: data.users})
          })
          .catch((error) => {
              //console.log(error);
          });
        }
    }
    private GetParticipatingYears = async () => {
        const token = await authService.getAccessToken();
        // const year = this.upcomingRaceYear();
        if(token != null){
          const headers = new Headers();
          headers.append("Accept", "application/json");
          headers.append("Content-Type", "application/json");
          headers.append( "Authorization", `Bearer ${token}` );
          const response = await fetch(`/api/admin/ParticipatingYears/`, {
            headers: headers,
            method: "GET"
          })
          .then(res => res.json())
          .then(data => {
              // //console.log(data.participatingYears);
            //   if(data.participatingYears != null){
            //     data.participatingYears.forEach(function (year : string) {
            //         this.participatingYears(year : year)
            //     });
            //   }
              
              this.setState({dialogParticipatingYear: data.participatingYears})
          })
          .catch((error) => {
              //console.log(error);
          });
        }
    }
    //api/file/FullExport/{year} /api/file/ActiveExport/{year}
    private ExportFile = async () => {
        var year = this.state.dialogChosenYear;
        if(year !== ""){
            const token = await authService.getAccessToken();
            // const year = this.upcomingRaceYear();
            if(token != null){
            const headers = new Headers();
            headers.append("Accept", "text/csv");
            headers.append( "Authorization", `Bearer ${token}` );
            const response = await fetch(`api/file/CreateFile/${year}`, {
                headers: headers,
                method: "POST"
            }) 
                    .then(response => response.blob())
                    .then(blob => {
                        var url = window.URL.createObjectURL(blob);
                        var a = document.createElement('a');
                        a.href = url;
                        a.download = "getAllFiles.csv";
                        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                        a.click();    
                        a.remove();  //afterwards we remove the element again         
                    })     
            .catch((error) => {
                console.log(error);
            });
            }
        }
        
    }
    private GetAllCarClasses = async () => {
        const token = await authService.getAccessToken();
        // const year = this.upcomingRaceYear();
        if(token != null){
          const headers = new Headers();
          headers.append("Accept", "application/json");
          headers.append("Content-Type", "application/json");
          headers.append( "Authorization", `Bearer ${token}` );
          const response = await fetch(`/api/list/Classes/`, {
            headers: headers,
            method: "GET"
          })
          .then(res => res.json())
          .then(data => {
              // //console.log(data.result);
              this.setState({dialogCarClasses: data.classes})
          })
          .catch((error) => {
              //console.log(error);
          });
        }
    }
    private handleCloseDialog = () => {
        this.setState({dialogSubject: ""});
        this.setState({dialogDateOpenRange : []});
        this.setState({dialogUsers : []});
        this.setState({dialogExport : false}); 
        this.setState({dialogChosenYear : ""});
        this.setState({dialogOpen : false}); 
    }
    private handleDateChange = (id : any, date: Date | null, field: string) => {
        const newState = { [field]: date } as any;
        this.setState(newState);
    }

    public render() : React.ReactElement{
        const {classes} = this.props;
        // var listRow1 = [classes.Registration, classes.CarClasses, classes.ManageAdmins];
        // var listRow2 = [classes.AddRegistration, classes.AddCarClass, classes.Export];
        var data : { [id: string] : any; } = {};
        data["Manage Admins"] = this.state.dialogUsers;
        data["Open/Close Registration"] = this.state.dialogDateOpenRange;
        data["Car Classes"] = this.state.dialogCarClasses;
        var dtOpen : { [id: string] : any; } = {};
        dtOpen["Manage Admins"] = this.manageAdminColumns;
        dtOpen["Open/Close Registration"] = this.registrationDatesColumns;
        dtOpen["Car Classes"] = this.carClassesColumns;
        
        return(  
            <React.Fragment>
                <Paper className={classes.paper}>
                    <Card className={classes.bigBoiCardi}>
                        <Grid container justify="space-around" spacing={2}>
                        {this.adminPrivilegeList.map((entry : any, index : number) => 
                            <Grid item xs={2}>
                                <Card className={classes.smolCard}>
                                    <Button fullWidth={true} className={classes.button} onClick={() => this.handleOpenDialog(this.adminPrivilegeList[index])} variant="contained">
                                        <ThemeProvider theme={theme}>
                                            <Typography variant="subtitle1">
                                                {entry}
                                            </Typography>
                                        </ThemeProvider>
                                    </Button>
                                </Card>
                            </Grid>
                        )}
                        </Grid>
                        <Grid container justify="space-around" spacing={2}>
                        {this.addList.map((entry : any, index : number) => 
                            <Grid item xs={2}>
                                <Card className={classes.smolCard}>
                                    <Button fullWidth={true} className={classes.button} onClick={() => this.handleOpenDialog(this.addList[index]) } variant="contained">
                                        <ThemeProvider theme={theme}>
                                            <Typography variant="subtitle1">
                                                {entry}
                                            </Typography>
                                        </ThemeProvider>
                                    </Button>
                                </Card>
                            </Grid>
                        )}
                        </Grid>
                    </Card>
                    {/* <br/>
                    <Card className={classes.bigBoiCardi}>
                        <Grid container justify="space-around" spacing={2}>
                        {this.addList.map((entry : any, index : number) => 
                            <Grid item xs={2}>
                                <Card className={classes.smolCard}>
                                    <Button fullWidth={true} className={list[index]} onClick={() => this.handleOpenDialog(this.addList[index])} variant="contained">
                                        <ThemeProvider theme={theme}>
                                            <Typography variant="subtitle1">
                                                {entry}
                                            </Typography>
                                        </ThemeProvider>
                                    </Button>
                                </Card>
                            </Grid>
                        )}
                        </Grid>
                    </Card> */}
                </Paper>
                

                <Dialog
                    fullScreen
                    open={this.state.dialogOpen}
                    onClose={this.handleCloseDialog}
                    aria-labelledby={this.state.dialogSubject}
                    aria-describedby={this.state.dialogSubject}
                >
                    <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={this.handleCloseDialog} aria-label="close">
                        <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                        {this.state.dialogSubject}
                        
                        </Typography>
                    </Toolbar>
                    </AppBar>
                    <DialogTitle ></DialogTitle>
                    <DialogContent>
                    <MUIDataTable title=""
                        data={data[this.state.dialogSubject]}
                        columns={dtOpen[this.state.dialogSubject]}/> 
                    </DialogContent>
                </Dialog>
                <Dialog
                    // fullScreen
                    open={this.state.dialogExport}
                    onClose={this.handleCloseDialog}
                    aria-labelledby={this.state.dialogSubject}
                    aria-describedby={this.state.dialogSubject}
                >
                    <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={this.handleCloseDialog} aria-label="close">
                        <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                        {this.state.dialogSubject}
                        
                        </Typography>
                    </Toolbar>
                    </AppBar>
                    <DialogTitle ></DialogTitle>
                    <DialogContent>
                    <TextField
                            name="ParticipatingYear"
                            select
                            label="Participating Year"
                            className={classes.textField}
                            value={(this.state.dialogChosenYear === "") ? '' : this.state.dialogChosenYear}
                            onChange={this.handleParticipatingYearChange}
                            // disabled={!this.props.editMode}
                            SelectProps={{
                                MenuProps: {
                                    className: classes.menu,
                                },
                            }}
                            margin="normal"
                            fullWidth
                        >
                            {this.state.dialogParticipatingYear.map((option, index) => (
                                <MenuItem key={index} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </TextField>
                        <Button onClick={this.ExportFile} variant="contained">
                            <ThemeProvider theme={theme}>
                                <Typography variant="subtitle1">
                                Download
                                </Typography>
                            </ThemeProvider>
                        </Button>
                    </DialogContent>
                </Dialog>
                
            </React.Fragment>
        );
    }
}

const styles = createStyles((theme: Theme) => ({
    smolCard: {
        maxWidth: 150,
        margin: 25
      },
    appBar: {
        position: 'relative',
        backgroundColor: grey[200]
    },
    paper:{
        padding: theme.spacing(3, 2),
        position: 'relative'
    },
    center:{
        textAlign: 'center'
    },
    backButton: {
      marginRight: theme.spacing(1),
    },
    completed: {
      display: 'inline-block',
    },
    wizardControl:{
        marginTop: 15,
        textAlign: 'center', 
    },
    regStep:{
        textAlign: 'center', 
    },
    phoneCode:{
        width: 50,
    },
    button: {
        minHeight: 70,
        color: theme.palette.getContrastText(red[500]),
        backgroundColor: red[500],
        '&:hover': {
            backgroundColor: red[700],
        },
    },
    // Registration: {
    //     minHeight: 70,
    //     color: theme.palette.getContrastText(red[200]),
    //     backgroundColor: red[200],
    //     '&:hover': {
    //         backgroundColor: red[400],
    //     },
    // },
    // CarClasses: {
    //     minHeight: 70,
    //     color: theme.palette.getContrastText(red[300]),
    //     backgroundColor: red[300],
    //     '&:hover': {
    //       backgroundColor: red[700],
    //     },
    //   },
    // ManageAdmins: {
    //     minHeight: 70,
    //     color: theme.palette.getContrastText(red[400]),
    //     backgroundColor: red[400],
    //     '&:hover': {
    //         backgroundColor: red['A400'],
    //     },
    // },
    // AddRegistration: {
    //     minHeight: 70,
    //     color: theme.palette.getContrastText(red[500]),
    //     backgroundColor: red[500],
    //     '&:hover': {
    //         backgroundColor: red[700],
    //     },
    // },
    // AddCarClass: {
    //     minHeight: 70,
    //     color: theme.palette.getContrastText(red[600]),
    //     backgroundColor: red[600],
    //     '&:hover': {
    //         backgroundColor: red[700],
    //     },
    // },
    // Export: {
    //     minHeight: 70,
    //     color: theme.palette.getContrastText(red[700]),
    //     backgroundColor: red[700],
    //     '&:hover': {
    //       backgroundColor: red[700],
    //     },
    //   },
    root:{
        width: '90%',
        disabled:{
            color: "rgba(0, 0, 0, .67);",
            fontStyle: 'italic',
            fontWeight: 'bold'
        }
    },
  }))
  
  export default withStyles(styles)(AdminPrivilege);