import React, { Component } from 'react';
import { withStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Dialog, DialogTitle, DialogContent, DialogActions, withWidth, Typography, List, ListItem, Chip, Button, Grid, Divider, Box, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import FaceIcon from '@material-ui/icons/Face';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import convert from '../util/UnitConversion';
import Car from '../interfaces/ICar';
import Crew from '../interfaces/ICrew';
import Race from '../interfaces/IRace';
import CarRace from '../interfaces/ICarRace';

interface ICarInfoProps {
    classes: any;
    theme: any;
    width: any;
    handleCancel: any;
    car: Car;
    show: boolean;
}

interface ICarInfoState {
}

export class CarInfo extends Component<ICarInfoProps, ICarInfoState> {
    public constructor(props: Readonly<ICarInfoProps>) {
        super(props);
        this.state = {
        }
    }
    private lubeTypes = [{ value: 'wet', label: 'Wet Sump' },
    { value: 'dry', label: 'Dry Sump' }];

    private brakeTypes = [{ value: 'disc', label: 'Disc' },
    { value: 'drum', label: 'Drum' },
    { value: 'disc/drum', label: 'Disc/Drum' }];

    public render(): React.ReactElement {
        const { classes, width } = this.props;
        const brake: any | undefined = this.brakeTypes.find(i => i.value === this.props.car.brakeType);
        const lube: any | undefined = this.lubeTypes.find(i => i.value === this.props.car.lubricationSystem);
        return (
            <Dialog
                maxWidth={"md"}
                fullWidth
                fullScreen={width !== "lg" && width !== "xl" && width !== "md"}
                open={this.props.show}
                onClose={this.props.handleCancel}
                aria-labelledby="dialog-title"
                aria-describedby="dialog-description"
                scroll="paper"
            >
                <DialogTitle >
                    {this.props.car.year + " " + this.props.car.make + " " + this.props.car.model}
                    {(width === "sm" || width === "xs") &&
                        <IconButton aria-label="close" className={classes.closeButton} onClick={this.props.handleCancel}>
                            <CloseIcon />
                        </IconButton>
                    }
                </DialogTitle>
                <DialogContent dividers>
                    <Grid container>
                        <Grid container spacing={1} alignItems="center">
                            <Grid item xs={12} className={classes.center}><Typography variant="h6"><strong>Event Information</strong></Typography><Divider /></Grid>
                            <Grid item xs={12} sm={3}><Typography variant="body1"><strong>Participating Event:</strong></Typography></Grid><Grid item xs={12} sm={9}><Typography variant="body1">
                                {this.props.car.carRace.map((value, index) => { 
                                    if(index < this.props.car.carRace.length - 1){
                                        return (value.race.name + " & ")
                                    }
                                    else{
                                        return (value.race.name) 
                                    }
                                })}</Typography></Grid>
                                {this.props.car.carRace.map((value : CarRace, index) => {
                                    return( value.race !== undefined && value.runGroup !== null && value.runGroup !== ""  && <React.Fragment key={index}><Grid item xs={12} sm={3}><Typography variant="body1"><strong>
                                            {value.race.name} Run Group:
                                        </strong></Typography></Grid><Grid item xs={12} sm={3}>
                                        <Box className={classes.stamp}><Typography variant="h4" className={classes.innerStamp}>
                                            {value.runGroup}
                                        </Typography></Box></Grid></React.Fragment>)
                                })}
                            <Grid item xs={12} className={classes.center}><Typography variant="h6"><strong>Vehicle Information</strong></Typography><Divider /></Grid>
                            <Grid item xs={12} sm={3}><Typography variant="body1"><strong>Class:</strong></Typography></Grid><Grid item xs={12} sm={9}><Typography variant="body1">{this.props.car.class}</Typography></Grid>
                            <Grid item xs={12} sm={3}><Typography variant="body1"><strong>Color:</strong></Typography></Grid><Grid item xs={12} sm={9}><Typography variant="body1">{this.props.car.color}</Typography></Grid>
                            <Grid item xs={12} sm={3}><Typography variant="body1"><strong>Car Number:</strong></Typography></Grid><Grid item xs={12} sm={9}><Typography variant="body1">{this.props.car.preferredCarNumber} (Alternatives: {this.props.car.alternateCarNumber1}, {this.props.car.alternateCarNumber2})</Typography></Grid>
                            <Grid item xs={12} sm={3}><Typography variant="body1"><strong>Horse Power (HP):</strong></Typography></Grid><Grid item xs={12} sm={9}><Typography variant="body1">{this.props.car.horsePower}</Typography></Grid>
                            <Grid item xs={12} sm={3}><Typography variant="body1"><strong>Displacement:</strong></Typography></Grid><Grid item xs={12} sm={9}><Typography variant="body1">{this.props.car.displacement}cc</Typography></Grid>
                            <Grid item xs={12} sm={3}><Typography variant="body1"><strong>Induction Type:</strong></Typography></Grid><Grid item xs={12} sm={9}><Typography variant="body1">{this.props.car.inductionType}</Typography></Grid>
                            <Grid item xs={12} sm={3}><Typography variant="body1"><strong>Chassis Number:</strong></Typography></Grid><Grid item xs={12} sm={9}><Typography variant="body1">{this.props.car.chassisNumber}</Typography></Grid>
                            <Grid item xs={12} sm={3}><Typography variant="body1"><strong>Lubrication System:</strong></Typography></Grid><Grid item xs={12} sm={9}><Typography variant="body1">{(lube !== undefined) ? lube.label : "No System Selected"}</Typography></Grid>
                            <Grid item xs={12} sm={3}><Typography variant="body1"><strong>Brake Type:</strong></Typography></Grid><Grid item xs={12} sm={9}><Typography variant="body1">{(brake !== undefined) ? brake.label : "No Type Selected"}</Typography></Grid>
                            <Grid item xs={12} sm={3}><Typography variant="body1"><strong>Modifications:</strong></Typography></Grid><Grid item xs={12} sm={9}><Typography variant="body1">{this.props.car.modificationReason}</Typography></Grid>
                            <Grid item xs={12} sm={3}><Typography variant="body1"><strong>History:</strong></Typography></Grid><Grid item xs={12} sm={9}><Typography variant="body1">{this.props.car.historyOfCar}</Typography></Grid>
                            <Grid item xs={12} sm={12} className={classes.center}><Typography variant="h6"><strong>Tire Information</strong></Typography><Divider /></Grid>
                            <Grid item xs={6} sm={3}><Typography variant="body1"><strong>Tires:</strong></Typography></Grid><Grid item xs={6} sm={9}><Typography variant="body1">{this.props.car.tireMake} {this.props.car.tireModel}</Typography></Grid>
                            <Grid item xs={6} sm={3}><Typography variant="body1"><strong>Front Size:</strong></Typography></Grid><Grid item xs={6} sm={3}><Typography variant="body1">{this.props.car.tireSizeFront}</Typography></Grid>
                            <Grid item xs={6} sm={3}><Typography variant="body1"><strong>Rear Size:</strong></Typography></Grid><Grid item xs={6} sm={3}><Typography variant="body1">{this.props.car.tireSizeRear}</Typography></Grid>
                            <Grid item xs={12} sm={12} className={classes.center}><Typography variant="h6"><strong>Wheel Information</strong></Typography><Divider /></Grid>
                            <Grid item xs={12} sm={3}><Typography variant="body1"><strong>Front Width:</strong></Typography></Grid><Grid item xs={12} sm={3}><Typography variant="body1">{this.props.car.frontWheelWidth} in. ({convert(this.props.car.frontWheelWidth, "metric", "mm")} mm.)</Typography></Grid>
                            <Grid item xs={12} sm={3}><Typography variant="body1"><strong>Front Diameter:</strong></Typography></Grid><Grid item xs={12} sm={3}><Typography variant="body1">{this.props.car.frontWheelDiameter} in. ({convert(this.props.car.frontWheelDiameter, "metric", "mm")} mm.)</Typography></Grid>
                            <Grid item xs={12} sm={3}><Typography variant="body1"><strong>Rear Width:</strong></Typography></Grid><Grid item xs={12} sm={3}><Typography variant="body1">{this.props.car.rearWheelWidth} in. ({convert(this.props.car.rearWheelWidth, "metric", "mm")} mm.)</Typography></Grid>
                            <Grid item xs={12} sm={3}><Typography variant="body1"><strong>Rear Diameter:</strong></Typography></Grid><Grid item xs={12} sm={3}><Typography variant="body1">{this.props.car.rearWheelDiameter} in. ({convert(this.props.car.rearWheelDiameter, "metric", "mm")} mm.)</Typography></Grid>
                            <Grid item xs={12}></Grid>
                            <Grid item xs={12} className={classes.center}><Typography variant="h6"><strong>Crew Information</strong></Typography><Divider /></Grid>
                            {(this.props.car.driverModel !== undefined && this.props.car.driverModel.length > 0 && this.props.car.driverModel[0].fname !== "") &&
                                <React.Fragment>
                                    <Grid item xs={12} sm={3}><Typography variant="body1"><strong>Driver:</strong></Typography></Grid>
                                    <Grid item xs={12} sm={9}>
                                        <List dense>
                                            {this.props.car.driverModel.map((value, index) => {
                                                return (
                                                    <ListItem key={index}>
                                                        <Typography variant="h6">
                                                            <Chip
                                                                icon={<DriveEtaIcon />}
                                                                color="primary"
                                                                label={value.fname + " " + value.lname} />
                                                        </Typography>
                                                    </ListItem>)
                                            })}
                                        </List>
                                    </Grid>
                                </React.Fragment>
                            }
                            {this.props.car.carRace.map((value, index) => {
                                let raceCrew: Crew[] = this.props.car.crew.filter(x => x.race?.id === value.race.id && !x.isOvercrew);
                                return (
                                    <React.Fragment key={index}>
                                    <Grid item xs={12} sm={3}><Typography variant="body1"><strong>{value.race.name + " Crew:"}</strong></Typography></Grid>
                                    <Grid item xs={12} sm={9}>
                                        <List dense>
                                            {(raceCrew !== undefined) && raceCrew.map((value, index) => {
                                                return (
                                                    <ListItem key={index}>
                                                        <Typography variant="h6" className={classes.center}>
                                                            <Chip
                                                                icon={<CheckCircleIcon />}
                                                                color="primary"
                                                                label={value.fname + " " + value.lname} />
                                                        </Typography>
                                                    </ListItem>)
                                            })}
                                            {(raceCrew === undefined || raceCrew.length === 0) &&
                                                <ListItem>
                                                    <Typography variant="h6" className={classes.center}>
                                                        <Chip
                                                            variant="outlined"
                                                            icon={<FaceIcon />}
                                                            label="No Crew Assigned"
                                                        />
                                                    </Typography>
                                                </ListItem>
                                            }
                                        </List>
                                    </Grid>
                                </React.Fragment>
                                )
                            })
                            }
                            {this.props.car.crew.some(e => e.isOvercrew) &&
                                    <React.Fragment>
                                    <Grid item xs={12} sm={3}><Typography variant="body1"><strong>Overcrew:</strong></Typography></Grid>
                                    <Grid item xs={12} sm={9}>
                                    <List dense>
                                        {(this.props.car.crew !== undefined) && this.props.car.crew.map((value, index) => {
                                            if (value.isOvercrew) {
                                                return (
                                                    <ListItem key={index}>
                                                        <Typography variant="h6" className={classes.center}>
                                                            <Chip
                                                                icon={<MonetizationOnIcon />}
                                                                color="secondary"
                                                                label={this.props.car.carRace.length > 1 ? value.fname + " " + value.lname +  "(" + value.race?.name + ")" : value.fname + " " + value.lname}
                                                            />
                                                        </Typography>
                                                    </ListItem>)
                                            }
                                        })}
                                    </List>
                                </Grid>
                                </React.Fragment>
                            }
                                {this.props.car.carRace.some(e => e.race.hasLounge) &&
                                <React.Fragment>
                                    <Grid item xs={12} sm={3}><Typography variant="body1"><strong>Lounge Members:</strong></Typography></Grid>
                                    <Grid item xs={12} sm={9}>
                                        <List dense>
                                            {(this.props.car.lounge[0] !== undefined) && this.props.car.lounge.map((value, index) => {
                                                if (index === 0)
                                                    return (
                                                        <ListItem key={index}>
                                                            <Typography variant="h6" className={classes.center}>
                                                                <Chip
                                                                    icon={<CheckCircleIcon />}
                                                                    color="primary"
                                                                    label={value.fname + " " + value.lname} />
                                                            </Typography>
                                                        </ListItem>
                                                    )
                                                else
                                                    return (
                                                        <ListItem key={index}>
                                                            <Typography variant="h6" className={classes.center}>
                                                                <Chip
                                                                    icon={<MonetizationOnIcon />}
                                                                    color="secondary"
                                                                    label={value.fname + " " + value.lname} />
                                                            </Typography>
                                                        </ListItem>)
                                            })}
                                            {(this.props.car.lounge.length === 0) &&
                                                <ListItem>
                                                    <Typography variant="h6" className={classes.center}>
                                                        <Chip
                                                            variant="outlined"
                                                            icon={<FaceIcon />}
                                                            label="No Lounge Members Chosen"
                                                        />
                                                    </Typography>
                                                </ListItem>
                                            }
                                        </List>
                                    </Grid>
                                </React.Fragment>
                                }
                            </Grid>
                        </Grid>
                </DialogContent>
                {(width !== "sm" && width !== "xs") &&
                    <DialogActions>
                        <Button onClick={this.props.handleCancel} variant="contained" autoFocus>Close</Button>
                    </DialogActions>
                }
            </Dialog>
        );
    }
}

const styles = createStyles((theme: Theme) => ({
    center: {
        textAlign: "center"
    },
    stamp: {
        borderWidth: 2,
        borderColor: theme.palette.secondary.main,
        borderRadius: '5px',
        borderStyle: 'solid',
        width: 'fit-content'
    },
    innerStamp: {
        backgroundColor: theme.palette.secondary.main,
        padding: '3px',
        margin: '2px',
        color: '#FFFFFF'
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
}))

export default withWidth()(withStyles(styles, { withTheme: true })(CarInfo));