import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router';
import Layout from './components/Layout';
import AuthorizeRoute from './services/api-authorization/AuthorizeRoute';
import ApiAuthorizationRoutes from './services/api-authorization/ApiAuthorizationRoutes';
import { ApplicationPaths } from './services/api-authorization/ApiAuthorizationConstants';
import { Snackbar, SnackbarContent, IconButton, LinearProgress } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import Dashboard from './components/Dashboard';
import { withStyles, createStyles, Theme, ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { red, grey, green } from '@material-ui/core/colors';
import AccountInfo from './components/AccountInfo';
import AddEntrantForm from './components/AddEntrantForm'
import AdminDashboard from './components/AdminDashboard';
import AdminPrivilege from './components/AdminPrivilege';
import Entrant from './interfaces/IEntrant';
import { withRouter } from "react-router";
import authService from './services/api-authorization/AuthorizeService';
import APIService from './services/APIService';
import RegistrationEvent from './interfaces/IRegistrationEvent';
import CheckInModule from './components/CheckInModule';
import TrackRental from './components/TrackRental';
import TrackRentalAdmin from './components/TrackRentalAdmin';
import TrackRentalAdminPriceSheet  from './components/TrackRentalAdminPriceSheet';

interface IAppProps {
    classes: any; 
}
interface IAppState {
    event: RegistrationEvent;
    message: string;
    setMessage: any;
    messageType: string;
    loading: boolean;
    setLoading: any;
    currentEntrant?: Entrant;
    _isMounted: boolean;
    isAdmin: boolean;
    featureMode: string;
    setFeature: any;
}

export class App extends Component<IAppProps, IAppState> {
    static displayName = App.name;    
    private apiService: APIService = new APIService;
    public constructor(props: Readonly<IAppProps>) {
        super(props);
        this.state = {
            event: {name: "", id : "", year: "", registrationOpenDate : new Date(), registrationCloseDate : new Date(), race : []},
            message: "",
            setMessage: this.setMessage,
            messageType: "info",
            loading: false,
            setLoading: this.setLoading,
            _isMounted: false,
            isAdmin: false,
            featureMode: "raceRegistration",
            setFeature : this.setFeature
        };
        this.apiService = new APIService;
        this.setMessage = this.setMessage.bind(this);
        this.setFeature = this.setFeature.bind(this);
        this.closeMessage = this.closeMessage.bind(this);
        this.UserDashboard = this.UserDashboard.bind(this);
        this.UserAccount = this.UserAccount.bind(this);
        this.NewUser = this.NewUser.bind(this);
        this.setLoading = this.setLoading.bind(this);
        this.AdminDashboard = this.AdminDashboard.bind(this);
        this.AdminPrivilege = this.AdminPrivilege.bind(this);
        this.CheckIn = this.CheckIn.bind(this);
        this.TrackRental = this.TrackRental.bind(this);
        this.TrackRentalAdmin = this.TrackRentalAdmin.bind(this);
        this.TrackRentalAdminPriceSheet = this.TrackRentalAdminPriceSheet.bind(this);
    };

    //usage: setMessage("ERROR MESSAGE", "error") will display an error message to user
    private setMessage = (m: string, type: string): void => {
        this.setState({ message: m })
        this.setState({ messageType: type })
        return;
    }
    private setLoading = (loading: boolean) => {
        this.setState({ loading: loading })
    }
    private closeMessage() {
        this.setState({ message: "" })
    }
    private setFeature = (featureMode: string): void => {
        this.setState({ featureMode })
        return;
    }
    private UserDashboard() {
        return (<Layout {...this.state} featureMode="raceRegistration"><Dashboard {...this.state} /></Layout>);
    }
    private AdminDashboard() {
        return (<Layout {...this.state} featureMode="raceRegistration"><AdminDashboard {...this.state} /></Layout>);
    }
    private AdminPrivilege() {
        return (<Layout {...this.state} featureMode="raceRegistration"><AdminPrivilege {...this.state} /></Layout>);
    }
    private UserAccount() {
        return (<Layout {...this.state}><AccountInfo {...this.state} /></Layout>);
    }
    private NewUser() {
        return (<AddEntrantForm {...this.state} />);
    }
    private CheckIn() {
        return (<Layout {...this.state} featureMode="raceRegistration"><CheckInModule {...this.state} /></Layout>);
    }
    private TrackRental() {
        return (<Layout {...this.state} featureMode="trackRental"><TrackRental {...this.state} /></Layout>);
    }
    private TrackRentalAdmin() {
        return (<Layout {...this.state} featureMode="trackRental"><TrackRentalAdmin {...this.state} /></Layout>);
    }
    private TrackRentalAdminPriceSheet() {
        return (<Layout {...this.state} featureMode="trackRental"><TrackRentalAdminPriceSheet {...this.state} /></Layout>);
    }
    private async checkAdminClaim() {
        const adminClaim = await authService.checkIfAdmin();
        var result = true;
        if (adminClaim !== true) {
            result = false;
        }
        this.setState({ isAdmin: result });
    };

    private async getDefaultEvent() {
        await this.apiService.getDefaultEvent().then((result) => {
            this.setState({ event: result })})
       
    };
    
    public componentDidMount() {
        this.getDefaultEvent();
        this.checkAdminClaim().then(() => { this.setState({ _isMounted: true }) });
        if (window.location.pathname.toLowerCase() === "/trackrental" || window.location.pathname.toLowerCase() === "/trackrentaladmin" || window.location.pathname.toLowerCase() === "/trackrentaladminpricesheet"){
            this.setFeature("trackRental");
        }
    };

    public render() {
        const { classes } = this.props;
        const theme = createMuiTheme({
            palette: {
                primary: {
                    main: this.state.featureMode === "trackRental" ? grey[700] : grey[100],
                },
                secondary: {
                    main: this.state.featureMode === "trackRental" ? red[700] : red[700],
                },
            },
            overrides: {
                MuiFormLabel: createStyles({
                    root: {
                        '&.Mui-focused': {
                            color: "black"
                        },
                    }
                }),
                MuiInput: createStyles({
                    underline: {
                        '&:after': {
                            borderBottomColor: "black"
                        },
                    }
                }),
                MuiButton: createStyles({
                    textPrimary: {
                        color: "black",
                    }
                }),
                MuiStepConnector: createStyles({
                    lineHorizontal: {
                        marginTop: "15px",
                    }
                }),
                MuiStepper: createStyles({
                    root: {
                        padding: 0,
                    }
                }),
                MuiDialog: createStyles({
                    paperScrollPaper: {
                        //maxHeight:"100vh",
                        overflowY: "unset",
                    },
                }),
                MuiDialogContent: createStyles({
                    root: {
                        overflowX: 'hidden'
                    }
                }),
                MuiDialogActions: createStyles({
                    root: {
                        display: 'block'
                    }
                }),
                MuiLinearProgress: createStyles({
                    root: {
                        marginTop: '-5px',
                        marginRight: '-8px',
                        marginLeft: '-8px',
                        '@media (max-width: 600px)': {
                            marginTop: '-7px'
                        }
                    }
                }),
                MuiMobileStepper: createStyles({
                    dotActive: {
                        backgroundColor: red[700],
                    }
                }),
                MuiTooltip: createStyles({
                    popper: {
                        zIndex: 1300,
                    }
                }),
                MuiChip: createStyles({
                    root: {
                        minHeight: 32,
                        height: "auto",
                        whiteSpace: "unset"
                    },
                    label: {
                        whiteSpace: "unset",
                        textOverflow: "unset",
                        minWidth: 135,
                        textAlign: "center"
                    }
                }),
                MuiCardHeader: createStyles({
                    content: {
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    }
                }),
                MuiSnackbarContent: createStyles({
                    root: {
                        '@media (min-width: 600px)': {
                            minWidth: '100%'
                        },
                    zIndex:1401
                    }
                }),
            }
        });
        return (
            <React.Fragment>
                {this.state._isMounted &&
                    <ThemeProvider theme={theme}>
                    <LinearProgress color="secondary" className={(this.state.loading) ? classes.loadingBar : classes.hide} />
                        <Snackbar
                            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                            open={this.state.message !== ""}
                            autoHideDuration={6000}
                            onClose={this.closeMessage}
                        >
                            <SnackbarContent
                                className={classes[this.state.messageType]}
                                aria-describedby="client-snackbar"
                                message={<span id="message-id" className={classes.message}>
                                    {(this.state.messageType === "error") ? <ErrorIcon /> : (this.state.messageType === "success") ? <CheckCircleIcon /> : <InfoIcon />}
                                    {this.state.message}</span>}
                                action={[
                                    <IconButton key="close" aria-label="close" color="inherit" onClick={this.closeMessage} >
                                        <CloseIcon className={classes.icon} />
                                    </IconButton>
                                ]}
                            />
                        </Snackbar>
                        <Switch>
                            <AuthorizeRoute exact path="/" component={this.UserDashboard} />
                            <AuthorizeRoute exact path="/Dashboard" component={this.UserDashboard} />
                            <AuthorizeRoute exact path="/Account" component={this.UserAccount} />
                            <AuthorizeRoute path="/NewUser" component={this.NewUser} />
                            <AuthorizeRoute path="/AdminDashboard" component={this.AdminDashboard} />
                            <AuthorizeRoute path="/AdminPrivilege" component={this.AdminPrivilege} />
                            <AuthorizeRoute path="/TrackRental" component={this.TrackRental} />
                            <AuthorizeRoute path="/TrackRentalAdmin" component={this.TrackRentalAdmin} />
                            <AuthorizeRoute path="/TrackRentalAdminPriceSheet" component={this.TrackRentalAdminPriceSheet} />
                            <AuthorizeRoute path="/CheckIn" component={this.CheckIn} />
                            <Route path="/authentication/logged-out">
                                <Redirect to="/" />
                            </Route>
                            <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />
                        </Switch>
                    </ThemeProvider>
                }
            </React.Fragment>
        );
    }
}
const styles = createStyles((theme: Theme) => ({
    '@global': {
        '*::-webkit-scrollbar': {
            width: '0.6em'
        },
        '*::-webkit-scrollbar-track': {
            '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
            backgroundColor: grey[300]
        },
        '*::-webkit-scrollbar-thumb': {
            backgroundColor: red[700],
            outline: '1px solid slategrey',
        },
        '*::-webkit-scrollbar:horizontal': {
            height: '0.6em'
        }       
    },

    error: {
        backgroundColor: theme.palette.error.dark,
    },
    success: {
        backgroundColor: green[600],
    },
    info: {
        backgroundColor: theme.palette.primary.main,
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
    loadingBar: {
        marginTop: theme.spacing(8) + 1,
        top: theme.spacing(8) + 1,
        marginRight: theme.spacing(-1),
        marginLeft: theme.spacing(-1),
        zIndex: 10,
        position: "sticky",
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(7) + 1,
            top: theme.spacing(7) + 1,
        }
    },
    hide: {
        marginTop: theme.spacing(8) + 1,
        visibility: "hidden"
    },
    logo: {
        height: '50px',
        paddingRight: theme.spacing(2)
    },
}));

export default withStyles(styles)(App);