import React, { Component, PureComponent } from 'react';
import { Snackbar, SnackbarContent, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, List, ListItem, ListItemText, ListItemSecondaryAction, createStyles, Divider, withStyles, Theme, CircularProgress, Box } from '@material-ui/core';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import CartItem from '../interfaces/ICartItem';
import { green } from '@material-ui/core/colors';
import Entry from '../interfaces/IEntry';


export interface IShoppingCartProps {
    classes: any;
    cartItems:CartItem[];
    shoppingCartBalance:number;
    loading: boolean;
}

export interface IShoppingCartState {
    showCartDetails: boolean;
}

export class ShoppingCart extends PureComponent<IShoppingCartProps, IShoppingCartState> {
    static displayName = ShoppingCart.name;
    public constructor(props: Readonly<IShoppingCartProps>) {
        super(props);
        this.state = {
            showCartDetails: false
        }
    }

    private toggleCartDetails() {
        this.setState({ showCartDetails: !this.state.showCartDetails })
    }

    public render(): React.ReactElement<IShoppingCartProps> {
        const { classes } = this.props;
        return (<Snackbar
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            open={true}
            ContentProps={{
                'aria-describedby': 'message-id',
            }}
            className={classes.root}
        >
            <SnackbarContent
                className={classes.cartPanel}
                message={
                    <ExpansionPanel className={classes.cartPanel} TransitionProps={{ unmountOnExit: true }} expanded={this.state.showCartDetails} onChange={() => this.toggleCartDetails()} >
                        <ExpansionPanelSummary
                            expandIcon={<ExpandLessIcon className={classes.icon}/>}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                        <span id="message-id" className={classes.message}>Estimated Cost: </span>
                        {this.props.loading ? <CircularProgress size={18}/> : "$" + parseFloat(Math.round((this.props.shoppingCartBalance * 100) / 100).toString()).toFixed(2) }
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <Divider />
                            {this.props.loading ? <Box className={classes.box}><CircularProgress /></Box> :
                            <List dense={true} className={classes.cartList}>
                                {this.props.cartItems.map((value, index) => {
                                    if (value.quantity > 0) {return (
                                        <React.Fragment key={index}>
                                            <ListItem className={classes.secondary} >
                                                <ListItemText
                                                    primary={<span>{value.name} x {value.quantity}</span>}
                                                />
                                                <ListItemSecondaryAction>
                                                    <span>${parseFloat((Math.round(value.price * value.quantity * 100) / 100).toString()).toFixed(2)}</span>
                                                </ListItemSecondaryAction>
                                            </ListItem><Divider />
                                        </React.Fragment>
                                    )}
                                })}
                            </List>
                            }
                        </ExpansionPanelDetails>
                    </ExpansionPanel>}
            />
        </Snackbar>)
    }
}
const styles = createStyles((theme: Theme) => ({
    cartPanel:{        
        backgroundColor: green[600],
        color: theme.palette.getContrastText(green[600]),
        boxShadow: 'none',
        padding: 0,
    },
    secondary:{
        paddingRight: '100px'
    },
    root:{        
        zIndex: "1300",
    },
    icon:{        
        color: theme.palette.getContrastText(green[600]),
    },
    box:{      
        width: '100%',  
        textAlign: 'center'
    },
    message:{
        paddingRight : theme.spacing(1)
    }
}))

export default withStyles(styles)(ShoppingCart)