import authService from './api-authorization/AuthorizeService';
import Class from '../interfaces/IClass';
import Entry from '../interfaces/IEntry';
import Crew from '../interfaces/ICrew';
import PrepShop from '../interfaces/IPrepShop';
import Entrant from '../interfaces/IEntrant';
import Car from '../interfaces/ICar';
import Driver from '../interfaces/IDriver';
import LoungeMember from '../interfaces/ILoungeMember';
import RegistrationEvent from '../interfaces/IRegistrationEvent';
import Race from '../interfaces/IRace';
import ITrackSoundLevel from '../interfaces/ITrackSoundLevel';
import ITrackRental from '../interfaces/ITrackRental';
import ITrackBlackoutDate from '../interfaces/ITrackBlackoutDate';
import ITrackCaterer from '../interfaces/ITrackCaterer';
import ITrackService from '../interfaces/ITrackService';

interface Result{
    message?: string;
    status: number;
    ok:boolean;
}

export default class APIService {
    constructor() {
    }

    public async addEntrant(entrant: Entrant): Promise<Result> {
        let result: Result = {status: 0, ok: false}
        const token = await authService.getAccessToken();
        await fetch("/api/entrant/", {
            method: 'POST',
            headers: !token ? {} : {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(
                {
                    Fname: entrant.fname,
                    Lname: entrant.lname,
                    Mname: entrant.mname,
                    Address1: entrant.address1,
                    Address2: entrant.address2,
                    Address3: entrant.address3,
                    City: entrant.city,
                    State: entrant.state,
                    Dob: entrant.dob,
                    Zipcode: entrant.zipCode,
                    Country: entrant.country,
                    PhoneNumber: entrant.phoneNumber,
                }
            )
        }).then(async (response) => {
            if (response.ok) {
                await response.json()
            }
            else {
                await response.text().then(async (text) => {
                    //props.setMessage("Error completing operation. Please try again. (" + response.status + ")", "error");
                    //Log Client side errors
                    if (response.status >= 400 && response.status < 500) {
                        let html = new DOMParser().parseFromString(text, 'text/html').querySelector('pre');
                        if (html !== null) {
                            this.log(response, html.innerHTML);
                        }
                    }
                });
            }
            result.ok = response.ok;
            result.status = response.status;

        }).catch(async (e) => {
            //props.setMessage("Error completing operation. Please try again.", "error");
            result.ok = false;
        })
        return(result);
    }

    //User/Entrant
    public async getEntrant(): Promise<any> {
        let result: any = false;
        const token = await authService.getAccessToken();
        await fetch("/api/entrant/", {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        }).then(async (response) => {
            if (response.ok) {
                await response.json().then((data) => {
                    result = {data}
                })
            }
            else {
                await response.text().then(async (text) => {
                    //Log Client side errors
                    if (response.status >= 400 && response.status < 500) {
                        let html = new DOMParser().parseFromString(text, 'text/html').querySelector('pre');
                        if (html !== null) {
                            this.log(response, html.innerHTML);
                        }
                    }
                });
            }
        }).catch((e) => {
            console.error(e)
        })
        return (result);
    };

    public async checkUserHasAnEntrant() : Promise<boolean> {
        let result: boolean = false;
        const token = await authService.getAccessToken();
        await fetch("/api/entrant/list", {
            headers: !token ? {} : {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            }
        }).then(async (response) => {
            if (response.ok) {
                await response.json().then((data) => {
                    if (data.result === "No Entrant under this user") {
                        result = true;
                    }
                    else {
                        result = false;
                    }
                })
            }
            else {
                await response.text().then(async (text) => {
                    //Log Client side errors
                    if (response.status >= 400 && response.status < 500) {
                        let html = new DOMParser().parseFromString(text, 'text/html').querySelector('pre');
                        if (html !== null) {
                            this.log(response, html.innerHTML);
                        }
                    }
                    result = false;
                });
            }

        }).catch(async (e) => {
            console.error(e);
            result = false;
        })
        return (result);
    };

    public async updateEntrant(entrant: Entrant) : Promise<Result> {
        let result: Result = {status: 0, ok: false}
        const token = await authService.getAccessToken();
        await fetch("/api/entrant/", {
            method: 'PUT',
            headers: !token ? {} : {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(
                {
                    Fname: entrant.fname,
                    Lname: entrant.lname,
                    Mname: entrant.mname,
                    Address1: entrant.address1,
                    Address2: entrant.address2,
                    Address3: entrant.address3,
                    City: entrant.city,
                    State: entrant.state,
                    Dob: entrant.dob,
                    Zipcode: entrant.zipCode,
                    Country: entrant.country,
                    PhoneNumber: entrant.phoneNumber,
                    id: entrant.id,
                    businessName: entrant.businessName
                }
            )
        }).then(async (response) => {
            if (response.ok) {
                await response.json();
            }
            else {
                await response.text().then(async (text) => {
                    if (response.status >= 400 && response.status < 500) {
                        let html = new DOMParser().parseFromString(text, 'text/html').querySelector('pre');
                        if (html !== null) {
                            this.log(response, html.innerHTML);
                        }
                    }
                });
            }
            result.ok = response.ok;
            result.status = response.status;
        }).catch(async (e) => {
            result.ok = false;
        })
        return (result);
    };

    //Driver
    public async getEntrantDriverInfo(): Promise<any> {
        let result: any = false;
        const token = await authService.getAccessToken();
        await fetch("/api/driver/", {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        }).then(async (response) => {
            if (response.ok) {
                await response.json().then((data) => {
                    result = data;
                })
            }
            else {
                await response.text().then(async (text) => {
                    //Log Client side errors
                    if (response.status >= 400 && response.status < 500) {
                        let html = new DOMParser().parseFromString(text, 'text/html').querySelector('pre');
                        if (html !== null) {
                            this.log(response, html.innerHTML);
                        }
                    }
                });
            }
        }).catch((e) => {
            console.error(e)
        })
        return (result);
    };

    //Entry Form
    public async startEntry(isInterestedInTop : boolean, participatingYear: string, registrationEvent: RegistrationEvent) : Promise<Result> {
        let result: Result = {status: 0, ok: false}
        const token = await authService.getAccessToken();
        await fetch("/api/entryform/", {
            method: 'POST',
            headers: !token ? {} : {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
                "Accept": "application/json"
            },
            body: JSON.stringify(
                {
                    isInterestedInTop,
                    participatingYear,
                    event : registrationEvent,
                    eventId : registrationEvent.id
                }
            )
        }).then(async (response) => {
            if (response.ok) {
                await response.json().then(() => {
                    // props.handleCancel();
                })
            }
            else {
                await response.text().then(async (text) => {
                    //Log Client side errors
                    if (response.status >= 400 && response.status < 500) {
                        let html = new DOMParser().parseFromString(text, 'text/html').querySelector('pre');
                        if (html !== null) {
                            this.log(response, html.innerHTML);
                        }
                    }
                });
            }
            result.ok = response.ok;
            result.status = response.status;

        }).catch(async (e) => {
            result.ok = false;
        })
        return(result);
    }

    public async udpateEntry(entry : Entry, imperial : boolean): Promise<Result> {
        let result: Result = {status: 0, ok: false}

        const token = await authService.getAccessToken();
        await fetch("/api/entryform/" + entry.id, {
            method: 'PUT',
            headers: !token ? {} : {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(
                {
                    id: entry.id,
                    entrantId: entry.entrantId,
                    isRemovableTrailer: entry.isRemovableTrailer,
                    TrailerLength: entry.trailerLength,
                    TransporterLength: entry.transporterLength,
                    TransporterWidth: entry.transporterWidth,
                    TransporterType: entry.transporterType,
                    HasTrasporterAwning: entry.hasTrasporterAwning,
                    AwningWidth: entry.awningWidth,
                    AwningLength: entry.awningLength,
                    HasSharedSpace: entry.hasSharedSpace,
                    SharedSpaceParticipants: entry.sharedSpaceParticipants,
                    AdjacentParticipants: entry.adjacentParticipants,
                    ParticipatingYear: entry.participatingYear,
                    Event: entry.event,
                    IsInterestedInTop: entry.isInterestedInTop,
                    AwningOrientation: entry.awningOrientation,
                    PrepShop: entry.prepShop
                }
            )
        }).then(async (response) => {
            if (response.ok) {
                await response.json();
            }
            else {
                await response.text().then(async (text) => {
                    //Log Client side errors
                    if (response.status >= 400 && response.status < 500) {
                        let html = new DOMParser().parseFromString(text, 'text/html').querySelector('pre');
                        if (html !== null) {
                            this.log(response, html.innerHTML);
                        }
                    }
                });
            }
            result.ok = response.ok;
            result.status = response.status;

        }).catch(async (e) => {
            result.ok = false;
        })
        await fetch(`api/entrant/ChangeMeasurementSystemPreference/${imperial}/true`, {
            method: 'PUT',
            headers: !token ? {} : {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        });
        return (result);
    };
    
    public async getEntryFormsList(): Promise<Entry[]> {
        let result : Entry[] = [];
        const token = await authService.getAccessToken();
        await fetch("/api/entryform/", {
            method: "GET",
            headers: !token ? {} : { 'Authorization': `Bearer ${token}`,
            "Accept" : "application/json",
            "Content-Type" : "application/json",
        }
        }).then(async (response) => {
            if (response.ok) {
                await response.json().then((data) => {
                    if (data.entrantForms != null && data.entrantForms.length > 0) {
                        result = data.entrantForms;
                    }
                })
            }
            else {
                await response.text().then(async (text) => {
                    //Log Client side errors
                    if (response.status >= 400 && response.status < 500) {
                        let html = new DOMParser().parseFromString(text, 'text/html').querySelector('pre');
                        if (html !== null) {
                            this.log(response, html.innerHTML);
                        }
                    }
                });
            }
        }).catch((e) => {
            console.error(e)
        })
        return (result);
    }

    //Car
    public async addCar(id: string, car : Car, imperial : boolean, carImage : File | null, historical : boolean, historicalId: string): Promise<Result> {
        let result: Result = {status: 0, ok: false}
        const token = await authService.getAccessToken();
        await fetch(`/api/entryform/${id}/car`, {
            method: 'POST',
            headers: !token ? {} : {
                'Authorization': `Bearer ${token}`, 
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(car)
        }).then(async (response) => {
            if (response.ok) {
                await response.json().then(async (data) => {
                    await this.ChangeMeasurementSystemPreference(imperial);
                    if (token != null && data != null && data.result.result != null && data.result.result.id != null) {
                        await this.processImage(data, token, carImage);
                    } else if (token != null && data != null && data.result != null && data.result.id != null) {
                        if (historical) {
                            await this.copyHistoricalImage(id,data.result, historicalId)
                        }
                        else if (carImage != null && carImage.type !== undefined) {
                            await this.processImage(data.result, token, carImage);
                        }
                    }
        
                }).then(() => { 
                }).catch((e) => {
                    console.error(e)
                })
            }
            else {
                await response.text().then(async (text) => {
                    //Log Client side errors
                    if (response.status >= 400 && response.status < 500) {
                        let html = new DOMParser().parseFromString(text, 'text/html').querySelector('pre');
                        if (html !== null) {
                            this.log(response, html.innerHTML);
                        }
                    }
                });
            }
            result.ok = response.ok;
            result.status = response.status;
        }).catch((e) => {
            console.error(e)
        })
        return (result);
    }

    public async updateCar(id: string, carId : string, car : Car, imperial : boolean, carImage : File | null, historical : boolean, historicalId: string): Promise<Result> {
        let result: Result = {status: 0, ok: false}
        const token = await authService.getAccessToken();
        await fetch(`/api/entryform/${id}/car/${carId}`, {
            method: 'PUT',
            headers: !token ? {} : {
                'Authorization': `Bearer ${token}`, 
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(car)
        }).then(async (response) => {
            if (response.ok) {
                await response.json().then(async (data) => {
                    await this.ChangeMeasurementSystemPreference(imperial);
                    if (token != null && data != null && data.result.result != null && data.result.result.id != null) {
                        await this.processImage(data, token, carImage);
                    } else if (token != null && data != null && data.result != null && data.result.id != null) {
                        if (historical) {
                            await this.copyHistoricalImage(id,data.result, historicalId)
                        }
                        else if (carImage != null && carImage.type !== undefined) {
                            await this.processImage(data.result, token, carImage);
                        }
                    }
        
                }).then(() => { 
                }).catch((e) => {
                    console.error(e)
                })
            }
            else {
                await response.text().then(async (text) => {
                    //Log Client side errors
                    if (response.status >= 400 && response.status < 500) {
                        let html = new DOMParser().parseFromString(text, 'text/html').querySelector('pre');
                        if (html !== null) {
                            this.log(response, html.innerHTML);
                        }
                    }
                });
            }
            result.ok = response.ok;
            result.status = response.status;
        }).catch((e) => {
            console.error(e)
        })
        return (result);
    }

    public async deleteCar(id : string) : Promise<Result> {
        let result: Result = {status: 0, ok: false}
        const token = await authService.getAccessToken();
        await fetch(`/api/car/${id}/deleteCar/`, {
            method: "POST",
            headers: !token ? {} : {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
        }).then(async (response) => {
            if (response.ok) {
                await response.json().then(() => {
                })
            }
            else {
                await response.text().then(async (text) => {
                    //Log Client side errors
                    if (response.status >= 400 && response.status < 500) {
                        let html = new DOMParser().parseFromString(text, 'text/html').querySelector('pre');
                        if (html !== null) {
                            this.log(response, html.innerHTML);
                        }
                    }
                });
            }
            result.ok = response.ok;
            result.status = response.status;

        }).catch(async (e) => {
            result.ok = false;
        })
        return (result);
    }

    public async withdrawCar(id: string) : Promise<Result> {
        let result: Result = {status: 0, ok: false}
        const token = await authService.getAccessToken();
        await fetch(`/api/car/${id}/updateStatus/Withdrawn`, {
            method: "POST",
            headers: !token ? {} : {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
        }).then(async (response) => {
            if (response.ok) {
                await response.json().then(() => {
                    // props.setMessage("Car Successfully Withdrawn.", "success")
                    //props.getEntryForms();
                })
            }
            else {
                await response.text().then(async (text) => {
                    // props.setMessage("Error completing operation. Please try again. (" + response.status + ")", "error");
                    //Log Client side errors
                    if (response.status >= 400 && response.status < 500) {
                        let html = new DOMParser().parseFromString(text, 'text/html').querySelector('pre');
                        if (html !== null) {
                            this.log(response, html.innerHTML);
                        }
                    }
                });
            }
            result.ok = response.ok;
            result.status = response.status;
        }).catch(async (e) => {
            // props.setMessage("Error completing operation. Please try again.", "error");
            result.ok = false;
        })
        return (result);
    }
    //Crew
    public async updateCrew(id: string, crewList: any[]) : Promise<Result>{
        let result: Result = {status: 0, ok: false}
        const token = await authService.getAccessToken();
        await fetch(`/api/car/${id}/updateCrew/`, {
            method: "POST",
            headers: !token ? {} : {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body:  JSON.stringify(crewList),
        }).then(async (response) => {
            if (response.ok) {
                await response.json();
            }
            else {
                await response.text().then(async (text) => {
                    //Log Client side errors
                    if (response.status >= 400 && response.status < 500) {
                        let html = new DOMParser().parseFromString(text, 'text/html').querySelector('pre');
                        if (html !== null) {
                            this.log(response, html.innerHTML);
                        }
                    }
                });
            }
            result.ok = response.ok;
            result.status = response.status;
        }).catch(async (e) => {
            result.ok = false;
        })
        return (result);
    }

    //PrepShops
    public async getPrepShopList() : Promise<PrepShop[]>{
        let result : PrepShop[] = [];
        const token = await authService.getAccessToken();
        await fetch("/api/list/PrepShops", {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        }).then(async (response) => {
            if (response.ok) {
                await response.json().then((data) => {
                    var arr: PrepShop[] = [];
                    if (data != null) {
                        data.prepshops.forEach(function (value: any) {
                            arr.push(value)
                        })
                    }
                    result = arr;
                })
            }
            else {
                await response.text().then(async (text) => {
                    //Log Client side errors
                    if (response.status >= 400 && response.status < 500) {
                        let html = new DOMParser().parseFromString(text, 'text/html').querySelector('pre');
                        if (html !== null) {
                            this.log(response, html.innerHTML);
                        }
                    }
                });
            }
        }).catch((e) => {
            console.error(e)
        });
        return (result);
    };

    //Classes
    public async getClasses(): Promise<Class[]> {
        let result: Class[] = [];
        const token = await authService.getAccessToken();
        await fetch("/api/list/Classes", {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        }).then(async (response) => {
            if (response.ok) {
                await response.json().then((data) => {
                    var arr: Class[] = [];
                    if (data != null) {
                        data.classes.forEach(function (value: any) {
                            arr.push(value)
                        })
                    }
                    arr.sort((a, b) => {
                        var nameA = a.name.toUpperCase(); // ignore upper and lowercase
                        var nameB = b.name.toUpperCase(); // ignore upper and lowercase
                        if (nameA < nameB) {
                            return -1;
                        }
                        if (nameA > nameB) {
                            return 1;
                        }
                        return 0;
                    });
                    result = arr;
                })
            }
            else {
                await response.text().then(async (text) => {
                    //Log Client side errors
                    if (response.status >= 400 && response.status < 500) {
                        let html = new DOMParser().parseFromString(text, 'text/html').querySelector('pre');
                        if (html !== null) {
                            this.log(response, html.innerHTML);
                        }
                    }
                });
            }
        }).catch((e) => {
            console.error(e)
        });
        return (result);
    }

    //Client Error Logger
    private async log(response: Response, message: string) {
        const token = await authService.getAccessToken();
        await fetch(`/api/log/logClientError/${response.status}`, {
            method: 'POST',
            headers: !token ? {} : {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(message)
        })
    }

    //Misc Functions
    public async UpdatePaymentDue(id: string){
        const token = await authService.getAccessToken();
        await fetch(`api/entryform/Payment/${id}`, {
            method: 'PUT',
            headers: !token ? {} : {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        });
    }

    private async ChangeMeasurementSystemPreference(imperial: boolean){
        const token = await authService.getAccessToken();
        await fetch(`api/entrant/ChangeMeasurementSystemPreference/${imperial}/true`, {
            method: 'PUT',
            headers: !token ? {} : {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        });
    }

    public async getDefaultEvent(){
        let result: any;
        const token = await authService.getAccessToken();
        await fetch(`api/event/defaultEvent`, {
            method: 'GET',
            headers: !token ? {} : {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then(async (response) => {
            if (response.ok) {
                await response.json().then((data) => {
                    result = data.result;
                });
            }
            else {
                await response.text().then(async (text) => {
                    //Log Client side errors
                    if (response.status >= 400 && response.status < 500) {
                        let html = new DOMParser().parseFromString(text, 'text/html').querySelector('pre');
                        if (html !== null) {
                            this.log(response, html.innerHTML);
                        }
                    }
                });
            }
        }).catch((e) => {
            console.error(e)
        });
        return (result);
    }

    public async getEvent(eventId: string){
        let result: any;
        const token = await authService.getAccessToken();
        await fetch(`api/entryform/event/${eventId}/`, {
            method: 'GET',
            headers: !token ? {} : {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then(async (response) => {
            if (response.ok) {
                await response.json().then((data) => {
                    result = data.result;
                });
            }
            else {
                await response.text().then(async (text) => {
                    //Log Client side errors
                    if (response.status >= 400 && response.status < 500) {
                        let html = new DOMParser().parseFromString(text, 'text/html').querySelector('pre');
                        if (html !== null) {
                            this.log(response, html.innerHTML);
                        }
                    }
                });
            }
        }).catch((e) => {
            console.error(e)
        });
        return (result);
    }

    public async getRaces(eventId: string){
        let result: Race[] = [];
        const token = await authService.getAccessToken();
        await fetch(`api/event/GetRacesByEvent/${eventId}/`, {
            method: 'GET',
            headers: !token ? {} : {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then(async (response) => {
            if (response.ok) {
                await response.json().then((data) => {
                    result = data.result;
                });
            }
            else {
                await response.text().then(async (text) => {
                    //Log Client side errors
                    if (response.status >= 400 && response.status < 500) {
                        let html = new DOMParser().parseFromString(text, 'text/html').querySelector('pre');
                        if (html !== null) {
                            this.log(response, html.innerHTML);
                        }
                    }
                });
            }
        }).catch((e) => {
            console.error(e)
        });
        return (result);
    }

    public async isRegistrationOpen(eventId: string){
        let result: boolean = false;
        const token = await authService.getAccessToken();
        await fetch(`api/entryform/registrationOpen/${eventId}/`, {
            method: 'GET',
            headers: !token ? {} : {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then(async (response) => {
            if (response.ok) {
                await response.json().then((data) => {
                    result = data.result;
                });
            }
            else {
                await response.text().then(async (text) => {
                    //Log Client side errors
                    if (response.status >= 400 && response.status < 500) {
                        let html = new DOMParser().parseFromString(text, 'text/html').querySelector('pre');
                        if (html !== null) {
                            this.log(response, html.innerHTML);
                        }
                    }
                });
            }
        }).catch((e) => {
            console.error(e)
        });
        return (result);
    }

    private async copyHistoricalImage(id : string, data : any, historicalId : string){
        const token = await authService.getAccessToken();
        const response = await fetch(`/api/entryform/${id}/car/${data.id}/copyImage/${historicalId}`, {
            method: 'PUT',
            headers: !token ? {} : {
                'Authorization': `Bearer ${token}`
                , 'Content-Type': 'application/json'
            },
        });
        response.json().catch((e) => {
            console.error(e)
        })
    }

    private processImage = async (data: any, token: string, carImage: File | null) => {
        var oReq;
        if (carImage != null && carImage.type !== undefined) {
            oReq = new XMLHttpRequest();
            const content_: FormData = new FormData();
            content_.append("CarImageFile", carImage as Blob, carImage.name);
            oReq.open("POST", `/api/entryform/${data.registrationFormId}/car/${data.id}/uploadCarImage`, true);
            oReq.setRequestHeader("Authorization", "Bearer " + token);
            oReq.onload = function () { };
            oReq.send(content_);
        }
        else
            //Remove from DB if image is null
            if (carImage === null) {
                oReq = new XMLHttpRequest();
                const content_: FormData = new FormData();
                content_.append("CarImageFile", new Blob(), "");
                oReq.open("POST", `/api/entryform/${data.registrationFormId}/car/${data.id}/uploadCarImage`, true);
                oReq.setRequestHeader("Authorization", "Bearer " + token);
                oReq.onload = function () { };
                oReq.send(content_);
            }
        return oReq;
    }

    public async startRentalRequest(trackActivityDescription: string, startDate: Date, endDate: Date, soundLevel: ITrackSoundLevel): Promise<Result> {
        let result: Result = {status: 0, ok: false}
        const token = await authService.getAccessToken();
        await fetch(`/api/trackrental/`, {
            method: 'POST',
            headers: !token ? {} : {
                'Authorization': `Bearer ${token}`, 
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(
                {
                    description: trackActivityDescription,
                    startDate: startDate,
                    endDate: endDate,
                    soundLevel: soundLevel.id
                })
        }).then(async (response) => {
            if (response.ok) {
                await response.json().then(async (data) => {
                }).then(() => { 
                }).catch((e) => {
                    console.error(e)
                })
            }
            else {
                await response.text().then(async (text) => {
                    //Log Client side errors
                    if (response.status >= 400 && response.status < 500) {
                        let html = new DOMParser().parseFromString(text, 'text/html').querySelector('pre');
                        if (html !== null) {
                            this.log(response, html.innerHTML);
                        }
                    }
                });
            }
            result.ok = response.ok;
            result.status = response.status;
        }).catch((e) => {
            console.error(e)
        })
        return (result);
    }

    public async addWorksheetToRentalRequest(request : ITrackRental): Promise<Result> {
        let result: Result = {status: 0, ok: false}
        const token = await authService.getAccessToken();
        await fetch(`/api/trackrental/${request.id}/worksheet`, {
            method: 'PUT',
            headers: !token ? {} : {
                'Authorization': `Bearer ${token}`, 
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(request)
        }).then(async (response) => {
            if (response.ok) {
                await response.json().then(async (data) => {
                }).then(() => { 
                }).catch((e) => {
                    console.error(e)
                })
            }
            else {
                await response.text().then(async (text) => {
                    //Log Client side errors
                    if (response.status >= 400 && response.status < 500) {
                        let html = new DOMParser().parseFromString(text, 'text/html').querySelector('pre');
                        if (html !== null) {
                            this.log(response, html.innerHTML);
                        }
                    }
                });
            }
            result.ok = response.ok;
            result.status = response.status;
        }).catch((e) => {
            console.error(e)
        })
        return (result);
    }

    public async approveRentalRequest(request : ITrackRental, comments : string) : Promise<Result> {
        request.emailMessage = comments;
        let result: Result = {status: 0, ok: false}
        const token = await authService.getAccessToken();
        await fetch(`/api/trackrental/${request.id}/approveRequest`, {
            method: 'PUT',
            headers: !token ? {} : {
                'Authorization': `Bearer ${token}`, 
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(request)
        }).then(async (response) => {
            if (response.ok) {
                await response.json().then(async (data) => {
                }).then(() => { 
                }).catch((e) => {
                    console.error(e)
                })
            }
            else {
                await response.text().then(async (text) => {
                    //Log Client side errors
                    if (response.status >= 400 && response.status < 500) {
                        let html = new DOMParser().parseFromString(text, 'text/html').querySelector('pre');
                        if (html !== null) {
                            this.log(response, html.innerHTML);
                        }
                    }
                });
            }
            result.ok = response.ok;
            result.status = response.status;
        }).catch((e) => {
            console.error(e)
        })
        return (result);
    }

    public async approveRentalRequestWorksheet(request : ITrackRental, comments : string): Promise<Result> {
        request.emailMessage = comments;
        let result: Result = {status: 0, ok: false}
        const token = await authService.getAccessToken();
        await fetch(`/api/trackrental/${request.id}/approveWorksheet`, {
            method: 'PUT',
            headers: !token ? {} : {
                'Authorization': `Bearer ${token}`, 
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(request)
        }).then(async (response) => {
            if (response.ok) {
                await response.json().then(async (data) => {
                }).then(() => { 
                }).catch((e) => {
                    console.error(e)
                })
            }
            else {
                await response.text().then(async (text) => {
                    //Log Client side errors
                    if (response.status >= 400 && response.status < 500) {
                        let html = new DOMParser().parseFromString(text, 'text/html').querySelector('pre');
                        if (html !== null) {
                            this.log(response, html.innerHTML);
                        }
                    }
                });
            }
            result.ok = response.ok;
            result.status = response.status;
        }).catch((e) => {
            console.error(e)
        })
        return (result);
    }

    public async rejectRentalRequest(request : ITrackRental, comments : string): Promise<Result> {
        request.emailMessage = comments;
        let result: Result = {status: 0, ok: false}
        const token = await authService.getAccessToken();
        await fetch(`/api/trackrental/${request.id}/reject`, {
            method: 'PUT',
            headers: !token ? {} : {
                'Authorization': `Bearer ${token}`, 
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(request)
        }).then(async (response) => {
            if (response.ok) {
                await response.json().then(async (data) => {
                }).then(() => { 
                }).catch((e) => {
                    console.error(e)
                })
            }
            else {
                await response.text().then(async (text) => {
                    //Log Client side errors
                    if (response.status >= 400 && response.status < 500) {
                        let html = new DOMParser().parseFromString(text, 'text/html').querySelector('pre');
                        if (html !== null) {
                            this.log(response, html.innerHTML);
                        }
                    }
                });
            }
            result.ok = response.ok;
            result.status = response.status;
        }).catch((e) => {
            console.error(e)
        })
        return (result);
    }

    public async rejectRentalWorksheet(request : ITrackRental, comments : string): Promise<Result> {
        request.emailMessage = comments;
        let result: Result = {status: 0, ok: false}
        const token = await authService.getAccessToken();
        await fetch(`/api/trackrental/${request.id}/rejectWorksheet`, {
            method: 'PUT',
            headers: !token ? {} : {
                'Authorization': `Bearer ${token}`, 
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(request)
        }).then(async (response) => {
            if (response.ok) {
                await response.json().then(async (data) => {
                }).then(() => { 
                }).catch((e) => {
                    console.error(e)
                })
            }
            else {
                await response.text().then(async (text) => {
                    //Log Client side errors
                    if (response.status >= 400 && response.status < 500) {
                        let html = new DOMParser().parseFromString(text, 'text/html').querySelector('pre');
                        if (html !== null) {
                            this.log(response, html.innerHTML);
                        }
                    }
                });
            }
            result.ok = response.ok;
            result.status = response.status;
        }).catch((e) => {
            console.error(e)
        })
        return (result);
    }

    public async sendCorrespondence(request : ITrackRental, emailMessage : string): Promise<Result> {
        request.emailMessage = emailMessage;
        let result: Result = {status: 0, ok: false}
        const token = await authService.getAccessToken();
        await fetch(`/api/trackrental/${request.id}/emailUser`, {
            method: 'POST',
            headers: !token ? {} : {
                'Authorization': `Bearer ${token}`, 
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(request)
        }).then(async (response) => {
            if (response.ok) {
                await response.json().then(async (data) => {
                }).then(() => { 
                }).catch((e) => {
                    console.error(e)
                })
            }
            else {
                await response.text().then(async (text) => {
                    //Log Client side errors
                    if (response.status >= 400 && response.status < 500) {
                        let html = new DOMParser().parseFromString(text, 'text/html').querySelector('pre');
                        if (html !== null) {
                            this.log(response, html.innerHTML);
                        }
                    }
                });
            }
            result.ok = response.ok;
            result.status = response.status;
        }).catch((e) => {
            console.error(e)
        })
        return (result);
    }

    public async getUserTrackRentals(): Promise<Entry[]> {
        let result : Entry[] = [];
        const token = await authService.getAccessToken();
        await fetch("/api/trackrental/", {
            method: "GET",
            headers: !token ? {} : { 'Authorization': `Bearer ${token}`,
            "Accept" : "application/json",
            "Content-Type" : "application/json",
        }
        }).then(async (response) => {
            if (response.ok) {
                await response.json().then((data) => {
                    if (data.result != null && data.result.length > 0) {
                        result = data.result;
                    }
                })
            }
            else {
                await response.text().then(async (text) => {
                    //Log Client side errors
                    if (response.status >= 400 && response.status < 500) {
                        let html = new DOMParser().parseFromString(text, 'text/html').querySelector('pre');
                        if (html !== null) {
                            this.log(response, html.innerHTML);
                        }
                    }
                });
            }
        }).catch((e) => {
            console.error(e)
        })
        return (result);
    }

    public async getTrackRentals(): Promise<Entry[]> {
        let result : Entry[] = [];
        const token = await authService.getAccessToken();
        await fetch("/api/trackrental/All", {
            method: "GET",
            headers: !token ? {} : { 'Authorization': `Bearer ${token}`,
            "Accept" : "application/json",
            "Content-Type" : "application/json",
        }
        }).then(async (response) => {
            if (response.ok) {
                await response.json().then((data) => {
                    if (data.result != null && data.result.length > 0) {
                        result = data.result;
                    }
                })
            }
            else {
                await response.text().then(async (text) => {
                    //Log Client side errors
                    if (response.status >= 400 && response.status < 500) {
                        let html = new DOMParser().parseFromString(text, 'text/html').querySelector('pre');
                        if (html !== null) {
                            this.log(response, html.innerHTML);
                        }
                    }
                });
            }
        }).catch((e) => {
            console.error(e)
        })
        return (result);
    }

    public async getSoundLevels(): Promise<Class[]> {
        let result: Class[] = [];
        const token = await authService.getAccessToken();
        await fetch("/api/trackrental/soundlevels", {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        }).then(async (response) => {
            if (response.ok) {
                await response.json().then((data) => {
                    var arr: any[] = [];
                    if (data != null) {
                        data.result.forEach(function (value: any) {
                            arr.push({id : value.id, name : value.name, price: value.price})
                        })
                    }
                    arr.sort((a, b) => {
                        var nameA = a.name.toUpperCase(); // ignore upper and lowercase
                        var nameB = b.name.toUpperCase(); // ignore upper and lowercase
                        if (nameA < nameB) {
                            return -1;
                        }
                        if (nameA > nameB) {
                            return 1;
                        }
                        return 0;
                    });
                    result = arr;
                })
            }
            else {
                await response.text().then(async (text) => {
                    //Log Client side errors
                    if (response.status >= 400 && response.status < 500) {
                        let html = new DOMParser().parseFromString(text, 'text/html').querySelector('pre');
                        if (html !== null) {
                            this.log(response, html.innerHTML);
                        }
                    }
                });
            }
        }).catch((e) => {
            console.error(e)
        });
        return (result);
    }
    
    public async updateSoundLevel(soundlevel : ITrackSoundLevel): Promise<Class[]> {
        let result : any[] = [];
        const token = await authService.getAccessToken();
        await fetch(`/api/trackrental/soundlevel/${soundlevel.id}`, {
            method: "PUT",
            headers: !token ? {} : { 'Authorization': `Bearer ${token}`,
            "Accept" : "application/json",
            "Content-Type" : "application/json"
            },
            body: JSON.stringify(soundlevel)
        }).then(async (response) => {
            if (response.ok) {
                await response.json().then((data) => {
                    if (data.result != null && data.result.length > 0) {
                        result = data.result;
                    }
                })
            }
            else {
                await response.text().then(async (text) => {
                    //Log Client side errors
                    if (response.status >= 400 && response.status < 500) {
                        let html = new DOMParser().parseFromString(text, 'text/html').querySelector('pre');
                        if (html !== null) {
                            this.log(response, html.innerHTML);
                        }
                    }
                });
            }
        }).catch((e) => {
            console.error(e)
        })
        return (result);
    }
    
    public async updateSoundLevels(soundlevels : ITrackSoundLevel[]): Promise<Class[]> {
        let result : any[] = [];
        const token = await authService.getAccessToken();
        await fetch("/api/trackrental/soundlevels/", {
            method: "PUT",
            headers: !token ? {} : { 'Authorization': `Bearer ${token}`,
            "Accept" : "application/json",
            "Content-Type" : "application/json",
            },
            body: JSON.stringify(soundlevels)
        }).then(async (response) => {
            if (response.ok) {
                await response.json().then((data) => {
                    if (data.result != null && data.result.length > 0) {
                        result = data.result;
                    }
                })
            }
            else {
                await response.text().then(async (text) => {
                    //Log Client side errors
                    if (response.status >= 400 && response.status < 500) {
                        let html = new DOMParser().parseFromString(text, 'text/html').querySelector('pre');
                        if (html !== null) {
                            this.log(response, html.innerHTML);
                        }
                    }
                });
            }
        }).catch((e) => {
            console.error(e)
        })
        return (result);
    }

    public async getBlackoutDates(): Promise<any[]> {
        let result : any[] = [];
        const token = await authService.getAccessToken();
        await fetch("/api/trackrental/blackoutDates", {
            method: "GET",
            headers: !token ? {} : { 'Authorization': `Bearer ${token}`,
            "Accept" : "application/json",
            "Content-Type" : "application/json",
        }
        }).then(async (response) => {
            if (response.ok) {
                await response.json().then((data) => {
                    if (data.result != null && data.result.length > 0) {                        
                        let date : any[] = data.result;
                        date.forEach((e : Date[]) => {
                            result.push({"from" : new Date(e[0]), "to" : new Date(e[1])});
                        });
                    }
                })
            }
            else {
                await response.text().then(async (text) => {
                    //Log Client side errors
                    if (response.status >= 400 && response.status < 500) {
                        let html = new DOMParser().parseFromString(text, 'text/html').querySelector('pre');
                        if (html !== null) {
                            this.log(response, html.innerHTML);
                        }
                    }
                });
            }
        }).catch((e) => {
            console.error(e)
        })
        return (result);
    }

    public async getAdminSetBlackOutDates(): Promise<any[]> {
        let result : any[] = [];
        const token = await authService.getAccessToken();
        await fetch("/api/trackrental/adminSetBlackOutDates", {
            method: "GET",
            headers: !token ? {} : { 'Authorization': `Bearer ${token}`,
            "Accept" : "application/json",
            "Content-Type" : "application/json",
        }
        }).then(async (response) => {
            if (response.ok) {
                await response.json().then((data) => {
                    if (data.result != null && data.result.length > 0) {
                        result = data.result;
                    }
                })
            }
            else {
                await response.text().then(async (text) => {
                    //Log Client side errors
                    if (response.status >= 400 && response.status < 500) {
                        let html = new DOMParser().parseFromString(text, 'text/html').querySelector('pre');
                        if (html !== null) {
                            this.log(response, html.innerHTML);
                        }
                    }
                });
            }
        }).catch((e) => {
            console.error(e)
        })
        return (result);
    }

    public async addBlackoutDates(name : string, startDate : Date, endDate: Date): Promise<Result> {
        let result: Result = {status: 0, ok: false}
        const token = await authService.getAccessToken();
        await fetch(`/api/trackrental/blackoutDates/`, {
            method: 'POST',
            headers: !token ? {} : {
                'Authorization': `Bearer ${token}`, 
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(
                {
                    name: name,
                    startDate: startDate,
                    endDate: endDate
                })
            }).then(async (response) => {
            if (response.ok) {
                await response.json().then(async (data) => {
                }).then(() => { 
                }).catch((e) => {
                    console.error(e)
                })
            }
            else {
                await response.text().then(async (text) => {
                    //Log Client side errors
                    if (response.status >= 400 && response.status < 500) {
                        let html = new DOMParser().parseFromString(text, 'text/html').querySelector('pre');
                        if (html !== null) {
                            this.log(response, html.innerHTML);
                        }
                    }
                });
            }
            result.ok = response.ok;
            result.status = response.status;
        }).catch((e) => {
            console.error(e)
        })
        return (result);
    }
    
    public async removeBlackoutDates(id : string): Promise<Result> {
        let result: Result = {status: 0, ok: false}
        const token = await authService.getAccessToken();
        await fetch(`/api/trackrental/blackoutDates/${id}`, {
            method: 'DELETE',
            headers: !token ? {} : {
                'Authorization': `Bearer ${token}`, 
                'Content-Type': 'application/json'
            }
            }).then(async (response) => {
            if (response.ok) {
                await response.json().then(async (data) => {
                }).then(() => { 
                }).catch((e) => {
                    console.error(e)
                })
            }
            else {
                await response.text().then(async (text) => {
                    //Log Client side errors
                    if (response.status >= 400 && response.status < 500) {
                        let html = new DOMParser().parseFromString(text, 'text/html').querySelector('pre');
                        if (html !== null) {
                            this.log(response, html.innerHTML);
                        }
                    }
                });
            }
            result.ok = response.ok;
            result.status = response.status;
        }).catch((e) => {
            console.error(e)
        })
        return (result);
    }

    public async getActiveCaterers(): Promise<any[]> {
        let result : any[] = [];
        const token = await authService.getAccessToken();
        await fetch("/api/trackrental/caterers", {
            method: "GET",
            headers: !token ? {} : { 'Authorization': `Bearer ${token}`,
            "Accept" : "application/json",
            "Content-Type" : "application/json",
        }
        }).then(async (response) => {
            if (response.ok) {
                await response.json().then((data) => {
                    if (data.result != null && data.result.length > 0) {
                        result = data.result;
                    }
                })
            }
            else {
                await response.text().then(async (text) => {
                    //Log Client side errors
                    if (response.status >= 400 && response.status < 500) {
                        let html = new DOMParser().parseFromString(text, 'text/html').querySelector('pre');
                        if (html !== null) {
                            this.log(response, html.innerHTML);
                        }
                    }
                });
            }
        }).catch((e) => {
            console.error(e)
        })
        return (result);
    }
    
    public async getAllCaterers(): Promise<any[]> {
        let result : any[] = [];
        const token = await authService.getAccessToken();
        await fetch("/api/trackrental/caterers/all", {
            method: "GET",
            headers: !token ? {} : { 'Authorization': `Bearer ${token}`,
            "Accept" : "application/json",
            "Content-Type" : "application/json",
        }
        }).then(async (response) => {
            if (response.ok) {
                await response.json().then((data) => {
                    if (data.result != null && data.result.length > 0) {
                        result = data.result;
                    }
                })
            }
            else {
                await response.text().then(async (text) => {
                    //Log Client side errors
                    if (response.status >= 400 && response.status < 500) {
                        let html = new DOMParser().parseFromString(text, 'text/html').querySelector('pre');
                        if (html !== null) {
                            this.log(response, html.innerHTML);
                        }
                    }
                });
            }
        }).catch((e) => {
            console.error(e)
        })
        return (result);
    }

    public async updateCaterer(caterer : ITrackCaterer): Promise<any[]> {
        let result : any[] = [];
        const token = await authService.getAccessToken();
        await fetch(`/api/trackrental/caterer/${caterer.id}`, {
            method: "PUT",
            headers: !token ? {} : { 'Authorization': `Bearer ${token}`,
            "Accept" : "application/json",
            "Content-Type" : "application/json",
            },
            body: JSON.stringify(caterer)
        }).then(async (response) => {
            if (response.ok) {
                await response.json().then((data) => {
                    if (data.result != null && data.result.length > 0) {
                        result = data.result;
                    }
                })
            }
            else {
                await response.text().then(async (text) => {
                    //Log Client side errors
                    if (response.status >= 400 && response.status < 500) {
                        let html = new DOMParser().parseFromString(text, 'text/html').querySelector('pre');
                        if (html !== null) {
                            this.log(response, html.innerHTML);
                        }
                    }
                });
            }
        }).catch((e) => {
            console.error(e)
        })
        return (result);
    }

    public async updateCaterers(caterers : ITrackCaterer[]): Promise<any[]> {
        let result : any[] = [];
        const token = await authService.getAccessToken();
        await fetch("/api/trackrental/caterers", {
            method: "PUT",
            headers: !token ? {} : { 'Authorization': `Bearer ${token}`,
            "Accept" : "application/json",
            "Content-Type" : "application/json",
            },
            body: JSON.stringify(caterers)
        }).then(async (response) => {
            if (response.ok) {
                await response.json().then((data) => {
                    if (data.result != null && data.result.length > 0) {
                        result = data.result;
                    }
                })
            }
            else {
                await response.text().then(async (text) => {
                    //Log Client side errors
                    if (response.status >= 400 && response.status < 500) {
                        let html = new DOMParser().parseFromString(text, 'text/html').querySelector('pre');
                        if (html !== null) {
                            this.log(response, html.innerHTML);
                        }
                    }
                });
            }
        }).catch((e) => {
            console.error(e)
        })
        return (result);
    }
    public async removeCaterers(caterers : ITrackCaterer[]): Promise<Result> {
        let result: Result = {status: 0, ok: false}
        const token = await authService.getAccessToken();
        await fetch(`/api/trackrental/caterers/`, {
            method: 'DELETE',
            headers: !token ? {} : {
                'Authorization': `Bearer ${token}`, 
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(caterers)
            }).then(async (response) => {
            if (response.ok) {
                await response.json().then(async (data) => {
                }).then(() => { 
                }).catch((e) => {
                    console.error(e)
                })
            }
            else {
                await response.text().then(async (text) => {
                    //Log Client side errors
                    if (response.status >= 400 && response.status < 500) {
                        let html = new DOMParser().parseFromString(text, 'text/html').querySelector('pre');
                        if (html !== null) {
                            this.log(response, html.innerHTML);
                        }
                    }
                });
            }
            result.ok = response.ok;
            result.status = response.status;
        }).catch((e) => {
            console.error(e)
        })
        return (result);
    }
    public async updateTrackService(service : ITrackService): Promise<Class[]> {
        let result : any[] = [];
        const token = await authService.getAccessToken();
        await fetch(`/api/trackrental/service/${service.id}`, {
            method: "PUT",
            headers: !token ? {} : { 'Authorization': `Bearer ${token}`,
            "Accept" : "application/json",
            "Content-Type" : "application/json",
            },
            body: JSON.stringify(service)
        }).then(async (response) => {
            if (response.ok) {
                await response.json().then((data) => {
                    if (data.result != null && data.result.length > 0) {
                        result = data.result;
                    }
                })
            }
            else {
                await response.text().then(async (text) => {
                    //Log Client side errors
                    if (response.status >= 400 && response.status < 500) {
                        let html = new DOMParser().parseFromString(text, 'text/html').querySelector('pre');
                        if (html !== null) {
                            this.log(response, html.innerHTML);
                        }
                    }
                });
            }
        }).catch((e) => {
            console.error(e)
        })
        return (result);
    }
    
    public async updateTrackServices(services : ITrackService[]): Promise<Class[]> {
        let result : any[] = [];
        const token = await authService.getAccessToken();
        await fetch("/api/trackrental/services/", {
            method: "PUT",
            headers: !token ? {} : { 'Authorization': `Bearer ${token}`,
            "Accept" : "application/json",
            "Content-Type" : "application/json",
            },
            body: JSON.stringify(services)
        }).then(async (response) => {
            if (response.ok) {
                await response.json().then((data) => {
                    if (data.result != null && data.result.length > 0) {
                        result = data.result;
                    }
                })
            }
            else {
                await response.text().then(async (text) => {
                    //Log Client side errors
                    if (response.status >= 400 && response.status < 500) {
                        let html = new DOMParser().parseFromString(text, 'text/html').querySelector('pre');
                        if (html !== null) {
                            this.log(response, html.innerHTML);
                        }
                    }
                });
            }
        }).catch((e) => {
            console.error(e)
        })
        return (result);
    }

    public async getTrackServiceByName(service : ITrackService): Promise<any[]> {
        let result : any[] = [];
        const token = await authService.getAccessToken();
        await fetch(`/api/trackrental/service/name/${service.name}`, {
            method: "GET",
            headers: !token ? {} : { 'Authorization': `Bearer ${token}`,
            "Accept" : "application/json",
            "Content-Type" : "application/json",
        }
        }).then(async (response) => {
            if (response.ok) {
                await response.json().then((data) => {
                    if (data.result != null && data.result.length > 0) {
                        result = data.result;
                    }
                })
            }
            else {
                await response.text().then(async (text) => {
                    //Log Client side errors
                    if (response.status >= 400 && response.status < 500) {
                        let html = new DOMParser().parseFromString(text, 'text/html').querySelector('pre');
                        if (html !== null) {
                            this.log(response, html.innerHTML);
                        }
                    }
                });
            }
        }).catch((e) => {
            console.error(e)
        })
        return (result);
    }

    public async getTrackServiceById(service : ITrackService): Promise<any[]> {
        let result : any[] = [];
        const token = await authService.getAccessToken();
        await fetch(`/api/trackrental/service/name/${service.id}`, {
            method: "GET",
            headers: !token ? {} : { 'Authorization': `Bearer ${token}`,
            "Accept" : "application/json",
            "Content-Type" : "application/json",
        }
        }).then(async (response) => {
            if (response.ok) {
                await response.json().then((data) => {
                    if (data.result != null && data.result.length > 0) {
                        result = data.result;
                    }
                })
            }
            else {
                await response.text().then(async (text) => {
                    //Log Client side errors
                    if (response.status >= 400 && response.status < 500) {
                        let html = new DOMParser().parseFromString(text, 'text/html').querySelector('pre');
                        if (html !== null) {
                            this.log(response, html.innerHTML);
                        }
                    }
                });
            }
        }).catch((e) => {
            console.error(e)
        })
        return (result);
    }
    
    public async getAllTrackServices(): Promise<any[]> {
        let result : any[] = [];
        const token = await authService.getAccessToken();
        await fetch(`/api/trackrental/service/all`, {
            method: "GET",
            headers: !token ? {} : { 'Authorization': `Bearer ${token}`,
            "Accept" : "application/json",
            "Content-Type" : "application/json",
        }
        }).then(async (response) => {
            if (response.ok) {
                await response.json().then((data) => {
                    if (data.result != null && data.result.length > 0) {
                        result = data.result;
                    }
                })
            }
            else {
                await response.text().then(async (text) => {
                    //Log Client side errors
                    if (response.status >= 400 && response.status < 500) {
                        let html = new DOMParser().parseFromString(text, 'text/html').querySelector('pre');
                        if (html !== null) {
                            this.log(response, html.innerHTML);
                        }
                    }
                });
            }
        }).catch((e) => {
            console.error(e)
        })
        return (result);
    }

}