import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { Divider } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import { withStyles, createStyles, Theme } from '@material-ui/core/styles';
import Entry from '../interfaces/IEntry';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import AddRegistration from './AddEntry';
import EntryCard from './EntryCard';
import APIService from '../services/APIService';
import RegistrationEvent from '../interfaces/IRegistrationEvent';

export interface IDashboardProps {
    event: RegistrationEvent;
    classes: any;
    setMessage: any;
    setLoading: any;
    loading: boolean;
}

export interface IDashboardState {
    tabVal: number;
    regOpen: boolean;
    eventDate: Date;
    showRegDialog: boolean;
    entries: Entry[];
    _isMounted: boolean;
}
interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}
export class Dashboard extends Component<IDashboardProps, IDashboardState> {
    private apiService: APIService = new APIService;
    static displayName = Dashboard.name;
    public constructor(props: Readonly<IDashboardProps>) {
        super(props);
        this.state = {
            tabVal: 0,
            regOpen: false,
            showRegDialog: false,
            entries: [],
            _isMounted: false,
            eventDate: new Date('1900-12-17')
        };
        this.TabPanel = this.TabPanel.bind(this);
        this.getEntryForms = this.getEntryForms.bind(this);
        this.apiService = new APIService;
    }
    private handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        this.setState({ tabVal: newValue });
    };
    private a11yProps(index: any) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }
    //This is more of a cosmetic function. Calculates the year they are submitting 
    private upcomingRaceYear(): string {
        const currentDate = new Date();
        const raceDate = new Date('September 1, ' + currentDate.getFullYear().toString());
        if (currentDate >= raceDate) {
            return (currentDate.getFullYear() + 1).toString();

        } else {
            return (currentDate.getFullYear()).toString();
        }
    }
    private registrationOpen = async () =>{
        await this.apiService.isRegistrationOpen(this.props.event.id).then(result => {
            this.setState({regOpen : result});
        });
    }
    private handleCloseRegDialog = () => {
        this.setState({ showRegDialog: false });
    }
    private openAddRegDialog = () => {
        this.setState({ showRegDialog: true });
    }

    private TabPanel(props: TabPanelProps): React.ReactElement {
        const { children, value, index, ...other } = props;
        const { classes } = this.props;
        return (
            <Typography
                component="div"
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                <Box p={3} className={classes.tabContainer}>{children}</Box>
            </Typography>
        );
    }
    private getEntryForms = async () => {
        this.props.setLoading(true);
        await this.apiService.getEntryFormsList().then(result => {
            result.sort((a,b) => {
                return (Number(b.participatingYear) - Number(a.participatingYear))
            })
            this.setState({entries : result});
        });
        this.props.setLoading(false);
    }
    
    public componentDidMount() {
        this.getEntryForms().then(() => {this.registrationOpen()}).then(() => { this.setState({ _isMounted: true }); });
    }

    public render(): React.ReactElement<IDashboardProps> {
        const { classes } = this.props;
        const noEntryForCurrentYear: boolean = (this.state.entries.filter(i => i.participatingYear === this.upcomingRaceYear()).length === 0);
        return (
            <React.Fragment>
            {this.state._isMounted &&
            <React.Fragment>
                <AppBar position="fixed" className={classes.tabBar}>
                    <Tabs value={this.state.tabVal} onChange={this.handleTabChange} centered aria-label="simple tabs example">
                        {noEntryForCurrentYear && <Tab label={this.upcomingRaceYear()} {...this.a11yProps(0)} />}
                        {this.state.entries.map((value, index) => {
                            return (<Tab key={index} label={value.participatingYear} {...this.a11yProps(index + (noEntryForCurrentYear ? 1 : 0))} />)
                        })}
                    </Tabs>
                </AppBar>

                {(this.state.entries.filter(i => i.participatingYear === this.upcomingRaceYear()).length === 0  && !this.props.loading ) &&
                    <React.Fragment>
                        {this.state.regOpen ?
                        <this.TabPanel value={this.state.tabVal} index={0}>
                            <Paper className={classes.carList}>
                                <Typography variant="h5" className={classes.header}>{this.upcomingRaceYear()}  Reunion Entry</Typography>
                                <Divider />
                                <Typography variant="body1" className={classes.header}>You have not yet started an entry for {this.upcomingRaceYear()}</Typography>
                                <Box p={3} className={classes.header} ><Button disabled={this.props.loading} variant="contained" onClick={this.openAddRegDialog}>Begin Registration</Button></Box>
                                <AddRegistration
                                    participatingYear={this.upcomingRaceYear()}
                                    handleCancel={this.handleCloseRegDialog}
                                    imperial={true}
                                    setMessage={this.props.setMessage}
                                    registrationEvent={this.props.event}
                                    setLoading={this.props.setLoading}
                                    loading={this.props.loading}
                                    getEntryForms={this.getEntryForms}
                                    open={this.state.showRegDialog}
                                />
                            </Paper>
                        </this.TabPanel>:
                        <this.TabPanel value={this.state.tabVal} index={0}>
                            <Paper className={classes.carList}>
                                <Typography variant="h5" className={classes.header}>{this.upcomingRaceYear()}  Reunion Entry</Typography>
                                <Divider />
                                <Typography variant="body1" className={classes.header}>{this.upcomingRaceYear()} Registration will open shortly</Typography>
                            </Paper>
                        </this.TabPanel>}
                    </React.Fragment>
                }
                {this.state.entries.map((value, index) => {
                    return (
                        <this.TabPanel key={index} value={this.state.tabVal} index={index + (noEntryForCurrentYear ? 1 : 0)}>
                            <EntryCard
                                setMessage={this.props.setMessage}
                                setLoading={this.props.setLoading}
                                loading={this.props.loading}
                                key={index}
                                getEntryForms={this.getEntryForms}
                                entry={value}
                                regOpen={this.state.regOpen && value.participatingYear === this.upcomingRaceYear()}
                            />
                        </this.TabPanel>
                    )
                })}
                </React.Fragment>
                }
            </React.Fragment>
        );
    }
}
const styles = createStyles((theme: Theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        position: 'relative',
    },
    tabContainer: {
        padding: 0,
    },
    tabBar: {
        top: theme.spacing(-1),
        zIndex: 5,
        right: 0,
        marginTop: theme.spacing(8),
        [theme.breakpoints.up('sm')]: {
            marginTop: theme.spacing(9),
        },
    },
    carList: {
        padding: theme.spacing(3, 2),
        position: 'relative',
    },
    header: {
        textAlign: "center"
    }
}))

export default withStyles(styles)(Dashboard)