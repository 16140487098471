import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import Card from '@material-ui/core/Card';
import Chip from '@material-ui/core/Chip';
import CardContent from '@material-ui/core/CardContent';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import FaceIcon from '@material-ui/icons/Face';
import { withStyles, createStyles, Theme } from '@material-ui/core/styles';
import LoungeMember from '../interfaces/ILoungeMember';
import RegistrationEvent from '../interfaces/IRegistrationEvent';
import Race from '../interfaces/IRace';
//test
export interface ILoungeFormProps {
    event: RegistrationEvent;
    updateLoungeMembers:any;
    setMessage:any;
    handleDateChange:any;
    loungeMembers: LoungeMember[] ;
    classes:any;
}

export interface ILoungeFormmState {
    loungeMembers: LoungeMember[];
    loungeFName: string | null;
    loungeLName: string | null;
    dateValid:boolean;
    validationAttempt:boolean;
}

export class LoungeForm extends Component<ILoungeFormProps, ILoungeFormmState> {
    
    public constructor(props: Readonly<ILoungeFormProps>) {
        super(props);
        this.state = {
            loungeMembers: this.props.loungeMembers,
            dateValid:true,            
            loungeFName:null,
            loungeLName:null,
            validationAttempt: false,
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.validateDate = this.validateDate.bind(this);
        this.inLoungeMemberArray = this.inLoungeMemberArray.bind(this);
        this.addLoungeMember = this.addLoungeMember.bind(this);
        this.removeLoungeMember = this.removeLoungeMember.bind(this);
    }   
    private handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newState = { [event.target.name]: event.target.value } as any;
        this.setState(newState);
    }

    private handleDateChange = (date: Date | null, field: string) => {
        const newState = { [field]: date } as any;
        this.setState(newState);
    }

    public validateDate(date: Date | null) : boolean{
        this.setState({dateValid : true})
        if (date === null || date > new Date())
        {
            this.setState({dateValid : false})
        }
        return this.state.dateValid;
    }

    private inLoungeMemberArray(array : LoungeMember[], loungeMember : LoungeMember) : boolean { 
        return array.filter(member => (loungeMember.fname === member.fname && loungeMember.lname === member.lname)).length > 0;
    }

    private addLoungeMember() :void{
        this.setState({validationAttempt:true});
        let currentLoungeMembers : LoungeMember[]; 
        (this.state.loungeMembers !== undefined) ? currentLoungeMembers = this.state.loungeMembers : currentLoungeMembers = [];  
        if(this.state.loungeFName === null ||this.state.loungeFName === ""){
            this.props.setMessage("Missing First Name", "error");
            return;
        }
        if(this.state.loungeLName === null || this.state.loungeLName === ""){
            this.props.setMessage("Missing Last Name", "error");
            return;
        }

        const newLoungeMember : any = {fname : this.state.loungeFName[0].toUpperCase() + this.state.loungeFName.slice(1), 
            lname : this.state.loungeLName[0].toUpperCase() + this.state.loungeLName.slice(1)}
        if (!this.inLoungeMemberArray(currentLoungeMembers, newLoungeMember)){
            currentLoungeMembers.push(newLoungeMember);
            this.setState({loungeMembers : currentLoungeMembers});
        }
        else{
            this.props.setMessage("Participant is already on the Lounge List", "error");
        }

        this.setState({loungeFName : "", loungeLName : "", validationAttempt : false});
        this.props.updateLoungeMembers(this.state.loungeMembers);
        return;
    }
    private removeLoungeMember(index : number) : void{
        let currentLoungeMembers : LoungeMember[]; 
        (this.state.loungeMembers !== undefined) ? currentLoungeMembers = this.state.loungeMembers : currentLoungeMembers = [];
        currentLoungeMembers.splice(index, 1);
        this.setState({loungeMembers : currentLoungeMembers});
        return;    
    }       

    public render() : React.ReactElement{
        const {classes} = this.props;
        console.log(this.props.event)
        let loungeRace : Race = this.props.event.race.filter(x => x.hasLounge)[0];
        return( <Grid container spacing={2}>                
            <Grid item xs={12} md={12}>

            <Grid container spacing={2} className={classes.center}>  
            
            <Grid item xs={12} md={4} className={classes.center}>
                    <TextField
                        id="loungeFName"
                        name="loungeFName"
                        label="First Name"
                        className={classes.textField}
                        margin="normal"
                        value={this.state.loungeFName}
                        onChange={this.handleChange}
                    />
                </Grid>
                <Grid item xs={12} md={4} className={classes.center}>              
                    <TextField
                        id="loungeLName"
                        name="loungeLName"
                        label="Last Name"
                        className={classes.textField}
                        margin="normal"
                        value={this.state.loungeLName}
                        onChange={this.handleChange}
                    />
                </Grid>
                <Grid item xs={12} className={classes.center}>
                <Typography variant="body2" gutterBottom >
                        <i>Each entry includes 1 lounge guest. 
                            Additional passes can be purchased for ${loungeRace.loungePrice}.00. 
                            Drivers have access to the lounge at no additional cost.</i>
                </Typography> 
                </Grid>
                <Grid item xs={12}> 
                <Button variant="contained" className={classes.button}
                        onClick={this.addLoungeMember}>
                    Add
                </Button> 
                
            </Grid> 
            </Grid>    
            </Grid>
            <Grid item xs={4}>
            </Grid>
            <Grid item xs={12} md={4}>
                <Card>
                <CardContent>                    
                <Typography variant="h6" className={classes.title}>
                    Lounge Members
                </Typography>
                <List dense>
                {(this.state.loungeMembers[0] !== undefined) && this.state.loungeMembers.map((value, index) => {
                if (index === 0)
                return (         
                    <React.Fragment>               
                    <ListItem>
                        <Typography variant="h6" className={classes.center}>                    
                            <Chip                                 
                            icon={<CheckCircleIcon />}
                            color="primary"
                            label={value.fname + " " + value.lname}
                            onDelete={() => this.removeLoungeMember(index)} />
                        </Typography>
                    </ListItem>   
                    <Divider/>  
                    </React.Fragment>                  
                    )
                else
                return (                       
                    <ListItem>
                        <Typography variant="h6" className={classes.center}>                    
                            <Chip                                 
                            icon={<MonetizationOnIcon />}
                            color="secondary"
                            label={value.fname + " " + value.lname}                            
                            onDelete={() => this.removeLoungeMember(index)} />
                        </Typography>
                    </ListItem>)
                })}
                {(this.state.loungeMembers.length === 0) &&
                    <ListItem>
                        <Typography variant="h6" className={classes.center}>          
                        <Chip
                            variant="outlined"
                            icon={<FaceIcon />}
                            label="No Lounge Members Chosen"
                        />
                        </Typography>
                    </ListItem>
                }
                </List>
                </CardContent>
                </Card>
            </Grid>                
            <Grid item xs={4}>
            </Grid>
            </Grid>);
    }
}


const styles = createStyles((theme: Theme) => ({
    button: {
      margin: theme.spacing(1),
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1)
    },
    center:{
        textAlign: 'center', 
        marginLeft: 'auto',
        marginRight: 'auto',
    },
  }))
  
  export default withStyles(styles)(LoungeForm);