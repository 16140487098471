export default function convert(value: number | null, targetMeasurementSystem: string, base: string): number | null {
    if (value !== null) {
        //Metric to Imperial
        if (targetMeasurementSystem === "imperial") {
            if (base === "feet") {
                return Number(Math.max(Math.round(value * 3.281 * 100) / 100).toFixed(2));
            } else {
                return Number(Math.max(Math.round(value / 25.4 * 100) / 100).toFixed(2));
            }
        } else {
            //Imperial to Metric
            if (base === "meters") {
                return Number(Math.max(Math.round(value / 3.281 * 100) / 100).toFixed(2));
            } else {
                return Number(Math.max(Math.round(value * 25.4 * 100) / 100).toFixed(2));
            }
        }
    }
    else {
        return null;
    }
}