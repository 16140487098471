import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import { green, orange, blue, red, grey, deepPurple, blueGrey, deepOrange } from '@material-ui/core/colors';
import CloseIcon from '@material-ui/icons/Close';
import { Grid, Divider, Card, Container, Toolbar, IconButton, TextField, DialogActions } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import MUIDataTable from "mui-datatables";
import { withStyles, createStyles, Theme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import authService from '../services/api-authorization/AuthorizeService';
import Entry from '../interfaces/IEntry';
import { LineChart, Line, PieChart, Sector, Pie, Cell, ResponsiveContainer } from 'recharts';
import StatusData from '../interfaces/IStatusData';
import Car from '../interfaces/ICar';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import CarWizardAdmin from './CarWizardAdmin';
import Entrant from '../interfaces/IEntrant';
import ImageSearchIcon from '@material-ui/icons/ImageSearch';
import GetAppIcon from '@material-ui/icons/GetApp';
import CircularProgress from '@material-ui/core/CircularProgress';
import Race from '../interfaces/IRace';
import RegistrationEvent from '../interfaces/IRegistrationEvent';

// import classes from '*.module.css';

export interface IDashboardProps {
    classes: any;
    setMessage: any;
    setLoading: any;
    loading: boolean;
    event: RegistrationEvent;
    isAdmin: boolean;
}

export interface IDashboardState {
    tabVal: number;
    regOpen: boolean;
    showRegDialog: boolean;
    showCarDialog: boolean;
    data: any | null;
    loading: boolean;
    carsByStatusList: Car[];
    // statusCountDict : IHash;
    statusCountDict: StatusData[];
    dialogOpen: boolean;
    dialogSubject: string;
    activeIndex: number;
    dialogMoreInfoOpen: boolean;
    dialogCar?: Car;
    dialogEntry: Entry;
    dialogEntrant: Entrant;
    //to be deleted later:
    dialogExport: boolean;
    dialogChosenYear: string;
    dialogParticipatingYear: string[];
    dialogExportSubject: string;
    showImgDialog: boolean;
    img: string;
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

export class AdminDashboard extends Component<IDashboardProps, IDashboardState>{
    private columns = [
        {
            name: "id",
            label: "Image",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
                    return (
                        (value !== undefined && value !== null) ?
                            <IconButton aria-label="show-image" onClick={() => this.showImgDialog(value.toString())}>
                                <ImageSearchIcon />
                            </IconButton> :
                            "No image"
                    );
                }
            }
        },
        {
            name: "race",
            label: "Participating Race(s)",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value: any) => {
                    return (value);
                }
            }
        },
        {
            name: "year",
            label: "Year",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "make",
            label: "Make",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "model",
            label: "Model",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "class",
            label: "Class",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "chassisNumber",
            label: "Chassis Number",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "entrantFullName",
            label: "Entrant",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "driverFullName",
            label: "Driver",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "creationDate",
            label: "Creation Date",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value: any) => {
                    if(value !== null){
                        return (new Date(value).toLocaleString());
                    }
                }
            }
        },
        {
            name: "submitDate",
            label: "Submit Date",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value: any) => {
                    if(value !== null){
                        return (new Date(value).toLocaleString());
                    }
                }
            }
        },
        {
            name: "id",
            label: "More Info",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
                    return (
                        <Button className={this.props.classes.BasicassButton} onClick={() => this.handleOpenMoreInfoDialog(value)}><ArrowForwardIcon /></Button>
                    );
                }
            }
        },

    ];

    private options = {
        filterType: 'checkbox'
    };

    public constructor(props: Readonly<IDashboardProps>) {
        super(props);
        const emptyEntry: Entry = {
            id: "00000000-0000-0000-0000-000000000000",
            entrantId: "00000000-0000-0000-0000-000000000000",
            event: this.props.event,
            isRemovableTrailer: false,
            transporterLength: 0,
            transporterWidth: 0,
            trailerLength: 0,
            transporterType: "",
            hasTrasporterAwning: false,
            awningWidth: 0,
            awningLength: 0,
            hasSharedSpace: false,
            sharedSpaceParticipants: "",
            adjacentParticipants: "",
            paymentDue: 0,
            paymentDone: 0,
            isApprovedToPay: false,
            participatingYear: "",
            awningOrientation: "",
            isInterestedInTop: false,
            acceptedPrivacyPolicy: false,
            carModel: [],
            prepShop: {
                id: undefined,
                name: "",
                phone: "",
                email: "",
            },
            prepShopName: "",
            prepShopContactNumber: "",
            prepShopEmailAddress: "",
        };
        this.state = {
            dialogExport: false,
            dialogParticipatingYear: [],
            dialogChosenYear: "",
            dialogExportSubject: "",

            tabVal: 0,
            regOpen: true,
            showRegDialog: false,
            showCarDialog: false,
            data: null,
            loading: false,
            carsByStatusList: [],
            statusCountDict: [],
            dialogOpen: false,
            dialogSubject: "",
            activeIndex: 0,
            dialogMoreInfoOpen: false,
            showImgDialog: false,
            img: "",
            dialogEntry: emptyEntry,
            dialogEntrant: { id: "", fname: "", mname: "", lname: "", dob: new Date(), address1: "", address2: "", address3: "", city: "", state: "", country: "", zipCode: "", phoneNumber: "", email: "", usesMetricSystem: false },
        };
        this.getCarListByStatus = this.getCarListByStatus.bind(this);
        this.upcomingRaceYear = this.upcomingRaceYear.bind(this);
        this.getStatusCount = this.getStatusCount.bind(this);
        this.getEntryForm = this.getEntryForm.bind(this);
        this.getEntrantByID = this.getEntrantByID.bind(this);
        // this.getEntrantForTable = this.getEntrantForTable.bind(this);

    }
    //   private handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    //     this.setState({tabVal:newValue});
    //   };
    public componentDidMount() {
        // this.getCarListByStatus();
        this.getStatusCount();
    }
    private a11yProps(index: any) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }
    // ------------------------------------------------------------------ EXPORT START ------------------------------------------------------------------ //
    private exportButtons = ["Export All Entries", "Export Active Entries"];
    private handleParticipatingYearChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ dialogChosenYear: event.target.value });
    }
    private handleOpenDialogForExport = (dialogExportSubject: string) => {
        this.GetParticipatingYears();
        this.setState({ dialogExport: true });
        this.setState({ dialogExportSubject });
    }
    private handleCloseDialogForExport = () => {
        this.setState({ dialogChosenYear: "" });
        this.setState({ dialogExport: false });
        this.setState({ dialogExportSubject: "" });
    }
    // private showImgDialog =  (img: string) => {
        private showImgDialog =  async (id: string) => {
        // this.setState({ img, showImgDialog: true })
        const token = await authService.getAccessToken();
        // const year = this.upcomingRaceYear();
        if (token != null) {
            const headers = new Headers();
            headers.append("Accept", "application/json");
            headers.append("Content-Type", "application/json");
            headers.append("Authorization", `Bearer ${token}`);
            const response = await fetch(`/api/car/${id}/image`, {
                headers: headers,
                method: "GET"
            })
                .then(res => res.json())
                .then(data => {
                    if(data.image != null){
                        this.setState({ img: data.image, showImgDialog: true })    
                    }
                    else{
                        this.props.setMessage(" No Image uploaded.", "info")
                    }
                    // this.setState({ dialogParticipatingYear: data.participatingYears })
                })
                .catch((error) => {
                    //console.log(error);
                });
        }
    }

    private closeImgDialog = () => {
        this.setState({ img: "", showImgDialog: false })
    }

    private GetParticipatingYears = async () => {
        const token = await authService.getAccessToken();
        // const year = this.upcomingRaceYear();
        if (token != null) {
            const headers = new Headers();
            headers.append("Accept", "application/json");
            headers.append("Content-Type", "application/json");
            headers.append("Authorization", `Bearer ${token}`);
            const response = await fetch(`/api/admin/ParticipatingYears/`, {
                headers: headers,
                method: "GET"
            })
                .then(res => res.json())
                .then(data => {
                    // //console.log(data.participatingYears);
                    //   if(data.participatingYears != null){
                    //     data.participatingYears.forEach(function (year : string) {
                    //         this.participatingYears(year : year)
                    //     });
                    //   }

                    this.setState({ dialogParticipatingYear: data.participatingYears })
                })
                .catch((error) => {
                    //console.log(error);
                });
        }
    }
    //api/file/FullExport/{year} /api/file/ActiveExport/{year}
    private ExportFile = async () => {
        var year = this.state.dialogChosenYear;
        var subject = this.state.dialogExportSubject;
        var request = "";
        var exportName = "";
        if (this.exportButtons[0] === subject) {
            request = "FullExport";
            exportName = "AllEntries";
        }
        else if (this.exportButtons[1] === subject) {
            request = "ActiveExport";
            exportName = "ActiveEntries";
        }
        if (year !== "" && subject !== "" && exportName !== "") {
            const token = await authService.getAccessToken();
            // const year = this.upcomingRaceYear();
            if (token != null) {
                const headers = new Headers();
                headers.append("Accept", "text/csv");
                headers.append("Authorization", `Bearer ${token}`);
                const response = await fetch(`api/file/${request}/${year}`, {
                    headers: headers,
                    method: "POST"
                })
                    .then(response => response.blob())
                    .then(blob => {
                        var url = window.URL.createObjectURL(blob);
                        var a = document.createElement('a');
                        a.href = url;
                        a.download = `${exportName}.csv`;
                        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                        a.click();
                        a.remove();  //afterwards we remove the element again         
                    })
                    .catch((error) => {
                        //console.log(error);
                    });
            }
        }

    }

    // ------------------------------------------------------------------ EXPORT END ------------------------------------------------------------------ //
    private handleOpenMoreInfoDialog = async (carID: string) => {
        var newCar: Car;
        const token = await authService.getAccessToken();
        const year = this.upcomingRaceYear();
        //console.log(this.state.statusCountDict);
        if (token != null) {
            const headers = new Headers();
            headers.append("Accept", "application/json");
            headers.append("Content-Type", "application/json");
            headers.append("Authorization", `Bearer ${token}`);
            const response = await fetch(`api/car/` + carID, {
                headers: headers,
                method: "GET"
            })
                .then(res => res.json())
                .then(data => {
                    //console.log("LINE 261 CAR:");
                    console.log(data.result);
                    newCar = data.result;

                    this.setState({ dialogCar: newCar });
                })
                .catch((error) => {
                    //console.log(error);
                });
            //console.log("LINE 269 CAR:");
            //console.log(this.state.dialogCar);
        }
        if (this.state.dialogCar !== null && this.state.dialogCar !== undefined) {
            await this.getEntryForm(this.state.dialogCar.registrationFormId);
            //console.log("LINE 274 ENTRY:");
            //console.log(this.state.dialogEntry);
            // this.setState({dialogEntry: this.})
        }
        // this.getCarListByStatus();
        this.setState({ dialogMoreInfoOpen: true });
    };

    private handleCloseMoreInfoDialog = () => {
        this.getCarListByStatus();
        this.setState({ dialogMoreInfoOpen: false });
        // this.setState({carsByStatusList : []});
        // this.getStatusCount();
    };
    private handleOpenDialog = (dialogSubject: string) => {
        this.setState({ dialogSubject });
        this.getCarListByStatus();
        this.setState({ dialogOpen: true });
    };

    private handleCloseDialog = () => {
        this.setState({ dialogOpen: false });
        this.setState({ carsByStatusList: [] });
        this.getStatusCount();
    };

    private upcomingRaceYear(): string {
        const currentDate = new Date();
        const raceDate = new Date('October 1, ' + currentDate.getFullYear().toString());
        if (currentDate >= raceDate) {
            return (currentDate.getFullYear() + 1).toString();

        } else {
            return (currentDate.getFullYear()).toString();
        }
    }
    private getStatusCount = async () => {
        const token = await authService.getAccessToken();
        const year = this.upcomingRaceYear();
        var statusDict: StatusData[] = [];
        //console.log(this.state.statusCountDict);
        if (token != null) {
            const headers = new Headers();
            headers.append("Accept", "application/json");
            headers.append("Content-Type", "application/json");
            headers.append("Authorization", `Bearer ${token}`);
            const response = await fetch(`api/car/statusList/` + year, {
                headers: headers,
                method: "GET"
            })
                .then(res => res.json())
                .then(data => {
                    //console.log(data.result);
                    var carStatusDict = data.result;
                    var pendingCount = 0;
                    var acceptedCount = 0;
                    var draftCount = 0;
                    var declinedCount = 0;
                    var withdrawnCount = 0;
                    var waitlistedCount = 0;
                    if (carStatusDict.Pending != undefined) {
                        pendingCount = carStatusDict.Pending;
                    }
                    if (carStatusDict.Accepted != undefined) {
                        acceptedCount = carStatusDict.Accepted;
                    }
                    if (carStatusDict.Draft != undefined) {
                        draftCount = carStatusDict.Draft;
                    }
                    if (carStatusDict.Declined != undefined) {
                        declinedCount = carStatusDict.Declined;
                    }
                    if (carStatusDict.Withdrawn != undefined) {
                        withdrawnCount = carStatusDict.Withdrawn;
                    }
                    if (carStatusDict.Waitlisted != undefined) {
                        waitlistedCount = carStatusDict.Waitlisted;
                    }
                    statusDict = [
                        { name: "Draft", value: draftCount },
                        { name: "Pending", value: pendingCount },
                        { name: "Waitlisted", value: waitlistedCount },
                        { name: "Accepted", value: acceptedCount },
                        { name: "Declined", value: declinedCount },
                        { name: "Withdrawn", value: withdrawnCount }
                    ]
                    this.setState({ statusCountDict: statusDict })
                })
                .catch((error) => {
                    //console.log(error);
                });
            //console.log(this.state.statusCountDict);
        }
    }
    private getEntryForm = async (id: string) => {
        this.props.setLoading(true);
        //console.log("get entry form")
        const token = await authService.getAccessToken();
        if (token != null) {
            const headers = new Headers();
            headers.append("Accept", "application/json");
            headers.append("Content-Type", "application/json");
            headers.append("Authorization", `Bearer ${token}`);
            await fetch("/api/entryform/" + id, {
                headers: headers,
                method: "GET"
            })
                .then(res => res.json())
                .then(data => {
                    //console.log(data)
                    if (data.result != null) {
                        this.setState({ dialogEntry: data.result })
                        console.log(data.result)
                        if (data.result.entrantModel != null) {
                            const entrant: Entrant = {
                                id: data.result.entrantId,
                                fname: data.result.entrantModel.fname,
                                mname: data.result.entrantModel.mname,
                                lname: data.result.entrantModel.lname,
                                email: data.result.entrantModel.email,
                                address1: data.result.entrantModel.address1,
                                address2: data.result.entrantModel.address2,
                                address3: data.result.entrantModel.address3,
                                city: data.result.entrantModel.city,
                                state: data.result.entrantModel.state,
                                zipCode: data.result.entrantModel.zipCode,
                                country: data.result.entrantModel.country,
                                dob: data.result.entrantModel.dob,
                                usesMetricSystem: data.result.entrantModel.usesMetricSystem,
                                phoneNumber: data.result.entrantModel.phoneNumber,
                            };
                            this.setState({ dialogEntrant: entrant });
                            // this.getEntrantByID(data.result.Entrant)
                        }
                    }
                    this.props.setLoading(false);
                })
                .catch((error) => {
                    //console.log(error);
                    this.props.setLoading(false);
                });
        }
    }
    private getEntrantByID = async (entryFormID: string) => {
        this.props.setLoading(true);
        try {
            //console.log("get entry form")
            const token = await authService.getAccessToken();
            var firstName = "";
            var middleName = "";
            var lastName = "";
            if (token != null) {
                const headers = new Headers();
                headers.append("Accept", "application/json");
                headers.append("Content-Type", "application/json");
                headers.append("Authorization", `Bearer ${token}`);
                await fetch(`/api/entrant/entryFormId/${entryFormID}`, {
                    headers: headers,
                    method: "GET"
                })
                    .then(res => res.json())
                    .then(data => {
                        //console.log(data)
                        this.setState({ dialogEntrant: data.result });
                        this.props.setLoading(false);
                    })
                    .catch((error) => {
                        //console.log(error);
                        this.setState({
                            dialogEntrant:
                                { id: "", fname: "", mname: "", lname: "", dob: new Date(), address1: "", address2: "", address3: "", city: "", state: "", country: "", zipCode: "", phoneNumber: "", email: "", usesMetricSystem: false }
                        });
                        this.props.setLoading(false);
                    });
            }
        }
        catch (error) {
            //console.log(error);
            this.setState({
                dialogEntrant:
                    { id: "", fname: "", mname: "", lname: "", dob: new Date(), address1: "", address2: "", address3: "", city: "", state: "", country: "", zipCode: "", phoneNumber: "", email: "", usesMetricSystem: false }
            });
        }
    }
    private getCarListByStatus = async () => {
        const token = await authService.getAccessToken();
        const year = this.upcomingRaceYear();
        this.props.setLoading(true);
        if (token != null) {
            const headers = new Headers();
            headers.append("Accept", "application/json");
            headers.append("Content-Type", "application/json");
            headers.append("Authorization", `Bearer ${token}`);
            const response = await fetch(`/api/car/CarListByStatus/` + year + `/` + this.state.dialogSubject, {
                headers: headers,
                method: "GET"
            })
                .then(res => res.json())
                .then(data => {
                    this.setState({ carsByStatusList: data.result })
                    this.props.setLoading(false);
                })
                .catch((error) => {
                    this.props.setLoading(false);
                    //console.log(error);
                });
        }
    }

    private COLORS = [blue[500], orange[500], deepPurple[500], green[500], red[500], grey[500]];

    private renderActiveShape: any = (pieProperties: any) => {
        const RADIAN = Math.PI / 180;
        const sin = Math.sin(-RADIAN * pieProperties.midAngle);
        const cos = Math.cos(-RADIAN * pieProperties.midAngle);
        const sx = pieProperties.cx + (pieProperties.outerRadius + 10) * cos;
        const sy = pieProperties.cy + (pieProperties.outerRadius + 10) * sin;
        const mx = pieProperties.cx + (pieProperties.outerRadius + 30) * cos;
        const my = pieProperties.cy + (pieProperties.outerRadius + 30) * sin;
        const ex = mx + (cos >= 0 ? 1 : -1) * 22;
        const ey = my;
        const textAnchor = cos >= 0 ? 'start' : 'end';

        return (
            <g>
                <Sector
                    cx={pieProperties.cx}
                    cy={pieProperties.cy}
                    innerRadius={pieProperties.innerRadius}
                    outerRadius={pieProperties.outerRadius}
                    startAngle={pieProperties.startAngle}
                    endAngle={pieProperties.endAngle}
                    fill={pieProperties.fill}
                />
                <Sector
                    cx={pieProperties.cx}
                    cy={pieProperties.cy}
                    startAngle={pieProperties.startAngle}
                    endAngle={pieProperties.endAngle}
                    innerRadius={pieProperties.outerRadius + 6}
                    outerRadius={pieProperties.outerRadius + 10}
                    fill={pieProperties.fill}
                />
                <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={pieProperties.fill} fill="none" />
                <circle cx={ex} cy={ey} r={2} fill={pieProperties.fill} stroke="none" />
                <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`${pieProperties.payload.name} ${pieProperties.value}`}</text>
                <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
                    {`(${(pieProperties.percent * 100).toFixed(2)}%)`}
                </text>
            </g>
        );
    };
    private onPieEnter: any = (data: any, index: number) => {
        this.setState({ activeIndex: index });
    }
    public render(): React.ReactElement<IDashboardProps> {
        const { classes } = this.props;
        var list = [classes.Draft, classes.Pending, classes.Waitlisted, classes.Accepted, classes.Declined, classes.Withdrawn]
        return (
            <React.Fragment>
                <Card>
                    <Card className={classes.bigBoiCardi}>
                        <Grid container justify="space-around" spacing={2}>
                            {this.state.statusCountDict.map((entry: any, index: number) =>
                                <Grid item xs={2} key={index}>
                                    <Card className={classes.smolCard}>
                                        <Button fullWidth={true} className={list[index]} onClick={() => this.handleOpenDialog(entry.name)} variant="contained">
                                            <Typography variant="subtitle1" component="small">
                                                {entry.name}
                                            </Typography>
                                        </Button>
                                        <Typography align="center" variant="h4" component="h4">
                                            {entry.value}
                                        </Typography>
                                    </Card>
                                </Grid>
                            )}
                        </Grid>
                    </Card>
                    <Box m={4} />

                    <Card className={classes.bigBoiCardi}>
                        <ResponsiveContainer height={500} width="90%">
                            <PieChart>
                                <Pie
                                    activeIndex={this.state.activeIndex}
                                    activeShape={this.renderActiveShape}
                                    onMouseEnter={this.onPieEnter}
                                    dataKey="value"
                                    data={this.state.statusCountDict}
                                    nameKey="name"
                                    cy="50%"
                                    cx="50%"
                                    labelLine={false}
                                    outerRadius="80%"
                                    fill="#8884d8">
                                    {
                                        this.state.statusCountDict.map((entry: any, index: number) => <Cell key={`cell-${index}`} fill={this.COLORS[index]} />)
                                    }
                                </Pie>
                            </PieChart>
                        </ResponsiveContainer>
                    </Card>
                    <Card className={classes.bigBoiCardi}>
                        <Grid container justify="space-around" spacing={2}>
                            {this.exportButtons.map((entry: any, index: number) =>
                                <Grid item xs={2} key={index}>
                                    <Card className={classes.smolCard}>
                                        <Button fullWidth={true} className={this.props.classes.ExportButton} onClick={() => this.handleOpenDialogForExport(this.exportButtons[index])} variant="contained">
                                            <Typography variant="subtitle1">
                                                {entry}
                                            </Typography>
                                        </Button>
                                    </Card>
                                </Grid>
                            )}
                        </Grid>

                    </Card>
                </Card>


                <Dialog
                    fullScreen
                    // fullWidth
                    open={this.state.dialogOpen}
                    onClose={this.handleCloseDialog}
                    aria-labelledby={this.state.dialogSubject + " Cars"}
                    aria-describedby={"List of " + this.state.dialogSubject + " Cars"}>
                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={this.handleCloseDialog} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" className={classes.title}>
                                {this.state.dialogSubject + " Cars"}
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <DialogTitle >{""}</DialogTitle>
                    <DialogContent >
                        {this.props.loading ?
                        <Box className={classes.center}>
                            <CircularProgress color="secondary" />
                        </Box>                      
                        :
                        <MUIDataTable title=""
                            data={this.state.carsByStatusList}
                            columns={this.columns} />}
                    </DialogContent>
                </Dialog>
                <Dialog onClose={this.closeImgDialog} aria-labelledby="simple-dialog-title" open={this.state.showImgDialog}  maxWidth='lg'>
                <DialogContent dividers>
                    <img width="800" src={this.state.img}/> 
                    </DialogContent>
                    <DialogActions className={classes.right}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={this.closeImgDialog}
                    >
                        Close
                    </Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        startIcon={<GetAppIcon />}
                        href={this.state.img} 
                        download
                    >
                        Download
                    </Button>
                    </DialogActions>
                </Dialog>      
                {this.state.dialogMoreInfoOpen == true &&
                    <Dialog
                        fullScreen
                        // fullWidth
                        open={this.state.dialogMoreInfoOpen}
                        onClose={this.handleCloseMoreInfoDialog}
                        aria-labelledby="Admin Page for Car"
                        aria-describedby="Admin updates car info including the status of car">
                        <AppBar className={classes.appBar}>
                            <Toolbar>
                                <Typography variant="h6" className={classes.title}>
                                    {(this.state.dialogCar) && (
                                        "Update " + this.state.dialogCar.year + " " + this.state.dialogCar.make + " " + this.state.dialogCar.model
                                    )}
                                </Typography>
                            </Toolbar>
                        </AppBar>
                        {/* <DialogTitle ></DialogTitle> */}
                        <DialogContent>
                            <CarWizardAdmin
                                setLoading={this.props.setLoading}
                                loading={this.props.loading}
                                setMessage={this.props.setMessage}
                                imperial={true}
                                handleCancel={this.handleCloseMoreInfoDialog}
                                editMode={true}
                                entry={this.state.dialogEntry}
                                entrant={this.state.dialogEntrant}
                                car={this.state.dialogCar}
                                isAdmin={this.props.isAdmin}
                            />
                        </DialogContent>
                    </Dialog>
                }
                <Dialog
                    // fullScreen
                    open={this.state.dialogExport}
                    // onClose={this.handleCloseDialogForExport}
                    aria-labelledby={this.state.dialogExportSubject}
                    aria-describedby={this.state.dialogExportSubject}
                >
                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={this.handleCloseDialogForExport} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" className={classes.title}>
                                {this.state.dialogExportSubject}
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <DialogContent>
                        <TextField
                            name="ParticipatingYear"
                            select
                            label="Participating Year"
                            className={classes.textField}
                            value={(this.state.dialogChosenYear === "") ? '' : this.state.dialogChosenYear}
                            onChange={this.handleParticipatingYearChange}
                            // disabled={!this.props.editMode}
                            SelectProps={{
                                MenuProps: {
                                    className: classes.menu,
                                },
                            }}
                            margin="normal"
                            fullWidth
                        >
                            {this.state.dialogParticipatingYear.map((option, index) => (
                                <MenuItem key={index} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </TextField>
                        <Button className={classes.Draft} onClick={this.ExportFile} variant="contained">
                            {/* <ThemeProvider theme={theme}> */}
                            <Typography variant="subtitle1">
                                Download
                      </Typography>
                            {/* </ThemeProvider> */}
                        </Button>
                    </DialogContent>
                </Dialog>

            </React.Fragment>

        );
    }
}
const styles = createStyles((theme: Theme) => ({
    smolCard: {
        maxWidth: 150,
        margin: 25
    },
    bigBoiCardi: {
        margin: 25
    },
    appBar: {
        position: 'relative',
        backgroundColor: grey[200]
    },
    BasicassButton: {
        color: theme.palette.getContrastText(red[200]),
        backgroundColor: red[200],
        '&:hover': {
            backgroundColor: red[100],
        },
    },
    ExportButton: {
        // color: theme.palette.getContrastText(deepOrange['A400']),
        // backgroundColor: deepOrange['A400'],
        // '&:hover': {
        //   backgroundColor: deepOrange['A700'],
        // },
        color: theme.palette.getContrastText(deepOrange[700]),
        backgroundColor: deepOrange[700],
        '&:hover': {
            backgroundColor: deepOrange[900],
        },
    },
    Draft: {
        color: theme.palette.getContrastText(blue[500]),
        backgroundColor: blue[500],
        '&:hover': {
            backgroundColor: blue[700],
        },
    },
    Pending: {
        color: theme.palette.getContrastText(orange[500]),
        backgroundColor: orange[500],
        '&:hover': {
            backgroundColor: orange[700],
        },
    },
    Accepted: {
        color: theme.palette.getContrastText(green[500]),
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[700],
        },
    },
    Declined: {
        color: theme.palette.getContrastText(red[500]),
        backgroundColor: red[500],
        '&:hover': {
            backgroundColor: red[700],
        },
    },
    Withdrawn: {
        color: theme.palette.getContrastText(grey[500]),
        backgroundColor: grey[500],
        '&:hover': {
            backgroundColor: grey[700],
        },
    },
    Waitlisted: {
        color: theme.palette.getContrastText(deepPurple[500]),
        backgroundColor: deepPurple[500],
        '&:hover': {
            backgroundColor: deepPurple[700],
        },
    },
    root: {
        backgroundColor: theme.palette.background.paper,
        position: 'relative',
    },
    tabContainer: {
        padding: 0,
        marginTop: theme.spacing(14)
    },
    tabBar: {
        position: 'absolute',
        top: theme.spacing(-1),
        zIndex: 5,
        right: 0,
        marginTop: theme.spacing(8),
        [theme.breakpoints.up('sm')]: {
            marginTop: theme.spacing(9),
        },
    },
    carList: {
        padding: theme.spacing(3, 2),
        position: 'relative',
    },
    fab: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    fabGreen: {
        color: theme.palette.common.white,
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[600],
        },
    },
    header: {
        textAlign: "center"
    },
    dialog: {
        maxWidth: '950px'
    },
    right:{        
        textAlign: 'right'
    },
    center:{        
        textAlign: 'center'
    }
}))

export default withStyles(styles)(AdminDashboard)