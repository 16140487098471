import React, { Component } from 'react';
import { withStyles, createStyles, Theme } from '@material-ui/core/styles';
import APIService from '../services/APIService';
import { Paper, Grid, Typography, Tabs, Tab, List, ListItem, ListItemIcon, Checkbox, ListItemText, ListItemSecondaryAction, IconButton, TextField, Button, Dialog, DialogTitle, DialogContent, DialogContentText, FormControl, InputLabel, Select, MenuItem, FormControlLabel, Switch, DialogActions, Icon, Box, Divider, CircularProgress, Chip } from '@material-ui/core';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import DoneIcon from '@material-ui/icons/Done';
import { green, red } from '@material-ui/core/colors';
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import BuildIcon from '@material-ui/icons/Build';
import PersonIcon from '@material-ui/icons/Person';
import EventSeatIcon from '@material-ui/icons/EventSeat';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import FaceIcon from '@material-ui/icons/Face';
import CarInfo  from './CarInfo';
import Car from '../interfaces/ICar';
import authService from '../services/api-authorization/AuthorizeService';
import CarRace from '../interfaces/ICarRace';
import Driver from '../interfaces/IDriver';
import Crew from '../interfaces/ICrew';

interface ICheckInPerson{
    id: string;
    crewId: string | null;
    loungeId: string | null;
    peopleId: string | null;
    carId: string;
    fname: string;
    lname: string;
    role: string;
    checkedIn:boolean;
    loungeWristbandNumber:string;
    wristbandNumber:string;
    driverInfo?: Driver;
    wristbandReplaced: boolean;
    waiverPage:string;
}

export interface ICheckInPersonProps {
    classes: any;
    setMessage: any;
    setLoading: any;
    loading: boolean;
}

export interface ICheckInPersonState {
    firstName: string;
    lastName:string;
    wristbandNumber: string;
    waiverPage: string;
    wristbandReplaced: boolean;
    showDialog: boolean;
    loungeWristbandNumber: string;
    selectedItem: ICheckInPerson | null;
    personList: ICheckInPerson[];
    relatedCar: Car[];
    driverFilter: boolean;
    loungeFilter: boolean;
    crewFilter: boolean;
    entrantFilter: boolean;
    _isMounted: boolean;
}

export class CheckInPerson extends Component<ICheckInPersonProps, ICheckInPersonState> {
    static displayName = CheckInPerson.name;
    private apiService: APIService = new APIService;
    public constructor(props: Readonly<ICheckInPersonProps>) {
        super(props);
        this.state = {
            firstName : "",
            lastName: "",
            wristbandNumber: "",
            waiverPage: "",
            showDialog: false,
            selectedItem: null,
            personList: [],
            _isMounted: false,
            loungeWristbandNumber: "",
            wristbandReplaced: false,
            driverFilter: true,
            loungeFilter: true,
            crewFilter: true,
            entrantFilter: true,
            relatedCar: []
        };
        this.apiService = new APIService;
        this.handleChange = this.handleChange.bind(this);
        this.renderRow = this.renderRow.bind(this);
        this.handleToggle = this.handleToggle.bind(this);
    }

    private handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newState = { [event.target.name]: event.target.value } as any;
        this.setState(newState);
    }

    private handleToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newState = { [event.target.name]: !this.state[event.target.name as keyof ICheckInPersonState] } as any;
        this.setState(newState);
    }

    private showCheckInDialog = () =>{
        this.setState({showDialog: true})
    }

    private hideCheckInDialog = () =>{
        this.setState({showDialog: false})
    }

    private checkInItem = (person : ICheckInPerson) => {
        this.showCheckInDialog();
        this.setState({selectedItem : person});
        this.setState({loungeWristbandNumber : person.loungeWristbandNumber, wristbandReplaced : person.wristbandReplaced, waiverPage: person.waiverPage, wristbandNumber : person.wristbandNumber});
        if (person.role  === "Entrant" && person.peopleId !== null) {
            this.getEntrantRelatedCars(person.peopleId);
        } else {
            this.getRelatedCar(person.carId);
        }
    }

    private getParticipants = async (fname : string, lname : string, crew : boolean, lounge : boolean, driver : boolean, entrant : boolean) => {
        if((this.state.firstName === null || this.state.firstName === "") && (this.state.lastName === null || this.state.lastName === "")){
            this.props.setMessage("Please enter in a name", "warning");
            return;
        }
        const token = await authService.getAccessToken();
        const currentDate = new Date();
        this.props.setLoading(true);
        if (token != null) {
            const headers = new Headers();
            headers.append("Accept", "application/json");
            headers.append("Content-Type", "application/json");
            headers.append("Authorization", `Bearer ${token}`);
            if (fname === ""){
                fname = "NULL";
            }
            if (lname === ""){
                lname = "NULL";                
            }
            let url = `/api/car/SearchForParticipantsWithFilter/` +  (currentDate.getFullYear()).toString() + `/accepted/`   + crew + `/`  + lounge + `/`  + driver + `/`  + entrant + `/` + fname + `/` + lname;
            const response = await fetch(url, {
                headers: headers,
                method: "GET"
            })
                .then(res => res.json())
                .then(data => {
                    this.setState({ personList: data.result })
                    this.props.setLoading(false);
                })
                .catch((error) => {
                    this.props.setLoading(false);
                    console.log(error);
                });
        }
    }

    private checkIn = async() =>{
        const token = await authService.getAccessToken();
        if (this.state.selectedItem?.id === "00000000-0000-0000-0000-000000000000"){
            await fetch(`/api/checkin/participant`, {
                method: 'POST',
                headers: !token ? {} : {
                    'Authorization': `Bearer ${token}`, 
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    id: this.state.selectedItem?.id,
                    PeopleId: this.state.selectedItem?.peopleId,
                    CrewID: this.state.selectedItem?.crewId,
                    LoungeId: this.state.selectedItem?.loungeId,
                    Role: this.state.selectedItem?.role,
                    WristbandNumber: this.state.wristbandNumber,
                    LoungeWristbandNumber: this.state.loungeWristbandNumber,
                    WaiverPage: this.state.waiverPage,
                    CheckedIn: true,
                    WristbandReplaced: this.state.wristbandReplaced,
                    ParticipatingYear: this.state.relatedCar[0].participatingYear
                })
            })
        }
        else{
            await fetch(`/api/checkin/updateParticipant`, {
                method: 'PUT',
                headers: !token ? {} : {
                    'Authorization': `Bearer ${token}`, 
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    id: this.state.selectedItem?.id,
                    PeopleId: this.state.selectedItem?.peopleId,
                    CrewID: this.state.selectedItem?.crewId,
                    LoungeId: this.state.selectedItem?.loungeId,
                    Role: this.state.selectedItem?.role,
                    WristbandNumber: this.state.wristbandNumber,
                    LoungeWristbandNumber: this.state.loungeWristbandNumber,
                    WaiverPage: this.state.waiverPage,
                    CheckedIn: true,
                    WristbandReplaced: this.state.wristbandReplaced,
                    ParticipatingYear: this.state.relatedCar[0].participatingYear
                })
            })
        }
        this.getParticipants(this.state.firstName, this.state.lastName, this.state.crewFilter, 
            this.state.loungeFilter, this.state.driverFilter, this.state.entrantFilter)
        this.hideCheckInDialog();
    }

    private getRelatedCar = async (id : string) =>{
        this.props.setLoading(true);
        const token = await authService.getAccessToken();
        if (token != null) {
            const headers = new Headers();
            headers.append("Accept", "application/json");
            headers.append("Content-Type", "application/json");
            headers.append("Authorization", `Bearer ${token}`);
            const response = await fetch(`api/car/` + id, {
                headers: headers,
                method: "GET"
            })
                .then(res => res.json())
                .then(data => {
                    const relatedCar : Car[] = [];
                    relatedCar.push(data.result);
                    this.setState({ relatedCar});
                    this.props.setLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                    this.props.setLoading(false);
                });
        }
    }
    private getEntrantRelatedCars = async (id : string) =>{
        const token = await authService.getAccessToken();
        const currentDate = new Date();
        this.props.setLoading(true);
        if (token != null) {
            const headers = new Headers();
            headers.append("Accept", "application/json");
            headers.append("Content-Type", "application/json");
            headers.append("Authorization", `Bearer ${token}`);
            const response = await fetch(`api/car/getCarsByEntrant/` + id, {
                headers: headers,
                method: "GET"
            })
                .then(res => res.json())
                .then(data => {
                    const relatedCar : Car[] = [];
                    data.result.forEach((car : Car) => {
                        if(car.status === 'Accepted' && car.participatingYear === currentDate.getFullYear().toString()){
                            relatedCar.push(car);
                        }
                    });
                    this.setState({ relatedCar});
                    this.props.setLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                    this.props.setLoading(false);
                });
        }
    }

    private renderCarCompact = (car : Car, fullsize : boolean) => {
        const { classes } = this.props;
        return(
            <Grid spacing={2} xs={fullsize ? 12 : 5}>
            <Grid item xs={12}><Typography variant="h6"><strong>{car.year + " " + car.make + " " + car.model}</strong></Typography></Grid>
            <Grid item xs={12} sm={3}><Typography variant="h6"><strong>Is Paid:</strong></Typography></Grid><Grid item xs={12} sm={9} style={{backgroundColor: car.isPaid ? "#74ed75": "#ffc73a" }}><Typography variant="h6">{car.isPaid ? "Yes" : "No"}</Typography></Grid>
            <Grid item xs={12} sm={3}><Typography variant="body1"><strong>Shirt Size:</strong></Typography></Grid><Grid item xs={12} sm={9} ><Typography variant="body1">{car.tshirtSize}</Typography></Grid>
            <Grid item xs={12}><Typography variant="body1"><strong>Participating Event:</strong></Typography></Grid>
                <Grid item xs={12} ><Typography variant="body1">
                    {car.carRace.map((value, index) => { 
                        if(index < car.carRace.length - 1){
                            return (value.race.name + " & ")
                        }
                        else{
                            return (value.race.name) 
                        }
                    })}
                </Typography></Grid>
                {car.carRace.map((value : CarRace, index) => {
                    return( value.race !== undefined && value.runGroup !== null && value.runGroup !== ""  && <React.Fragment key={index}><Grid item xs={12}><Typography variant="body1"><strong>
                            {value.race.name} Run Group:
                        </strong></Typography></Grid><Grid item xs={12}>
                        <Box className={classes.stamp}><Typography variant="h4" className={classes.innerStamp}>
                            {value.runGroup}
                        </Typography></Box></Grid></React.Fragment>)
                })}
                <Grid item xs={12} container>                    
                {(car.driverModel !== undefined && car.driverModel.length > 0 && car.driverModel[0].fname !== "") &&
                    <React.Fragment>
                        <Grid item xs={3}><Typography variant="body1"><strong>Driver:</strong></Typography></Grid>
                        <Grid item xs={9}><List dense>
                                {car.driverModel.map((value, index) => {
                                    return (
                                        <ListItem key={index}>
                                            <Typography variant="h6">
                                                <Chip
                                                    icon={<DriveEtaIcon />}
                                                    color="primary"
                                                    label={value.fname + " " + value.lname} />
                                            </Typography>
                                        </ListItem>)
                                })}
                            </List></Grid>
                    </React.Fragment>
                }
                {car.carRace.map((value, index) => {
                    if (car){
                        let raceCrew: Crew[] = car.crew.filter(x => x.race?.id === value.race.id && !x.isOvercrew);
                        return (
                            <React.Fragment key={index}>
                            <Grid item xs={3}><Typography variant="body1"><strong>{value.race.name + " Crew:"}</strong></Typography></Grid>
                            <Grid item xs={9}>
                                <List dense>
                                    {(raceCrew !== undefined) && raceCrew.map((value, index) => {
                                        return (
                                            <ListItem key={index}>
                                                <Typography variant="h6" className={classes.center}>
                                                    <Chip
                                                        icon={<CheckCircleIcon />}
                                                        color="primary"
                                                        label={value.fname + " " + value.lname} />
                                                </Typography>
                                            </ListItem>)
                                    })}
                                    {(raceCrew === undefined || raceCrew.length === 0) &&
                                        <ListItem>
                                            <Typography variant="h6" className={classes.center}>
                                                <Chip
                                                    variant="outlined"
                                                    icon={<FaceIcon />}
                                                    label="No Crew Assigned"
                                                />
                                            </Typography>
                                        </ListItem>
                                    }
                                </List>
                            </Grid>
                        </React.Fragment>
                        )
                    }
                })
                }
                {car.crew.some(e => e.isOvercrew) &&
                            <React.Fragment>
                            <Grid item xs={3}><Typography variant="body1"><strong>Overcrew:</strong></Typography></Grid>
                            <Grid item xs={9}>
                            <List dense>
                                {(car.crew !== undefined) && car.crew.map((value, index) => {
                                    if (car && value.isOvercrew) {
                                        return (
                                            <ListItem key={index}>
                                                <Typography variant="h6" className={classes.center}>
                                                    <Chip
                                                        icon={<MonetizationOnIcon />}
                                                        color="secondary"
                                                        label={car.carRace.length > 1 ? value.fname + " " + value.lname +  "(" + value.race?.name + ")" : value.fname + " " + value.lname}
                                                    />
                                                </Typography>
                                            </ListItem>)
                                    }
                                })}
                            </List>
                        </Grid>
                        </React.Fragment>
                }
                {car.carRace.some(e => e.race.hasLounge) &&
                    <React.Fragment>
                        <Grid item xs={3}><Typography variant="body1"><strong>Lounge Members:</strong></Typography></Grid>
                        <Grid item xs={9}>
                            <List dense>
                                {(car.lounge[0] !== undefined) && car.lounge.map((value, index) => {
                                    if (index === 0)
                                        return (
                                            <ListItem key={index}>
                                                <Typography variant="h6" className={classes.center}>
                                                    <Chip
                                                        icon={<CheckCircleIcon />}
                                                        color="primary"
                                                        label={value.fname + " " + value.lname} />
                                                </Typography>
                                            </ListItem>
                                        )
                                    else
                                        return (
                                            <ListItem key={index}>
                                                <Typography variant="h6" className={classes.center}>
                                                    <Chip
                                                        icon={<MonetizationOnIcon />}
                                                        color="secondary"
                                                        label={value.fname + " " + value.lname} />
                                                </Typography>
                                            </ListItem>)
                                })}
                                {(car.lounge.length === 0) &&
                                    <ListItem>
                                        <Typography variant="h6" className={classes.center}>
                                            <Chip
                                                variant="outlined"
                                                icon={<FaceIcon />}
                                                label="No Lounge Members Chosen"
                                            />
                                        </Typography>
                                    </ListItem>
                                }
                            </List>
                        </Grid>
                    </React.Fragment>
                }
                </Grid>                
                {(car.adminNotes !== null && car.adminNotes !== "") &&
                    <>
                        <Grid item xs={12} className={classes.center}><Typography variant="body1"><strong>Admin Comments</strong></Typography><Divider /></Grid>
                        <Grid item xs={12} style={{backgroundColor: "#c1c1c1" }}><Typography variant="body1" style={{fontWeight: "bold" }}>{car.adminNotes}</Typography></Grid>
                    </>
                }
            </Grid>
        )
    }

    private renderCar = (car : Car) => {
        const { classes } = this.props;
        return(
            <>
            <Grid item xs={12}>
                <Typography variant="h6">{car !== undefined && car.year + " " + car.make + " " + car.model}</Typography>                
            </Grid>            
            {car !== undefined &&
                <>
                    <Grid item xs={12} sm={12} style={{backgroundColor: car.isPaid ? "#74ed75": "#ffc73a" }}><Typography variant="h6" ><strong>Is Paid:</strong>{car.isPaid ? "Yes" : "No"}</Typography></Grid>
                    <Grid item xs={12} sm={3}><Typography variant="body1"><strong>Shirt Size:</strong></Typography></Grid><Grid item xs={12} sm={9} ><Typography variant="body1">{car.tshirtSize}</Typography></Grid>
                </>
                }
            <Grid item xs={12} sm={3}><Typography variant="body1"><strong>Participating Event:</strong></Typography></Grid>
                {car !== undefined && 
                <Grid item xs={12} ><Typography variant="body1">
                    {car.carRace.map((value, index) => { 
                        if(car !== undefined && index < car.carRace.length - 1){
                            return (value.race.name + " & ")
                        }
                        else{
                            return (value.race.name) 
                        }
                    })}</Typography></Grid>
                }
                {car !== undefined && car.carRace.map((value : CarRace, index) => {
                    return( value.race !== undefined && value.runGroup !== null && value.runGroup !== ""  && <React.Fragment key={index}><Grid item xs={12} sm={3}><Typography variant="body1"><strong>
                            {value.race.name} Run Group:
                        </strong></Typography></Grid><Grid item xs={12} sm={3}>
                        <Box className={classes.stamp}><Typography variant="h4" className={classes.innerStamp}>
                            {value.runGroup}
                        </Typography></Box></Grid></React.Fragment>)
                })}
                {car !== undefined &&
                <>
                <Grid item xs={12} className={classes.center}><Typography variant="h6"><strong>Crew Information</strong></Typography><Divider /></Grid>
                {(car.driverModel !== undefined && car.driverModel.length > 0 && car.driverModel[0].fname !== "") &&
                    <React.Fragment>
                        <Grid item xs={12} sm={3}><Typography variant="body1"><strong>Driver:</strong></Typography></Grid>
                        <Grid item xs={12} sm={9}>
                            <List dense>
                                {car.driverModel.map((value, index) => {
                                    return (
                                        <ListItem key={index}>
                                            <Typography variant="h6">
                                                <Chip
                                                    icon={<DriveEtaIcon />}
                                                    color="primary"
                                                    label={value.fname + " " + value.lname} />
                                            </Typography>
                                        </ListItem>)
                                })}
                            </List>
                        </Grid>
                    </React.Fragment>
                }
                {car.carRace.map((value, index) => {
                    if (car){
                        let raceCrew: Crew[] = car.crew.filter(x => x.race?.id === value.race.id && !x.isOvercrew);
                        return (
                            <React.Fragment key={index}>
                            <Grid item xs={12} sm={3}><Typography variant="body1"><strong>{value.race.name + " Crew:"}</strong></Typography></Grid>
                            <Grid item xs={12} sm={9}>
                                <List dense>
                                    {(raceCrew !== undefined) && raceCrew.map((value, index) => {
                                        return (
                                            <ListItem key={index}>
                                                <Typography variant="h6" className={classes.center}>
                                                    <Chip
                                                        icon={<CheckCircleIcon />}
                                                        color="primary"
                                                        label={value.fname + " " + value.lname} />
                                                </Typography>
                                            </ListItem>)
                                    })}
                                    {(raceCrew === undefined || raceCrew.length === 0) &&
                                        <ListItem>
                                            <Typography variant="h6" className={classes.center}>
                                                <Chip
                                                    variant="outlined"
                                                    icon={<FaceIcon />}
                                                    label="No Crew Assigned"
                                                />
                                            </Typography>
                                        </ListItem>
                                    }
                                </List>
                            </Grid>
                        </React.Fragment>
                        )
                    }
                })
                }
                {car.crew.some(e => e.isOvercrew) &&
                        <React.Fragment>
                        <Grid item xs={12} sm={3}><Typography variant="body1"><strong>Overcrew:</strong></Typography></Grid>
                        <Grid item xs={12} sm={9}>
                        <List dense>
                            {(car.crew !== undefined) && car.crew.map((value, index) => {
                                if (car && value.isOvercrew) {
                                    return (
                                        <ListItem key={index}>
                                            <Typography variant="h6" className={classes.center}>
                                                <Chip
                                                    icon={<MonetizationOnIcon />}
                                                    color="secondary"
                                                    label={car.carRace.length > 1 ? value.fname + " " + value.lname +  "(" + value.race?.name + ")" : value.fname + " " + value.lname}
                                                />
                                            </Typography>
                                        </ListItem>)
                                }
                            })}
                        </List>
                    </Grid>
                    </React.Fragment>
                }
                    {car.carRace.some(e => e.race.hasLounge) &&
                    <React.Fragment>
                        <Grid item xs={12} sm={3}><Typography variant="body1"><strong>Lounge Members:</strong></Typography></Grid>
                        <Grid item xs={12} sm={9}>
                            <List dense>
                                {(car.lounge[0] !== undefined) && car.lounge.map((value, index) => {
                                    if (index === 0)
                                        return (
                                            <ListItem key={index}>
                                                <Typography variant="h6" className={classes.center}>
                                                    <Chip
                                                        icon={<CheckCircleIcon />}
                                                        color="primary"
                                                        label={value.fname + " " + value.lname} />
                                                </Typography>
                                            </ListItem>
                                        )
                                    else
                                        return (
                                            <ListItem key={index}>
                                                <Typography variant="h6" className={classes.center}>
                                                    <Chip
                                                        icon={<MonetizationOnIcon />}
                                                        color="secondary"
                                                        label={value.fname + " " + value.lname} />
                                                </Typography>
                                            </ListItem>)
                                })}
                                {(car.lounge.length === 0) &&
                                    <ListItem>
                                        <Typography variant="h6" className={classes.center}>
                                            <Chip
                                                variant="outlined"
                                                icon={<FaceIcon />}
                                                label="No Lounge Members Chosen"
                                            />
                                        </Typography>
                                    </ListItem>
                                }
                            </List>
                        </Grid>
                    </React.Fragment>
                    }
                    {(car.adminNotes !== null && car.adminNotes !== "") &&
                        <>
                            <Grid item xs={12} className={classes.center}><Typography variant="h6"><strong>Admin Comments</strong></Typography><Divider /></Grid>
                            <Grid item xs={12} style={{backgroundColor: "#c1c1c1" }}><Typography variant="body1" style={{fontWeight: "bold" }}>{car.adminNotes}</Typography></Grid>
                        </>
                    }
                    </>}
                </>
        )
    }

    private renderRow(props: ListChildComponentProps) {
        const { index, style } = props;    
        return (
          <ListItem button style={style} key={index} onClick={() => this.checkInItem(this.state.personList[index])} dense>
            <ListItemIcon>
                {this.state.personList[index].checkedIn ? <DoneIcon/> : <></>}
            </ListItemIcon>
            <ListItemText primary={`${this.state.personList[index].fname + " " + this.state.personList[index].lname}`} />
                {this.state.personList[index].role === "Driver" ? <DriveEtaIcon/> : this.state.personList[index].role  === "Crew" ? <BuildIcon/> : 
                this.state.personList[index].role  === "Entrant" ? <PersonIcon/> : <EventSeatIcon/>}
          </ListItem>
        );
      }

    public componentDidMount() {
        // this.getParticipants(this.state.firstName, this.state.lastName, this.state.crewFilter, 
        //     this.state.loungeFilter, this.state.driverFilter, this.state.entrantFilter).then(() => this.setState({ _isMounted: true }));
        this.setState({ _isMounted: true });
    }

    public componentDidUpdate(prevProps: ICheckInPersonProps) {
    }
    public render(): React.ReactElement<ICheckInPersonProps> {
        const { classes } = this.props;
        return (
            <React.Fragment>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextField
                            name="firstName"
                            label="First Name"
                            className={classes.textField}
                            value={(this.state.firstName === null) ? "" : this.state.firstName}
                            onChange={this.handleChange}
                            margin="normal"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            name="lastName"
                            label="Last Name"
                            className={classes.textField}
                            value={(this.state.lastName === null) ? "" : this.state.lastName}
                            onChange={this.handleChange}
                            margin="normal"
                            fullWidth
                        />                            
                    </Grid>  
                    <React.Fragment>       
                    <Grid item xs={3} className={classes.center}>
                        <FormControlLabel                            
                            control={<Checkbox checked={this.state.entrantFilter} name="entrantFilter" onChange={this.handleToggle}/>}
                            label={<><PersonIcon/> Entrant</>}
                        />                        
                    </Grid>
                    <Grid item xs={3} className={classes.center}>
                        <FormControlLabel
                            control={<Checkbox checked={this.state.driverFilter} name="driverFilter" onChange={this.handleToggle}/>}
                            label={<><DriveEtaIcon/> Driver</>}
                        />                        
                    </Grid>
                    <Grid item xs={3} className={classes.center}>
                        <FormControlLabel
                            control={<Checkbox checked={this.state.loungeFilter} name="loungeFilter" onChange={this.handleToggle}/>}
                            label={<><EventSeatIcon/> Lounge Member</>}
                        />                        
                    </Grid>
                    <Grid item xs={3} className={classes.center}>
                        <FormControlLabel
                            control={<Checkbox checked={this.state.crewFilter} name="crewFilter" onChange={this.handleToggle}/>}
                            label={<><BuildIcon/> Crew Member</>}
                        />                        
                    </Grid>
                    </React.Fragment>
                    <Grid item xs={12} className={classes.center}>
                        <Button className={classes.submit} 
                        onClick={ () => this.getParticipants(this.state.firstName, this.state.lastName, this.state.crewFilter, this.state.loungeFilter, this.state.driverFilter, this.state.entrantFilter)} 
                        autoFocus 
                        disabled={this.props.loading}>
                            Search
                        </Button>
                    </Grid>
                    {/* <Grid item xs={12} className={classes.center}>
                        <DoneIcon/> Participant Checked In                        
                    </Grid>         */}
                </Grid> 
            <Grid container spacing={2}>
                <Grid item xs={12} className={classes.center}>
                    <Typography variant={'h6'}>Results</Typography>
                </Grid>  
                <Grid item xs={12} className={classes.center}>    
                    {(this.state._isMounted && !this.props.loading) &&
                    <FixedSizeList height={370} width={"100%"} itemSize={46} itemCount={this.state.personList.length}>
                        {this.renderRow}
                    </FixedSizeList>}
                    {(this.state._isMounted && this.props.loading) &&
                    <CircularProgress color="secondary"/>}
                </Grid>
            </Grid>
            <Dialog
                fullWidth
                maxWidth='lg'
                open={this.state.showDialog}
                onClose={this.hideCheckInDialog}
                aria-labelledby="max-width-dialog-title">
                <DialogTitle id="max-width-dialog-title">{this.state.selectedItem?.fname + " " + this.state.selectedItem?.lname}</DialogTitle>
                {!this.props.loading &&
                    <>
                <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Grid item xs={12} className={classes.center}><Typography variant="h6"><strong>Car Information</strong></Typography><Divider /></Grid>
                            </Grid>
                            {this.state.relatedCar.length === 1 && this.state.relatedCar.map(car =>{
                                return(this.renderCar(car));
                            })}
                            
                            {this.state.relatedCar.length > 1 && this.state.relatedCar.map((car, index) =>{
                                return(<>
                                    {index % 2 !== 0 && <Grid xs={2} container justify="center" alignItems="center"><Divider orientation="vertical"/></Grid>}
                                    {(index > 1 && index % 2 === 0 )&& <Grid xs={12}><Divider/></Grid>}
                                    {this.renderCarCompact(car, (index === this.state.relatedCar.length - 1) && (index % 2 === 0) )}
                                </>);
                            })}
                        </Grid>
                        <Grid container spacing={2}>
                        {(this.state.selectedItem?.role === "Driver" && this.state.selectedItem.driverInfo) &&
                        <>
                            <Grid item xs={12} className={classes.center}><Typography variant="h6"><strong>Driver Information</strong></Typography><Divider /></Grid>                        
                            {/* <Grid item xs={12} sm={3}><Typography variant="body1"><strong>Email:</strong></Typography></Grid><Grid item xs={12} sm={9}><Typography variant="body1">{this.state.driverInfo.email}</Typography></Grid>
                            <Grid item xs={12} sm={3}><Typography variant="body1"><strong>Phone Number:</strong></Typography></Grid><Grid item xs={12} sm={9}><Typography variant="body1">{this.state.driverInfo.phoneNumber}</Typography></Grid>
                            <Grid item xs={12} sm={3}><Typography variant="body1"><strong>DOB:</strong></Typography></Grid><Grid item xs={12} sm={9}><Typography variant="body1">{this.state.driverInfo.dob}</Typography></Grid>
                            <Grid item xs={12} sm={3}><Typography variant="body1"><strong>Address:</strong></Typography></Grid><Grid item xs={12} sm={9}><Typography variant="body1">{this.state.driverInfo.address1 + " " + this.state.driverInfo.address2 + " " + this.state.driverInfo.city + " " + this.state.driverInfo.state + " " + this.state.driverInfo.country}</Typography></Grid> */}
                            <Grid item xs={12} sm={3}><Typography variant="body1"><strong>ICE Name:</strong></Typography></Grid><Grid item xs={12} sm={9}><Typography variant="body1">{this.state.selectedItem.driverInfo.icename}</Typography></Grid>
                            <Grid item xs={12} sm={3}><Typography variant="body1"><strong>ICE Contact Number:</strong></Typography></Grid><Grid item xs={12} sm={9}><Typography variant="body1">{this.state.selectedItem.driverInfo.icenumber}</Typography></Grid>
                            <Grid item xs={12} sm={3}><Typography variant="body1"><strong>License Issuer:</strong></Typography></Grid><Grid item xs={12} sm={9}><Typography variant="body1">{this.state.selectedItem.driverInfo.licenseIssuer}</Typography></Grid>
                            <Grid item xs={12} sm={3}><Typography variant="body1"><strong>License Expiry:</strong></Typography></Grid><Grid item xs={12} sm={9}><Typography variant="body1">{this.state.selectedItem.driverInfo.licenseExpiry !== null && new Date(this.state.selectedItem.driverInfo.licenseExpiry).toLocaleDateString('en-US')}</Typography></Grid>
                            <Grid item xs={12} sm={3}><Typography variant="body1"><strong>Medical Issuer:</strong></Typography></Grid><Grid item xs={12} sm={9}><Typography variant="body1">{this.state.selectedItem.driverInfo.medicalIssuer}</Typography></Grid>
                            <Grid item xs={12} sm={3}><Typography variant="body1"><strong>Medical Expiry:</strong></Typography></Grid><Grid item xs={12} sm={9}><Typography variant="body1">{this.state.selectedItem.driverInfo.medicalExpiry !== null && new Date(this.state.selectedItem.driverInfo.medicalExpiry).toLocaleDateString('en-US')}</Typography></Grid>
                        </>
                        }
                        {/* {this.state.selectedItem?.checkedIn ? <Typography>Checked-In Date</Typography> : <></>} */}
                        <Grid item xs={12} className={classes.center}><Typography variant="h6"><strong>Check-In Information</strong></Typography><Divider /></Grid>  
                        <Grid item xs={4}>
                            <TextField
                                name="wristbandNumber"
                                label="Wristband"
                                className={classes.textField}
                                value={(this.state.wristbandNumber === null) ? "" : this.state.wristbandNumber}
                                onChange={this.handleChange}
                                margin="normal"
                                fullWidth
                            />
                        </Grid>
                        {this.state.selectedItem?.role === "Lounge" ?                         
                        <Grid item xs={4}>
                            <TextField
                                name="loungeWristbandNumber"
                                label="Lounge Wristband"
                                className={classes.textField}
                                value={(this.state.loungeWristbandNumber === null) ? "" : this.state.loungeWristbandNumber}
                                onChange={this.handleChange}
                                margin="normal"
                                fullWidth
                            />
                        </Grid> : <></>}
                        <Grid item xs={4}>
                            <TextField
                                name="waiverPage"
                                label="Waiver Page"
                                className={classes.textField}
                                value={(this.state.waiverPage === null) ? "" : this.state.waiverPage}
                                onChange={this.handleChange}
                                margin="normal"
                                fullWidth
                            />         
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={<Checkbox checked={this.state.wristbandReplaced} name="wristbandReplaced" onChange={this.handleToggle}/>}
                                label="Wristband Replaced"
                            />     
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions className={classes.center}>
                    <Button onClick={this.hideCheckInDialog} className={classes.cancel}>
                        Close
                    </Button>
                    <Button onClick={this.checkIn} className={classes.submit}>
                        Check In
                    </Button>
                </DialogActions>
                    </>}
            </Dialog>
            </React.Fragment>
        )
    }
}

const styles = createStyles((theme: Theme) => ({
    paper: {
        padding: theme.spacing(3, 2),
        position: 'relative',
    },
    center:{
        textAlign: 'center',
    },
    submit: {
        backgroundColor: green[500],
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        color: 'white',
        '&:hover': {
            backgroundColor: green[700],
        }
    },
    cancel: {
        backgroundColor: red[500],
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        color: 'white',
        '&:hover': {
            backgroundColor: red[700],
        }
    },
}))

export default withStyles(styles)(CheckInPerson)