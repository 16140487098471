import React, { Component } from 'react';
import {DropzoneDialog} from 'material-ui-dropzone'
import Button from '@material-ui/core/Button';
import { withStyles, createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { Typography, Snackbar, IconButton, SnackbarContent } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Alert from 'reactstrap/lib/Alert';
import { green, orange, blue, red, grey, deepPurple, blueGrey, deepOrange } from '@material-ui/core/colors';
// import MuiAlert from
interface ICarImageUploadProps{
    classes:any;
    handleImageUpload:any;
    carImage:File | null;
    setMessage: any;
}
interface ICarImageUploadState{
    open: boolean;
    file: File | null;
    //errorMessageOpen: boolean;
}
// function Alert(props) {
//     return <MuiAlert elevation={6} variant="filled" {...props} />;
//   }
export class CarImageUpload extends Component<ICarImageUploadProps, ICarImageUploadState> {
    public constructor(props: Readonly<ICarImageUploadProps>) {
        super(props);
        this.state = {
            open: false,
            file: this.props.carImage,
            //errorMessageOpen: false
        };
    }
    
    private handleClose() {
        this.setState({
            open: false
        });
    }
    
    // private handleOpenError = () => {
    //     this.setState({
    //         errorMessageOpen: true
    //     })
    // }
    // private handleCloseError = () => {
    //     this.setState({
    //         errorMessageOpen: false
    //     })
    // }
    // file : File, 
    // private handleDrop = (file : File) => {
    //     if(file.size <= 0){
    //         // this.handleOpenError();
    //         //this.props.setMessage("Uploaded image is empty.", "info")
    //     }
    //     // console.log(func);
    // }
    private handleSave(file : File[]) {
        if(file[0].size > 0){
            //Saving files to state for further use and closing Modal.
            this.setState({
                open: false
            });
            this.props.handleImageUpload(file[0])
        }
        else{
            this.props.setMessage(file[0].name + " is empty. Please try a different image.", "error")
            // this.handleOpenError();
        }
        
    }

    private handleOpen() {
        this.setState({
            open: true,
        });
    }

    public render() {
        const {classes} = this.props;
        return (
            <div>
                {/* <Snackbar
                    anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                    }}
                    open={this.state.errorMessageOpen}
                    autoHideDuration={6000}
                    onClose={this.handleCloseError}
                    // classes={classes.red}
                    message="File size cannot be 0 KB"
                    action={
                    <React.Fragment>
                        <IconButton size="small" aria-label="close" color="inherit" onClick={this.handleCloseError}>
                        <CloseIcon fontSize="small" />
                        </IconButton>
                    </React.Fragment>
                    }
                >
                    <SnackbarContent
                            // onClose={this.handleCloseError}
                            className={this.props.classes.red}
                            message={"File size cannot be 0 KB"}
                        />
                />
                </Snackbar> */}
                <Button variant="contained" onClick={this.handleOpen.bind(this)} className={classes.uploadButton}>
                  Add Car Image
                </Button>
                <DropzoneDialog
                    filesLimit={1}
                    open={this.state.open}
                    onSave={this.handleSave.bind(this)}
                    acceptedFiles={['image/jpeg', 'image/png']}
                    maxFileSize={30000000}
                    onClose={this.handleClose.bind(this)}
                    dropzoneText={"Drag Image Here, or Click to Upload"}
                />
                
            </div>
        );
    }
}

const styles = createStyles((theme: Theme) => ({
    uploadButton:{
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: theme.spacing(3),
    },
    red:{
        backgroundColor: red[500],
        zIndex: 200000
    }
}))

export default withStyles(styles)(CarImageUpload);