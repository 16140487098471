import React, { Component } from 'react';
import { withStyles, createStyles, Theme } from '@material-ui/core/styles';
import APIService from '../services/APIService';
import { Paper, Grid, Typography, Button, Card, CardActions, CardContent, Divider, Dialog, DialogActions, DialogContent, DialogTitle, withWidth, Tab, Tabs, Box, Checkbox, FormControlLabel, FormGroup, ButtonGroup, Toolbar, TextField} from '@material-ui/core';
import { green, grey, red, yellow } from '@material-ui/core/colors';
import 'react-day-picker/lib/style.css';
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from 'moment'
import MUIDataTable from "mui-datatables";
import "react-big-calendar/lib/css/react-big-calendar.css";
import ITrackRental from '../interfaces/ITrackRental';
import {TrackRentalRequestDialogAdmin} from './TrackRentalRequestDialogAdmin';
import {TrackRentalWorksheetDialogAdmin} from './TrackRentalWorksheetDialogAdmin';
import TrackRentalInfoDialog from './TrackRentalInfoDialog';
import DayPicker, { DateUtils, DayModifiers } from 'react-day-picker';
import ITrackBlackoutDate from '../interfaces/ITrackBlackoutDate';

const localizer = momentLocalizer(moment);
const columns = [
    {
     name: "requestor",
     label: "Requestor",
     options: {
      filter: false,
      sort: true,
     }
    },
    {
     name: "requestorBusiness",
     label: "Business Name",
     options: {
      filter: false,
      sort: true,
     }
    },
    {
     name: "start",
     label: "Start Date",
     options: {
      filter: false,
      sort: true,
     }
    },
    {
     name: "end",
     label: "End Date",
     options: {
      filter: false,
      sort: true,
     }
    },
    {
     name: "status",
     label: "Status",
     options: {
      filter: true,
      sort: false,
     }
    },
   ];

interface TabPanelProps {
    children?: React.ReactNode;
    dir?: string;
    index: any;
    value: any;
}

interface IEventData {
    index?:number;
    title: string;
    start: Date;
    end: Date;
    status?: string;
}

export interface ITrackRentalAdminProps {
    classes: any;
    width: any;
    setMessage: any;
    setLoading: any;
    loading: boolean;
}

export interface ITrackRentalAdminState {
    _isMounted: boolean;
    mode: number;
    showDialog: boolean;
    dialogMode: string;
    selectedRental? : ITrackRental;
    userRentals : ITrackRental[];
    tableData : any[];
    eventData : IEventData[];
    blackoutStartDate: Date;
    blackoutEndDate: Date;
    blackoutDates: Date[];
    blackoutDateDesc: string;
    selectedBlackout? : ITrackBlackoutDate;
    trackBlackoutDates : ITrackBlackoutDate[];
}
  

export class TrackRentalAdmin extends Component<ITrackRentalAdminProps, ITrackRentalAdminState> {
    static displayName = TrackRentalAdmin.name;
    private apiService: APIService = new APIService;
    public constructor(props: Readonly<ITrackRentalAdminProps>) {
        super(props);
        this.state = {
            _isMounted: false,
            mode: 0,
            showDialog: false,
            dialogMode: "",
            userRentals : [],
            tableData : [],
            eventData : [],
            blackoutDateDesc: "",
            blackoutStartDate: new Date(),
            blackoutEndDate: new Date(),
            blackoutDates : [],
            trackBlackoutDates: []
        };
        this.TabPanel = this.TabPanel.bind(this);
        this.onSelectEvent = this.onSelectEvent.bind(this);
        this.handleDateClick = this.handleDateClick.bind(this);
    }

    private options = { 
        onRowClick: ((rowData : any, rowMeta: { dataIndex: number; rowIndex: number; }) => {
            switch (rowData[4]) {
                case "Request Approved (Pending User Worksheet)":
                    this.openDialog("worksheet", this.state.userRentals[rowMeta.dataIndex]);
                    break;
                case "Pending Request Approval":
                    this.openDialog("request", this.state.userRentals[rowMeta.dataIndex]);
                    break;
                case "Pending Worksheet Approval":
                    this.openDialog("worksheet", this.state.userRentals[rowMeta.dataIndex]);
                    break;
                case "Request Complete":
                    this.openDialog("info", this.state.userRentals[rowMeta.dataIndex]);
                    break;
                case "Request Rejected":
                    this.openDialog("info", this.state.userRentals[rowMeta.dataIndex]);
                    break;
                default:
                    this.openDialog("info", this.state.userRentals[rowMeta.dataIndex]);
                    break;
            }
        })
    };
    private onSelectEvent(event: any) {
        switch (event.status) {
            case "pendingWorksheetApproval":
                this.openDialog("worksheet", this.state.userRentals[event.index]);
                break;
            case "worksheetRejected":
                this.openDialog("worksheet", this.state.userRentals[event.index]);
                break;
            case "pendingApproval":
                this.openDialog("request", this.state.userRentals[event.index]);
                break;
            case "pendingWorksheet":
                this.openDialog("worksheet", this.state.userRentals[event.index]);
                break;
            case "blackoutDate":
                this.openBlackoutDialog(event.index);
                break;
            default:
                this.openDialog("info", this.state.userRentals[event.index]);
                break;
        }
    }
    
    private openDialog = (dialogMode : string, selectedRental? : ITrackRental) => {
        selectedRental && this.setState({ showDialog: true, dialogMode, selectedRental });
        selectedRental === undefined && this.setState({ showDialog: true, dialogMode });
    }
    private openBlackoutDialog = (index : number) => {
        let selectedBlackout = this.state.trackBlackoutDates[index - this.state.tableData.length];
        this.setState({ showDialog: true, dialogMode:"blackoutInfo", selectedBlackout });
    }

    private handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newState = { [event.target.name]: event.target.value } as any;
        this.setState(newState);
    };

    private closeDialog = () => {
        this.setState({ showDialog: false });
    };

    private handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        this.setState({ mode: newValue });
    };    

    private addBlackoutDate = async () =>{
        this.props.setLoading(true);
        await this.apiService.addBlackoutDates(this.state.blackoutDateDesc, this.state.blackoutStartDate, this.state.blackoutEndDate).then((result : any) => {
            this.props.setLoading(false);
            this.props.setMessage("Date Successfully Added", "success");
            this.setState({ showDialog: false, blackoutDateDesc: "", blackoutStartDate: new Date(), blackoutEndDate: new Date()});
        });
    };

    private removeBlackoutDate = async (id : string) =>{
        this.props.setLoading(true);
        await this.apiService.removeBlackoutDates(id).then((result : any) => {
            this.setState({ showDialog: false});
            this.props.setLoading(false);
            this.props.setMessage("Date Removed", "success");
        });
    };
    
    private handleDateClick(date : Date, modifiers: DayModifiers){
        if(moment(this.state.blackoutStartDate).format('MMMM Do YYYY') === moment(new Date()).format('MMMM Do YYYY')){
            this.setState({blackoutStartDate : date, blackoutEndDate : date});
        }
        else{
            const range = DateUtils.addDayToRange(date, {from: this.state.blackoutStartDate, to: this.state.blackoutEndDate});            
            if(!modifiers.disabled){
                this.setState({blackoutStartDate : range.from, blackoutEndDate : range.to});
            }
        }
    };

    private getTrackRentals = async () => {
        let tableData : any [] = [];
        let eventData : IEventData [] = [];
        let trackBlackoutDates : ITrackBlackoutDate[] = [];
        let status : string = "";
        await this.apiService.getTrackRentals().then((result : any) => {
            result.map((item : any, index: number) => {                
                switch (item.status) {
                    case "pendingApproval":
                        status = "Pending Request Approval";
                        break;
                    case "pendingWorksheet":
                        status = "Request Approved (Pending User Worksheet)";
                        break;
                    case "pendingWorksheetApproval":
                        status = "Pending Worksheet Approval";
                        break;
                    case "worksheetRejected":
                        status = "Request Approved (Pending User Worksheet)";
                        break;
                    case "accepted":
                        status = "Request Complete";
                        break;
                    case "rejected":
                        status = "Request Rejected";
                        break;
                    default:
                        status = "Status Unkown";
                        break;
                }
                let title = item.entrant.businessName && item.entrant.businessName!== "" ? item.entrant.businessName : `${item.entrant.lname}, ${item.entrant.fname}`;
                tableData.push([`${item.entrant.lname}, ${item.entrant.fname}`,item.entrant.businessName , moment(item.startDate).format('MMMM Do YYYY'), moment(item.endDate).format('MMMM Do YYYY'), status]);
                let startDate = new Date(moment(item.startDate).toString());
                let endDate = new Date(moment(item.endDate).toString());
                moment(item.startDate).isDST() ? startDate.setTime(startDate.getTime() + (7*60*60*1000)) : startDate.setTime(startDate.getTime() + (8*60*60*1000));  
                moment(item.endDate).isDST() ? endDate.setTime(endDate.getTime() + (7*60*60*1000)) : endDate.setTime(endDate.getTime() + (8*60*60*1000)); 
                eventData.push({status: item.status, index, start: startDate, end: endDate, title})
            });
            this.setState({ userRentals: result, tableData});
        });
        await this.apiService.getAdminSetBlackOutDates().then((result : any) => {
            result.map((item : any, index: number) => {
                let startDate = new Date(moment(item.startDate).toString());
                let endDate = new Date(moment(item.endDate).toString());
                moment(item.startDate).isDST() ? startDate.setTime(startDate.getTime() + (7*60*60*1000)) : startDate.setTime(startDate.getTime() + (8*60*60*1000));  
                moment(item.endDate).isDST() ? endDate.setTime(endDate.getTime() + (7*60*60*1000)) : endDate.setTime(endDate.getTime() + (8*60*60*1000)); 
                eventData.push({status: "blackoutDate", index : eventData.length, start: startDate, end: endDate, title : item.name});
                trackBlackoutDates.push({id: item.id, startDate: startDate, endDate: endDate, name : item.name});
            });
            this.setState({eventData,trackBlackoutDates });
        });
    }

    private TabPanel(props: TabPanelProps): React.ReactElement {
        const { children, value, index, ...other } = props;
        const { classes } = this.props;
        return (
            <Typography
                component="div"
                role="tabpanel"
                hidden={value !== index}
                id={`tabpanel-${index}`}
                aria-labelledby={`tab-${index}`}
                {...other}
            >
                <Box p={3} className={classes.tabContainer}>{children}</Box>
            </Typography>
        );
    }

    private renderEvent(event : any) {    
        return (
            <div style={{ height: 22}}>
                <Typography>{event.title}</Typography>
            </div>
        );
    }

    private CustomToolbar = (toolbar : any) => {
        const {classes} = this.props;
        const goToBack = () => {
          toolbar.date.setMonth(toolbar.date.getMonth() - 1);
          toolbar.onNavigate('prev');
        };
      
        const goToNext = () => {
          toolbar.date.setMonth(toolbar.date.getMonth() + 1);
          toolbar.onNavigate('next');
        };
      
        const goToCurrent = () => {
          const now = new Date();
          toolbar.date.setMonth(now.getMonth());
          toolbar.date.setYear(now.getFullYear());
          toolbar.onNavigate('current');
        };
      
        const label = () => {
          const date = moment(toolbar.date);
          return (
            <span><b>{date.format('MMMM')}</b><span> {date.format('YYYY')}</span></span>
          );
        };
      
        return (
          <div>
            <label>{label()}</label>
            <ButtonGroup size="small" aria-label="calendar-controls" className={classes.calendarControls}>
                <Button onClick={goToBack} variant="outlined" size="small">Prev Month</Button>
                <Button onClick={goToCurrent} variant="outlined" size="small">today</Button>
                <Button onClick={goToNext} variant="outlined" size="small">Next Month</Button>
            </ButtonGroup>
          </div >
        );
      };

    private eventRenderProps = (event : any, start : any, end : any, isSelected : any) => {
        const {classes} = this.props;
        let result = {};
        if(event.status === 'accepted'){
            result = {className : classes.approved};
        } else if(event.status === 'rejected'){
            result = {className : classes.rejected};
        }else if(event.status === 'pendingApproval'){
            result = {className : classes.pendingApproval};
        }else if(event.status === 'blackoutDate'){
            result = {className : classes.blackoutDate};
        }else{
            result = {className : classes.pending};
        }
        return result; 
      }
      
    public componentDidMount() {
        this.props.setLoading(true);
        this.getTrackRentals().then(() => {
            this.setState({ _isMounted: true }); 
            this.props.setLoading(false);
        });
    }

    public async componentDidUpdate(prevProps: ITrackRentalAdminProps) {
        if (prevProps.loading !== this.props.loading){
            this.getTrackRentals().then(() => {
                this.setState({ _isMounted: true });
            });  
        }
    }

    public render(): React.ReactElement<ITrackRentalAdminProps> {
        const { classes, width } = this.props;
        let baseDates = [{ before: new Date()}, new Date()]
        let disabledDates = baseDates.concat(this.state.blackoutDates);
        const from = this.state.blackoutStartDate;
        const to = this.state.blackoutEndDate;
        const modifiers = { start: from, end: to };
        //this comment should appear.
        return (
            <React.Fragment>
            {(this.state._isMounted && !this.props.loading) &&
            <Paper className={classes.paper}>
                <Tabs
                    value={this.state.mode}
                    onChange={this.handleTabChange}
                    indicatorColor="primary"
                    textColor="primary"
                    centered
                >
                    <Tab label="Calendar View" />
                    <Tab label="Table View" />
                </Tabs>
                <this.TabPanel value={this.state.mode} index={0}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Calendar
                                localizer={localizer}
                                defaultDate={new Date()}
                                defaultView="month"
                                events={this.state.eventData}
                                style={{ height: "100vh" }}
                                onSelectEvent={this.onSelectEvent}
                                selectable
                                length={0}
                                popup
                                views={['month']}
                                startAccessor="start"
                                endAccessor="end"
                                components={{
                                  event: this.renderEvent,
                                  toolbar: this.CustomToolbar
                                }}
                                eventPropGetter={this.eventRenderProps}
                            />
                        </Grid>
                        <Grid item xs={12} container justify="center">
                            <Grid item xs={3} >
                                <Button color="inherit" variant="contained" onClick={() => this.openDialog("blackout")} className={classes.toolbarButton}>Add Blackout Date.</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </this.TabPanel>
                <this.TabPanel value={this.state.mode} index={1}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <MUIDataTable
                                title={"Track Rental Requests"}
                                data={this.state.tableData}
                                columns={columns}
                                options={this.options}
                            />
                        </Grid>
                    </Grid>
                </this.TabPanel>
            </Paper>
            }
            {this.state.showDialog && 
                <Dialog
                    open={this.state.showDialog}
                    onClose={this.closeDialog}
                    maxWidth={"lg"}
                    fullWidth
                    fullScreen={width !== "lg" && width !== "xl"}
                    scroll="paper"
                >
                {this.state.dialogMode === "request" &&
                    <TrackRentalRequestDialogAdmin
                        {...this.props}
                        selectedRental={this.state.selectedRental}
                        closeDialog={this.closeDialog}
                    />
                }
                {(this.state.dialogMode === "info" && this.state.selectedRental !== undefined) &&
                    <TrackRentalInfoDialog
                        {...this.props}
                        selectedRental={this.state.selectedRental}
                        closeDialog={this.closeDialog}
                    />
                }
                {(this.state.dialogMode === "worksheet" && this.state.selectedRental !== undefined) &&
                    <TrackRentalWorksheetDialogAdmin
                        {...this.props}
                        selectedRental={this.state.selectedRental}
                        closeDialog={this.closeDialog}
                    />
                }
                {(this.state.dialogMode === "blackout") &&
                <>
                    <DialogTitle>Add Blackout Date</DialogTitle>
                    <DialogContent dividers>
                    <Grid container>
                        <Grid item xs={12} className={classes.center}>
                            <DayPicker 
                                className="Selectable"
                                modifiers={modifiers}
                                onDayClick={this.handleDateClick}
                                selectedDays={[from, { from, to }]}
                                disabledDays={disabledDates}
                                numberOfMonths={2}
                            />
                            <style>{`
                                .Selectable .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
                                background-color: #f0f8ff !important;
                                color: #4a90e2;
                                }
                                .Selectable .DayPicker-Day {
                                border-radius: 0 !important;
                                }
                                .Selectable .DayPicker-Day--start {
                                border-top-left-radius: 50% !important;
                                border-bottom-left-radius: 50% !important;
                                }
                                .Selectable .DayPicker-Day--end {
                                border-top-right-radius: 50% !important;
                                border-bottom-right-radius: 50% !important;
                                }
                            `}</style>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                name="blackoutDateDesc"
                                label="Blackout Date Short Description"
                                required
                                className={classes.textField}
                                value={this.state.blackoutDateDesc}
                                onChange={this.handleChange}
                                margin="normal"
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.closeDialog} variant="contained">Cancel</Button>
                        <Button onClick={this.addBlackoutDate} className={classes.submit} variant="contained">Add Date</Button>
                    </DialogActions>
                </>
                }
                {(this.state.dialogMode === "blackoutInfo"  && this.state.selectedBlackout) &&
                <>
                    <DialogTitle>Blackout Date Info</DialogTitle>
                    <DialogContent dividers>
                    <Grid container>
                        <Grid item xs={12}>
                            {moment(this.state.selectedBlackout.startDate).format('MMMM Do YYYY')} - {moment(this.state.selectedBlackout.endDate).format('MMMM Do YYYY')}
                        </Grid>
                        <Grid item xs={12}>
                            {this.state.selectedBlackout.name}
                        </Grid>
                    </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.closeDialog} variant="contained">Close</Button>
                        <Button onClick={() => {if(this.state.selectedBlackout !== undefined){this.removeBlackoutDate(this.state.selectedBlackout.id)}}} className={classes.reject} variant="contained">Remove Date</Button>
                    </DialogActions>
                </>
                }
                </Dialog>}
            </React.Fragment>
        )
    }
}

const styles = createStyles((theme: Theme) => ({
    paper: {
        padding: theme.spacing(3, 2),
        position: 'relative',
    },
    center:{
        textAlign: 'center',
    },
    tabContainer: {
        padding: 0,
    },
    blackoutDate:{
        backgroundColor: grey[900],  
        color: 'white',      
    },
    approved:{
        backgroundColor: green[500],        
    },
    pendingApproval:{
        backgroundColor: grey[500],        
    },
    pending:{
        backgroundColor: yellow[500],  
        color: 'black',      
    },
    rejected:{
        backgroundColor: red[500],  
        color: 'white',      
    },
    calendarControls: {
        float: 'right'
    },
    reject: {
        backgroundColor: red[500],
        marginLeft: theme.spacing(2),
        color: 'white',
        '&:hover': {
            backgroundColor: red[700],
        }
    },
    submit: {
        backgroundColor: green[500],
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        color: 'white',
        '&:hover': {
            backgroundColor: green[700],
        }
    },
    toolbarButton:{
        width: '100%'
    }
}))

export default withWidth()(withStyles(styles, { withTheme: true })(TrackRentalAdmin));