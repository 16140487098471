import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles, createStyles, Theme } from '@material-ui/core/styles';
import { green, orange, blue, red, grey, deepPurple } from '@material-ui/core/colors';
import Race from '../interfaces/IRace';
import Car from '../interfaces/ICar';
import CarRace from '../interfaces/ICarRace';
import { FormControlLabel, Checkbox } from '@material-ui/core';

export interface AdminRaceProps {
    handleChange:any; 
    changeRungroup: any;
    status: string;
    selectedRaces: Race[];
    isPaid:boolean;
    runGroups: CarRace[];
    classes:any;    
    validationAttempt: boolean;
    car?: Car;
    handleToggle:any;
    adminNotes: string;
 }

export interface AdminRaceFormState {
  rungroups: string[];
}

export class AdminRaceForm extends Component<AdminRaceProps, AdminRaceFormState> {
    public constructor(props: Readonly<AdminRaceProps>) {
        super(props);
        this.state = {
          rungroups : [ "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14"]
        };
        
    }   
    public componentDidMount(){
    }
    
    private status = [{value: 'Draft',label: 'Draft'},
        {value: 'Pending',label: 'Pending'},
        {value: 'Waitlisted',label: 'Waitlisted'},
        {value: 'Accepted',label: 'Accepted'},
        {value: 'Declined',label: 'Declined'},
        {value: 'Withdrawn',label: 'Withdrawn'}];

    public render() : React.ReactElement{
        const { classes } = this.props;
        return(
            <Grid item xs={12}>
                <Grid item xs={12} >    
                        <Typography className={classes.subheading}><b>Car Status</b></Typography>
                            <Divider />  
                    </Grid> 
                    
                <Grid item xs={6} className={classes.center}>    
                    {this.props.car?.creationDate !== null && this.props.car?.creationDate !== undefined && <Typography>Created: { new Date(this.props.car?.creationDate).toLocaleString()}</Typography>}
                </Grid> 
                
                <Grid item xs={6} className={classes.center}>
                    {this.props.car?.submitDate !== null && this.props.car?.submitDate !== undefined && <Typography>Submitted: {new Date(this.props.car?.submitDate).toLocaleString()}</Typography>}
                </Grid> 
                <Grid container justify="center" alignItems="center"> 
                    <br/>  
                    <Grid item xs={12} >
                        <TextField
                            name="status"
                            select
                            fullWidth
                            label="Status"
                            className={classes.textField}
                            value={(this.props.status === null) ? "" : this.props.status}
                            error={(this.props.status === null || this.props.status === "") && this.props.validationAttempt}
                            onChange={this.props.handleChange}
                            SelectProps={{
                            MenuProps: {
                                className: classes.menu,
                            },
                            }}
                            margin="normal"
                            >   
                            {this.status.map(option => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Divider />
                    <Grid item xs={12} className={classes.center}>    
                    <FormControlLabel
                        control={
                            <Checkbox
                                name="isPaid"
                                checked={this.props.isPaid}
                                onChange={this.props.handleToggle}
                                value={(this.props.isPaid === null) ? false : this.props.isPaid}
                                inputProps={{ 'aria-label': 'paid checkbox', }}
                            />
                        }
                        label={<Typography variant="subtitle1"> Paid </Typography>}
                    />
                    </Grid> 
                </Grid>
                <React.Fragment>
                    <Grid item xs={12} >
                        <Typography className={classes.subheading}><b>Run Group</b></Typography>
                            <Divider />  
                    </Grid>  
                    {this.props.selectedRaces.map((value, index) => {
                        return(
                            <React.Fragment>                            
                            <Grid container justify="center" alignItems="center" >         
                                <Grid item xs={3}>             
                                <TextField
                                  name={value.id}
                                  select
                                  fullWidth
                                  label={value.name + " Group"}
                                  className={classes.textField}
                                  value={(this.props.runGroups.filter(x => x.raceId === value.id).length > 0) ? this.props.runGroups.filter(x => x.raceId === value.id)[0].runGroup : ""}
                                  onChange={this.props.changeRungroup}
                                  SelectProps={{
                                  MenuProps: {
                                      className: classes.menu,
                                  },
                                  }}
                                  margin="normal"
                                  >   
                                  {this.state.rungroups.map(option => (
                                      <MenuItem key={option} value={option}>
                                          {option}
                                      </MenuItem>
                                  ))}
                                </TextField>
                                </Grid>    
                            </Grid>
                    </React.Fragment>
                    )})}
                    {(this.props.selectedRaces.length === 0)  && (
                        <React.Fragment>  
                            <br/>
                            <Typography variant="h6" className={classes.center}>
                                No Races Selected.
                            </Typography>   
                        </React.Fragment>
                    )} 
                </React.Fragment>
                  <Grid item xs={12}>
                      <TextField
                          name="adminNotes"
                          required
                          label="Admin Notes"
                          multiline
                          rows="3"
                          rowsMax="10"
                          className={classes.textField}
                          value={(this.props.adminNotes === null) ? "" : this.props.adminNotes}
                          error={(this.props.adminNotes === null || this.props.adminNotes === "") && this.props.validationAttempt}
                          helperText={(this.props.validationAttempt && this.props.adminNotes === "") ? "Required Field" : ""}
                          onChange={this.props.handleChange}
                          margin="normal"
                          fullWidth
                      />
                    </Grid>
            </Grid>
        );
    }
}

const styles = createStyles((theme: Theme) => ({
    Draft: {
        color: theme.palette.getContrastText(blue[500]),
        backgroundColor: blue[500],
        '&:hover': {
          backgroundColor: blue[700],
        },
      },
      Pending: {
        color: theme.palette.getContrastText(orange[500]),
        backgroundColor: orange[500],
        '&:hover': {
          backgroundColor: orange[700],
        },
      },
      Accepted: {
        color: theme.palette.getContrastText(green[500]),
        backgroundColor: green[500],
        '&:hover': {
          backgroundColor: green[700],
        },
      },
      Declined: {
        color: theme.palette.getContrastText(red[500]),
        backgroundColor: red[500],
        '&:hover': {
          backgroundColor: red[700],
        },
      },
      Withdrawn: {
        color: theme.palette.getContrastText(grey[500]),
        backgroundColor: grey[500],
        '&:hover': {
          backgroundColor: grey[700],
        },
      },
      Waitlisted: {
        color: theme.palette.getContrastText(deepPurple[500]),
        backgroundColor: deepPurple[500],
        '&:hover': {
          backgroundColor: deepPurple[700],
        },
      },
    button: {
      margin: theme.spacing(1),
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1)
    },
    paper:{    
      padding: theme.spacing(2, 2),
      marginLeft: '25%',
      marginRight: '25%',
    },  
    center:{
        textAlign: 'center', 
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    root: {
      width: '90%',
    },
    backButton: {
      marginRight: theme.spacing(1),
    },
    completed: {
      display: 'inline-block',
    },
    wizardControl:{
        marginTop: 15,
        textAlign: 'center', 
    },
    regStep:{
        textAlign: 'center', 
    },
    formSwitchLabel:{
        color:grey,
        marginTop: theme.spacing(2),
}
  }))
  
  export default withStyles(styles)(AdminRaceForm);