import React, { Component } from 'react';
import { withStyles, createStyles, Theme } from '@material-ui/core/styles';
import APIService from '../services/APIService';
import { Paper, Grid, Typography, Tabs, Tab, Button, Card, CardActions, CardContent, Divider, Dialog, DialogActions, DialogContent, DialogTitle, withWidth, TextField, MenuItem, Link} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { green } from '@material-ui/core/colors';
import 'react-day-picker/lib/style.css';
import { TrackRentalRequestDialog } from './TrackRentalRequestDialog';
import ITrackRental from '../interfaces/ITrackRental';
import moment from 'moment';
import { TrackRentalWorksheetDialog } from './TrackRentalWorksheetDialog';
import TrackRentalInfoDialog from './TrackRentalInfoDialog';

export interface ITrackRentalProps {
    classes: any;
    width: any;
    setMessage: any;
    setLoading: any;
    loading: boolean;
}

export interface ITrackRentalState {
    _isMounted: boolean;
    showDialog: boolean;
    dialogMode: string;
    startDate: Date;
    endDate: Date;
    userRentals : ITrackRental[];
    selectedRental? : ITrackRental;
    desc: string;
    soundLevel: string;
}

export class TrackRental extends Component<ITrackRentalProps, ITrackRentalState> {
    static displayName = TrackRental.name;
    private apiService: APIService = new APIService;
    public constructor(props: Readonly<ITrackRentalProps>) {
        super(props);
        this.state = {
            _isMounted: false,
            showDialog: false,
            dialogMode: "",
            startDate: new Date(),
            endDate: new Date(),
            userRentals: [],
            desc: "",
            soundLevel: ""
        };
        this.apiService = new APIService;
        this.openDialog = this.openDialog.bind(this);
        this.openWorksheetDialog = this.openWorksheetDialog.bind(this);
        this.openInfoDialog = this.openInfoDialog.bind(this);
        this.closeDialog = this.closeDialog.bind(this);
        this.getUserTrackRentals = this.getUserTrackRentals.bind(this);
    }

    private openDialog = (dialogMode : string) => {
        this.setState({ showDialog: true, dialogMode });
    }

    private openRequestDialog = (dialogMode : string, desc : string, soundLevel : string) => {
        this.setState({ showDialog: true, dialogMode, desc, soundLevel});
    }

    private openWorksheetDialog = (dialogMode : string, selectedRental : ITrackRental) => {
        this.setState({ showDialog: true, dialogMode, selectedRental });
    }

    private openInfoDialog = (dialogMode : string, selectedRental : ITrackRental) => {
        this.setState({ showDialog: true, dialogMode, selectedRental });
    }

    private openContactDialog = (dialogMode : string, selectedRental : ITrackRental) =>{
        this.setState({ showDialog: true, dialogMode, selectedRental });
    }

    private openCancelDialog = (dialogMode : string, selectedRental : ITrackRental) =>{
        this.setState({ showDialog: true, dialogMode, selectedRental });
    }

    private closeDialog = () => {
        this.setState({ showDialog: false });
    }

    private getUserTrackRentals = async () => {
        this.props.setLoading(true);
        await this.apiService.getUserTrackRentals().then((result : any) => {
            this.setState({ userRentals: result });
            this.props.setLoading(false);
        });
    }
    
    public componentDidMount() {        
        this.getUserTrackRentals().then(() => this.setState({ _isMounted: true }));
    }

    public async componentDidUpdate(prevProps: ITrackRentalProps) {
        if (prevProps.loading !== this.props.loading){
            await this.apiService.getUserTrackRentals().then((result : any) => {
                this.setState({ userRentals: result });
            });
        }
    }

    public render(): React.ReactElement<ITrackRentalProps> {
        const { classes, width } = this.props;
        return (
            <React.Fragment>
            {(this.state._isMounted && !this.props.loading) && 
            <Paper className={classes.paper}>
                <Grid container justify="center" spacing={2}>
                    <Grid item xs={10}>
                    <Typography gutterBottom variant="h4">Track Rental Requests</Typography>
                    <Typography gutterBottom variant="h5">Current Requests</Typography>
                    <Divider/>
                    </Grid>
                    {this.state.userRentals.map((item : ITrackRental)=> {
                        let status = "";
                        switch (item.status) {
                            case "pendingApproval":
                                status = "Pending Request Approval";
                                break;
                            case "pendingWorksheet":
                                status = "Action Required";
                                break;
                            case "pendingWorksheetApproval":
                                status = "Pending Worksheet Approval";
                                break;
                            case "accepted":
                                status = "Request Complete";
                                break;
                            case "rejected":
                                status = "Request Rejected";
                                break;
                            default:
                                status = "Status Unkown";
                                break;
                        }
                        if(item.status !== "accepted" && item.status !== "rejected"){
                        return (
                            <Grid item xs={10} key={item.id}>
                                <Card className={classes.root}>
                                <CardContent>
                                    <Typography gutterBottom>
                                    {moment(item.startDate).format('MMMM Do YYYY')} - {moment(item.endDate).format('MMMM Do YYYY')}
                                    </Typography>
                                    <Typography gutterBottom>
                                    {status}
                                    </Typography>
                                    <Typography variant="body2" component="p">
                                    {item.description}
                                    <br />
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    {item.status === "pendingWorksheet" && <Button variant="contained" size="small" onClick={() => this.openWorksheetDialog("worksheet", item)}>Complete Rental Worksheet</Button>}
                                    {item.status === "pendingWorksheetApproval" &&<Button variant="contained" size="small" onClick={() => this.openInfoDialog("info", item)}>View Full Information</Button>}
                                    <Button variant="contained" size="small" onClick={() => this.openRequestDialog("request", item.description, item.soundLevel)}>Duplicate</Button>
                                    <Button variant="contained" size="small" onClick={() => this.openContactDialog("contact", item)}>Contact Registration</Button>
                                    {/* {item.status === "pendingApproval" &&<Button variant="contained" size="small" onClick={() => this.openCancelDialog("cancel", item)}>Cancel</Button>} */}
                                </CardActions>
                                </Card>                        
                            </Grid>
                        );
                        }
                    })}
                    <Grid item xs={3}>
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            className={classes.button}
                            startIcon={<AddIcon />}
                            onClick={() => this.openRequestDialog("request", "", "")}
                        >
                            Begin New Request
                        </Button>
                    </Grid>
                    <Grid item xs={10}>
                    <Typography gutterBottom variant="h5">Completed/Closed Requests</Typography>
                    <Divider/>
                    </Grid>
                    {this.state.userRentals.map((item : ITrackRental)=> {
                        if(item.status === "accepted" || item.status === "rejected"){
                            let status = "";
                            switch (item.status) {
                                case "accepted":
                                    status = "Request Complete";
                                    break;
                                case "rejected":
                                    status = "Request Rejected";
                                    break;
                                default:
                                    status = "Status Unkown";
                                    break;
                            }
                            return (
                                <Grid item xs={10} key={item.id}>
                                    <Card className={classes.root}>
                                    <CardContent>
                                        <Typography gutterBottom>
                                        {moment(item.startDate).format('MMMM Do YYYY')} - {moment(item.endDate).format('MMMM Do YYYY')}
                                        </Typography>
                                        <Typography gutterBottom>
                                        {status}
                                        </Typography>
                                        <Typography variant="body2" component="p">
                                        {item.description}
                                        <br />
                                        </Typography>
                                    </CardContent>
                                    <CardActions>
                                        <Button variant="contained" size="small" onClick={() => this.openInfoDialog("info", item)}>View Full Information</Button>
                                        <Button variant="contained" size="small" onClick={() => this.openRequestDialog("request", item.description, item.soundLevel)}>Duplicate</Button>
                                        <Button variant="contained" size="small" onClick={() => this.openContactDialog("contact", item)}>Contact Registration</Button>
                                    </CardActions>
                                    </Card>                        
                                </Grid>
                            );
                        }
                    })}
                </Grid>
            </Paper>
            }
            {this.state.showDialog && 
                <Dialog
                    open={this.state.showDialog}
                    onClose={this.closeDialog}
                    maxWidth={"lg"}
                    fullWidth
                    fullScreen={width !== "lg" && width !== "xl"}
                    scroll="paper"
                >
                {(this.state.dialogMode === "info" && this.state.selectedRental !== undefined) &&
                <TrackRentalInfoDialog 
                    {...this.props}
                    selectedRental={this.state.selectedRental}
                    closeDialog={this.closeDialog}
                />}
                {this.state.dialogMode === "request" &&
                    <TrackRentalRequestDialog
                        {...this.props}
                        desc={this.state.desc}
                        soundLevel={this.state.soundLevel}
                        closeDialog={this.closeDialog}
                    />}
                {(this.state.dialogMode === "worksheet" && this.state.selectedRental) &&                     
                    <TrackRentalWorksheetDialog
                        {...this.props}
                        selectedRental={this.state.selectedRental}
                        closeDialog={this.closeDialog}
                    />}
                {/* {(this.state.dialogMode === "cancel" && this.state.selectedRental) &&
                    <>
                    <DialogTitle>Cancel Request</DialogTitle>
                    <DialogContent dividers>
                        <Typography>Are you sure you want to cancel request?</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Grid container spacing={1} className={classes.spacingButton}>
                            <Button onClick={this.closeDialog} variant="contained">Close</Button>
                            <Button onClick={this.closeDialog} color="secondary" variant="contained" >CANCEL</Button>
                        </Grid>
                    </DialogActions>
                    </>
                } */}
                {(this.state.dialogMode === "contact" && this.state.selectedRental) &&
                    <>
                    <DialogTitle>Contact Rental Staff</DialogTitle>
                    <DialogContent dividers>
                        <Typography>Please contact us at <a href="mailto:trackrentals@laguna-seca.com">trackrentals@laguna-seca.com </a> for questions and additional information.</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.closeDialog} variant="contained">Close</Button>
                    </DialogActions>
                </>
                }
            </Dialog>}
            </React.Fragment>
        )
    }
}

const styles = createStyles((theme: Theme) => ({
    paper: {
        padding: theme.spacing(3, 2),
        position: 'relative',
    },
    center:{
        textAlign: 'center',
    },
    submit: {
        backgroundColor: green[500],
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        color: 'white',
        '&:hover': {
            backgroundColor: green[700],
        }
    },
}))

export default withWidth()(withStyles(styles, { withTheme: true })(TrackRental));