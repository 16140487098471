import React, { Component, useState } from 'react';
import { createStyles, withStyles, Theme, ListItemText, ListItem, ListSubheader, List, Box, CircularProgress, IconButton, ListItemSecondaryAction, Typography, Divider, Collapse} from '@material-ui/core';
import AddBoxIcon from '@material-ui/icons/AddBox';
import InfoIcon from '@material-ui/icons/Info';
import Car from '../interfaces/ICar';
import authService from '../services/api-authorization/AuthorizeService';
import Entry from '../interfaces/IEntry';
import { grey } from '@material-ui/core/colors';
interface IHistoricalCarsProps {
    classes:any;
    populateCar:any;
    entry: Entry;
}
interface IHistoricalCarsState {
    carList: Car[];
    distinctYears: string[];
    loading: boolean;
}

const  CarItem = ({car, index, add} : {car: Car, index: number, add :any}) =>{
    const [open, setOpen] = useState(false);
    
    const handleClick = () => {
        setOpen(!open);
    };
    const lubeTypes = [{value: 'wet', label: 'Wet Sump'},
    {value: 'dry',label: 'Dry Sump'}];

    const brakeTypes = [{value: 'disc',label: 'Disc'},
    {value: 'drum',label: 'Drum'},
    {value: 'disc/drum',label: 'Disc/Drum'}];
    
    const brake : any | undefined = brakeTypes.find(i => i.value === car.brakeType);
    const lube : any | undefined = lubeTypes.find(i => i.value === car.lubricationSystem);

    return(<React.Fragment>
            <ListItem key={index}>
                <ListItemText primary={`${car.year} ${car.make} ${car.model}`} />
                <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="info" onClick={handleClick}>
                    <InfoIcon/> 
                    </IconButton>
                    <IconButton edge="end" aria-label="add" onClick={() => add(car)}>
                    <AddBoxIcon />
                    </IconButton>
                </ListItemSecondaryAction>
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <Typography variant="body2">
                <strong>Color: </strong>{car.color}<br></br>
                <strong>Car Number: </strong>{car.preferredCarNumber} <i>Alt:</i> {car.alternateCarNumber1} <i>Alt:</i> {car.alternateCarNumber2}<br></br>
                <strong>HP: </strong>{car.horsePower}<br></br>
                <strong>Displacement: </strong>{car.displacement}cc<br></br>
                <strong>Induction: </strong>{car.inductionType}<br></br>
                <strong>Chassis Number: </strong>{car.chassisNumber}<br></br>                
                <strong>Lubrication System: </strong>{(lube !== undefined) ? lube.label : "No System Selected"}<br></br>
                <strong>Brake Type: </strong>{(brake !== undefined) ? brake.label : "No Type Selected"}<br></br>
                <strong>Modifications: </strong>{(car.isModified) ? car.modificationReason : "None"}<br></br>
                <strong>Tires: </strong>{car.tireMake} {car.tireModel}<br></br>
                <strong>History: </strong>{car.historyOfCar}<br></br>  
                </Typography> 
            </Collapse>
        <Divider />
        </React.Fragment>);
}

export class HistoricalCars extends Component<IHistoricalCarsProps,IHistoricalCarsState> { 
    public constructor(props: Readonly<IHistoricalCarsProps>) {
        super(props);
        this.state = {
            carList: [],
            distinctYears: [],
            loading: false,
        }
    }

    private setLoading(loading : boolean){
        this.setState({loading});
    }

    private getHistoricalCars = async () => {
        this.setLoading(true);
            const token = await authService.getAccessToken();
            if(token != null){
            const headers = new Headers();
            headers.append("Accept", "application/json");
            headers.append("Content-Type", "application/json");
            headers.append( "Authorization", `Bearer ${token}` );
            await fetch(`/api/car/getCarsByEntrant/${this.props.entry.entrantId}`, {
            headers: headers,
            method: "GET"
        })
        .then(res => res.json())
        .then(data => {
            this.setState({carList:data.result});
            const years = this.state.carList.map(x => x.participatingYear);
            this.setState({distinctYears : ([...new Set(years)]).sort((a, b) => Number(b) - Number(a))});
            this.setLoading(false); 
        })
        .catch((error) => {
            console.error(error);
            this.setLoading(false);
            });
        }
    }

    public componentDidMount(){
        this.getHistoricalCars();
    }

    public render(): React.ReactElement {
        const {classes} = this.props;
        return(<React.Fragment>
        {(this.state.loading) ?
        <Box className={classes.center}>
        <CircularProgress color="secondary" /></Box> : (this.state.carList.length > 0) ?
        <List className={classes.root} subheader={<li />}>
            {this.state.distinctYears.map(participatingYear => (
                <li key={`section-${participatingYear}`} className={classes.listSection}>
                    <ul className={classes.ul}>
                        <ListSubheader className={classes.subheader} key={participatingYear}>{participatingYear}<Divider/></ListSubheader>
                        {this.state.carList.filter(car => car.participatingYear === participatingYear).map((car, index) => (
                            <CarItem
                                key={index}
                                car={car}
                                index={index}
                                add={this.props.populateCar}
                            />
                        ))}
                    </ul>
                </li>
            ))}
        </List> : <Typography variant="body1" className={classes.center}>You do not have any cars from past events.</Typography>}
        </React.Fragment>)
    }
}

const styles = createStyles((theme: Theme) => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        position: 'relative',
        overflow: 'auto',
    },
    listSection: {
        backgroundColor: "inherit",
    },
    ul: {
        backgroundColor: "inherit",
        padding: 0,
    },    
    center:{
        textAlign: 'center', 
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    subheader:{
        backgroundColor: grey[200],
    }
}))
    
export default withStyles(styles)(HistoricalCars);