import React, { Component } from 'react';
import { withStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Button, Box, Typography, Checkbox, FormControlLabel, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import { green, red } from '@material-ui/core/colors';
import APIService from '../services/APIService';
import RegistrationEvent from '../interfaces/IRegistrationEvent';

export interface IAddRegistrationProps {
    classes: any;
    imperial: boolean;
    handleCancel: any;
    setMessage: any;
    setLoading: any;
    participatingYear: string;
    registrationEvent: RegistrationEvent;
    loading: boolean;    
    getEntryForms: any;
    open:boolean;
}
export interface IAddRegistrationState {
    handleToggle: any;
    isInterestedInTop: boolean;
}

export class AddRegistration extends Component<IAddRegistrationProps, IAddRegistrationState> {
    private apiService: APIService = new APIService;
    public constructor(props: Readonly<IAddRegistrationProps>) {
        super(props);
        this.state = {
            handleToggle: this.handleToggle,
            isInterestedInTop: false
        }
        this.apiService = new APIService;
    }

    private handleSubmit = async () => {
        this.props.setLoading(true)
        await this.apiService.startEntry(this.state.isInterestedInTop, this.props.participatingYear, this.props.registrationEvent).then((result) => {
            if(result.ok){
                this.props.setMessage(this.props.participatingYear + " Entry Successfully Started.","success")
                this.props.setLoading(false);
                this.props.getEntryForms();
                this.props.handleCancel();
            }else{
                this.props.setMessage("Error completing operation. Please try again. (" + result.status + ")", "error");
                this.props.setLoading(false);
            }
        })
    }
    private handleToggle = () => {
        this.setState({ isInterestedInTop: !this.state.isInterestedInTop });
    }

    public render(): React.ReactElement {
        const { classes } = this.props;
        return (<Dialog
                    maxWidth={'lg'}
                    open={this.props.open}
                    aria-labelledby="dialog-title"
                    aria-describedby="dialog-description"
                    PaperProps={{className: classes.dialog}}
                >
                    <DialogTitle >Begin {this.props.participatingYear}  Reunion Entry</DialogTitle>
                    <DialogContent dividers className={classes.header}>
                        <Typography variant={'body1'}>
                        Click continue below to begin registration.
                        </Typography>
                        <FormControlLabel
                        control={
                        <Checkbox
                            checked={this.state.isInterestedInTop}
                            onChange={this.handleToggle}
                            value="isInterestedInTop"
                            inputProps={{
                                'aria-label': 'primary checkbox',
                            }} />
                        }
                        label={<Typography variant="subtitle1"> I am interested in a Track Orientation Program (TOP)</Typography>}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button className={classes.submit} onClick={this.handleSubmit} disabled={this.props.loading} variant="contained">
                            Continue
                        </Button>
                        <Button className={classes.cancel} onClick={this.props.handleCancel} variant="contained">
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>
        );
    }
}


const styles = createStyles((theme: Theme) => ({
    header: {
        textAlign: "center"
    },
    submit: {
        backgroundColor: green[500],
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        color: 'white',
        '&:hover': {
            backgroundColor: green[700],
        }
    },
    cancel: {
        backgroundColor: red[500],
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        color: 'white',
        '&:hover': {
            backgroundColor: red[700],
        }
    }
}))

export default withStyles(styles)(AddRegistration);