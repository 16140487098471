import React, { Component } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { withStyles, createStyles, Theme } from '@material-ui/core/styles';

export interface IUnitSelectorProps {
    handleChangeUnit: any;
    imperial: boolean;
    metricFactorSize: string;
    imperialFactorSize: string;
    classes:any;
    disabled?:boolean;
  }

export interface IUnitSelectorState {
}

export class UnitSelector extends Component<IUnitSelectorProps, IUnitSelectorState> {
    public constructor(props: Readonly<IUnitSelectorProps>) {
        super(props);
        this.state = {
        };
    }   

    public render() : React.ReactElement{
        const {classes} = this.props;
        return(                
        <FormControl className={classes.formControl }
            margin="normal">
            <InputLabel>Unit</InputLabel>
            <Select
            value={this.props.imperial ? 'imperial' : 'metric'}
            onChange={this.props.handleChangeUnit}
            className={classes.textField}
            disabled={this.props.disabled}
            inputProps={{
                name: 'units',
                id: 'units',
                tabIndex: -1 
            }}
            >
            <MenuItem value={"metric"}>{this.props.metricFactorSize}</MenuItem>
            <MenuItem value={"imperial"}>{this.props.imperialFactorSize}</MenuItem>
            </Select>
        </FormControl>)
    }
}

const styles = createStyles((theme: Theme) => ({
    textField: {
    },
  }))
  
  export default withStyles(styles)(UnitSelector);