import React, { Component } from 'react';
import { withStyles, createStyles, Theme } from '@material-ui/core/styles';
import APIService from '../services/APIService';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, withWidth} from '@material-ui/core';
import { green, red } from '@material-ui/core/colors';
import 'react-day-picker/lib/style.css';
import { DateUtils, DayModifiers } from 'react-day-picker';
import { TrackRentalRequestForm } from './TrackRentalRequestForm';
import ITrackSoundLevel from '../interfaces/ITrackSoundLevel';
import ITrackRental from '../interfaces/ITrackRental';
import moment from 'moment';

export interface ITrackRentalRequestDialogAdminProps {
    classes: any;
    width: any;
    setMessage: any;
    setLoading: any;
    loading: boolean;
    closeDialog: any;
    selectedRental?: ITrackRental;
}

export interface ITrackRentalRequestDialogAdminState {
    _isMounted: boolean;
    soundLevels: ITrackSoundLevel[];
    trackActivityDescription: string;
    startDate: Date;
    endDate: Date;
    trackSoundLevel: string;
    blackoutDates: Date[];
    comments : string;
    showCommentsDialog:boolean;
    dialogMode : string;
}

export class TrackRentalRequestDialogAdmin extends Component<ITrackRentalRequestDialogAdminProps, ITrackRentalRequestDialogAdminState> {
    static displayName = TrackRentalRequestDialogAdmin.name;
    private apiService: APIService = new APIService;
    public constructor(props: Readonly<ITrackRentalRequestDialogAdminProps>) {
        super(props);
        if(this.props.selectedRental){
            this.state = {
                _isMounted: false,
                soundLevels: [],
                trackSoundLevel: this.props.selectedRental.soundLevel,
                trackActivityDescription: this.props.selectedRental.description,
                startDate: new Date(this.props.selectedRental.startDate),
                endDate: new Date(this.props.selectedRental.endDate),
                blackoutDates : [],
                showCommentsDialog: false,
                comments: "",
                dialogMode: ""
            };
        }
        else{
            this.state = {
                _isMounted: false,
                soundLevels: [],
                trackSoundLevel: "",
                trackActivityDescription: "",
                startDate: new Date(),            
                endDate: new Date(),
                blackoutDates : [],
                showCommentsDialog: false,
                comments: "",
                dialogMode: ""
            };
        }
        this.apiService = new APIService;
        this.getSoundLevels = this.getSoundLevels.bind(this);
        this.handleDateClick = this.handleDateClick.bind(this);
        this.onReject = this.onReject.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.sendCorrespondence = this.sendCorrespondence.bind(this);
    }
    private openCommentsDialog = (dialogMode : string) => {
        this.setState({showCommentsDialog: true, dialogMode});
    }

    private closeDialog = () => {
        this.setState({showCommentsDialog: false, comments: "", dialogMode : "" });
    }

    private handleDateClick(date : Date, modifiers: DayModifiers){
        if(moment(this.state.startDate).format('MMMM Do YYYY') === moment(new Date()).format('MMMM Do YYYY')){
            this.setState({startDate : date, endDate : date});
        }
        else{
            const range = DateUtils.addDayToRange(date, {from: this.state.startDate, to: this.state.endDate});            
            if(!modifiers.disabled){
                this.setState({startDate : range.from, endDate : range.to});
            }
        }
    }
    private handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newState = { [event.target.name]: event.target.value } as any;
        this.setState(newState);
    };

    private getSoundLevels = async () => {
        await this.apiService.getSoundLevels().then((result : any) => {
            this.setState({ soundLevels: result });
        });
    }

    private submitRequest = async () => {
        this.props.setLoading(true);
        let selectedLevel = this.state.soundLevels.find( x => x.id === this.state.trackSoundLevel);
        if(selectedLevel !== undefined && this.props.selectedRental){
            await this.apiService.approveRentalRequest(this.props.selectedRental, this.state.comments).then((result : any) => {
                this.props.setLoading(false);
                //SucessMessage
            });
        }
    }

    private rejectRequest = async () => {
        this.props.setLoading(true);
        let selectedLevel = this.state.soundLevels.find( x => x.id === this.state.trackSoundLevel);
        if(selectedLevel !== undefined && this.props.selectedRental){
            await this.apiService.rejectRentalRequest(this.props.selectedRental, this.state.comments).then((result : any) => {
                this.props.setLoading(false);
                //SucessMessage
            });
        }
    }
    
    private sendEmail = async () => {
        this.props.setLoading(true);
        if(this.props.selectedRental){
            await this.apiService.sendCorrespondence(this.props.selectedRental, this.state.comments).then((result : any) => {
                this.props.setLoading(false);
            });
        }
    }

    private onReject(){
        if(this.state.startDate === null || this.state.startDate === undefined){
            this.props.setMessage("Start Date is Required", "error");
            return;
        }
        if(this.state.endDate  === null || this.state.endDate === undefined){
            this.props.setMessage("Start Date is Required", "error");
            return;
        }
        if(this.state.endDate < this.state.startDate){
            this.props.setMessage("End Date is Before Start Date", "error");
            return;
        }
        if (this.state.trackSoundLevel === "" || this.state.trackSoundLevel === undefined || this.state.trackSoundLevel === null){
            this.props.setMessage("Sound Level is Required", "error");
            return;
        }
        if (this.state.trackActivityDescription === ""){
            this.props.setMessage("Description is Required", "error");
            return;
        }
        this.rejectRequest().then(data => {
            this.props.closeDialog();
        });
    }

    private onSubmit(){
        if(this.state.startDate === null || this.state.startDate === undefined){
            this.props.setMessage("Start Date is Required", "error");
            return;
        }
        if(this.state.endDate  === null || this.state.endDate === undefined){
            this.props.setMessage("Start Date is Required", "error");
            return;
        }
        if(this.state.endDate < this.state.startDate){
            this.props.setMessage("End Date is Before Start Date", "error");
            return;
        }
        if (this.state.trackSoundLevel === "" || this.state.trackSoundLevel === undefined || this.state.trackSoundLevel === null){
            this.props.setMessage("Sound Level is Required", "error");
            return;
        }
        if (this.state.trackActivityDescription === ""){
            this.props.setMessage("Description is Required", "error");
            return;
        }
        this.submitRequest().then(data => {
            this.props.closeDialog();
        });
    }

    private sendCorrespondence(){
        this.sendEmail().then(data => {
            this.props.closeDialog();
        });
    }

    public componentDidMount() {        
        this.getSoundLevels().then(() => {
            if(this.props.selectedRental === undefined && this.state.soundLevels[0].id !== undefined){
                this.setState({_isMounted: true, trackSoundLevel : this.state.soundLevels[0].id });
            }
            if(this.props.selectedRental){
                this.setState({_isMounted: true, trackSoundLevel : this.props.selectedRental?.soundLevel});
            }
        });
    }

    public componentDidUpdate(prevProps: ITrackRentalRequestDialogAdminProps) {
    }

    public render(): React.ReactElement<ITrackRentalRequestDialogAdminProps> {
        const { classes, width } = this.props;
        return (
            <React.Fragment>
            {(this.state._isMounted && !this.props.loading) &&
            <>
                <DialogTitle>Track Rental Request </DialogTitle>
                <DialogContent dividers>
                    <TrackRentalRequestForm
                        {...this.state}                        
                        classes={this.props.classes}
                        handleDateClick={this.handleDateClick}
                        handleChange={this.handleChange}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.props.closeDialog} variant="contained">Cancel</Button>
                    <Button onClick={() => this.openCommentsDialog("email")} className={classes.commentsButton} variant="contained">Send Comments</Button>
                    <Button onClick={() => this.openCommentsDialog("reject")} className={classes.reject} variant="contained">Reject Request</Button>
                    <Button onClick={() => this.openCommentsDialog("approve")} className={classes.submit} variant="contained">Approve Request</Button>
                </DialogActions>
                {(this.state.showCommentsDialog) && 
                <Dialog
                    open={this.state.showCommentsDialog}
                    onClose={this.closeDialog}
                    maxWidth={"sm"}
                    fullWidth
                    fullScreen={width !== "lg" && width !== "xl" && width !== "md"}
                    scroll="paper">
                    <DialogTitle>Send Comments To User</DialogTitle>
                    <DialogContent dividers>
                        <TextField
                            name="comments"
                            multiline
                            rows={5}
                            className={classes.textField}
                            value={this.state.comments}
                            onChange={this.handleChange}
                            fullWidth
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.closeDialog} variant="contained">Cancel</Button>
                        {this.state.dialogMode === "reject" && <Button onClick={this.onReject} className={classes.reject} variant="contained">Reject Request</Button>}
                        {this.state.dialogMode === "approve" && <Button onClick={this.onSubmit} className={classes.submit} variant="contained">Approve Request</Button>}
                        {this.state.dialogMode === "email" && <Button onClick={this.sendCorrespondence} className={classes.submit} variant="contained">Email Comments</Button>}
                    </DialogActions>
                    
                </Dialog>}
            </>
            }
            </React.Fragment>
        )
    }
}

const styles = createStyles((theme: Theme) => ({
    paper: {
        padding: theme.spacing(3, 2),
        position: 'relative',
    },
    center:{
        textAlign: 'center',
    },
    submit: {
        backgroundColor: green[500],
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        color: 'white',
        '&:hover': {
            backgroundColor: green[700],
        }
    },
}))

export default withWidth()(withStyles(styles, { withTheme: true })(TrackRentalRequestDialogAdmin));
