import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import MenuItem from '@material-ui/core/MenuItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import InputAdornment from '@material-ui/core/InputAdornment';
import { withStyles, createStyles, Theme } from '@material-ui/core/styles';
import CarImageUpload from './CarImageUpload';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import UnitSelector from './UnitSelector';
import AutoSuggest from './AutoSuggest';
import {CarMakes} from '../lists/CarMakeList';
import grey from '@material-ui/core/colors/grey';
import { IconButton, Button, Box, FormGroup, FormLabel, FormControl, Checkbox, FormHelperText } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import Class from '../interfaces/IClass';
import Race from '../interfaces/IRace';

export interface ICarFormProps {
    handleChange:any;
    handleToggle:any;
    handleChangeUnit:any;
    handleAutoSuggestChange: any;
    handleImageUpload: any;
    deleteImage: any;
    selectedRaces: Race[];
    raceOptions: Race[];
    carYear?: number;
    carMake: string;
    carModel: string;
    carChassis: string;
    carClass:any;
    carColor:string;
    transponderRental: boolean;
    transponderNumber?: number;
    displacement?: number;
    horsepower?: number;
    induction: string;
    lubeSys: string;
    brakeSys: string;
    originalBrakes: boolean;
    modded: boolean;
    modifications: string;
    tireMake: string;
    tireModel: string;
    tireFrontSize: string;
    tireRearSize: string;
    wheelFrontWidth: number | null;
    wheelRearWidth: number | null;
    wheelFrontDia: number | null;
    wheelRearDia: number | null;
    prefCarNum?: string;
    altCarNum?: string;
    altCarNum2?: string ;
    carHistory: string;
    imperial:boolean;
    classes:any;    
    carImage: File | null;
    validationAttempt: boolean;
    ClassesList: Class[];
    tshirtSize:string;
    handleClassSelect: any;
    handleRaceSelect:any;
    addHistoricalCar: any;
    editMode:boolean;
    setMessage:any;
    isAdmin:boolean
    upcomingYear: string | undefined;
}

export interface ICarFormState {
}

export class CarForm extends Component<ICarFormProps, ICarFormState> {
    public constructor(props: Readonly<ICarFormProps>) {
        super(props);
        this.state = {
        };
        
    }   
    private lubeTypes = [{value: 'wet', label: 'Wet Sump'},
    {value: 'dry',label: 'Dry Sump'}];

    // private raceTypes = [{value: 'Prereunion',label: 'Pre-Reunion Only $725.00'},
    //     {value: 'Reunion',label: 'Reunion Only $975.00',},
    //     {value: 'Prereunion/Reunion',label: 'Pre-Reunion & Reunion  $1700.00'}];

    private brakeTypes = [{value: 'disc',label: 'Disc'},
        {value: 'drum',label: 'Drum'},
        {value: 'disc/drum',label: 'Disc/Drum'}];

    private shirtSizes = [{value: 'XS',label: 'XS'},
        {value: 'S',label: 'S'},
        {value: 'M',label: 'M'},
        {value: 'L',label: 'L'},
        {value: 'XL',label: 'XL'},
        {value: '2X',label: 'XXL'}];
    public render() : React.ReactElement{
        const {classes} = this.props;
        const imageName = (this.props.carImage !== null && this.props.carImage !== undefined) ? this.props.carImage.toString().match(/[^/|\]]*$/g) : null;
        return(
                <Grid container spacing={3}>
                    {!this.props.editMode &&<Grid item xs={12} className={classes.center}>  
                        <Button className={classes.button}  variant="contained" onClick={this.props.addHistoricalCar}>
                            Add Car From Past/Current Event
                        </Button>
                    </Grid> }
                    <Grid item xs={12} >    
                        <Typography className={classes.subheading}><b>Race Information</b></Typography>
                            <Divider />  
                    </Grid> 
                    <Grid item xs={12} className={classes.center}>
                    <FormControl required error={this.props.validationAttempt && this.props.selectedRaces.length === 0} component="fieldset" className={classes.formControl}>
                        <FormLabel component="legend">Participating Races</FormLabel>
                        <FormGroup row>
                        {this.props.raceOptions.map((value, index) => {
                            var label : string = value.name + " - $" + value.racePrice;
                            (value.discountRacePrice !== null) ? label += ` for first entry, $${value.discountRacePrice} for additional entries` : label += "";                            
                            return(
                            <FormControlLabel key={index}
                                control={<Checkbox checked={this.props.selectedRaces.some(x => x.id === value.id)} onChange={() => this.props.handleRaceSelect(value)} value={value} />}
                                label={label} 
                            />)
                            }
                        )}
                        </FormGroup>
                    </FormControl>
                    </Grid>
                    <Grid container spacing={1} style={{marginLeft: '15%', marginRight: '15%'}}>
                        <Grid item xs={12} className={classes.center}>
                            <b>{this.props.upcomingYear} Run Groups</b>
                        </Grid>  
                        {this.props.ClassesList.map((option, index) => (
                            option.name.toLowerCase() !== 'unsure' && <Grid item md={6} xs={12}>{option.name}</Grid>
                        ))}
                    </Grid>
                    <Grid item xs={12} md={4} >
                    <TextField
                        name="tshirtSize"
                        required
                        select
                        fullWidth
                        label="Shirt Size"
                        className={classes.textField}
                        value={(this.props.tshirtSize === null) ? "" : this.props.tshirtSize}
                        error={(this.props.tshirtSize === null || this.props.tshirtSize === "") && this.props.validationAttempt}
                        helperText={(this.props.validationAttempt && this.props.tshirtSize === "") ? "Required Field" : ""}
                        onChange={this.props.handleChange}
                        SelectProps={{
                        MenuProps: {
                            className: classes.menu,
                        },
                        }}
                        margin="normal"
                        >   
                        {this.shirtSizes.map(option => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                    </Grid>   
                    <Grid item xs={12} >    
                        <Typography className={classes.subheading}><b>Details</b></Typography>
                            <Divider />  
                    </Grid>              
                    <Grid item xs={12} md={3}>             
                        <TextField
                            name="carYear"
                            required
                            label="Year"
                            type="number"
                            className={classes.textField}
                            value={(this.props.carYear === null) ? "" : this.props.carYear}
                            error={(this.props.carYear === null || this.props.carYear === undefined || this.props.carYear > (new Date().getFullYear()+1) || this.props.carYear < 1900 ) && this.props.validationAttempt}
                            helperText={(this.props.carYear !== undefined && (this.props.carYear > (new Date().getFullYear()+1) || this.props.carYear < 1900)  && this.props.validationAttempt) ? "Not a valid year." : 
                            (this.props.validationAttempt && this.props.carYear === null) ? "Required Field" : ""}
                            onChange={this.props.handleChange}
                            margin="normal"
                            fullWidth
                            inputProps={{min:"1900", max: (new Date().getFullYear()+1), 
                            maxLength: 4}}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>              
                        <AutoSuggest
                            options={CarMakes}
                            label="Make"
                            required
                            name="carMake"
                            onChange={this.props.handleAutoSuggestChange}
                            value={(this.props.carMake === null) ? "" : this.props.carMake}
                            error={(this.props.carMake === null || this.props.carMake === "") && this.props.validationAttempt}
                            helperText={(this.props.validationAttempt && this.props.carMake === "") ? "Required Field" : ""}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>              
                    <TextField
                            name="carModel"
                            required
                            label="Model"
                            className={classes.textField}
                            value={(this.props.carModel === null) ? "" : this.props.carModel}
                            error={(this.props.carModel === null || this.props.carModel === "") && this.props.validationAttempt}
                            helperText={(this.props.validationAttempt && this.props.carModel === "") ? "Required Field" : ""}
                            onChange={this.props.handleChange}
                            margin="normal"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} md={3} className={classes.center}> 
                        <CarImageUpload
                            carImage={this.props.carImage}
                            handleImageUpload={this.props.handleImageUpload}
                            setMessage={this.props.setMessage}
                        />                            
                    {(this.props.carImage !== null && this.props.carImage !== undefined) ?
                    <React.Fragment>
                    <Typography>Uploaded: {(imageName !== null && imageName[0] !== "") ? imageName[0] : this.props.carImage.name}</Typography>
                    <Box className={classes.center}>
                    <br/>
                    {(this.props.carImage.type !== "image/jpeg") && 
                    <IconButton aria-label="download" href={this.props.carImage.toString()} download>
                        <GetAppIcon/>
                    </IconButton>}
                    <IconButton aria-label="delete" onClick={this.props.deleteImage}>
                        <DeleteForeverIcon/>
                    </IconButton>
                    </Box>
                    </React.Fragment>
                    : <Typography>No File Uploaded</Typography>}
                    <br></br>                  
                    <Typography variant="subtitle2">Image Requirements: High resolution image (~300dpi) of the car with a clean, simple background. 500kb or greater.</Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>              
                        <TextField
                            name="carChassis"
                            required
                            label="Chassis Number"
                            className={classes.textField}
                            value={(this.props.carChassis === null) ? "" : this.props.carChassis}
                            error={(this.props.carChassis === null || this.props.carChassis === "") && this.props.validationAttempt}
                            helperText={(this.props.validationAttempt && this.props.carChassis === "") ? "Required Field" : ""}
                            onChange={this.props.handleChange}
                            margin="normal"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            name="carClass"
                            select
                            label="Class"
                            className={classes.textField}
                            value={(this.props.carClass === null || this.props.carClass === "") ? "" : (this.props.ClassesList.filter(option => option.name === this.props.carClass)[0] !== undefined) ? this.props.ClassesList.filter(option => option.name === this.props.carClass)[0].id : ""}
                            helperText={(this.props.validationAttempt && this.props.carClass === "") ? "Required Field" : ""}
                            onChange={this.props.handleClassSelect}
                            SelectProps={{
                                MenuProps: {
                                    className: classes.menu,
                                },
                            }}
                            margin="normal"
                            fullWidth
                            >
                            {this.props.ClassesList.map((option, index) => (
                                <MenuItem key={index} value={option.id}>
                                    {option.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} md={6}>              
                        <TextField
                            name="carColor"
                            required
                            label="Color"
                            className={classes.textField}
                            value={(this.props.carColor === null) ? "" : this.props.carColor}
                            error={(this.props.carColor === null || this.props.carColor === "") && this.props.validationAttempt}
                            helperText={(this.props.validationAttempt && this.props.carColor === "") ? "Required Field" : ""}
                            onChange={this.props.handleChange}
                            margin="normal"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControlLabel 
                            className={classes.formSwitchLabel}
                            control={
                                <Switch
                                    name="transponderRental"
                                    checked={this.props.transponderRental}
                                    value={(this.props.transponderRental === null) ? false : this.props.transponderRental}
                                    color="secondary"
                                    onChange={this.props.handleToggle}
                                />
                            }
                            label="Transponder Rental Required"
                        />
                    </Grid>
                    {!this.props.transponderRental &&
                    <Grid item xs={12} md={4}>              
                        <TextField
                            name="transponderNumber"
                            label="Transponder Number"
                            className={classes.textField}
                            value={(this.props.transponderNumber === null) ? "" : this.props.transponderNumber}
                            onChange={this.props.handleChange}
                            margin="normal"
                        />
                    </Grid>
                    }
                    <Grid item xs={12}>    
                        <Typography className={classes.subheading}><b>Specifications</b></Typography>
                         <Divider />  
                    </Grid>   
                    <Grid item xs={12} md={3}>              
                        <TextField
                            name="displacement"
                            type="number"
                            required
                            label="Displacement"
                            className={classes.textField}
                            inputProps={{
                                min:0
                            }}
                            value={(this.props.displacement === null) ? "" : this.props.displacement}
                            error={(this.props.displacement === null || this.props.displacement === undefined || isNaN(this.props.displacement) || this.props.displacement as any === "") && this.props.validationAttempt}
                            helperText={(this.props.validationAttempt && this.props.displacement === null) ? "Required Field" : 
                            (this.props.validationAttempt && this.props.displacement !== undefined && this.props.displacement !== null  && (isNaN(this.props.displacement) || this.props.displacement as any === "")) ? "Must be a number." :  ""}
                            onChange={this.props.handleChange}
                            margin="normal"
                            InputProps={{
                                endAdornment:
                                    <InputAdornment position="end">cc</InputAdornment>
                                }}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TextField
                            name="horsepower"
                            type="number"
                            required
                            label="Horsepower"
                            className={classes.textField}
                            inputProps={{
                                min:0
                            }}
                            value={(this.props.horsepower === null) ? "" : this.props.horsepower}
                            error={(this.props.horsepower === null || this.props.horsepower === undefined || isNaN(this.props.horsepower) || this.props.horsepower as any === "") && this.props.validationAttempt}
                            helperText={(this.props.validationAttempt && this.props.horsepower === null) ? "Required Field" : 
                            (this.props.validationAttempt && this.props.horsepower !== undefined && this.props.horsepower !== null  && (isNaN(this.props.horsepower) || this.props.horsepower as any === "")) ? "Must be a number." :  ""}
                            onChange={this.props.handleChange}
                            margin="normal"
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>              
                        <TextField
                            name="induction"
                            required
                            label="Induction Type"
                            className={classes.textField}
                            value={(this.props.induction === null) ? "" : this.props.induction}
                            error={(this.props.induction === null || this.props.induction === "") && this.props.validationAttempt}
                            helperText={(this.props.validationAttempt && this.props.induction === "") ? "Required Field" : ""}
                            onChange={this.props.handleChange}
                            margin="normal"        
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>                       
                        <TextField
                            name="lubeSys"
                            select
                            required
                            fullWidth
                            label="Lubrication System"
                            className={classes.textField}
                            value={(this.props.lubeSys === null) ? "" : this.props.lubeSys}
                            error={(this.props.lubeSys === null || this.props.lubeSys === "") && this.props.validationAttempt}
                            helperText={(this.props.validationAttempt && this.props.lubeSys === "") ? "Required Field" : ""}
                            onChange={this.props.handleChange}
                            SelectProps={{
                            MenuProps: {
                                className: classes.menu,
                            },
                            }}
                            // helperText="Wet or Dry"
                            margin="normal"
                            >   
                            {this.lubeTypes.map(option => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} md={4}>   
                        <TextField
                            name="brakeSys"
                            required
                            select
                            fullWidth
                            label="Brake System"
                            className={classes.textField}
                            value={(this.props.brakeSys === null) ? "" : this.props.brakeSys}
                            error={(this.props.brakeSys === null || this.props.brakeSys === "") && this.props.validationAttempt}
                            helperText={(this.props.validationAttempt && this.props.brakeSys === "") ? "Required Field" : ""}
                            onChange={this.props.handleChange}
                            SelectProps={{
								MenuProps: {
									className: classes.menu,
								},
                            }}
                            // helperText="Disc, Drum, Disc/Drum"
                            margin="normal"
                            >   
                            {this.brakeTypes.map(option => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={4}>
                        <FormControlLabel 
                            className={classes.formSwitchLabel}
                            control={
                                <Switch
                                    name="originalBrakes"
                                    checked={this.props.originalBrakes}
                                    value={(this.props.originalBrakes === null) ? false : this.props.originalBrakes}
                                    color="secondary"
                                    onChange={this.props.handleToggle}
                                />
                            }
                            label="Original Brakes"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel 
                            className={classes.formSwitchLabel}
                            control={
                                <Switch
                                    name="modded"
                                    checked={this.props.modded}
                                    value={(this.props.modded === null) ? false : this.props.modded}
                                    color="secondary"
                                    onChange={this.props.handleToggle}
                                />
                            }
                            label="Modified from Original"
                        />
                    </Grid>                    
                    {this.props.modded &&
                    <Grid item xs={12}>
                        <TextField
                            name="modifications"
                            label="Modifications"
                            required
                            multiline
                            rowsMax="5"
                            className={classes.textField}
                            value={(this.props.modifications === null) ? "" : this.props.modifications}
                            error={(this.props.modifications === null || this.props.modifications === "") && this.props.validationAttempt && this.props.modded}
                            helperText={(this.props.validationAttempt && this.props.modifications === "") ? "Required Field" : ""}
                            onChange={this.props.handleChange}
                            margin="normal"
                            fullWidth
                        />
                    </Grid>}
                    <Grid item xs={12}>    
                        <Typography className={classes.subheading}><b>Tire &amp; Wheel Details</b></Typography>
                         <Divider />  
                    </Grid> 
                    <Grid item xs={12} md={3}>              
                        <TextField
                            name="tireMake"
                            required
                            label="Tire Make"
                            className={classes.textField}
                            value={(this.props.tireMake === null) ? "" : this.props.tireMake}
                            error={(this.props.tireMake === null || this.props.tireMake === "") && this.props.validationAttempt}
                            helperText={(this.props.validationAttempt && this.props.tireMake === "") ? "Required Field" : ""}
                            onChange={this.props.handleChange}
                            margin="normal"
                        />
                    </Grid>
                    <Grid item  xs={12} md={3}>              
                        <TextField
                            name="tireModel"
                            required
                            label="Tire Model"
                            className={classes.textField}
                            value={(this.props.tireModel === null) ? "" : this.props.tireModel}
                            error={(this.props.tireModel === null || this.props.tireModel === "") && this.props.validationAttempt}
                            helperText={(this.props.validationAttempt && this.props.tireModel === "") ? "Required Field" : ""}
                            onChange={this.props.handleChange}
                            margin="normal"
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>              
                        <TextField
                            name="tireFrontSize"
                            required
                            label="Front Tire Size"
                            className={classes.textField}
                            value={(this.props.tireFrontSize === null) ? "" : this.props.tireFrontSize}
                            error={(this.props.tireFrontSize === null || this.props.tireFrontSize === "") && this.props.validationAttempt}
                            helperText={(this.props.validationAttempt && this.props.tireFrontSize === null && this.props.tireFrontSize === "") ? "Required Field" : ""}
                            onChange={this.props.handleChange}
                            margin="normal"
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>              
                        <TextField
                            name="tireRearSize"
                            required
                            label="Rear Tire Size"
                            className={classes.textField}
                            value={(this.props.tireRearSize === null) ? "" : this.props.tireRearSize}
                            error={(this.props.tireRearSize === null || this.props.tireRearSize === "") && this.props.validationAttempt}
                            helperText={(this.props.validationAttempt && this.props.tireRearSize === null && this.props.tireRearSize === "") ? "Required Field" : ""}
                            onChange={this.props.handleChange}
                            margin="normal"
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>              
                        <TextField
                            name="wheelFrontWidth"    
                            type="number"   
                            required                     
                            label="Front Wheel Width"
                            className={classes.textField}
                            inputProps={{
                                min:0
                            }}
                            value={(this.props.wheelFrontWidth === null) ? "" : this.props.wheelFrontWidth}
                            error={(this.props.wheelFrontWidth === null || this.props.wheelFrontWidth === undefined || isNaN(this.props.wheelFrontWidth) || this.props.wheelFrontWidth as any === "") && this.props.validationAttempt}
                            helperText={(this.props.validationAttempt && this.props.wheelFrontWidth === null) ? "Required Field" : 
                            (this.props.validationAttempt && this.props.wheelFrontWidth !== undefined && this.props.wheelFrontWidth !== null  && (isNaN(this.props.wheelFrontWidth) || this.props.wheelFrontWidth as any === "")) ? "Must be a number." :  ""}
                            onChange={this.props.handleChange}
                            margin="normal"
                        />
                        <UnitSelector handleChangeUnit={this.props.handleChangeUnit} imperial={this.props.imperial} metricFactorSize="mm" imperialFactorSize="in"/>
                    </Grid>
                    <Grid item xs={12} md={3}>              
                        <TextField
                            name="wheelRearWidth"
                            type="number"
                            required
                            label="Rear Wheel Width"
                            className={classes.textField}
                            inputProps={{
                                min:0
                            }}
                            value={(this.props.wheelRearWidth === null) ? "" : this.props.wheelRearWidth}
                            error={(this.props.wheelRearWidth === null || this.props.wheelRearWidth === undefined || isNaN(this.props.wheelRearWidth) || this.props.wheelRearWidth as any === "") && this.props.validationAttempt}
                            helperText={(this.props.validationAttempt && this.props.wheelRearWidth === null) ? "Required Field" : 
                            (this.props.validationAttempt && this.props.wheelRearWidth !== undefined && this.props.wheelRearWidth !== null  && (isNaN(this.props.wheelRearWidth) || this.props.wheelRearWidth as any === "")) ? "Must be a number." :  ""}
                            onChange={this.props.handleChange}
                            margin="normal"
                        />
                        <UnitSelector handleChangeUnit={this.props.handleChangeUnit} imperial={this.props.imperial} metricFactorSize="mm" imperialFactorSize="in"/>
                    </Grid>
                    <Grid item xs={12} md={3}>              
                        <TextField
                            name="wheelFrontDia"
                            type="number"
                            required
                            label="Front Wheel Diameter"
                            className={classes.textField}
                            inputProps={{
                                min:0
                            }}
                            value={(this.props.wheelFrontDia === null) ? "" : this.props.wheelFrontDia}
                            error={(this.props.wheelFrontDia === null || this.props.wheelFrontDia === undefined || isNaN(this.props.wheelFrontDia) || this.props.wheelFrontDia as any === "") && this.props.validationAttempt}
                            helperText={(this.props.validationAttempt && this.props.wheelFrontDia === null) ? "Required Field" : 
                            (this.props.validationAttempt && this.props.wheelFrontDia !== undefined && this.props.wheelFrontDia !== null  && (isNaN(this.props.wheelFrontDia) || this.props.wheelFrontDia as any === "")) ? "Must be a number." :  ""}
                            onChange={this.props.handleChange}
                            margin="normal"
                        />
                        <UnitSelector handleChangeUnit={this.props.handleChangeUnit} imperial={this.props.imperial} metricFactorSize="mm" imperialFactorSize="in"/>
                    </Grid>
                    <Grid item xs={12} md={3}>              
                        <TextField
                            name="wheelRearDia"
                            type="number"
                            required
                            label="Rear Wheel Diameter"
                            className={classes.textField}
                            inputProps={{
                                min:0
                            }}
                            value={(this.props.wheelRearDia === null) ? "" : this.props.wheelRearDia}
                            error={(this.props.wheelRearDia === null || this.props.wheelRearDia === undefined || isNaN(this.props.wheelRearDia) || this.props.wheelRearDia as any === "") && this.props.validationAttempt}
                            helperText={(this.props.validationAttempt && this.props.wheelRearDia === null) ? "Required Field" : 
                            (this.props.validationAttempt && this.props.wheelRearDia !== undefined && this.props.wheelRearDia !== null  && (isNaN(this.props.wheelRearDia) || this.props.wheelRearDia as any === "")) ? "Must be a number." :  ""}
                            onChange={this.props.handleChange}
                            margin="normal"
                        />
                        <UnitSelector handleChangeUnit={this.props.handleChangeUnit} imperial={this.props.imperial} metricFactorSize="mm" imperialFactorSize="in"/>
                    </Grid>
                    <Grid item xs={12}>    
                        <Typography className={classes.subheading}><b>Miscellaneous</b></Typography>
                         <Divider />  
                    </Grid> 
                    <Grid item xs={12} md={4}>              
                        <TextField
                            inputProps={{
                                maxLength: 4
                                }
                            }
                            name="prefCarNum"
                            required
                            label="Preferred Car Number"
                            className={classes.textField}
                            value={(this.props.prefCarNum === null) ? "" : this.props.prefCarNum}
                            error={(this.props.prefCarNum === null || this.props.prefCarNum === "") && this.props.validationAttempt}
                            helperText={(this.props.validationAttempt && this.props.prefCarNum === "") ? "Required Field" : ""}
                            onChange={this.props.handleChange}
                            margin="normal"
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>              
                        <TextField
                            inputProps={{
                                maxLength: 4
                                }
                            }
                            name="altCarNum"
                            required
                            label="Alternate Car Number"
                            className={classes.textField}
                            value={(this.props.altCarNum === null) ? "" : this.props.altCarNum}
                            error={(this.props.altCarNum === null || this.props.altCarNum === "") && this.props.validationAttempt}
                            helperText={(this.props.validationAttempt && this.props.altCarNum === "") ? "Required Field" : ""}
                            onChange={this.props.handleChange}
                            margin="normal"
                        />
                    </Grid> 
                    <Grid item xs={12} md={4}>              
                        <TextField
                            inputProps={{
                                maxLength: 4
                                }
                            }
                            name="altCarNum2"
                            required
                            label="Alternate Car Number"
                            className={classes.textField}
                            value={(this.props.altCarNum2 === null) ? "" : this.props.altCarNum2}
                            error={(this.props.altCarNum2 === null || this.props.altCarNum2 === "") && this.props.validationAttempt}
                            helperText={(this.props.validationAttempt && this.props.altCarNum2 === "") ? "Required Field" : ""}
                            onChange={this.props.handleChange}
                            margin="normal"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            name="carHistory"
                            required
                            label="Car History"
                            multiline
                            rowsMax="10"
                            className={classes.textField}
                            value={(this.props.carHistory === null) ? "" : this.props.carHistory}
                            error={(this.props.carHistory === null || this.props.carHistory === "") && this.props.validationAttempt}
                            helperText={(this.props.validationAttempt && this.props.carHistory === "") ? "Required Field" : ""}
                            onChange={this.props.handleChange}
                            margin="normal"
                            fullWidth
                        />
                    </Grid>
                </Grid>
        );
    }
}

const styles = createStyles((theme: Theme) => ({
    button: {
      margin: theme.spacing(1),
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1)
    },
    paper:{    
      padding: theme.spacing(2, 2),
      marginLeft: '25%',
      marginRight: '25%',
    },  
    center:{
        textAlign: 'center', 
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    root: {
      width: '90%',
    },
    backButton: {
      marginRight: theme.spacing(1),
    },
    completed: {
      display: 'inline-block',
    },
    wizardControl:{
        marginTop: 15,
        textAlign: 'center', 
    },
    regStep:{
        textAlign: 'center', 
    },
    formSwitchLabel:{
        color:grey,
        marginTop: theme.spacing(2),
}
  }))
  
  export default withStyles(styles)(CarForm);