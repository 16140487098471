import React, { Component } from 'react';
import { withStyles, createStyles, Theme } from '@material-ui/core/styles';
import APIService from '../services/APIService';
import { green } from '@material-ui/core/colors';
import { Grid, Link, MenuItem, TextField, Typography } from '@material-ui/core';
import DayPicker, { DayModifiers } from 'react-day-picker';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import ITrackSoundLevel from '../interfaces/ITrackSoundLevel';
import moment from 'moment';

export interface ITrackRentalRequestFormProps {
    classes: any;
    soundLevels: ITrackSoundLevel[];
    trackActivityDescription: string;
    startDate: Date;
    endDate: Date;
    trackSoundLevel: string;
    handleDateClick: any;
    handleChange: any
    blackoutDates: Date[];
}

export interface ITrackRentalRequestFormState {
    _isMounted: boolean;
}

export class TrackRentalRequestForm extends Component<ITrackRentalRequestFormProps, ITrackRentalRequestFormState> {
    static displayName = TrackRentalRequestForm.name;
    private apiService: APIService = new APIService;
    public constructor(props: Readonly<ITrackRentalRequestFormProps>) {
        super(props);
        this.state = {
            _isMounted: false
        };
        this.apiService = new APIService;
    }

    public componentDidMount() {

    }

    public render(): React.ReactElement<ITrackRentalRequestFormProps> {
        const { classes } = this.props;
        let baseDates = [{ before: new Date()}, new Date()]
        let disabledDates = baseDates.concat(this.props.blackoutDates);
        const from = this.props.startDate;
        const to = this.props.endDate;
        const modifiers = { start: from, end: to };
        return (
            <Grid container spacing={2} justify="center">
                <Grid item xs={12} className={classes.center}>
                    <DayPicker 
                        className="Selectable"
                        modifiers={modifiers}
                        onDayClick={this.props.handleDateClick}
                        selectedDays={[from, { from, to }]}
                        disabledDays={disabledDates}
                        numberOfMonths={2}
                    />
                    <style>{`
                        .Selectable .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
                        background-color: #f0f8ff !important;
                        color: #4a90e2;
                        }
                        .Selectable .DayPicker-Day {
                        border-radius: 0 !important;
                        }
                        .Selectable .DayPicker-Day--start {
                        border-top-left-radius: 50% !important;
                        border-bottom-left-radius: 50% !important;
                        }
                        .Selectable .DayPicker-Day--end {
                        border-top-right-radius: 50% !important;
                        border-bottom-right-radius: 50% !important;
                        }
                    `}</style>
                </Grid>
                <Grid item xs={12} className={classes.center}>
                    {moment(this.props.startDate).format('MMMM Do YYYY') === moment(new Date()).format('MMMM Do YYYY') || moment(this.props.startDate).format('MMMM Do YYYY') === "Invalid date" ? <Typography>Select Start Date</Typography> : 
                    moment(this.props.endDate).format('MMMM Do YYYY') === moment(new Date()).format('MMMM Do YYYY') || moment(this.props.endDate).format('MMMM Do YYYY') === "Invalid date" ? <Typography>Select End Date</Typography> : 
                    <Typography>{moment(this.props.startDate).format('MMMM Do YYYY')} - {moment(this.props.endDate).format('MMMM Do YYYY')}</Typography>}
                </Grid>
                <Grid container item xs={12}>
                    <Grid item xs={12}>
                        <TextField
                            name="trackActivityDescription"
                            label="Track Activity Description"
                            required
                            multiline
                            rowsMax="5"
                            className={classes.textField}
                            value={(this.props.trackActivityDescription === null) ? "" : this.props.trackActivityDescription}
                            // error={(this.props.trackActivityDescription === null || this.props.trackActivityDescription === "") && this.props.validationAttempt && this.props.modded}
                            // helperText={(this.props.validationAttempt && this.props.trackActivityDescription === "") ? "Required Field" : ""}
                            onChange={this.props.handleChange}
                            margin="normal"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            name="trackSoundLevel"
                            required
                            select
                            fullWidth
                            label="Track Sound Level"
                            className={classes.textField}
                            value={(this.props.trackSoundLevel === null) ? "" : this.props.trackSoundLevel}
                            // error={(this.props.tshirtSize === null || this.props.tshirtSize === "") && this.props.validationAttempt}
                            // helperText={(this.props.validationAttempt && this.props.tshirtSize === "") ? "Required Field" : ""}
                            onChange={this.props.handleChange}
                            SelectProps={{
                            MenuProps: {
                                className: classes.menu,
                            },
                            }}
                            margin="normal"
                            >   
                            {this.props.soundLevels.map((option : ITrackSoundLevel) => (
                                <MenuItem key={option.id} value={option.id}>
                                    {option.name + " (Est Price: $" + option.price + ")"}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                </Grid>
                <Grid item xs={12} className={classes.center}>
                    <Link href="https://www.co.monterey.ca.us/Home/ShowDocument?id=98744" target="_new">View Track Rental Information Packet<OpenInNewIcon fontSize="small"/></Link>
                </Grid>
            </Grid>
        )
    }
}

const styles = createStyles((theme: Theme) => ({
    paper: {
        padding: theme.spacing(3, 2),
        position: 'relative',
    },
    center:{
        textAlign: 'center',
    },
    submit: {
        backgroundColor: green[500],
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        color: 'white',
        '&:hover': {
            backgroundColor: green[700],
        }
    },
}))

export default withStyles(styles)(TrackRentalRequestForm)