import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { countries } from '../lists/CountryList';
import { withStyles, createStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Box, Paper, Checkbox, MenuItem } from '@material-ui/core';
import authService from '../services/api-authorization/AuthorizeService';
import { green } from '@material-ui/core/colors';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { PhoneNumberUtil, PhoneNumber } from 'google-libphonenumber';
import APIService from '../services/APIService';
// @ts-ignore
import MuiPhoneNumber from "material-ui-phone-number";
import Entrant from '../interfaces/IEntrant';
import { Redirect } from 'react-router-dom';

export interface IEntrantFormProps {
    setMessage: any;
    classes: any;
    setLoading: any;
    loading: boolean;
}

export interface IEntrantFormState {
    entrantFName: string;
    entrantLName: string;
    entrantMName: string;
    entrantPhone: string;
    entrantDOB: Date | null;
    entrantAdd1: string;
    entrantAdd2: string;
    entrantAdd3: string;
    entrantCity: string;
    entrantState: string;
    entrantCountry: string;
    entrantPostal: string;
    setMessage: any;
    validationAttempt: boolean;
    dateValid: boolean;
    acceptedPolicy: boolean;
    newUser: boolean;
    redirect: boolean;
    entrantBusinessName : string;
}

export class EntrantForm extends Component<IEntrantFormProps, IEntrantFormState> {
    static displayName = EntrantForm.name;
    private apiService: APIService = new APIService;
    public constructor(props: Readonly<IEntrantFormProps>) {
        super(props);
        this.state = {
            entrantFName: "",
            entrantLName: "",
            entrantMName: "",
            entrantPhone: "",
            entrantDOB: null,
            entrantAdd1: "",
            entrantAdd2: "",
            entrantAdd3: "",
            entrantCity: "",
            entrantState: "",
            entrantCountry: "",
            entrantPostal: "",
            entrantBusinessName: "",
            setMessage: this.props.setMessage,
            validationAttempt: false,
            dateValid: true,
            acceptedPolicy: false,
            newUser: false,
            redirect: false
        };
        this.apiService = new APIService;
    }

    public validateDate(date: Date | null): boolean {
        this.setState({ dateValid: true })
        if (date === null || date > new Date()) {
            this.setState({ dateValid: false })
        }
        return this.state.dateValid;
    }

    private handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newState = { [event.target.name]: event.target.value } as any;
        this.setState(newState);
    }
    private handleDateChange = (date: Date | null, field: string) => {
        const newState = { [field]: date } as any;
        this.setState(newState);
    }
    private handleCheck = () => {
        this.setState({ acceptedPolicy: !this.state.acceptedPolicy });
    }
    private handlePhoneChange = (phone: string) => {
        this.setState({ entrantPhone: phone });
    }
    private handleSubmit = async () => {
        this.setState({ validationAttempt: true });
        try {
            // const phoneUtil: PhoneNumberUtil = PhoneNumberUtil.getInstance();
            // const phoneNumberProto: PhoneNumber = phoneUtil.parse(this.state.entrantPhone);

            if ((this.state.entrantFName === "" || this.state.entrantFName === null) || (this.state.entrantLName === "" || this.state.entrantLName === null) ||
                (this.state.entrantPhone === "" || this.state.entrantPhone === null) || (this.state.entrantDOB === null) || (this.state.entrantAdd1 === "" || this.state.entrantAdd1 === null) ||
                (this.state.entrantCity === "" || this.state.entrantCity === null) || ((this.state.entrantCountry === "United States" || this.state.entrantCountry === "Canada") && (this.state.entrantState === "" || this.state.entrantState === null)) ||
                (this.state.entrantCountry === "" || this.state.entrantCountry === null) || (this.state.entrantPostal === "" || this.state.entrantPostal === null) || this.state.entrantDOB > new Date()) {
                this.props.setMessage("Missing Required Fields", "error");
                return;
            }
            // if (!phoneUtil.isValidNumber(phoneNumberProto)) {
            //     this.props.setMessage("Invalid Phone Format", "error");
            //     return;
            // }
        } catch (e) {
            this.props.setMessage("Missing Required Fields", "error");
            return;
        }
        if (!this.state.acceptedPolicy) {
            this.props.setMessage("You must accept policy before continuing", "error");
            return;
        }
        let newEntrant : Entrant = {
            id: "",
            email: "",
            usesMetricSystem: false,
            fname: this.state.entrantFName,
            lname: this.state.entrantLName,
            mname: this.state.entrantMName,
            address1: this.state.entrantAdd1,
            address2: this.state.entrantAdd2,
            address3: this.state.entrantAdd3,
            city: this.state.entrantCity,
            state: (this.state.entrantCountry === "United States" || this.state.entrantCountry === "Canada") ? this.state.entrantState.toUpperCase() : "",
            dob: this.state.entrantDOB,
            zipCode: this.state.entrantPostal,
            country: this.state.entrantCountry,
            phoneNumber: this.state.entrantPhone,
            businessName: this.state.entrantBusinessName
        }
        this.props.setLoading(true);
        await this.apiService.addEntrant(newEntrant).then((result) => {
            if(result.ok){
                this.props.setMessage("Information Successfully Added.","success")
                this.props.setLoading(false);
                window.location.replace('/Dashboard');
            }else{
                this.props.setMessage("Error completing operation. Please try again. (" + result.status + ")", "error");
                this.props.setLoading(false);
            }
        });
        this.setState({ validationAttempt: false });
    };
    public async componentDidMount() {
        this.props.setLoading(true);
        const newUser = await authService.isNewUser();
        if (newUser !== "No Entrant under this user") {
            this.setRedirect();
        }
        else {
            this.setState({ newUser: true })
        }
        this.props.setLoading(false);
    }
    private setRedirect = () => {
        this.setState({
        redirect: true
        })
    }
    private renderRedirect = () => {
        if (this.state.redirect) {
        return <Redirect to='/Dashboard' />
        }
    }
    public render(): React.ReactElement {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                {this.renderRedirect()}
                {this.state.newUser &&
                    <Paper className={classes.paper}>
                        <Grid container spacing={4} className={classes.center}>
                            <Grid item xs={12}>
                                <Typography variant="h5" gutterBottom>
                                    Create Profile
                        </Typography>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    type="text"
                                    name="entrantFName"
                                    label="First Name"
                                    className={classes.textField}
                                    onChange={this.handleChange}
                                    value={this.state.entrantFName}
                                    margin="normal"
                                    fullWidth
                                    required
                                    error={this.state.validationAttempt && this.state.entrantFName === ""}
                                    helperText={(this.state.validationAttempt && this.state.entrantFName === "") ? "Required Field" : ""}
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    name="entrantMName"
                                    label="Middle Name"
                                    className={classes.textField}
                                    onChange={this.handleChange}
                                    value={this.state.entrantMName}
                                    margin="normal"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    name="entrantLName"
                                    label="Last Name"
                                    className={classes.textField}
                                    onChange={this.handleChange}
                                    value={this.state.entrantLName}
                                    margin="normal"
                                    fullWidth
                                    required
                                    error={this.state.validationAttempt && this.state.entrantLName === ""}
                                    helperText={(this.state.validationAttempt && this.state.entrantLName === "") ? "Required Field" : ""}
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    name="entrantBusinessName"
                                    label="Business Name"
                                    className={classes.textField}
                                    onChange={this.handleChange}
                                    value={this.state.entrantBusinessName}
                                    margin="normal"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <MuiPhoneNumber
                                    defaultCountry={'us'}
                                    name="entrantPhone"
                                    label="Phone Number"
                                    className={classes.textField}
                                    onChange={this.handlePhoneChange}
                                    value={this.state.entrantPhone}
                                    fullWidth
                                    required
                                    margin="normal"
                                    error={this.state.validationAttempt && this.state.entrantPhone.replace(/[\W\D\S]/, "") === ""}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        disableToolbar
                                        required
                                        fullWidth
                                        variant="inline"
                                        format="MM/dd/yyyy"
                                        placeholder="MM/dd/yyyy"
                                        margin="normal"
                                        name="entrantDOB"
                                        id="entrantDOB"
                                        label="Date of Birth"
                                        autoOk
                                        maxDate={new Date()}
                                        value={this.state.entrantDOB}
                                        error={(this.state.validationAttempt && this.state.entrantDOB === null) || !this.state.dateValid}
                                        helperText={(this.state.validationAttempt && this.state.entrantDOB === null) ? "Required Field" : (!this.state.dateValid) ? "Invalid Date" : ""}
                                        onChange={date => {
                                            if (this.validateDate(date)) this.handleDateChange(date, "entrantDOB");
                                        }}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    name="entrantAdd1"
                                    label="Street Address 1"
                                    className={classes.textField}
                                    onChange={this.handleChange}
                                    value={this.state.entrantAdd1}
                                    margin="normal"
                                    required
                                    error={this.state.validationAttempt && this.state.entrantAdd1 === ""}
                                    helperText={(this.state.validationAttempt && this.state.entrantAdd1 === "") ? "Required Field" : ""}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    name="entrantAdd2"
                                    label="Street Address 2 (Optional)"
                                    className={classes.textField}
                                    onChange={this.handleChange}
                                    value={this.state.entrantAdd2}
                                    margin="normal"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    name="entrantAdd3"
                                    label="Street Address 3 (Optional)"
                                    className={classes.textField}
                                    onChange={this.handleChange}
                                    value={this.state.entrantAdd3}
                                    margin="normal"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    select
                                    required
                                    label="Country"
                                    name="entrantCountry"
                                    onChange={this.handleChange}
                                    value={this.state.entrantCountry}
                                    className={classes.textField}
                                    SelectProps={{
                                        MenuProps: {
                                            className: classes.menu,
                                        },
                                    }}
                                    margin="normal"
                                    fullWidth
                                    error={this.state.validationAttempt && this.state.entrantCountry === ""}
                                    helperText={(this.state.validationAttempt && this.state.entrantCountry === "") ? "Required Field" : ""}
                                >
                                    {countries.map((option, index) => (
                                        <MenuItem key={index} value={option.label}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            {(this.state.entrantCountry === "United States" || this.state.entrantCountry === "Canada") &&
                                <Grid item xs={12} sm={3}>
                                    <TextField
                                        name="entrantState"
                                        label="State/Province"
                                        className={classes.textField}
                                        onChange={this.handleChange}
                                        value={this.state.entrantState.toUpperCase()}
                                        disabled={this.state.entrantCountry !== "United States" && this.state.entrantCountry !== "Canada"}
                                        margin="normal"
                                        fullWidth
                                        required
                                        inputProps={{ maxLength: 2 }}
                                        error={(this.state.entrantCountry === "United States" || this.state.entrantCountry === "Canada") && this.state.validationAttempt && this.state.entrantState === ""}
                                        helperText={(this.state.entrantCountry === "United States" || this.state.entrantCountry === "Canada") && (this.state.validationAttempt && this.state.entrantState === "") ? "Required Field" : ""}
                                    />
                                </Grid>}
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    name="entrantCity"
                                    label="City"
                                    className={classes.textField}
                                    onChange={this.handleChange}
                                    value={this.state.entrantCity}
                                    margin="normal"
                                    fullWidth
                                    required
                                    error={this.state.validationAttempt && this.state.entrantCity === ""}
                                    helperText={(this.state.validationAttempt && this.state.entrantCity === "") ? "Required Field" : ""}
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    name="entrantPostal"
                                    label="Postal Code"
                                    className={classes.textField}
                                    value={this.state.entrantPostal}
                                    onChange={this.handleChange}
                                    margin="normal"
                                    fullWidth
                                    required
                                    error={this.state.validationAttempt && this.state.entrantPostal === ""}
                                    helperText={(this.state.validationAttempt && this.state.entrantPostal === "") ? "Required Field" : ""}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={this.state.acceptedPolicy}
                                            onChange={this.handleCheck}
                                            value="acceptedPolicy"
                                            inputProps={{
                                                'aria-label': 'primary checkbox',
                                            }} />
                                    }
                                    label={<Typography variant="subtitle1" color={(this.state.validationAttempt && !this.state.acceptedPolicy) ? "error" : "initial"}> I have read and accepted the privacy policy.</Typography>}
                                /><br></br>
                                <Typography variant="subtitle2">
                                    <a href="https://www.co.monterey.ca.us/government/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
                                </Typography>
                            </Grid>
                        </Grid>
                        <Box className={classes.center}>
                            <Button className={classes.submit} onClick={this.handleSubmit} disabled={this.props.loading} variant="contained">
                                Submit
                </Button>
                        </Box>
                    </Paper>}
            </div>

        );
    }
}

const styles = createStyles((theme: Theme) => ({
    root: {
        margin: theme.spacing(-1),
        display: 'flex',
    },
    links: {
        right: theme.spacing(1),
        position: 'absolute',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    button: {
        margin: theme.spacing(1),
    },
    logo: {
        height: '50px',
        paddingRight: theme.spacing(2)
    },
    buttonLink: {
        textDecoration: 'none'
    },
    paper: {
        padding: theme.spacing(3, 2),
        position: 'relative'
    },
    center: {
        textAlign: 'center'
    },
    phoneCode: {
        width: 50,
    },
    submit: {
        backgroundColor: green[500],
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        color: 'white',
        '&:hover': {
            backgroundColor: green[700],
        }
    },
}))

export default withStyles(styles)(EntrantForm);