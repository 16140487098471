import React, { Component, ReactNode } from 'react';
import { withStyles, createStyles, Theme } from '@material-ui/core/styles';
import APIService from '../services/APIService';
import { Accordion, AccordionDetails, AccordionSummary, Button, Checkbox, DialogActions, DialogContent, DialogTitle, Divider, FormControlLabel, Grid, IconButton, Link, Typography, withWidth} from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import 'react-day-picker/lib/style.css';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import moment from 'moment';
import ITrackRental from '../interfaces/ITrackRental';
import ITrackSoundLevel from '../interfaces/ITrackSoundLevel';

export interface ITrackRentalInfoDialogProps {
    classes: any;
    width: any;
    setMessage: any;
    setLoading: any;
    loading: boolean;
    closeDialog: any;
    selectedRental: ITrackRental;
}

export interface ITrackRentalInfoDialogState {
    _isMounted: boolean;
    garagesRequested: number[];
    soundLevels: ITrackSoundLevel[];
    g1price: number;
    g2price: number;
    garage1to20: boolean;
    garage21to24: boolean;
}

export class TrackRentalInfoDialog extends Component<ITrackRentalInfoDialogProps, ITrackRentalInfoDialogState> {
    static displayName = TrackRentalInfoDialog.name;
    private apiService: APIService = new APIService;
    public constructor(props: Readonly<ITrackRentalInfoDialogProps>) {
        super(props);
        this.state = {
            _isMounted: false,
            garagesRequested: JSON.parse(this.props.selectedRental.garagesRequested),
            g1price: 0,
            g2price: 0,
            garage1to20: false,
            garage21to24: false,
            soundLevels: []
        };
        this.apiService = new APIService;
    }

    public renderCheckboxes(start : number, end : number){
        var elements : ReactNode[] = [];
        for (var i = start; i <= end; i++) {
            elements.push(
                <Grid item xs={3} key={i}>
                    <FormControlLabel
                        control={<Checkbox checked={this.state.garagesRequested.includes(i)} name={`${i}`} disabled/>}
                        label={`#${i}`}
                    />
                </Grid>
            )
        }
        return elements;
    };
    private getSoundLevels = async () => {
        await this.apiService.getSoundLevels().then((result : any) => {
            this.setState({ soundLevels: result });
        });
    }

    public componentDidMount() {
        let garages : number[] = JSON.parse(this.props.selectedRental.garagesRequested);
        let garage1to20 : boolean = false;
        let garage21to24 : boolean = false;
        garages.forEach(g => {
            if(g <= 20){
                garage1to20 = true;
            }
            if(g > 20){
                garage21to24 = true;
            }
        })
        let price = 0;
        let group1 : number[] = [];
        let group2 : number[] = [];
        for (var i = 1; i <= 24; i++) {
            if(garages.includes(i)){
                if(i <=20){
                    if(garage1to20){
                        group1.push(i);
                    }
                }
                else if (i <= 24){
                    if(garage21to24){
                        group2.push(i);
                    }
                }
            }
        }
        price = 0;
        if(garage1to20){
            if(this.props.selectedRental.garage1to20StartDate !== null && this.props.selectedRental.garage1to20EndDate !== null){
                let a = moment(this.props.selectedRental.garage1to20StartDate);
                let b = moment(this.props.selectedRental.garage1to20EndDate);
                if (group1.length > 9){
                    price = group1.length * (this.props.selectedRental.garage1to20Price - 50) * (b.diff(a, 'days') + 1);
                } else{
                    price = group1.length * (this.props.selectedRental.garage1to20Price) * (b.diff(a, 'days') + 1);
                }

            }
        }
        this.setState({g1price:price});
        price = 0;
        if(garage21to24){
            if (this.props.selectedRental.garage21to24StartDate !== null && this.props.selectedRental.garage21to24EndDate !== null){
                let a = moment(this.props.selectedRental.garage21to24StartDate);
                let b = moment(this.props.selectedRental.garage21to24EndDate);
                price = group2.length * (this.props.selectedRental.garage21to24Price) * (b.diff(a, 'days') + 1);
            }
        }
        this.setState({g2price:price, garage1to20, garage21to24});
        Promise.all([this.getSoundLevels()]).then(() =>{
            this.setState({ _isMounted: true }); 
        });
    }

    public render(): React.ReactElement<ITrackRentalInfoDialogProps> {
        const { classes, width } = this.props;
        return (
            <React.Fragment>
            {(this.state._isMounted && !this.props.loading) &&
            <>
                <DialogTitle>Track Rental Request Information</DialogTitle>
                <DialogContent dividers>
                <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography>Track Rental Date(s): {moment(this.props.selectedRental.startDate).format('MMMM Do YYYY')} - {moment(this.props.selectedRental.endDate).format('MMMM Do YYYY')}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography>Description of Track Activity: {this.props.selectedRental.description}</Typography>
                </Grid>
                <Grid item xs={10}>
                    <Typography>Track Rental Fee ({this.state.soundLevels.filter(x => {return (x.id === this.props.selectedRental.soundLevel)})[0].name})</Typography>
                </Grid>
                <Grid item xs={2}>
                    <Typography>${this.props.selectedRental.soundLevelPrice}</Typography>
                </Grid>
                <Grid item xs={8}>
                    <Typography>Number of Vehicles per Day:</Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography>{this.props.selectedRental.numberOfVehicles}</Typography>
                </Grid>
                <Grid item xs={12}>
                </Grid>
                <Grid item xs={12} className={classes.center}>
                    <Link href="https://www.co.monterey.ca.us/Home/ShowDocument?id=98744" target="_new">View Track Rental Information Packet<OpenInNewIcon fontSize="small"/></Link>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h5">Services</Typography>
                </Grid>
                {this.props.selectedRental.als && 
                <>
                    <Grid item xs={6}>
                        <Typography>ALS (Paramedic) Ambulance Service:</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography>${this.props.selectedRental.alsPrice}/day</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography>x {this.props.selectedRental.alsQty}</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography>${this.props.selectedRental.alsPrice * this.props.selectedRental.alsQty}</Typography>
                    </Grid>
                </>
                }
                {this.props.selectedRental.towTruck && 
                <>
                    <Grid item xs={6}>
                        <Typography>Tow Truck (Flatbed):</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography>${this.props.selectedRental.towTruckPrice}/day</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography>x {this.props.selectedRental.towTruckQty}</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography>${this.props.selectedRental.towTruckQty * this.props.selectedRental.towTruckPrice}</Typography>
                    </Grid>
                </>
                }
                <Grid item xs={12}>
                    <Typography variant="h6">Emergency Response Rescue – Fire / Jaws</Typography>
                </Grid>
                {this.props.selectedRental.rescueTeam2 && 
                <>
                    <Grid item xs={6}>
                        <Typography>Rescue Truck with 2-man crew:</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography>${this.props.selectedRental.rescueTeam2Price}</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography>${this.props.selectedRental.rescueTeam2Price}</Typography>
                    </Grid>
                </>
                }
                {this.props.selectedRental.rescueTeam3 && 
                <>
                    <Grid item xs={6}>
                        <Typography>Rescue Truck with 3-man crew:</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography>${this.props.selectedRental.rescueTeam3Price}</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography>${this.props.selectedRental.rescueTeam3Price}</Typography>
                    </Grid>
                </>
                }
                {this.props.selectedRental.fireEngine3 && 
                <>
                    <Grid item xs={6}>
                        <Typography>Rescue Truck with 3-man crew:</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography>${this.props.selectedRental.fireEngine3Price}</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography>${this.props.selectedRental.fireEngine3Price}</Typography>
                    </Grid>
                </>
                }
                <Grid item xs={12}>
                    <Typography variant="subtitle1"><strong>Additional Items</strong></Typography>
                    <Typography variant="body1">Fee information for rental radios (in addition to the six (6) provided), I.T. services / connectivity, electrical services / connectivity, facility building rental, etc., is available upon request.</Typography>
                    <Typography variant="subtitle1"><strong>Insurance Carrier</strong></Typography>                    
                    <Typography variant="body1">Customer insurance coverage must meet limits and include a Certificate of Insurance per Track Fee sheet, submitted two (2) weeks in advance of your track day and paid for by the renter directly.</Typography>
                    <Typography variant="subtitle1"><strong>Water Truck</strong></Typography>
                    <Typography variant="body1">A water truck is mandatory for use in the Skid Pad in the Paddock, except with the use of specialized drift equipment, or if specialized tires are utilized to eliminate surface damage.</Typography>
                    <Typography variant="subtitle1"><strong>Waste/Recycling Services</strong></Typography>
                    <Typography variant="body1">For groups in excess of 100 participants, you will be required to contract with Waste/Recycling Services to maintain the Paddock as required by COVID-19 protocols. Please contact Matt Fedorko at 469-964-3147, or by email at matt@bluestrikeenvironmental.com.</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h5">Track Safety Personnel</Typography>
                </Grid>
                {this.props.selectedRental.trackRentalSupervisor && 
                <>
                    <Grid item xs={6}>
                        <Typography>Track Rental Supervisor:</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography>${this.props.selectedRental.trackRentalSupervisorPrice}/day</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography>{this.props.selectedRental.trackRentalSupervisorDays} Days</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography>${this.props.selectedRental.trackRentalSupervisorDays * this.props.selectedRental.trackRentalSupervisorPrice}</Typography>
                    </Grid>
                </>
                }
                {this.props.selectedRental.starter && 
                <>
                    <Grid item xs={6}>
                        <Typography>Starter:</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography>${this.props.selectedRental.starterPrice}/day</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography>{this.props.selectedRental.starterDays} Days</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography>${this.props.selectedRental.starterDays * this.props.selectedRental.starterPrice}</Typography>
                    </Grid>
                </>
                }
                {this.props.selectedRental.marshal && 
                <>
                    <Grid item xs={6}>
                        <Typography>Course Marshal:</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography>${this.props.selectedRental.marshalPrice}/day</Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <Typography>{this.props.selectedRental.marshalDays} Days</Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <Typography>x {this.props.selectedRental.marshalQty}</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography>${this.props.selectedRental.marshalDays * this.props.selectedRental.marshalQty * this.props.selectedRental.marshalPrice}</Typography>
                    </Grid>
                </>
                }
                {this.props.selectedRental.soundTester && 
                <>
                    <Grid item xs={6}>
                        <Typography>Course Marshal:</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography>${this.props.selectedRental.soundTesterPrice}/day</Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <Typography>{this.props.selectedRental.soundTesterDays} Days</Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <Typography>x {this.props.selectedRental.soundTesterQty}</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography>${this.props.selectedRental.soundTesterDays * this.props.selectedRental.soundTesterQty * this.props.selectedRental.soundTesterPrice}</Typography>
                    </Grid>
                </>
                }
                {this.props.selectedRental.gridWorker && 
                <>
                    <Grid item xs={6}>
                        <Typography>Grid Worker:</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography>${this.props.selectedRental.gridWorkerPrice}/day</Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <Typography>{this.props.selectedRental.gridWorkerDays} Days</Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <Typography>x {this.props.selectedRental.gridWorkerQty}</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography>${this.props.selectedRental.gridWorkerDays * this.props.selectedRental.gridWorkerQty * this.props.selectedRental.gridWorkerPrice}</Typography>
                    </Grid>
                </>
                }
                {this.props.selectedRental.gasAttendant && 
                <>
                    <Grid item xs={6}>
                        <Typography>Gas Attendant:</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography>${this.props.selectedRental.gasAttendantPrice}/day</Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <Typography>{this.props.selectedRental.gasAttendantDays} Days</Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <Typography>x {this.props.selectedRental.gasAttendantQty}</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography>${this.props.selectedRental.gasAttendantDays * this.props.selectedRental.gasAttendantQty * this.props.selectedRental.gasAttendantPrice}</Typography>
                    </Grid>
                </>
                }
                {this.props.selectedRental.daytimeGateAttendant && 
                <>
                    <Grid item xs={6}>
                        <Typography>Daytime Gate Attendant (6:30am-6:00pm):</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography>${this.props.selectedRental.daytimeGateAttendantPrice}/day</Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <Typography>{this.props.selectedRental.daytimeGateAttendantDays} Days</Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <Typography>x {this.props.selectedRental.daytimeGateAttendantQty}</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography>${this.props.selectedRental.daytimeGateAttendantDays * this.props.selectedRental.daytimeGateAttendantQty * this.props.selectedRental.daytimeGateAttendantPrice}</Typography>
                    </Grid>
                </>
                }
                {this.props.selectedRental.nighttimeGateAttendant && 
                <>
                    <Grid item xs={6}>
                        <Typography>Nighttime Gate Attendant (6:00pm-6:30am):</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography>${this.props.selectedRental.nighttimeGateAttendantPrice}/day</Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <Typography>{this.props.selectedRental.nighttimeGateAttendantDays} Days</Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <Typography>x {this.props.selectedRental.nighttimeGateAttendantQty}</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography>${this.props.selectedRental.nighttimeGateAttendantDays * this.props.selectedRental.nighttimeGateAttendantQty * this.props.selectedRental.nighttimeGateAttendantPrice}</Typography>
                    </Grid>
                </>
                }
                {this.props.selectedRental.earlyLoadInLateLoadOut && 
                <>
                    <Grid item xs={6}>
                        <Typography>Early Load-In and Late Load-Outs:</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography>${this.props.selectedRental.earlyLoadInLateLoadOutPrice}/day</Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <Typography>{this.props.selectedRental.earlyLoadInLateLoadOutDays} Days</Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <Typography>x {this.props.selectedRental.earlyLoadInLateLoadOutQty}</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography>${this.props.selectedRental.earlyLoadInLateLoadOutDays * this.props.selectedRental.earlyLoadInLateLoadOutQty * this.props.selectedRental.earlyLoadInLateLoadOutPrice}</Typography>
                    </Grid>
                </>
                }
                {this.props.selectedRental.paddockCamping && 
                <>
                    <Grid item xs={6}>
                        <Typography>Paddock Camping:</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography>${this.props.selectedRental.paddockCampingPrice}/day</Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <Typography>{this.props.selectedRental.paddockCampingNights} Nights</Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <Typography>x{this.props.selectedRental.paddockCampingQty}</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography>${this.props.selectedRental.paddockCampingNights * this.props.selectedRental.paddockCampingQty * this.props.selectedRental.paddockCampingPrice}</Typography>
                    </Grid>
                </>
                }
                {this.props.selectedRental.coviD19AdminFee && 
                <>
                    <Grid item xs={6}>
                        <Typography>COVID-19 Administrative Fee:</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography>${this.props.selectedRental.coviD19AdminFeePrice}/day</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography>{this.props.selectedRental.coviD19AdminFeeDays} Days</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography>${this.props.selectedRental.coviD19AdminFeeDays * this.props.selectedRental.coviD19AdminFeePrice}</Typography>
                    </Grid>
                </>
                }
                {this.props.selectedRental.caterer && 
                <>
                    <Grid item xs={8}>
                        <Typography>Caterer</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography>{this.props.selectedRental.caterer}</Typography>
                    </Grid>
                </>
                }
                <Grid item xs={12}>
                    <Typography variant="h5">Additional Forms</Typography>
                    <Accordion>
                        <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        >
                        <Typography variant="h6">Garage Rental</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                        <Grid container spacing={2}>
                            {(this.props.selectedRental.garage1to20StartDate !== null && this.props.selectedRental.garage1to20EndDate !== null)  && 
                                <>
                                    <Grid item xs={12}>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="h6">Garages #1 - #20</Typography>
                                        <Typography>Each garage bay is 16’ wide x 32’ deep. $250 per garage bay per day, or $2,000 for 10 bays, per day, or $4,000 for 20 bays, per day.</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography>
                                            Dates requested: 
                                            {moment(this.props.selectedRental.garage1to20StartDate).format('MMMM Do YYYY') + "-" + moment(this.props.selectedRental.garage1to20EndDate).format('MMMM Do YYYY')}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} className={classes.center} >
                                        <Typography variant="h6">Garage Bay(s) Requested</Typography>
                                    </Grid>
                                        {this.renderCheckboxes(1, 20)}
                                    <Grid item xs={10}>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography>${this.state.g1price}</Typography>
                                    </Grid>
                                <Grid item xs={12}>
                                    <Divider/>
                                </Grid>
                                </>
                            }
                            {(this.props.selectedRental.garage21to24StartDate !== null && this.props.selectedRental.garage21to24EndDate !== null) && 
                                <>
                                    <Grid item xs={12}>
                                        <Typography variant="h6">Garages #21 - #24</Typography>
                                        <Typography>$250 for (1) or $1,000 for (4) per day</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography>
                                            Dates requested: 
                                            {moment(this.props.selectedRental.garage21to24StartDate).format('MMMM Do YYYY') + "-" + moment(this.props.selectedRental.garage21to24EndDate).format('MMMM Do YYYY')}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} className={classes.center} >
                                    </Grid>
                                        {this.renderCheckboxes(21, 24)}
                                    <Grid item xs={10}>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography>${this.state.g2price}</Typography>
                                    </Grid>
                                <Grid item xs={12}>
                                    <Divider/>
                                </Grid>
                                </>
                            }
                            {this.props.selectedRental.newmanBuilding4Bay && 
                            <>
                                <Grid item xs={12}>
                                    <>
                                        <Typography variant="h6">Newman Building 4-Bay Garage</Typography>
                                        <Typography>${this.props.selectedRental.newmanBuilding4BayPrice}/day</Typography>
                                    </>
                                </Grid>
                                <Grid item xs={10}>
                                    <Typography>
                                        Dates requested: {moment(this.props.selectedRental.newmanBuilding4BayStartDate).format('MMMM Do YYYY') + "-" + moment(this.props.selectedRental.newmanBuilding4BayEndDate).format('MMMM Do YYYY')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography>${(this.props.selectedRental.newmanBuilding4BayStartDate !== null || this.props.selectedRental.newmanBuilding4BayEndDate !== null) ? 
                                    (this.props.selectedRental.newmanBuilding4BayPrice) * (moment(this.props.selectedRental.newmanBuilding4BayStartDate).diff(moment(this.props.selectedRental.newmanBuilding4BayEndDate), 'days') + 1) : 0
                                    }</Typography>
                                </Grid>
                            <Grid item xs={12}>
                                <Divider/>
                            </Grid>
                            </>
                            }
                            {this.props.selectedRental.newmanBuilding2Bay && 
                                <>
                                    <Grid item xs={12}>
                                        <>
                                            <Typography variant="h6">Newman Building 2-Bay Garage</Typography>
                                            <Typography>${this.props.selectedRental.newmanBuilding2BayPrice}/day</Typography>
                                        </>
                                    </Grid>
                                    <Grid item xs={10}>
                                    <Typography>
                                        Dates requested: {moment(this.props.selectedRental.newmanBuilding2BayStartDate).format('MMMM Do YYYY') + "-" + moment(this.props.selectedRental.newmanBuilding2BayEndDate).format('MMMM Do YYYY')}
                                    </Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography>${(this.props.selectedRental.newmanBuilding2BayStartDate !== null || this.props.selectedRental.newmanBuilding2BayEndDate !== null) ? 
                                        (this.props.selectedRental.newmanBuilding2BayPrice) * (moment(this.props.selectedRental.newmanBuilding2BayStartDate).diff(moment(this.props.selectedRental.newmanBuilding2BayEndDate), 'days') + 1) : 0
                                        }</Typography>
                                    </Grid>
                            <Grid item xs={12}>
                                <Divider/>
                            </Grid>
                                </>
                            }
                            {this.props.selectedRental.stewardsRoom && 
                                <>
                                    <Grid item xs={12}>
                                        <>
                                            <Typography variant="h6">Stewards Room</Typography>
                                            <Typography>${this.props.selectedRental.stewardsRoomPrice}/day</Typography>
                                        </>
                                    </Grid>
                                    <Grid item xs={10}>
                                    <Typography>
                                        Dates requested: {moment(this.props.selectedRental.stewardsRoomStartDate).format('MMMM Do YYYY') + "-" + moment(this.props.selectedRental.stewardsRoomEndDate).format('MMMM Do YYYY')}
                                    </Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                    <Typography>${(this.props.selectedRental.stewardsRoomStartDate !== null || this.props.selectedRental.stewardsRoomEndDate !== null) ? 
                                    (this.props.selectedRental.stewardsRoomPrice) * (moment(this.props.selectedRental.stewardsRoomStartDate).diff(moment(this.props.selectedRental.stewardsRoomEndDate), 'days') + 1) : 0
                                    }</Typography>
                                    </Grid>
                            <Grid item xs={12}>
                                <Divider/>
                            </Grid>
                                </>
                            }
                            {this.props.selectedRental.timingAndScoring && 
                                <>
                                    <Grid item xs={12}>
                                        <>
                                            <Typography variant="h6">Timing &amp; Scoring Building</Typography>
                                            <Typography>${this.props.selectedRental.timingAndScoringPrice}/day</Typography>
                                        </>
                                    </Grid>
                                    <Grid item xs={10}>
                                        <Typography>
                                            Dates requested: {moment(this.props.selectedRental.timingAndScoringStartDate).format('MMMM Do YYYY') + "-" + moment(this.props.selectedRental.timingAndScoringEndDate).format('MMMM Do YYYY')}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                    <Typography>${(this.props.selectedRental.timingAndScoringStartDate !== null || this.props.selectedRental.timingAndScoringEndDate !== null) ? 
                                    (this.props.selectedRental.timingAndScoringPrice) * (moment(this.props.selectedRental.timingAndScoringStartDate).diff(moment(this.props.selectedRental.timingAndScoringEndDate), 'days') + 1) : 0
                                    }</Typography>
                                    </Grid>
                                <Grid item xs={12}>
                                    <Divider/>
                                </Grid>
                                </>
                            }
                            <Grid item xs={12}>
                                <Typography variant={"h6"}>Hours of Operation</Typography>
                                <Typography variant={"body2"}>
                                    Facilities will be open at 6:30AM each morning and closed at 6:00PM each evening. Early load-in and late load-out will
                                    only be allowed if pre-scheduled on an individual basis and approved by WeatherTech Raceway Laguna Seca
                                    management.
                                </Typography>
                                <Typography variant={"h6"}>Rules and Regulations</Typography>
                                <Typography variant={"body2"}>
                                    As partial consideration of the covenants and agreements on the part of WeatherTech Raceway Laguna Seca set forth
                                    herein, Renter agrees to comply with the following requirements during all times that this agreement is in effect:<br/>
                                    • Garages shall not be sublet to others<br/>
                                    • No signage of any type shall be placed on the exterior of any building or doors<br/>
                                    • Fixtures, pictures and signage on or within the building may not be removed<br/>
                                    • Removable hanging putty is the only approved method for hanging additional signage on the walls. Do not tape,
                                    Velcro, glue, staple, or use any other method without prior approval from WeatherTech Raceway Laguna Seca
                                    management.<br/>
                                    • WeatherTech Raceway Laguna Seca is not responsible for cars or equipment in garages<br/>
                                    • No welding or open flames of any kind shall be permitted in garages, and no fuel shall be stored therein<br/>
                                    • Garage beams shall not be used for hoisting purposes<br/>
                                    • At no time shall Renter permit more than one (1) car to be kept in any one garage, nor more than two (2)
                                    motorcycles per garage, nor more than four (4) go-karts per garage. Further, Renter always agrees that it shall use
                                    said garage(s) in a careful and safe manner and keep the floor neat and clean and free of all debris.<br/>
                                    • Renter shall always be responsible for the conduct of all guests, employees, and agents and will take all precautions
                                    in order to avoid accidents and ensure the safety of others.<br/>
                                    • Renter shall be responsible for the removal cost of any waste, hazardous or otherwise, including, but not limited to,
                                    fuel, oil, or tires left on the premises by the Renter or any participants.<br/>
                                    • Renter shall fully comply with all posted rules and regulations. In the event of any dispute, the decision of
                                    WeatherTech Raceway Laguna Seca (or their designated agent) shall be final and binding.<br/>
                                    • Renter shall provide a certificate of insurance listing the following as additionally insured: County of Monterey, its
                                    officers, agents and employees; A &amp; D Narigi Consulting LLC, its officers, agents and employees; and
                                    WeatherTech, its parent subsidiary and affiliated companies, and their officers, directors and employees.<br/>
                                </Typography>
                                <Typography variant={"h6"}>Damage Responsibility</Typography>
                                <Typography variant={"body2"}>
                                    Any damage to any rented facility will be charged back to the renter after the fact. A walk-through will be scheduled with
                                    the client prior to and after use to determine the condition of the area and assess any damage.
                                    Renter shall be held responsible for any undue damages to the WeatherTech Raceway Laguna Seca facility and/or its
                                    garages. Renter agrees for itself, its employees, agents, drivers, crew members, guests, and invitees to fully protect,
                                    indemnify, and hold harmless County of Monterey, its officers, agents and employees; A &amp; D Narigi Consulting LLC, its
                                    officers, agents and employees; and WeatherTech, its parent subsidiary and affiliated companies, and their officers,
                                    directors and employees are named as additional insureds from any and all liability to third persons or property of third
                                    persons resulting from Renter’s use of said garage(s).
                                    Renter agrees to require all its employees, agents, drivers, crew members, guests, and invitees to sign the standard waiver
                                    and release form furnished by K &amp; K Insurance prior to admission of any such persons to WeatherTech Raceway Laguna
                                    Seca.
                                </Typography>
                            </Grid>
                        </Grid>
                        </AccordionDetails>
                    </Accordion>
                    </Grid>
                    <Grid item xs={8}>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography>Total: ${this.props.selectedRental.estimatedCost}</Typography>
                    </Grid>
                </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.props.closeDialog} variant="contained">Close</Button>
                </DialogActions>
            </>
            }
            </React.Fragment>
        )
    }
}

const styles = createStyles((theme: Theme) => ({
    paper: {
        padding: theme.spacing(3, 2),
        position: 'relative',
    },
    center:{
        textAlign: 'center',
    },
    submit: {
        backgroundColor: green[500],
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        color: 'white',
        '&:hover': {
            backgroundColor: green[700],
        }
    },
}))

export default withWidth()(withStyles(styles, { withTheme: true })(TrackRentalInfoDialog));
