import OptionType from '../interfaces/IOptionType'
export const CarMakes: OptionType[] = [
  { label:"Abarth"},
  { label:"Alfa Romeo"},
  { label:"Aston Martin"},
  { label:"Audi"},
  { label:"Bentley"},
  { label:"BMW"},
  { label:"Bugatti"},
  { label:"Cadillac"},
  { label:"Chevrolet"},
  { label:"Chrysler"},
  { label:"Citroën"},
  { label:"Dacia"},
  { label:"Daewoo"},
  { label:"Daihatsu"},
  { label:"Dodge"},
  { label:"Donkervoort"},
  { label:"DS"},
  { label:"Ferrari"},
  { label:"Fiat"},
  { label:"Fisker"},
  { label:"Ford"},
  { label:"Honda"},
  { label:"Hummer"},
  { label:"Hyundai"},
  { label:"Infiniti"},
  { label:"Iveco"},
  { label:"Jaguar"},
  { label:"Jeep"},
  { label:"Kia"},
  { label:"KTM"},
  { label:"Lada"},
  { label:"Lamborghini"},
  { label:"Lancia"},
  { label:"Land Rover"},
  { label:"Landwind"},
  { label:"Lexus"},
  { label:"Lotus"},
  { label:"Maserati"},
  { label:"Maybach"},
  { label:"Mazda"},
  { label:"McLaren"},
  { label:"Mercedes-Benz"},
  { label:"MG"},
  { label:"Mini"},
  { label:"Mitsubishi"},
  { label:"Morgan"},
  { label:"Nissan"},
  { label:"Opel"},
  { label:"Peugeot"},
  { label:"Porsche"},
  { label:"Renault"},
  { label:"Rolls-Royce"},
  { label:"Rover"},
  { label:"Saab"},
  { label:"Seat"},
  { label:"Skoda"},
  { label:"Smart"},
  { label:"SsangYong"},
  { label:"Subaru"},
  { label:"Suzuki"},
  { label:"Tesla"},
  { label:"Toyota"},
  { label:"Volkswagen"},
  { label:"Volvo"},
  ];