import React, { Component } from 'react';
import clsx from 'clsx';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import AssessmentIcon from '@material-ui/icons/Assessment';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import Divider from '@material-ui/core/Divider';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import SettingsIcon from '@material-ui/icons/Settings';
import logo from '../img/weatherTech.png';
import authService from '../services/api-authorization/AuthorizeService';
import { ApplicationPaths } from '../services/api-authorization/ApiAuthorizationConstants';
import { withStyles, createStyles, Theme } from '@material-ui/core/styles';
import { NavLink } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import { MenuItem, Grid, Tooltip, TextField, withWidth } from '@material-ui/core';
import Entrant from '../interfaces/IEntrant';
import APIService from '../services/APIService';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';

const drawerWidth = 240;
export interface INavMenuProps {
    classes: any;
    setLoading: any;
    loading: boolean;
    width: any;
    currentEntrant?: Entrant;
    isAdmin: boolean;
    showMenu: boolean;
    handleDrawerToggle: any;
    featureMode: string;
    setFeature: any;
}

export interface INavMenuState {
    auth: ConstrainBoolean;
    newUser: boolean;
    _isMounted: boolean;
}

export class NavMenu extends Component<INavMenuProps, INavMenuState> {
    private apiService: APIService = new APIService;
    static displayName = NavMenu.name;
    public constructor(props: Readonly<INavMenuProps>) {
        super(props);
        this.state = {
            auth: false,
            newUser: true,
            _isMounted: false
        };
        this.checkAuth = this.checkAuth.bind(this);
        this.apiService = new APIService;
    };

    private handleDrawerOpen = () => {
        this.setOpen(true);
    };

    private handleDrawerClose = () => {
        this.setOpen(false);
    };

    private setOpen(open: boolean) {
        this.props.handleDrawerToggle(open)
    };

    private async checkAuth() {
        const authorized = await authService.isAuthenticated();
        this.setState({ auth: authorized });
    };

    private async checkUserHasAnEntrant() {
        await this.apiService.checkUserHasAnEntrant().then((newUser) => {
            this.setState({ newUser })
        })
    };

    public componentDidMount() {
        this.checkAuth();
        this.checkUserHasAnEntrant();
    };

    public render(): React.ReactElement<INavMenuProps> {
        const { classes, width } = this.props;
        const logoutPath = { pathname: `${ApplicationPaths.LogOut}`, state: { local: true } };
        return (
            <div className={classes.root}>
                <AppBar
                    position="fixed"
                    className={clsx(classes.appBar, {
                        [classes.appBarShift]: this.props.showMenu,
                    })}
                >
                    <Toolbar>
                        {(window.location.pathname !== "/NewUser") &&
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                onClick={this.handleDrawerOpen}
                                edge="start"
                                className={clsx(classes.menuButton, {
                                    [classes.hide]: this.props.showMenu,
                                })}
                            >
                                <MenuIcon />
                            </IconButton>}
                        <img src={logo} className={classes.logo} />
                        <Typography variant="h6" className={classes.title}>
                        {this.props.featureMode === "trackRental" ? "Track Rental" : "Race Registration" }
                        </Typography>
                        <Box className={classes.links}>
                            <Grid container alignItems="center">
                                {width !== "xs" &&
                                    <NavLink to={logoutPath} className={classes.buttonLink}>
                                        <Button className={classes.button} variant="contained" color="secondary" startIcon={<AccountCircleIcon />} >
                                            LogOut
                                    </Button>
                                    </NavLink>}
                            </Grid>
                        </Box>
                    </Toolbar>
                </AppBar>
                {window.location.pathname !== "/NewUser" &&
                    <Drawer variant="permanent"
                        className={clsx(classes.drawer, {
                            [classes.drawerOpen]: this.props.showMenu,
                            [classes.drawerClose]: !this.props.showMenu,
                        })}
                        classes={{
                            paper: clsx({
                                [classes.drawerOpen]: this.props.showMenu,
                                [classes.drawerClose]: !this.props.showMenu,
                            }),
                        }}
                        open={this.props.showMenu}
                    >
                        <div className={classes.toolbar}>
                            <IconButton onClick={this.handleDrawerClose}>
                                {classes.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                            </IconButton>
                        </div>
                        <Divider />
                        {this.props.featureMode === "trackRental" ? 
                        <List>
                            {!this.props.isAdmin &&
                                <React.Fragment>
                                    <NavLink to="/TrackRental" className={classes.buttonLink} onClick={() => { window.scrollTo(0, 0); this.handleDrawerClose()}}>
                                        <ListItem button key={"Rentals"}>
                                            <ListItemIcon><DriveEtaIcon /></ListItemIcon>
                                            <ListItemText className={classes.buttonTest} primary={"Rentals"} />
                                        </ListItem>
                                    </NavLink>
                                </React.Fragment>
                            }
                            {this.props.isAdmin &&
                                <React.Fragment>
                                    <NavLink to="/TrackRentalAdmin" className={classes.buttonLink} onClick={() => { window.scrollTo(0, 0); this.handleDrawerClose()}}>
                                        <ListItem button key={"Rentals"}>
                                            <ListItemIcon><DriveEtaIcon /></ListItemIcon>
                                            <ListItemText className={classes.buttonTest} primary={"Admin Dashboard"} />
                                        </ListItem>
                                    </NavLink>
                                    <NavLink to="/TrackRentalAdminPriceSheet" className={classes.buttonLink} onClick={() => { window.scrollTo(0, 0); this.handleDrawerClose()}}>
                                        <ListItem button key={"PriceSheet"}>
                                            <ListItemIcon><SettingsIcon /></ListItemIcon>
                                            <ListItemText className={classes.buttonTest} primary={"Price Sheet"} />
                                        </ListItem>
                                    </NavLink>
                                </React.Fragment>
                            }
                            <NavLink to="/Account" className={classes.buttonLink} onClick={() => { window.scrollTo(0, 0); this.handleDrawerClose() }}>
                                <ListItem button key={"Account"}>
                                    <ListItemIcon><AccountCircleIcon /></ListItemIcon>
                                    <ListItemText className={classes.buttonTest} primary={"Account"} />
                                </ListItem>
                            </NavLink>
                            <Divider />
                            <NavLink to="/Dashboard" className={classes.buttonLink} onClick={() => this.props.setFeature("raceRegistration")}>
                                <ListItem button key={"DashboardSwap"}>
                                    <ListItemIcon><OpenInNewIcon /></ListItemIcon>
                                    <ListItemText className={classes.buttonTest} primary={<>Swap To<br/>Event Registration</>} />
                                </ListItem>
                            </NavLink>
                            {width === "xs" &&
                                <React.Fragment>
                                    <Divider />
                                    <NavLink to={logoutPath} className={classes.buttonLink}>
                                        <ListItem button key={"Account"}>
                                            <ListItemIcon><ExitToAppIcon /></ListItemIcon>
                                            <ListItemText className={classes.buttonTest} primary={"LogOut"} />
                                        </ListItem>
                                    </NavLink>
                                </React.Fragment>}

                            <Divider />
                        </List> :
                        <List>
                            {!this.props.isAdmin &&
                                <NavLink to="/Dashboard" className={classes.buttonLink} onClick={() => { window.scrollTo(0, 0); this.handleDrawerClose()}}>
                                    <ListItem button key={"Registrations"}>
                                        <ListItemIcon><DriveEtaIcon /></ListItemIcon>
                                        <ListItemText className={classes.buttonTest} primary={"Registrations"} />
                                    </ListItem>
                                </NavLink>
                            }
                            {this.props.isAdmin &&
                                <React.Fragment>
                                    <NavLink to="/AdminDashboard" className={classes.buttonLink} onClick={() => { window.scrollTo(0, 0); this.handleDrawerClose()}}>
                                        <ListItem button key={"Admin"}>
                                            <ListItemIcon><AssessmentIcon /></ListItemIcon>
                                            <ListItemText className={classes.buttonTest} primary={"Admin"} />
                                        </ListItem>
                                    </NavLink>
                                    <NavLink to="/CheckIn" className={classes.buttonLink} onClick={() => { window.scrollTo(0, 0); this.handleDrawerClose() }}>
                                        <ListItem button key={"CheckIn"}>
                                            <ListItemIcon><AssignmentTurnedInIcon /></ListItemIcon>
                                            <ListItemText className={classes.buttonTest} primary={"Check In"} />
                                        </ListItem>
                                    </NavLink>
                                </React.Fragment>
                            }
                            <NavLink to="/Account" className={classes.buttonLink} onClick={() => { window.scrollTo(0, 0); this.handleDrawerClose() }}>
                                <ListItem button key={"Account"}>
                                    <ListItemIcon><AccountCircleIcon /></ListItemIcon>
                                    <ListItemText className={classes.buttonTest} primary={"Account"} />
                                </ListItem>
                            </NavLink>
                            <Divider />
                            <NavLink to="/TrackRental" className={classes.buttonLink} onClick={() => this.props.setFeature("trackRental")}>
                                <ListItem button key={"TrackRentalSwap"}>
                                    <ListItemIcon><OpenInNewIcon /></ListItemIcon>
                                    <ListItemText className={classes.buttonTest} primary={<>Swap To<br/>Track Rental</>} />
                                </ListItem>
                            </NavLink>
                            {width === "xs" &&
                                <React.Fragment>
                                    <Divider />
                                    <NavLink to={logoutPath} className={classes.buttonLink}>
                                        <ListItem button key={"Account"}>
                                            <ListItemIcon><ExitToAppIcon /></ListItemIcon>
                                            <ListItemText className={classes.buttonTest} primary={"LogOut"} />
                                        </ListItem>
                                    </NavLink>
                                </React.Fragment>}

                            <Divider />
                        </List>}
                    </Drawer>}
            </div>
        );
    }
}

const styles = createStyles((theme: Theme) => ({
    root: {
        margin: theme.spacing(-1),
        display: 'flex',
    },
    userSelect: {
        width: '250px',
    },
    links: {
        right: theme.spacing(1),
        position: 'absolute',
    },
    buttonTest: {
        color: theme.palette.text.primary
    },
    verticalDivider: {
        width: "2px",
        display: "list-item",
        height: "40px",
    },
    title: {
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(7) + 1,
        },
        [theme.breakpoints.down('sm')]: {
            width: theme.spacing(0),
        }
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    button: {
        margin: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            padding: "4px 10px",
        }
    },
    logo: {
        height: '50px',
        paddingRight: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            paddingRight: theme.spacing(6),
            margin: "auto"
        }
    },
    buttonLink: {
        textDecoration: 'none'
    }
}))

export default withWidth()(withStyles(styles)(NavMenu));