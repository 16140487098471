import React, { Component } from 'react';
import { withStyles, createStyles, Theme } from '@material-ui/core/styles';
import APIService from '../services/APIService';
import { Button, DialogActions, DialogContent, DialogTitle, withWidth} from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import 'react-day-picker/lib/style.css';
import { DateUtils, DayModifiers } from 'react-day-picker';
import { TrackRentalRequestForm } from './TrackRentalRequestForm';
import ITrackSoundLevel from '../interfaces/ITrackSoundLevel';
import moment from 'moment';

export interface ITrackRentalRequestDialogProps {
    classes: any;
    width: any;
    setMessage: any;
    setLoading: any;
    loading: boolean;
    closeDialog: any;
    desc? : string;
    soundLevel? : string;
}

export interface ITrackRentalRequestDialogState {
    _isMounted: boolean;
    soundLevels: ITrackSoundLevel[];
    trackActivityDescription: string;
    startDate: Date;
    endDate: Date;
    trackSoundLevel: string;
    blackoutDates: Date[];

}

export class TrackRentalRequestDialog extends Component<ITrackRentalRequestDialogProps, ITrackRentalRequestDialogState> {
    static displayName = TrackRentalRequestDialog.name;
    private apiService: APIService = new APIService;
    public constructor(props: Readonly<ITrackRentalRequestDialogProps>) {
        super(props);
        this.state = {
            _isMounted: false,
            soundLevels: [],
            trackSoundLevel: "",
            trackActivityDescription: this.props.desc ? this.props.desc : "",
            startDate: new Date(),            
            endDate: new Date(),
            blackoutDates : []
        };
        this.apiService = new APIService;
        this.getSoundLevels = this.getSoundLevels.bind(this);
        this.handleDateClick = this.handleDateClick.bind(this);
        this.getBlackoutDates = this.getBlackoutDates.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    private handleDateClick(date : Date, modifiers: DayModifiers){
        if(moment(this.state.startDate).format('MMMM Do YYYY') === moment(new Date()).format('MMMM Do YYYY')){
            this.setState({startDate : date, endDate : date});
        }
        else{
            const range = DateUtils.addDayToRange(date, {from: this.state.startDate, to: this.state.endDate});            
            if(!modifiers.disabled){
                this.setState({startDate : range.from, endDate : range.to});
            }
        }
    }

    private handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newState = { [event.target.name]: event.target.value } as any;
        this.setState(newState);
    };

    private getSoundLevels = async () => {
        await this.apiService.getSoundLevels().then((result : any) => {
            this.setState({ soundLevels: result });
        });
    }
    private getBlackoutDates = async () => {
        await this.apiService.getBlackoutDates().then((result : any) => {
            this.setState({ blackoutDates: result });
        });
    }
    private submitRequest = async () => {
        this.props.setLoading(true);
        let selectedLevel = this.state.soundLevels.find( x => x.id === this.state.trackSoundLevel);
        if(selectedLevel !== undefined){
            await this.apiService.startRentalRequest(this.state.trackActivityDescription, this.state.startDate, this.state.endDate, selectedLevel).then((result : any) => {
                this.props.setLoading(false);
            });
        }
    }

    private onSubmit(){
        if(this.state.startDate === null || this.state.startDate === undefined){
            this.props.setMessage("Start Date is Required", "error");
            return;
        }
        if(this.state.endDate  === null || this.state.endDate === undefined){
            this.props.setMessage("Start Date is Required", "error");
            return;
        }
        if(this.state.endDate < this.state.startDate){
            this.props.setMessage("End Date is Before Start Date", "error");
            return;
        }
        if (this.state.trackSoundLevel === "" || this.state.trackSoundLevel === undefined || this.state.trackSoundLevel === null){
            this.props.setMessage("Sound Level is Required", "error");
            return;
        }
        if (this.state.trackActivityDescription === ""){
            this.props.setMessage("Description is Required", "error");
            return;
        }
        this.submitRequest().then(data => {
            this.props.closeDialog();
        });
    }
    
    public componentDidMount() {        
        this.getSoundLevels().then(() => this.getBlackoutDates()).then(() => this.setState({ _isMounted: true, trackSoundLevel : (this.state.soundLevels[0].id !== undefined) ? this.state.soundLevels[0].id : "" }));
    }

    public componentDidUpdate(prevProps: ITrackRentalRequestDialogProps) {
    }

    public render(): React.ReactElement<ITrackRentalRequestDialogProps> {
        const { classes, width } = this.props;
        return (
            <React.Fragment>
            {(this.state._isMounted && !this.props.loading) &&
            <>
                <DialogTitle>New Track Rental Request </DialogTitle>
                <DialogContent dividers>
                    <TrackRentalRequestForm
                        {...this.state}
                        classes={this.props.classes}
                        handleDateClick={this.handleDateClick}
                        handleChange={this.handleChange}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.props.closeDialog} variant="contained">Cancel</Button>
                    <Button onClick={this.onSubmit} variant="contained">Submit Request</Button>
                </DialogActions>
            </>
            }
            </React.Fragment>
        )
    }
}

const styles = createStyles((theme: Theme) => ({
    paper: {
        padding: theme.spacing(3, 2),
        position: 'relative',
    },
    center:{
        textAlign: 'center',
    },
    submit: {
        backgroundColor: green[500],
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        color: 'white',
        '&:hover': {
            backgroundColor: green[700],
        }
    },
}))

export default withWidth()(withStyles(styles, { withTheme: true })(TrackRentalRequestDialog));
