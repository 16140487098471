import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import UnitSelector from './UnitSelector';
import { withStyles, createStyles, Theme } from '@material-ui/core/styles';
import { MenuItem, Checkbox } from '@material-ui/core';
import PrepShop from '../interfaces/IPrepShop';
import APIService from '../services/APIService';
// @ts-ignore
import MuiPhoneNumber from "material-ui-phone-number";

export interface IPaddockFormProps {
    handleChange: any;
    handleToggle: any;
    handleChangeUnit: any;
    handlePrepShopSelect: any;
    handlePhoneChange: any;
    removableTrailer: boolean;
    removableTrailerLength: number | null;
    transporterType: string;
    transporterLength: number | null;
    transporterWidth: number | null;
    awning: boolean;
    awningWidth: number | null;
    awningLength: number | null;
    sharePitWith: string;
    pitNextTo: string;
    prepShop: PrepShop | null;
    customPrepShopTeam: string;
    customPrepShopNumber: string;
    customPrepShopEmail: string;
    imperial: boolean;
    isInterestedInTOP: boolean;
    customPrepShop: boolean;
    awningOrientation: string;
    classes: any;
    editMode: boolean;
    validationAttempt: boolean;
}

export interface IPaddockFormState {
    prepShopList: PrepShop[];
    _isMounted: boolean;
}

export class PaddockInfo extends Component<IPaddockFormProps, IPaddockFormState> {
    private apiService: APIService = new APIService;
    public constructor(props: Readonly<IPaddockFormProps>) {
        super(props);
        this.state = {
            prepShopList: [],
            _isMounted: false
        };
        this.getPrepShopList = this.getPrepShopList.bind(this);
        this.apiService = new APIService;
    }

    private awningOrientationTypes = [{ value: 'driver', label: 'Driver Side' },
    { value: 'passenger', label: 'Curb/Passenger Side' }];

    private handlePrepShopSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
        const customPrepShop: PrepShop = this.state.prepShopList.filter(item => item.id === event.target.value)[0];
        this.props.handlePrepShopSelect(customPrepShop);
    }

    private getPrepShopList = async () => {
        await this.apiService.getPrepShopList().then((result : any) => {
            this.setState({ prepShopList: result })
        })
    }

    public componentDidMount() {
        this.getPrepShopList().then(() => { this.setState({ _isMounted: true }); });;
    }

    public render(): React.ReactElement {
        const { classes } = this.props;
        return (
            <React.Fragment>
            {(this.state._isMounted) &&
            <Grid container spacing={1}>
                <Grid item xs={12} sm={4} className={classes.switch}>
                    <FormControlLabel className={classes.formSwitchLabel}
                        control={
                            <Switch
                                name="removableTrailer"
                                checked={this.props.removableTrailer}
                                value={(this.props.removableTrailer === null) ? false : this.props.removableTrailer}
                                color="secondary"
                                onChange={this.props.handleToggle}
                                disabled={!this.props.editMode}
                            />
                        }
                        label="Removable Trailer"
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    {this.props.removableTrailer && <React.Fragment>
                        <TextField
                            name="removableTrailerLength"
                            label="Removable Trailer Length"
                            className={classes.textField}
                            margin="normal"
                            onChange={this.props.handleChange}
                            value={(this.props.removableTrailerLength === null) ? "" : this.props.removableTrailerLength}
                            disabled={!this.props.editMode}
                            type="number"
                            InputLabelProps={{ shrink: true }}
                        />
                        <UnitSelector handleChangeUnit={this.props.handleChangeUnit} imperial={this.props.imperial} metricFactorSize="m" imperialFactorSize="ft" disabled={!this.props.editMode} />
                    </React.Fragment>
                    }
                </Grid>
                <Grid item xs={12}>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField
                        name="transporterType"
                        label="Transporter/Trailer Type"
                        className={classes.textField}
                        value={(this.props.transporterType === null) ? "" : this.props.transporterType}
                        onChange={this.props.handleChange}
                        disabled={!this.props.editMode}
                        margin="normal"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField
                        name="transporterLength"
                        label="Overall Transporter Length"
                        className={classes.textField}
                        value={(this.props.transporterLength === null) ? "" : this.props.transporterLength}
                        onChange={this.props.handleChange}
                        disabled={!this.props.editMode}
                        margin="normal"
                        type="number"
                        InputLabelProps={{ shrink: true }}
                    />
                    <UnitSelector handleChangeUnit={this.props.handleChangeUnit} imperial={this.props.imperial} metricFactorSize="m" imperialFactorSize="ft" disabled={!this.props.editMode} />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField
                        name="transporterWidth"
                        label="Overall Transporter Width"
                        className={classes.textField}
                        value={(this.props.transporterWidth === null) ? "" : this.props.transporterWidth}
                        onChange={this.props.handleChange}
                        disabled={!this.props.editMode}
                        margin="normal"
                        type="number"
                        InputLabelProps={{ shrink: true }}
                    />
                    <UnitSelector handleChangeUnit={this.props.handleChangeUnit} imperial={this.props.imperial} metricFactorSize="m" imperialFactorSize="ft" disabled={!this.props.editMode} />
                </Grid>
                <Grid item xs={12} sm={3} className={classes.switch}>
                    <FormControlLabel className={classes.formSwitchLabel}
                        control={
                            <Switch
                                name="awning"
                                checked={this.props.awning}
                                value={(this.props.awning === null) ? false : this.props.awning}
                                color="secondary"
                                onChange={this.props.handleToggle}
                                disabled={!this.props.editMode}
                            />
                        }
                        label="Has Awning"

                    />
                </Grid>
                {this.props.awning && <React.Fragment>
                    <Grid item xs={12} sm={3}>
                        <TextField
                            name="awningLength"
                            label="Awning Length"
                            className={classes.textField}
                            value={(this.props.awningLength === null) ? "" : this.props.awningLength}
                            onChange={this.props.handleChange}
                            disabled={!this.props.editMode}
                            margin="normal"
                            type="number"
                            InputLabelProps={{ shrink: true }}
                        />
                        <UnitSelector handleChangeUnit={this.props.handleChangeUnit} imperial={this.props.imperial} metricFactorSize="m" imperialFactorSize="ft" disabled={!this.props.editMode} />

                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <TextField
                            name="awningWidth"
                            label="Awning Width"
                            className={classes.textField}
                            value={(this.props.awningWidth === null) ? "" : this.props.awningWidth}
                            onChange={this.props.handleChange}
                            disabled={!this.props.editMode}
                            margin="normal"
                            type="number"
                            InputLabelProps={{ shrink: true }}
                        />
                        <UnitSelector handleChangeUnit={this.props.handleChangeUnit} imperial={this.props.imperial} metricFactorSize="m" imperialFactorSize="ft" disabled={!this.props.editMode} />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TextField
                            name="awningOrientation"
                            required
                            select
                            fullWidth
                            label="Awning Orientation"
                            disabled={!this.props.editMode}
                            className={classes.textField}
                            value={(this.props.awningOrientation === null) ? "" : this.props.awningOrientation}
                            error={(this.props.awningOrientation === null || this.props.awningOrientation === "") && this.props.validationAttempt}
                            helperText={(this.props.validationAttempt && this.props.awningOrientation === "") ? "Required Field" : ""}
                            onChange={this.props.handleChange}
                            SelectProps={{
                                MenuProps: {
                                    className: classes.menu,
                                },
                            }}
                            margin="normal"
                        >
                            {this.awningOrientationTypes.map(option => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                </React.Fragment>}
                <Grid item xs={12}>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        name="sharePitWith"
                        label="Participants you'd like to share pit space with"
                        className={classes.textField}
                        value={(this.props.sharePitWith === null) ? "" : this.props.sharePitWith}
                        onChange={this.props.handleChange}
                        disabled={!this.props.editMode}
                        margin="normal"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        name="pitNextTo"
                        label="Participants you'd like to be pitted next to"
                        className={classes.textField}
                        value={(this.props.pitNextTo === null) ? "" : this.props.pitNextTo}
                        onChange={this.props.handleChange}
                        disabled={!this.props.editMode}
                        margin="normal"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} className={classes.center}>
                    <FormControlLabel className={classes.formSwitchLabel}
                        control={
                            <Switch
                                name="customPrepShop"
                                checked={this.props.customPrepShop}
                                value={(this.props.customPrepShop === null) ? false : this.props.customPrepShop}
                                color="secondary"
                                onChange={this.props.handleToggle}
                                disabled={!this.props.editMode}
                            />
                        }
                        label="Custom Prep Shop"

                    />
                </Grid>
                {!this.props.customPrepShop && <React.Fragment>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            name="prepShop"
                            select
                            label="Prep Shop Name"
                            className={classes.textField}
                            value={(this.props.prepShop === null) ? '' : this.props.prepShop.id}
                            onChange={this.handlePrepShopSelect}
                            disabled={!this.props.editMode}
                            SelectProps={{
                                MenuProps: {
                                    className: classes.menu,
                                },
                            }}
                            margin="normal"
                            fullWidth
                        >
                            {this.state.prepShopList.map((option, index) => (
                                <MenuItem key={index} value={option.id}>
                                    {option.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            InputProps={{
                                readOnly: true,
                            }}
                            name="prepShopNumber"
                            label="Prep Shop Contact Number"
                            value={(this.props.prepShop === null) ? '' : this.props.prepShop.phone}
                            onChange={this.props.handleChange}
                            margin="normal"
                            disabled
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            InputProps={{
                                readOnly: true,
                            }}
                            name="prepShopEmail"
                            label="Prep Shop Email"
                            className={classes.textField}
                            value={(this.props.prepShop === null) ? '' : this.props.prepShop.email}
                            onChange={this.props.handleChange}
                            margin="normal"
                            disabled
                            fullWidth
                        />
                    </Grid>
                </React.Fragment>}
                {this.props.customPrepShop && <React.Fragment>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            name="customPrepShopTeam"
                            label="Prep Shop Name"
                            className={classes.textField}
                            value={(this.props.customPrepShopTeam === null) ? '' : this.props.customPrepShopTeam}
                            onChange={this.props.handleChange}
                            disabled={!this.props.editMode}
                            margin="normal"
                            fullWidth
                        >
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <MuiPhoneNumber
                            defaultCountry={'us'} 
                            name="customPrepShopNumber"
                            label="Phone Number"
                            onChange={(val:string) => this.props.handlePhoneChange(val, "customPrepShopNumber")}
                            value={this.props.customPrepShopNumber}
                            disabled={!this.props.editMode}
                            required
                            fullWidth
                            margin="normal"
                            error={(this.props.customPrepShopNumber !== null) ? this.props.customPrepShopNumber.replace(/[\W\D\S]/, "") === "" : ""}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            name="customPrepShopEmail"
                            label="Prep Shop Email"
                            className={classes.textField}
                            value={(this.props.customPrepShopEmail === null) ? '' : this.props.customPrepShopEmail}
                            onChange={this.props.handleChange}
                            disabled={!this.props.editMode}
                            margin="normal"
                            fullWidth
                        />
                    </Grid>
                </React.Fragment>}

                <Grid item xs={12} className={classes.center}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                name="isInterestedInTOP"
                                checked={this.props.isInterestedInTOP}
                                onChange={this.props.handleToggle}
                                disabled={!this.props.editMode}
                                className={classes.checkbox}
                                value={(this.props.isInterestedInTOP === null) ? false : this.props.isInterestedInTOP}
                                inputProps={{ 'aria-label': 'primary checkbox', }}
                            />
                        }
                        label={<Typography variant="subtitle1"> I am interested in a Track Orientation Program (TOP)</Typography>}
                    />
                </Grid>
            </Grid>
            }</React.Fragment>
        );
    }
}

const styles = createStyles((theme: Theme) => ({
    button: {
        margin: theme.spacing(1),
    },
    checkbox: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
    },
    paper: {
        padding: theme.spacing(2, 2),
        marginLeft: '25%',
        marginRight: '25%',
    },
    center: {
        textAlign: 'center',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    root: {
        width: '90%',
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
    completed: {
        display: 'inline-block',
    },
    wizardControl: {
        marginTop: 15,
        textAlign: 'center',
    },
    regStep: {
        textAlign: 'center',
    },
    formSwitchLabel: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(3)
    }
}))

export default withStyles(styles)(PaddockInfo);