import React, { Component } from 'react';
import { withStyles, createStyles, Theme } from '@material-ui/core/styles';
import PersonIcon from '@material-ui/icons/Person';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import PeopleIcon from '@material-ui/icons/People';
import CarForm from './CarForm';
import DriverForm from './DriverForm';
import CrewForm from './CrewForm';
import LoungeForm from './LoungeForm';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import MobileStepper from '@material-ui/core/MobileStepper';
import CloseIcon from '@material-ui/icons/Close';
import { Button, Box, Stepper, Step, StepLabel, Typography, StepButton, CircularProgress, Dialog, DialogTitle, DialogContent, DialogActions, withWidth, IconButton, Checkbox, FormControlLabel } from '@material-ui/core';
import { green, red, blue } from '@material-ui/core/colors';
import Crew from '../interfaces/ICrew';
import Class from '../interfaces/IClass';
import clsx from 'clsx';
import { StepIconProps } from '@material-ui/core/StepIcon';
import Car from '../interfaces/ICar';
import RegConfirmation from './RegConfirmation';
import Entrant from '../interfaces/IEntrant';
import Entry from '../interfaces/IEntry';
import StepConnector from '@material-ui/core/StepConnector';
import HistoricalCars from './HistoricalCars';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import APIService from '../services/APIService';
import convert from '../util/UnitConversion';
import LoungeMember from '../interfaces/ILoungeMember';
import Race from '../interfaces/IRace';

//                     ____ 
//                   .'* *.'
//                __/_*_*(_
//               / _______ \
//              _\_)/___\(_/_ 
//            /_((  U   U ))_\
//             \ \()) - (()/ /
//              ' \(((()))/ '
//             / ' \)).))/ ' \
//            / _ \ - | - /_  \
//           (   ( .;''';. .'  )
//           _\"__ /    )\ __"/_
//             \/  \   ' /  \/
//              .'  '...' ' )
//               / /  |  \ \
//              / .   .   . \
//             /   .     .   \
//            /   /   |   \   \
//          .'   /    b    '.  '.
//      _.-'    /     Bb     '-. '-._ 
//  _.-'       |      BBb       '-.  '-. 
// (________mrf\____.dBBBb.________)____)
export interface ICarWizardProps {
    classes: any;
    theme: any;
    width: any;
    handleCancel: any;
    setMessage: any;
    editMode: boolean;
    setLoading: any;
    entry: Entry;
    car?: Car;
    loading: boolean;
    showCarDialog: boolean;
    upcomingYear?: string;
}
export interface ICarWizardState {
    _isMounted: boolean;
    steps: number[];
    completedSteps: number[];
    activeStep: number;
    skippedSteps: number[];
    prevActiveStep: number;
    steplabels: string[];
    selectedRaces: Race[];
    raceOptions: Race[];
    carYear?: number;
    carMake: string;
    carModel: string;
    carChassis: string;
    carClass: any;
    carColor: string;
    transponderRental: boolean;
    transponderNumber?: number;
    displacement?: number;
    horsepower?: number;
    induction: string;
    lubeSys: string;
    brakeSys: string;
    originalBrakes: boolean;
    modded: boolean;
    modifications: string;
    tireMake: string;
    tireModel: string;
    tireFrontSize: string;
    tireRearSize: string;
    wheelFrontWidth: number | null;
    wheelRearWidth: number | null;
    wheelFrontDia: number | null;
    wheelRearDia: number | null;
    prefCarNum?: string;
    altCarNum?: string;
    altCarNum2?: string;
    carHistory: string;
    imperial: boolean;
    carImage: File | null;
    ClassesList: Class[];
    sameAsEntrant: boolean
    driverEmail: string;
    driverEmailConfirm: string;
    driverFName: string;
    driverMName?: string;
    driverLName: string;
    driverPhone: string;
    driverDOB: Date | null;
    driverAdd1: string;
    driverAdd2?: string;
    driverAdd3?: string;
    driverCity: string;
    driverState: string;
    driverCountry: string;
    driverPostal: string;
    ICEName: string;
    ICENumber: string;
    driverLicenseIssuer: string;
    driverLicenseExpiry: Date | null;
    driverMedicalIssuer: string;
    driverMedicalExpiry: Date | null;
    driverYears?: number;
    driverNumRaces?: number;
    driverRacesList: string;
    driverIncidents: string;
    driverSuspensions: string;
    blackFlagged: boolean;
    blackFlaggedReason: string;
    driverId?: string;
    driverPeopleId?: string;
    tshirtSize: string;
    crew: Crew[];
    loungeMembers: LoungeMember[];

    setMessage: any;
    handleChange: any;
    handleAutoSuggestChange: any;
    handleToggle: any;
    handleRaceSelect: any;
    handleChangeUnit: any;
    handleDateChange: any;
    updateCrew: any;
    handleImageUpload: any;
    deleteImage: any;
    handleClassSelect: any;
    handlePhoneChange: any;
    addHistoricalCar: any;
    showDialog: boolean;
    historicalImage: boolean;
    historicalId: string;
    validationAttempt: boolean;
    editMode: boolean;
    entrant: Entrant;
    dialogContent: string;
    scrollAtBottom?: boolean;
    updateLoungeMembers: any;
    agreementChecked: boolean;
}
export class CarWizard extends Component<ICarWizardProps, ICarWizardState> {
    private apiService: APIService = new APIService;
    public constructor(props: Readonly<ICarWizardProps>) {
        super(props);
        this.state = {
            _isMounted: false,
            steps: [0, 1, 2, 4],
            completedSteps: [],
            activeStep: 0,
            skippedSteps: [],
            prevActiveStep: -1,
            steplabels: ["Car Information", "Driver Information", "Crew Information", "Confirmation"],
            carMake: "",
            carImage: null,
            transponderRental: false,
            modded: false,
            originalBrakes: false,
            imperial: true,
            selectedRaces: [],
            tireFrontSize: "",
            tireRearSize: "",
            wheelFrontDia: null,
            wheelRearDia: null,
            wheelFrontWidth: null,
            wheelRearWidth: null,
            carYear: undefined,
            carModel: "",
            carChassis: "",
            carClass: "",
            carColor: "",
            transponderNumber: undefined,
            displacement: undefined,
            horsepower: undefined,
            induction: "",
            lubeSys: "",
            brakeSys: "",
            modifications: "",
            tireMake: "",
            tireModel: "",
            prefCarNum: undefined,
            altCarNum: undefined,
            altCarNum2: undefined,
            carHistory: "",
            ClassesList: [],
            sameAsEntrant: false,
            driverEmail: "",
            driverEmailConfirm: "",
            driverFName: "",
            driverMName: "",
            driverLName: "",
            driverPhone: "",
            driverDOB: null,
            driverAdd1: "",
            driverAdd2: "",
            driverAdd3: "",
            driverCity: "",
            driverState: "",
            driverPostal: "",
            ICEName: "",
            ICENumber: "",
            driverLicenseIssuer: "",
            driverLicenseExpiry: null,
            driverMedicalIssuer: "",
            driverMedicalExpiry: null,
            driverYears: undefined,
            driverNumRaces: undefined,
            driverRacesList: "",
            driverIncidents: "",
            driverSuspensions: "",
            blackFlaggedReason: "",
            blackFlagged: false,
            driverCountry: "",
            historicalImage: false,
            showDialog: false,
            historicalId: "",
            dialogContent: "",
            tshirtSize: "M",
            crew: [],
            loungeMembers: [],
            handlePhoneChange: this.handlePhoneChange.bind(this),
            handleChange: this.handleChange,
            handleDateChange: this.handleDateChange,
            handleAutoSuggestChange: this.handleAutoSuggestChange,
            handleToggle: this.handleToggle,
            handleRaceSelect: this.handleRaceSelect,
            handleChangeUnit: this.handleChangeUnit,
            updateLoungeMembers: this.updateLoungeMembers,
            updateCrew: this.updateCrew,
            handleImageUpload: this.handleImageUpload.bind(this),
            deleteImage: this.deleteImage.bind(this),
            handleClassSelect: this.handleClassSelect.bind(this),
            setMessage: this.props.setMessage,
            addHistoricalCar: this.addHistoricalCar.bind(this),
            validationAttempt: false,
            editMode: this.props.editMode,
            entrant: { id: "", fname: "", mname: "", lname: "", dob: new Date(), address1: "", address2: "", address3: "", city: "", state: "", country: "", zipCode: "", phoneNumber: "", email: "", usesMetricSystem: false },
            raceOptions: [],
            agreementChecked: false
        }
        this.getStepContent = this.getStepContent.bind(this);
        this.handleNext = this.handleNext.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
        this.updateCrew = this.updateCrew.bind(this);
        this.updateLoungeMembers = this.updateLoungeMembers.bind(this);
        this.handleAutoSuggestChange = this.handleAutoSuggestChange.bind(this);
        this.handleImageUpload = this.handleImageUpload.bind(this);
        this.deleteImage = this.deleteImage.bind(this)
        this.ColorlibStepIcon = this.ColorlibStepIcon.bind(this);
        this.handleClassSelect = this.handleClassSelect.bind(this);
        this.handlePhoneChange = this.handlePhoneChange.bind(this);
        this.addHistoricalCar = this.addHistoricalCar.bind(this);
        this.closeDialog = this.closeDialog.bind(this);
        this.populateCarFromHistorical = this.populateCarFromHistorical.bind(this);
        this.showConfirmationDialog = this.showConfirmationDialog.bind(this);
        this.getEntrantDriverInfo = this.getEntrantDriverInfo.bind(this);
        this.ScrollChevron = this.ScrollChevron.bind(this);
        // this.handleScroll = this.handleScroll.bind(this);
        this.apiService = new APIService;
    };

    private addHistoricalCar(): void {
        this.setState({ dialogContent: "historical" })
        this.setState({ showDialog: true })
    };

    private showConfirmationDialog(): void {
        this.setState({ dialogContent: "confirmation" })
        this.setState({ showDialog: true })
    };

    private closeDialog(): void {
        this.setState({ showDialog: false })
    };

    private handleImageUpload(file: File): void {
        this.setState({ historicalImage: false })
        this.setState({ carImage: file })
    };

    private deleteImage(): void {
        this.setState({ carImage: null })
    };

    private populateCarFromHistorical = (car: Car): void => {
        this.setState({
            carMake: car.make,
            carImage: car.carImageToDisplay,
            transponderRental: car.isTransponderRentalNeeded,
            modded: car.isModified,
            originalBrakes: car.isBrakeSystemOriginal,
            tireFrontSize: car.tireSizeFront,
            tireRearSize: car.tireSizeRear,
            wheelFrontDia: car.frontWheelDiameter,
            wheelRearDia: car.rearWheelDiameter,
            wheelFrontWidth: car.frontWheelWidth,
            wheelRearWidth: car.rearWheelWidth,
            carYear: car.year,
            carModel: car.model,
            carChassis: car.chassisNumber,
            carClass: "",
            carColor: car.color,
            transponderNumber: car.transponderNumber,
            displacement: car.displacement,
            horsepower: car.horsePower,
            induction: car.inductionType,
            lubeSys: car.lubricationSystem,
            brakeSys: car.brakeType,
            modifications: car.modificationReason,
            tireMake: car.tireMake,
            tireModel: car.tireModel,
            prefCarNum: car.preferredCarNumber,
            altCarNum: car.alternateCarNumber1,
            altCarNum2: car.alternateCarNumber2,
            carHistory: car.historyOfCar,
            historicalImage: (car.carImageToDisplay !== null) ? true : false,
            historicalId: car.id
        });
        this.closeDialog();
    }

    private handleClassSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newClass: Class = this.state.ClassesList.filter((item: any) => item.id === event.target.value)[0];
        this.setState({ carClass: newClass.name });
    };

    private handlePhoneChange = (phone: string, field: string) => {
        const newState = { [field]: phone } as any;
        this.setState(newState);
    };

    private handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newState = { [event.target.name]: event.target.value } as any;
        this.setState(newState);
    };

    private handleRaceSelect = (race : Race) => {
        let current : Race[] = this.state.selectedRaces;
        if(current.some(x => x.id === race.id)){
            current.splice(current.indexOf(current.filter(x => x.id === race.id)[0]), 1);
        }else{
            current.push(race);
        }
        this.setState({selectedRaces : current});
    };

    private handleAutoSuggestChange = (field: string, value: string) => {
        const newState = { [field]: value } as any;
        this.setState(newState);
    };

    private handleDateChange = (date: Date | null, field: string) => {
        const newState = { [field]: date } as any;
        this.setState(newState);
    };
    
    private getClassesList = async () => {
        await this.apiService.getClasses().then((result : any) => {
            this.setState({ ClassesList: result })
        })
    }

    private getEntrant = async () => {
        this.props.setLoading(true);
        await this.apiService.getEntrant().then((result : any) => {
            this.setState({entrant : result.data}); 
            this.setState({imperial: !result.data.usesMetricSystem});
            if (result.usesMetricSystem) {
                this.setState({
                    wheelFrontDia: convert(this.state.wheelFrontDia, "metric", "mm"),
                    wheelRearDia: convert(this.state.wheelRearDia, "metric", "mm"),
                    wheelFrontWidth: convert(this.state.wheelFrontWidth, "metric", "mm"),
                    wheelRearWidth: convert(this.state.wheelRearWidth, "metric", "mm")
                });
            }
            this.props.setLoading(false);
        })
    }

    private getEntrantDriverInfo = async () => {
        await this.apiService.getEntrantDriverInfo().then((data : any) => {
            if (data.result !== null){
                this.setState({
                    driverIncidents: data.result.incidents, driverLicenseExpiry: data.result.licenseExpiry,
                    driverLicenseIssuer: data.result.licenseIssuer, driverMedicalExpiry: data.result.medicalExpiry,
                    driverMedicalIssuer: data.result.medicalIssuer, driverSuspensions: data.result.suspensions,
                    driverNumRaces: data.result.numOfVintageEvents, driverRacesList: data.result.racesList,
                    driverYears: data.result.yearsDriving, driverId: data.result.id,
                    driverPeopleId: data.result.peopleId, blackFlagged: data.result.isBlackFlagged,
                    blackFlaggedReason: data.result.blackFlaggedReason, ICEName: data.result.icename,
                    ICENumber: data.result.icenumber
                })
            }
        })
    }
    private handleSubmit = async (action: string) => {
        this.props.setLoading(true);
        if (this.state.carYear === null || this.state.carMake === null || this.state.carModel === null ||
            this.state.carYear === undefined || this.state.carMake === "" || this.state.carModel === "" || this.state.selectedRaces.length === 0)  {
            this.props.setMessage("Races, Car Year, Make, and Model are required to save as draft.", "error");
            this.props.setLoading(false);
            return;
        }
        var driverList: any = [{
            Fname: this.state.driverFName,
            Mname: this.state.driverMName,
            Lname: this.state.driverLName,
            Email: this.state.driverEmail,
            Address1: this.state.driverAdd1,
            Address2: this.state.driverAdd2,
            Address3: this.state.driverAdd3,
            PhoneNumber: this.state.driverPhone,
            City: this.state.driverCity,
            state: this.state.driverState,
            ZipCode: this.state.driverPostal,
            Country: this.state.driverCountry,
            UsesMetricSystem: !this.state.imperial,
            Icename: this.state.ICEName,
            Icenumber: this.state.ICENumber,
            LicenseIssuer: this.state.driverLicenseIssuer,
            LicenseExpiry: this.state.driverLicenseExpiry,
            MedicalIssuer: this.state.driverMedicalIssuer,
            MedicalExpiry: this.state.driverMedicalExpiry,
            YearsDriving: this.state.driverYears !== undefined && (this.state.driverYears > 999 || this.state.driverYears < 0) ? 0 : this.state.driverYears,
            RacesList: this.state.driverRacesList,
            Incidents: this.state.driverIncidents,
            Suspensions: this.state.driverSuspensions,
            IsBlackFlagged: this.state.blackFlagged,
            BlackFlaggedReason: this.state.blackFlaggedReason,
            NumOfVintageEvents: this.state.driverNumRaces !== undefined && (this.state.driverNumRaces > 999 || this.state.driverNumRaces < 0) ? 0 : this.state.driverNumRaces,
            PeopleId: this.state.sameAsEntrant ? this.state.entrant.id : "00000000-0000-0000-0000-000000000000"
        }]

        if (this.state.driverDOB !== null) {
            driverList[0].Dob = this.state.driverDOB
        }
        if (this.state.driverPeopleId !== undefined && this.state.driverPeopleId !== "") {
            driverList[0].PeopleId = this.state.driverPeopleId
        }
        if (this.state.driverId !== undefined && this.state.driverId !== "") {
            driverList[0].DriverId = this.state.driverId
        }
        var crewList: Crew[] = [];
        this.state.selectedRaces.forEach(race => {
            this.state.crew.filter(x => x.race?.id === race.id).forEach(crew =>
                {
                    crewList.push(crew);
                })
        });

        var loungeList: LoungeMember[] = [];
        if(this.state.selectedRaces.some(x => x.hasLounge === true)){
            loungeList = this.state.loungeMembers;
        }
        var car: any = {
            RegistrationFormId: this.props.entry.id,
            Model: this.state.carModel,
            Make: this.state.carMake,
            Year: this.state.carYear,
            ChassisNumber: this.state.carChassis,
            Class: this.state.carClass,
            Color: this.state.carColor,
            TransponderNumber: this.state.transponderNumber,
            IsTransponderRentalNeeded: this.state.transponderRental,
            Displacement: this.state.displacement,
            HorsePower: this.state.horsepower,
            InductionType: this.state.induction,
            LubricationSystem: this.state.lubeSys,
            BrakeType: this.state.brakeSys,
            IsBrakeSystemOriginal: this.state.originalBrakes,
            IsModified: this.state.modded,
            ModificationReason: this.state.modifications,
            TireMake: this.state.tireMake,
            TireModel: this.state.tireModel,
            TireSizeFront: this.state.tireFrontSize,
            TireSizeRear: this.state.tireRearSize,
            FrontWheelWidth: (this.state.imperial) ? this.state.wheelFrontWidth : convert(this.state.wheelFrontWidth, "imperial", "inches"),
            FrontWheelDiameter: (this.state.imperial) ? this.state.wheelFrontDia : convert(this.state.wheelFrontDia, "imperial", "inches"),
            RearWheelWidth: (this.state.imperial) ? this.state.wheelRearWidth : convert(this.state.wheelRearWidth, "imperial", "inches"),
            RearWheelDiameter: (this.state.imperial) ? this.state.wheelRearDia : convert(this.state.wheelRearDia, "imperial", "inches"),
            PreferredCarNumber: this.state.prefCarNum,
            AlternateCarNumber1: this.state.altCarNum,
            AlternateCarNumber2: this.state.altCarNum2,
            HistoryOfCar: this.state.carHistory,
            ParticipatingYear: this.props.entry.participatingYear,
            Status: action === "submit" ? "Pending" : "Draft",
            IsPaid: false,
            DriverModel: driverList,
            Crew: crewList,
            Lounge: loungeList,
            CarRace: [],
            TshirtSize: this.state.tshirtSize
        };
        this.state.selectedRaces.forEach(race => {
            car.CarRace.push({RaceId: race.id});
        });
        if (this.props.car !== undefined) {
            car.Id = this.props.car.id
        }
        if (this.state.editMode){
            await this.apiService.updateCar(this.props.entry.id, car.Id ,car, this.state.imperial, this.state.carImage, this.state.historicalImage, this.state.historicalId).then((result : any) => {
                if(result.ok){
                    this.props.setMessage("Car Successfully Updated.", "success")
                    this.props.handleCancel(); 
                }else{
                    this.props.setMessage("Error completing operation. Please try again. (" + result.status + ")", "error");
                    this.props.setLoading(false);
                }
            }).finally(() => {                
                if(this.state.showDialog){
                    this.closeDialog();
                }
            })
        }
        else{
            await this.apiService.addCar(this.props.entry.id, car, this.state.imperial, this.state.carImage, this.state.historicalImage, this.state.historicalId).then((result : any) => {
                if(result.ok){
                    this.props.setMessage("Car Successfully Created.", "success")
                    this.props.handleCancel(); 
                }else{
                    this.props.setMessage("Error completing operation. Please try again. (" + result.status + ")", "error");
                    this.props.setLoading(false);
                }
            }).finally(() => {
                if(this.state.showDialog){
                    this.closeDialog();
                }
            })
        }
    };

    private handleToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.name === "sameAsEntrant" && !this.state[event.target.name as keyof ICarWizardState]) {
            this.setState({ driverPhone: this.state.entrant.phoneNumber });
            this.setState({
                driverAdd1: this.state.entrant.address1, driverAdd2: this.state.entrant.address2, driverAdd3: this.state.entrant.address3, driverCity: this.state.entrant.city,
                driverCountry: this.state.entrant.country, driverDOB: this.state.entrant.dob, driverEmail: this.state.entrant.email, driverEmailConfirm: this.state.entrant.email,
                driverFName: this.state.entrant.fname, driverLName: this.state.entrant.lname, driverMName: this.state.entrant.mname, driverPostal: this.state.entrant.zipCode,
                driverState: this.state.entrant.state, driverPeopleId: this.state.entrant.id, driverId: ""
            })
            this.getEntrantDriverInfo();
        }
        if (event.target.name === "sameAsEntrant" && this.state[event.target.name as keyof ICarWizardState]) {
            this.setState({ driverPhone: "" });
            this.setState({
                driverAdd1: "", driverAdd2: "", driverAdd3: "", driverCity: "",
                driverCountry: "", driverDOB: null, driverEmail: "", driverEmailConfirm: "",
                driverFName: "", driverLName: "", driverMName: "", driverPostal: "",
                driverState: ""
            })
            this.setState({
                driverIncidents: "", ICENumber: "",
                driverLicenseExpiry: null, driverLicenseIssuer: "",
                driverMedicalExpiry: null, driverMedicalIssuer: "",
                driverSuspensions: "", driverNumRaces: undefined,
                driverRacesList: "", driverYears: undefined,
                driverId: "", driverPeopleId: "", blackFlagged: false,
                blackFlaggedReason: "", ICEName: ""
            })
        }
        const newState = { [event.target.name]: !this.state[event.target.name as keyof ICarWizardState] } as any;
        this.setState(newState);
    };

    private updateCrew = (crewMembers: Crew[]): void => {
        const newState = { crew: crewMembers } as any;
        this.setState(newState);
    };

    private updateLoungeMembers = (loungeMembers: LoungeMember[]): void => {
        this.setState({loungeMembers});
    };
    
    private handleChangeUnit = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
        if (event.target.value === "imperial" && this.state.imperial === false) {
            this.setState({
                wheelFrontDia: convert(this.state.wheelFrontDia, "imperial", "in"),
                wheelRearDia: convert(this.state.wheelRearDia, "imperial", "in"),
                wheelFrontWidth: convert(this.state.wheelFrontWidth, "imperial", "in"),
                wheelRearWidth: convert(this.state.wheelRearWidth, "imperial", "in"), 
                imperial: true
            });
        }
        if (event.target.value === "metric" && this.state.imperial === true) {
            this.setState({
                wheelFrontDia: convert(this.state.wheelFrontDia, "metric", "mm"),
                wheelRearDia: convert(this.state.wheelRearDia, "metric", "mm"),
                wheelFrontWidth: convert(this.state.wheelFrontWidth, "metric", "mm"),
                wheelRearWidth: convert(this.state.wheelRearWidth, "metric", "mm"), 
                imperial: false
            });
        }
    }

    private handleNext(): void {
        this.setState({ validationAttempt: true });
        switch (this.state.activeStep) {
            case 0:
                if ((this.state.carYear === null || this.state.carYear === undefined) || (this.state.induction === null || this.state.induction === "") ||
                    (this.state.carMake === null || this.state.carMake === "") || (this.state.carModel === null || this.state.carModel === "") ||
                    (this.state.selectedRaces.length === 0) ||
                    (this.state.carChassis === null || this.state.carChassis === "") || (this.state.wheelRearDia === null || this.state.wheelRearDia === undefined) ||
                    (this.state.wheelFrontDia === null || this.state.wheelFrontDia === undefined) || (this.state.wheelRearWidth === null || this.state.wheelRearWidth === undefined) ||
                    (this.state.wheelFrontWidth == null || this.state.wheelFrontWidth === undefined) || (this.state.carColor === null || this.state.carColor === "") ||
                    (this.state.displacement === null || this.state.displacement === undefined) || (this.state.horsepower === null || this.state.horsepower === undefined) ||
                    (this.state.lubeSys === null || this.state.lubeSys === "") || (this.state.brakeSys === null || this.state.brakeSys === "") ||
                    ((this.state.modded && this.state.modifications === null) || (this.state.modded && this.state.modifications === "")) ||
                    (this.state.tireMake === null || this.state.tireMake === "") || (this.state.tireModel === null || this.state.tireModel === "") ||
                    (this.state.tireFrontSize === null || this.state.tireFrontSize === "") || (this.state.tireRearSize === null || this.state.tireRearSize === "") ||
                    (this.state.altCarNum === null || this.state.altCarNum === "") || (this.state.altCarNum2 === null || this.state.altCarNum2 === "") ||
                    (this.state.prefCarNum === null || this.state.prefCarNum === "") || (this.state.carHistory === null || this.state.carHistory === "")) {
                    this.props.setMessage("Missing Required Fields", "error");
                    return;
                }
                if (this.state.carYear !== undefined && (this.state.carYear > (new Date().getFullYear() + 1) || this.state.carYear < 1900)) {
                    this.props.setMessage("Invalid Information", "error");
                    return;
                }
                if(this.state.carImage?.size === 0){
                    this.props.setMessage("Image cannot be 0KB.", "error")
                }
                if (isNaN(this.state.carYear) || this.state.carYear as any === "" || isNaN(this.state.wheelFrontDia) || this.state.wheelFrontDia as any === "" ||
                    isNaN(this.state.wheelFrontWidth) || this.state.wheelFrontWidth as any === "" || isNaN(this.state.wheelRearWidth) || this.state.wheelRearWidth as any === "" ||
                    isNaN(this.state.wheelRearDia) || this.state.wheelRearDia as any === "" || isNaN(this.state.displacement) || this.state.displacement as any === "" ||
                    isNaN(this.state.horsepower) || this.state.horsepower as any === "") {
                    this.props.setMessage("Value Must Be a Number", "error");
                    return;
                }
                break;
            case 1:
                if (this.state.driverFName === null || this.state.driverLName === null || this.state.driverEmail === null || this.state.driverEmailConfirm === null || this.state.driverPhone === null ||
                    this.state.driverDOB === null || this.state.driverCity === null || this.state.driverPostal === null ||
                    this.state.driverNumRaces === null || (this.state.blackFlagged && this.state.blackFlaggedReason === null) || this.state.driverRacesList === null || this.state.driverIncidents === null ||
                    this.state.driverCountry === null || this.state.driverLicenseExpiry === null || this.state.driverLicenseIssuer === null || this.state.driverMedicalExpiry === null ||
                    this.state.driverMedicalIssuer === null || this.state.driverYears === null || this.state.driverSuspensions === null ||
                    this.state.driverFName === "" || this.state.driverLName === "" || this.state.driverEmail === "" || this.state.driverEmailConfirm === "" || this.state.driverPhone === "" ||
                    this.state.driverCity === "" || ((this.state.driverCountry === "United States" || this.state.driverCountry === "Canada") && (this.state.driverState === "" || this.state.driverState === null)) || this.state.driverPostal === "" ||
                    this.state.driverNumRaces === undefined || (this.state.blackFlagged && this.state.blackFlaggedReason === "") || this.state.driverRacesList === "" || this.state.driverIncidents === "" ||
                    this.state.driverCountry === "" || this.state.driverLicenseIssuer === "" || this.state.driverMedicalIssuer === "" || this.state.driverYears === undefined || this.state.driverSuspensions === "") {
                    this.props.setMessage("Missing Required Fields", "error");
                    return;
                }
                if (this.state.driverEmail.toLowerCase() !== this.state.driverEmailConfirm.toLowerCase()) {
                    this.props.setMessage("Emails Do Not Match", "error");
                    return;
                }
                if (this.state.driverDOB > new Date() || new Date(this.state.driverDOB).toString() === "Invalid Date" ||
                    new Date(this.state.driverMedicalExpiry).toString() === "Invalid Date" || new Date(this.state.driverLicenseExpiry).toString() === "Invalid Date") {
                    this.props.setMessage("Invalid date", "error");
                    return;
                }
                if (this.state.driverYears > 999 || this.state.driverYears < 0) {
                    this.setState({ driverYears: 0 })
                }
                if (this.state.driverNumRaces > 999 || this.state.driverNumRaces < 0) {
                    this.setState({ driverNumRaces: 0 })
                }
                if (isNaN(this.state.driverYears) || this.state.driverYears as any === "" || isNaN(this.state.driverNumRaces) || this.state.driverNumRaces as any === "") {
                    this.props.setMessage("Value Must Be a Number", "error");
                    return;
                }
                break;
            case 2:
                break;
            case 3:
                break;
            default:
        }
        const stepper = document.getElementById("stepper");
        if (stepper !== null) {
            stepper.scrollTop = 0;
        }
        this.setState({ scrollAtBottom: false });
        this.setState({ prevActiveStep: this.state.activeStep });
        this.setState({ activeStep: this.state.activeStep + 1 });
        this.setState({ validationAttempt: false });
    };

    private handleEdit(step: number): void {
        const stepper = document.getElementById("stepper");
        if (stepper !== null) {
            stepper.scrollTop = 0;
        }
        this.setState({ scrollAtBottom: false });
        this.setState({ prevActiveStep: this.state.activeStep });
        this.setState({ activeStep: step });
    };
    //hides assistive chevron, after user has reached the end of page
    // private handleScroll() {
    //     const stepper = document.getElementById("stepper");
    //     if (stepper !== null && stepper.scrollTop / (stepper.scrollHeight - stepper.clientHeight) === 1) {
    //         this.setState({ scrollAtBottom: true });
    //     }
    // }

    private getStepContent(step: number): React.ReactElement {
        const { classes } = this.props;
        const validCarsInEntry = this.props.entry.carModel.filter(x => x.status === 'Pending' || x.status === 'Accepted' || x.status === 'Waitlisted' || x.status === 'Draft');
        const selectedCar = validCarsInEntry.find(x => this.props.car !== undefined && x.id === this.props.car.id);
        const maxCrew = selectedCar === undefined ? 
        (validCarsInEntry.length === 0 ? 4 : validCarsInEntry.length === 1 ? 3 : 2) : 
        (validCarsInEntry.indexOf(selectedCar) === 0 ? 4 : validCarsInEntry.indexOf(selectedCar) === 1 ? 3 : 2);
        switch (step) {
            case 0:
                return (<React.Fragment>
                    {this.state._isMounted &&
                        <React.Fragment>
                            <CarForm {...this.state} isAdmin={false} upcomingYear={this.props.upcomingYear} />
                        </React.Fragment>}
                    {!this.state._isMounted &&
                        <Box className={classes.center}>
                            <CircularProgress color="secondary" />
                        </Box>
                    }
                </React.Fragment>);
            case 1:
                return (
                    <DriverForm
                        {...this.state}
                    />);
            case 2:
                return (<React.Fragment>
                    <Grid item xs={12}>
                        <Typography className={classes.subheading}><b>Crew Information</b></Typography>
                        <Divider />
                        <CrewForm
                            event={this.props.entry.event}
                            maxCrew={maxCrew}
                            {...this.state}
                        />
                    </Grid>
                    {this.state.selectedRaces.some(e => e.hasLounge) &&
                        <Grid item xs={12}>
                            <Typography className={classes.subheading}><b>Lounge Information</b></Typography>
                            <Divider />
                            <LoungeForm
                                event={this.props.entry.event}
                                {...this.state}
                            />
                        </Grid>}
                </React.Fragment>);
            case 3:
                return (
                    <RegConfirmation
                        {...this.state}
                        handleStep={this.handleEdit} />
                );
            default:
                return <h1>Error !</h1>;
        }
    };
    private ScrollChevron(): React.ReactElement {
        const { classes } = this.props;
        return (<ExpandMoreIcon className={classes.scrollChevron} color="secondary" />)
    }

    private ColorlibStepIcon(props: StepIconProps) {
        const { classes } = this.props;
        const { active, completed } = props;

        const icons: { [index: string]: React.ReactElement } = {
            1: <DriveEtaIcon />,
            2: <PersonIcon />,
            3: <PeopleIcon />,
            4: <CheckCircleIcon />,
        };

        return (
            <div
                className={clsx(classes.stepIcon, {
                    [classes.active]: active,
                    [classes.completed]: completed,
                })}
            >
                {icons[String(props.icon)]}
            </div>
        );
    }
    private getRaces(){
        this.apiService.getRaces(this.props.entry.event.id).then(result => {        
            result.sort((a: Race, b: Race) => {
                let nameA = a.name.toUpperCase(); 
                let nameB = b.name.toUpperCase(); 
                if (nameA < nameB) {
                  return -1;
                }
                if (nameA > nameB) {
                  return 1;
                }
                return 0;
             });    
            this.setState({raceOptions : result})
        })
    };

    public componentWillMount() {        
        console.log("You have summoned the wizard!");
    };
    public componentWillUnmount() {
        console.log("The wizard has been defeated")
    };
    public componentDidMount() {
        this.getEntrant();
        this.getRaces();
        this.getClassesList().then(() => this.setState({ _isMounted: true }));
        if (this.props.editMode && this.props.car !== undefined) {
            this.setState({
                carChassis: this.props.car.chassisNumber, carModel: this.props.car.model, carYear: this.props.car.year,
                carColor: this.props.car.color, carClass: this.props.car.class, carHistory: this.props.car.historyOfCar,
                carMake: this.props.car.make, carImage: this.props.car.carImageToDisplay,
                transponderRental: this.props.car.isTransponderRentalNeeded, transponderNumber: this.props.car.transponderNumber, displacement: this.props.car.displacement,
                horsepower: this.props.car.horsePower, induction: this.props.car.inductionType, lubeSys: this.props.car.lubricationSystem,
                brakeSys: this.props.car.brakeType, originalBrakes: this.props.car.isBrakeSystemOriginal, modded: this.props.car.isModified,
                modifications: this.props.car.modificationReason, tireMake: this.props.car.tireMake, tireModel: this.props.car.tireModel,
                tireFrontSize: this.props.car.tireSizeFront, tireRearSize: this.props.car.tireSizeRear, wheelFrontDia: this.props.car.frontWheelDiameter,
                wheelFrontWidth: this.props.car.frontWheelWidth, wheelRearDia: this.props.car.rearWheelDiameter, wheelRearWidth: this.props.car.rearWheelWidth,
                prefCarNum: this.props.car.preferredCarNumber, altCarNum: this.props.car.alternateCarNumber1, altCarNum2: this.props.car.alternateCarNumber2, tshirtSize: this.props.car.tshirtSize      
            });            
            let selectedRaces : Race[] = [];
            this.props.car.carRace.forEach(carRace => {
                selectedRaces.push(carRace.race);
            });
            this.setState({selectedRaces})
            if (this.props.car.driverModel.length > 0) {
                this.setState({ sameAsEntrant: (this.props.entry.entrantId === this.props.car.driverModel[0].peopleId) });
                this.setState({ ICENumber: this.props.car.driverModel[0].icenumber })
                this.setState({ driverPhone: this.props.car.driverModel[0].phoneNumber })
                this.setState({
                    driverAdd1: this.props.car.driverModel[0].address1, driverAdd2: this.props.car.driverModel[0].address2, driverAdd3: this.props.car.driverModel[0].address3,
                    driverCity: this.props.car.driverModel[0].city, driverMName: this.props.car.driverModel[0].mname, driverCountry: this.props.car.driverModel[0].country,
                    driverDOB: (this.props.car.driverModel[0].dob !== null && this.props.car.driverModel[0].dob.toString() === "0001-01-01T00:00:00") ? null : this.props.car.driverModel[0].dob, driverEmail: this.props.car.driverModel[0].email, driverIncidents: this.props.car.driverModel[0].incidents,
                    driverLName: this.props.car.driverModel[0].lname, driverLicenseExpiry: this.props.car.driverModel[0].licenseExpiry, driverLicenseIssuer: this.props.car.driverModel[0].licenseIssuer,
                    driverMedicalExpiry: this.props.car.driverModel[0].medicalExpiry, driverMedicalIssuer: this.props.car.driverModel[0].medicalIssuer, driverState: this.props.car.driverModel[0].state,
                    driverPostal: this.props.car.driverModel[0].zipCode, driverSuspensions: this.props.car.driverModel[0].suspensions, driverEmailConfirm: this.props.car.driverModel[0].email,
                    driverFName: this.props.car.driverModel[0].fname, driverNumRaces: this.props.car.driverModel[0].numOfVintageEvents, driverRacesList: this.props.car.driverModel[0].racesList,
                    driverYears: this.props.car.driverModel[0].yearsDriving, driverId: this.props.car.driverModel[0].driverId,
                    blackFlagged: this.props.car.driverModel[0].isBlackFlagged, blackFlaggedReason: this.props.car.driverModel[0].blackFlaggedReason, ICEName: this.props.car.driverModel[0].icename
                })
            }
            if (this.props.car.crew !== undefined && this.props.car.crew.length > 0) {
                let crew: Crew[] = [];
                this.props.car.crew.map((value) => {
                    crew.push(value);
                });
                this.setState({crew})
            }
            if (this.props.car.lounge !== undefined && this.props.car.lounge.length > 0) {
                let loungeMembers: LoungeMember[] = [];
                this.props.car.lounge.map((value) => {
                    loungeMembers.push(value);
                });
                this.setState({ loungeMembers })
            }
        }

    }

    public render(): React.ReactElement {
        const { classes, width, theme } = this.props;
        return (
            <React.Fragment>
                <Dialog
                    maxWidth={"xl"}
                    fullWidth
                    fullScreen={width !== "lg" && width !== "xl" && width !== "md"}
                    open={this.props.showCarDialog}
                    aria-labelledby="dialog-title"
                    aria-describedby="dialog-description"
                    scroll="paper"
                    PaperProps={{
                        className: classes.dialog
                    }}
                >
                    <DialogTitle >
                        {(this.props.editMode && this.props.car !== undefined) ?
                            <React.Fragment>Edit {this.props.car.year + " " + this.props.car.make + " " + this.props.car.model}</React.Fragment> :
                            <React.Fragment>Add {this.props.upcomingYear}  Reunion Car</React.Fragment>
                        }

                        {(width === "sm" || width === "xs") &&
                            <IconButton aria-label="close" className={classes.closeButton} onClick={this.props.handleCancel} disabled={this.props.loading}>
                                <CloseIcon />
                            </IconButton>
                            //   <div className={classes.mobileAction}>
                            //   <Button size="small" className={classes.cancel} onClick={this.props.handleCancel} disabled={this.props.loading}>Cancel</Button>
                            //   </div>
                        }
                    </DialogTitle>
                    {(width !== "sm" && width !== "xs") &&
                        <Stepper alternativeLabel activeStep={this.state.activeStep} connector={<QontoConnector />} >
                            {this.state.steplabels.map((label, index) => (
                                <Step key={label}>
                                    <StepButton aria-label="Info" onClick={() => this.handleEdit(index)}>
                                        < StepLabel StepIconComponent={this.ColorlibStepIcon}>{label}</StepLabel>
                                    </StepButton>
                                </Step>))}
                        </Stepper>}
                        
                    {/* <DialogContent dividers id="stepper" onScroll={this.handleScroll}> */}
                    <DialogContent dividers id="stepper">
                        {this.state.activeStep !== this.state.steps.length &&
                            <Box className={classes.form}>
                                {this.getStepContent(this.state.activeStep)}
                            </Box>
                        }
                    </DialogContent>
                    {/* {this.state._isMounted && !this.state.scrollAtBottom && <Box className={classes.scrollChevronGutter}><this.ScrollChevron /></Box>} */}

                    <DialogActions className={classes.center}>
                        {(width === "sm" || width === "xs") ?
                            <React.Fragment>
                                <MobileStepper
                                    variant="dots"
                                    steps={4}
                                    position="static"
                                    activeStep={this.state.activeStep}
                                    className={classes.root}
                                    nextButton={
                                        this.state.activeStep === this.state.steps.length - 1 ?
                                            <Button size="small" onClick={this.showConfirmationDialog} className={classes.submit} disabled={this.props.loading}>
                                                Submit
                                            </Button> :
                                            <Button size="small" onClick={this.handleNext}>
                                                Next
                                             {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                                            </Button>
                                    }
                                    backButton={
                                        <Button size="small" onClick={() => this.handleEdit(this.state.activeStep - 1)} disabled={this.state.activeStep === 0}>
                                            {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                                            Back
                                        </Button>
                                    }
                                />
                                <Button size="small" className={classes.button} onClick={() => this.handleSubmit("draft")} disabled={this.props.loading}>Save As Draft</Button>
                            </React.Fragment> :
                            <Grid container spacing={1} className={classes.actions}>
                                <Grid item xs={12} md={4}>
                                    <Button className={classes.cancel} onClick={this.props.handleCancel} variant="contained">Cancel</Button>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <Button className={classes.button} onClick={() => this.handleSubmit("draft")} variant="contained" disabled={this.props.loading}>Save As Draft</Button>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    {this.state.activeStep === this.state.steps.length - 1 ?
                                        <Button variant="contained" onClick={this.showConfirmationDialog} className={classes.submit} disabled={this.props.loading}>Submit</Button> :
                                        <Button variant="contained" onClick={this.handleNext} className={classes.submit} disabled={this.props.loading}>Next Step</Button>
                                    }
                                </Grid>
                            </Grid>}
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={this.state.showDialog}
                    onClose={this.closeDialog}
                    fullWidth
                    scroll="paper"
                    fullScreen={width !== "lg" && width !== "xl" && width !== "md"}
                >
                    {this.state.dialogContent === "historical" ?
                        <React.Fragment>
                            <DialogTitle id="simple-dialog-title">Cars From Past/Current Events</DialogTitle>
                            <DialogContent dividers>
                                <HistoricalCars
                                    entry={this.props.entry}
                                    populateCar={this.populateCarFromHistorical}
                                />
                            </DialogContent>
                            <DialogActions>
                                <Box p={3} className={classes.actions}>
                                    <Button className={classes.cancel} onClick={this.closeDialog} variant="contained">Cancel</Button>
                                </Box>
                            </DialogActions>
                        </React.Fragment> :
                        <React.Fragment>
                            <DialogTitle id="simple-dialog-title">Confirm Car Submittal</DialogTitle>
                            <DialogContent dividers>
                                <Typography variant="body1" >
                                    After submitting you will no longer be able to edit this car's information.<br></br>
                                    If you still wish to edit this car in the future, click "Save as Draft".<br></br>
                                    You will still be able to update your crew up until the day of the event<br></br>
                                    <br/>
                                    <b>Entry Request Agreement </b>
                                    <br/>
                                    As a driver and/or entrant, I hereby make this request for entry into the Monterey Pre-Reunion 
                                    and/or Rolex Monterey Motorsports Reunion (“Event”) with the knowledge that motor racing is dangerous, 
                                    and that if accepted I will be required to sign a Release of Liability &amp; Waiver form before being allowed to compete. In requesting this entry, 
                                    I acknowledge that I understand and voluntarily assume these and all other risks inherent in vintage racing and shall indemnify and hold the County of Monterey, 
                                    A&amp;D Narigi Consulting LLC, their officers, agents and employees harmless of all liability of claims, allegations, demands, obligations, suits, actions, causes of action, 
                                    proceedings, rights, damages and costs of any nature arising out of participating in the Event. 
                                    <br/>
                                    <br/>
                                    Upon entry acceptance, I agree to fully pay the entry fee(s) for all accepted vehicles under my name or company name prior to the event. 
                                    Only entrants that have fully paid for each vehicle will be permitted to participate. Payments are due at the time of acceptance. 
                                    Entrants who are representing a group of cars participating in a full run group, a separate agreement will be required. If withdrawing prior to June 15, 
                                    a refund minus a $150 administrative fee will be returned. Refunds are unavailable after June 15.
                                    <br/>
                                    <br/>       
                                    <FormControlLabel control={<Checkbox checked={this.state.agreementChecked} name="agreementChecked" onChange={this.handleToggle} />} label="By checking this box, I am agreeing to the terms as stated above." />
                                </Typography>
                            </DialogContent>
                            <DialogActions>
                                <Grid container spacing={1} className={classes.actions}>
                                    <Grid item xs={12} md={4}>
                                        <Button className={classes.cancel} onClick={this.closeDialog} variant="contained" disabled={this.props.loading}>Cancel</Button>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Button className={classes.button} onClick={() => this.handleSubmit("draft")} variant="contained" disabled={this.props.loading}>Save As Draft</Button>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Button className={classes.submit} onClick={() => this.handleSubmit("submit")} variant="contained" disabled={this.props.loading || !this.state.agreementChecked}>Submit</Button>
                                    </Grid>
                                </Grid>
                            </DialogActions>
                        </React.Fragment>}
                </Dialog>
            </React.Fragment>
        );
    }
}
const QontoConnector = withStyles({
    alternativeLabel: {
        top: 10,
        left: 'calc(-50% + 16px)',
        right: 'calc(50% + 16px)',
    },
    active: {
        '& $line': {
            borderColor: '#b71c1c',
        },
    },
    completed: {
        '& $line': {
            borderColor: '#b71c1c',
        },
    },
    line: {
        borderColor: '#eaeaf0',
        borderTopWidth: 3,
        borderRadius: 1,
    },
})(StepConnector);

const styles = createStyles((theme: Theme) => ({
    header: {
        textAlign: "center"
    },
    mobileAction: {
        float: "right"
    },
    center: {
        textAlign: "center"
    },
    actions: {
        textAlign: "center",
        paddingLeft: "0px",
        paddingRight: "0px"
    },
    form: {
        overflowY: 'auto',
        overflowX: 'hidden',
        width: 'auto',
        padding: theme.spacing(2),
    },
    submit: {
        backgroundColor: green[500],
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        color: 'white',
        '&:hover': {
            backgroundColor: green[700],
        }
    },
    button: {
        backgroundColor: blue[500],
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        color: 'white',
        '&:hover': {
            backgroundColor: blue[700],
        }
    },
    cancel: {
        backgroundColor: red[500],
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        color: 'white',
        '&:hover': {
            backgroundColor: red[700],
        }
    },
    stepIcon: {
        backgroundColor: '#ccc',
        zIndex: 1,
        color: '#fff',
        width: 50,
        height: 50,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    scrollChevronGutter: {
        height: "0px",
        textAlign: "center"
    },
    scrollChevron: {
        animation: '$chevAni 1s infinite alternate linear',
        position: 'relative',
        fontSize: '50px',
        bottom: '35px'
    },
    '@keyframes chevAni': {
        from: {
            transform: 'translate(0px, -10px)'
        },
        to: {
            transform: 'translate(0px, 0px)'
        },
    },
    active: {
        backgroundImage:
            'linear-gradient( 136deg, rgb(255, 205, 210) 0%, rgb(244, 67, 54) 50%, rgb(183, 28, 28) 100%)',
        boxShadow: '4px 1px 5px rgba(0,0,0,.25)',
    },
    completed: {
        backgroundImage:
            'linear-gradient( 136deg, rgb(255, 205, 210) 0%, rgb(244, 67, 54) 50%, rgb(183, 28, 28) 100%)',
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
}))
export default withWidth()(withStyles(styles, { withTheme: true })(CarWizard));
