import React, { Component } from 'react';
import { withStyles, createStyles, Theme } from '@material-ui/core/styles';
import APIService from '../services/APIService';
import { Paper, Grid, Typography, Tabs, Tab, List, ListItem, ListItemIcon, Checkbox, ListItemText, ListItemSecondaryAction, IconButton, TextField, Button, Dialog, DialogTitle, DialogContent, DialogContentText, FormControl, InputLabel, Select, MenuItem, FormControlLabel, Switch, DialogActions, Icon, Divider, Box, Chip, CircularProgress } from '@material-ui/core';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import DoneIcon from '@material-ui/icons/Done';
import { green, red } from '@material-ui/core/colors';
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import FaceIcon from '@material-ui/icons/Face';
import Car from '../interfaces/ICar';
import authService from '../services/api-authorization/AuthorizeService';
import CarRace from '../interfaces/ICarRace';
import Crew from '../interfaces/ICrew';

interface ICheckInVehicle{
    id: string;
    carId: string;
    parkingPass1: string;
    parkingPass2: string;
    parkingPass1IssuedTo: string;
    parkingPass2IssuedTo: string;
    checkedIn: boolean;
    car: Car;
    entrantName:string;
}

export interface ICheckInVehicleProps {
    classes: any;
    setMessage: any;
    setLoading: any;
    loading: boolean;
}

export interface ICheckInVehicleState {
    parkingPass1:string;
    parkingPass2:string;
    holder1:string;
    holder2:string;
    checkInMode: number;
    firstName: string;
    lastName:string;
    year: number | null;
    make: string;
    model: string;
    showDialog: boolean;
    selectedItem: number | null;
    cars: ICheckInVehicle[];
    selectedCheckin?: ICheckInVehicle;
    selectedCar?: Car;
    _isMounted: boolean;
}

export class CheckInVehicle extends Component<ICheckInVehicleProps, ICheckInVehicleState> {
    static displayName = CheckInVehicle.name;
    private apiService: APIService = new APIService;
    public constructor(props: Readonly<ICheckInVehicleProps>) {
        super(props);
        this.state = {
            checkInMode:0,
            firstName : "",
            lastName: "",
            year: null,
            make: "",
            model: "",
            showDialog: false,
            selectedItem: null,
            cars: [],
            parkingPass1:"",
            parkingPass2:"",
            holder1:"",
            holder2:"",
            _isMounted: false
        };
        this.apiService = new APIService;
        this.handleChange = this.handleChange.bind(this);
        this.renderRow = this.renderRow.bind(this);
    }

    private handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newState = { [event.target.name]: event.target.value } as any;
        this.setState(newState);
    }

    private showCheckInDialog = () =>{
        this.setState({showDialog: true})
    }

    private hideCheckInDialog = () =>{
        this.setState({showDialog: false})
    }

    private checkInItem = (car : ICheckInVehicle) => {
        this.getRelatedCar(car.carId);
        this.setState({selectedCheckin: car, parkingPass1 : car.parkingPass1, parkingPass2 : car.parkingPass2, holder1 : car.parkingPass1IssuedTo, holder2 : car.parkingPass2IssuedTo});
        
        this.showCheckInDialog();
    }

    private getCarListByStatus = async (make : string, model : string, year : number | null) => {
        const token = await authService.getAccessToken();
        const currentDate = new Date();
        let yearString = "";
        this.props.setLoading(true);
        if (token != null) {
            const headers = new Headers();
            headers.append("Accept", "application/json");
            headers.append("Content-Type", "application/json");
            headers.append("Authorization", `Bearer ${token}`);
            if (make === ""){
                make = "NULL";
            }
            if (model === ""){
                model = "NULL";                
            }
            if (year === null || year === undefined){
                yearString = "NULL";                
            }
            else{
                yearString = year.toString();
            }
            if(yearString === ""){
                yearString = "NULL";
            }
            const response = await fetch(`/api/car/CarListByStatusWithFilter/` +  currentDate.getFullYear().toString() + `/accepted/` +model  + `/`+ make + `/`+ yearString, {
                headers: headers,
                method: "GET"
            })
                .then(res => res.json())
                .then(data => {
                    this.setState({ cars: data.result })
                    this.props.setLoading(false);
                })
                .catch((error) => {
                    this.props.setLoading(false);
                    console.log(error);
                });
        }
    }

    private getRelatedCar = async (id : string) =>{
        const token = await authService.getAccessToken();
        if (token != null) {
            const headers = new Headers();
            headers.append("Accept", "application/json");
            headers.append("Content-Type", "application/json");
            headers.append("Authorization", `Bearer ${token}`);
            const response = await fetch(`api/car/` + id, {
                headers: headers,
                method: "GET"
            })
                .then(res => res.json())
                .then(data => {
                    this.setState({ selectedCar: data.result });
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }

    private checkIn = async() =>{
        const token = await authService.getAccessToken();
        if (this.state.selectedCheckin?.id === "00000000-0000-0000-0000-000000000000"){
            await fetch(`/api/checkin/vehicle`, {
                method: 'POST',
                headers: !token ? {} : {
                    'Authorization': `Bearer ${token}`, 
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    id: this.state.selectedCheckin?.id,
                    CarId: this.state.selectedCar?.id,
                    ParkingPass1: this.state.parkingPass1,
                    ParkingPass2: this.state.parkingPass2,
                    ParkingPass1IssuedTo: this.state.holder1,
                    ParkingPass2IssuedTo: this.state.holder2
                })
            })
        }
        else{
            await fetch(`/api/checkin/updateVehicle`, {
                method: 'PUT',
                headers: !token ? {} : {
                    'Authorization': `Bearer ${token}`, 
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    id: this.state.selectedCheckin?.id,
                    CarId: this.state.selectedCar?.id,
                    ParkingPass1: this.state.parkingPass1,
                    ParkingPass2: this.state.parkingPass2,
                    ParkingPass1IssuedTo: this.state.holder1,
                    ParkingPass2IssuedTo: this.state.holder2
                })
            })
        }
        this.getCarListByStatus(this.state.make, this.state.model, this.state.year)
        this.hideCheckInDialog();
    }

    private renderRow(props: ListChildComponentProps) {
        const { index, style } = props;    
        return (
          <ListItem button style={style} key={index} onClick={() => this.checkInItem(this.state.cars[index])} dense>
            <ListItemIcon>
                {(this.state.cars[index].checkedIn && this.state.cars[index].parkingPass1 !== "" && this.state.cars[index].parkingPass2 !== ""
                 && this.state.cars[index].parkingPass1 !== null && this.state.cars[index].parkingPass2 !== null)? 
                <DoneAllIcon/> : 
                this.state.cars[index].checkedIn ? 
                <DoneIcon/> : 
                <></>}
            </ListItemIcon>
            <ListItemText primary={`${this.state.cars[index].car.year + " " + this.state.cars[index].car.make + " " + this.state.cars[index].car.model}`} />
          </ListItem>
        );
      }

    public componentDidMount() {
        // this.getCarListByStatus(this.state.make, this.state.model, this.state.year).then(() => this.setState({ _isMounted: true }));
        this.setState({ _isMounted: true });
    }

    public componentDidUpdate(prevProps: ICheckInVehicleProps) {
    }
    public render(): React.ReactElement<ICheckInVehicleProps> {
        const { classes } = this.props;
        return (
            <React.Fragment>
                <Grid container spacing={2}>
                    <Grid item xs={4}>             
                        <TextField
                            name="year"                                
                            label="Year"
                            type="number"
                            className={classes.textField}
                            value={(this.state.year === null) ? "" : this.state.year}
                            // error={(this.state.year === null || this.state.year === undefined || this.state.year > (new Date().getFullYear()+1) || this.state.year < 1900 )}
                            helperText={(this.state.year !== null && this.state.year !== undefined && (this.state.year > (new Date().getFullYear()+1) || this.state.year < 1900)) ? "Not a valid year.": ""}
                            onChange={this.handleChange}
                            margin="normal"
                            fullWidth                                                        
                            // InputLabelProps={{ shrink: true }}
                            inputProps={{min:"1900", max: (new Date().getFullYear()+1), 
                            maxLength: 4}}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            name="make"
                            label="Make"
                            className={classes.textField}
                            value={(this.state.make === null) ? "" : this.state.make}
                            onChange={this.handleChange}
                            margin="normal"
                            fullWidth
                        />         
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            name="model"
                            label="Model"
                            className={classes.textField}
                            value={(this.state.model === null) ? "" : this.state.model}
                            onChange={this.handleChange}
                            margin="normal"
                            fullWidth
                        />         
                    </Grid>
                    <Grid item xs={12} className={classes.center}>
                        <Button className={classes.submit} 
                        onClick={() => this.getCarListByStatus(this.state.make, this.state.model, this.state.year)} 
                        autoFocus 
                        disabled={this.props.loading}>
                            Search
                        </Button>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    {/* <React.Fragment>
                    <Grid item xs={6} className={classes.center}>
                        <DoneIcon/> Vehicle Partially Checked In
                    </Grid>
                    <Grid item xs={6} className={classes.center}>
                        <DoneAllIcon/> Vehicle Fully Checked In
                    </Grid>
                    </React.Fragment> */}
                    <Grid item xs={12} className={classes.center}>
                        <Typography variant={'h6'}>Results</Typography>
                    </Grid>
                    <Grid item xs={12} className={classes.center}>                        
                        {(this.state._isMounted && !this.props.loading) && <FixedSizeList  height={370} width={"100%"} itemSize={46} itemCount={this.state.cars.length}>
                            {this.renderRow}
                        </FixedSizeList>}
                        {(this.state._isMounted && this.props.loading) &&
                            <CircularProgress color="secondary"/>}
                    </Grid>
                </Grid> 
            <Dialog
                fullWidth
                maxWidth='lg'
                open={this.state.showDialog}
                onClose={this.hideCheckInDialog}
                aria-labelledby="max-width-dialog-title">
                <DialogTitle id="max-width-dialog-title">{this.state.selectedCar?.year + " " + this.state.selectedCar?.make + " " + this.state.selectedCar?.model}</DialogTitle>
                <DialogContent>                    
                    <Grid container spacing={2}>
                        {this.state.selectedCar && 
                        <Grid container spacing={1} alignItems="center">
                            <Grid item xs={12} className={classes.center}><Typography variant="h6"><strong>Event Information</strong></Typography><Divider /></Grid>
                            <Grid item xs={12} sm={3}><Typography variant="body1"><strong>Participating Event:</strong></Typography></Grid><Grid item xs={12} sm={9}><Typography variant="body1">
                                {this.state.selectedCar.carRace.map((value, index) => { 
                                    if(this.state.selectedCar && index < this.state.selectedCar.carRace.length - 1){
                                        return (value.race.name + " & ")
                                    }
                                    else{
                                        return (value.race.name) 
                                    }
                                })}</Typography></Grid>
                                {this.state.selectedCar.carRace.map((value : CarRace, index) => {
                                    return( value.race !== undefined && value.runGroup !== null && value.runGroup !== ""  && <React.Fragment key={index}><Grid item xs={12} sm={3}><Typography variant="body1"><strong>
                                            {value.race.name} Run Group:
                                        </strong></Typography></Grid><Grid item xs={12} sm={3}>
                                        <Box className={classes.stamp}><Typography variant="h4" className={classes.innerStamp}>
                                            {value.runGroup}
                                        </Typography></Box></Grid></React.Fragment>)
                                })}
                            <Grid item xs={12} className={classes.center}><Typography variant="h6"><strong>Vehicle Information</strong></Typography><Divider /></Grid>
                            <Grid item xs={12} sm={3}><Typography variant="body1"><strong>Entrant:</strong></Typography></Grid><Grid item xs={12} sm={9}><Typography variant="body1">{this.state.selectedCheckin?.entrantName}</Typography></Grid>
                            <Grid item xs={12} sm={3}><Typography variant="body1"><strong>Class:</strong></Typography></Grid><Grid item xs={12} sm={9}><Typography variant="body1">{this.state.selectedCar.class}</Typography></Grid>
                            <Grid item xs={12} sm={3}><Typography variant="body1"><strong>Color:</strong></Typography></Grid><Grid item xs={12} sm={9}><Typography variant="body1">{this.state.selectedCar.color}</Typography></Grid>
                            <Grid item xs={12} sm={3}><Typography variant="body1"><strong>Car Number:</strong></Typography></Grid><Grid item xs={12} sm={9}><Typography variant="body1">{this.state.selectedCar.preferredCarNumber} (Alternatives: {this.state.selectedCar.alternateCarNumber1}, {this.state.selectedCar.alternateCarNumber2})</Typography></Grid>
                            <Grid item xs={12} sm={3}><Typography variant="body1"><strong>Horse Power (HP):</strong></Typography></Grid><Grid item xs={12} sm={9}><Typography variant="body1">{this.state.selectedCar.horsePower}</Typography></Grid>
                            <Grid item xs={12} sm={3}><Typography variant="body1"><strong>Displacement:</strong></Typography></Grid><Grid item xs={12} sm={9}><Typography variant="body1">{this.state.selectedCar.displacement}cc</Typography></Grid>
                            <Grid item xs={12} sm={3}><Typography variant="body1"><strong>Induction Type:</strong></Typography></Grid><Grid item xs={12} sm={9}><Typography variant="body1">{this.state.selectedCar.inductionType}</Typography></Grid>
                            <Grid item xs={12} sm={3}><Typography variant="body1"><strong>Chassis Number:</strong></Typography></Grid><Grid item xs={12} sm={9}><Typography variant="body1">{this.state.selectedCar.chassisNumber}</Typography></Grid>
                            <Grid item xs={12} sm={3}><Typography variant="body1"><strong>Modifications:</strong></Typography></Grid><Grid item xs={12} sm={9}><Typography variant="body1">{this.state.selectedCar.modificationReason}</Typography></Grid>
                            <Grid item xs={12} sm={3}><Typography variant="body1"><strong>History:</strong></Typography></Grid><Grid item xs={12} sm={9}><Typography variant="body1">{this.state.selectedCar.historyOfCar}</Typography></Grid>
                            <Grid item xs={12} sm={3}><Typography variant="body1"><strong>Shirt Size:</strong></Typography></Grid><Grid item xs={12} sm={9}><Typography variant="body1">{this.state.selectedCar.tshirtSize}</Typography></Grid>
                            <Grid item xs={12} sm={3}><Typography variant="h6"><strong>Is Paid:</strong></Typography></Grid><Grid item xs={12} sm={9} style={{backgroundColor: this.state.selectedCar.isPaid ? "#74ed75": "#ffc73a" }}><Typography variant="h6">{this.state.selectedCar.isPaid ? "Yes" : "No"}</Typography></Grid>
                            <Grid item xs={12}></Grid>
                            <Grid item xs={12} className={classes.center}><Typography variant="h6"><strong>Crew Information</strong></Typography><Divider /></Grid>
                            {(this.state.selectedCar.driverModel !== undefined && this.state.selectedCar.driverModel.length > 0 && this.state.selectedCar.driverModel[0].fname !== "") &&
                                <React.Fragment>
                                    <Grid item xs={12} sm={3}><Typography variant="body1"><strong>Driver:</strong></Typography></Grid>
                                    <Grid item xs={12} sm={9}>
                                        <List dense>
                                            {this.state.selectedCar.driverModel.map((value, index) => {
                                                return (
                                                    <ListItem key={index}>
                                                        <Typography variant="h6">
                                                            <Chip
                                                                icon={<DriveEtaIcon />}
                                                                color="primary"
                                                                label={value.fname + " " + value.lname} />
                                                        </Typography>
                                                    </ListItem>)
                                            })}
                                        </List>
                                    </Grid>
                                </React.Fragment>
                            }
                            {this.state.selectedCar.carRace.map((value, index) => {
                                if (this.state.selectedCar){
                                    let raceCrew: Crew[] = this.state.selectedCar.crew.filter(x => x.race?.id === value.race.id && !x.isOvercrew);
                                    return (
                                        <React.Fragment key={index}>
                                        <Grid item xs={12} sm={3}><Typography variant="body1"><strong>{value.race.name + " Crew:"}</strong></Typography></Grid>
                                        <Grid item xs={12} sm={9}>
                                            <List dense>
                                                {(raceCrew !== undefined) && raceCrew.map((value, index) => {
                                                    return (
                                                        <ListItem key={index}>
                                                            <Typography variant="h6" className={classes.center}>
                                                                <Chip
                                                                    icon={<CheckCircleIcon />}
                                                                    color="primary"
                                                                    label={value.fname + " " + value.lname} />
                                                            </Typography>
                                                        </ListItem>)
                                                })}
                                                {(raceCrew === undefined || raceCrew.length === 0) &&
                                                    <ListItem>
                                                        <Typography variant="h6" className={classes.center}>
                                                            <Chip
                                                                variant="outlined"
                                                                icon={<FaceIcon />}
                                                                label="No Crew Assigned"
                                                            />
                                                        </Typography>
                                                    </ListItem>
                                                }
                                            </List>
                                        </Grid>
                                    </React.Fragment>
                                    )
                                }
                            })
                            }
                            {this.state.selectedCar.crew.some(e => e.isOvercrew) &&
                                    <React.Fragment>
                                    <Grid item xs={12} sm={3}><Typography variant="body1"><strong>Overcrew:</strong></Typography></Grid>
                                    <Grid item xs={12} sm={9}>
                                    <List dense>
                                        {(this.state.selectedCar.crew !== undefined) && this.state.selectedCar.crew.map((value, index) => {
                                            if (this.state.selectedCar && value.isOvercrew) {
                                                return (
                                                    <ListItem key={index}>
                                                        <Typography variant="h6" className={classes.center}>
                                                            <Chip
                                                                icon={<MonetizationOnIcon />}
                                                                color="secondary"
                                                                label={this.state.selectedCar.carRace.length > 1 ? value.fname + " " + value.lname +  "(" + value.race?.name + ")" : value.fname + " " + value.lname}
                                                            />
                                                        </Typography>
                                                    </ListItem>)
                                            }
                                        })}
                                    </List>
                                </Grid>
                                </React.Fragment>
                            }
                                {this.state.selectedCar.carRace.some(e => e.race.hasLounge) &&
                                <React.Fragment>
                                    <Grid item xs={12} sm={3}><Typography variant="body1"><strong>Lounge Members:</strong></Typography></Grid>
                                    <Grid item xs={12} sm={9}>
                                        <List dense>
                                            {(this.state.selectedCar.lounge[0] !== undefined) && this.state.selectedCar.lounge.map((value, index) => {
                                                if (index === 0)
                                                    return (
                                                        <ListItem key={index}>
                                                            <Typography variant="h6" className={classes.center}>
                                                                <Chip
                                                                    icon={<CheckCircleIcon />}
                                                                    color="primary"
                                                                    label={value.fname + " " + value.lname} />
                                                            </Typography>
                                                        </ListItem>
                                                    )
                                                else
                                                    return (
                                                        <ListItem key={index}>
                                                            <Typography variant="h6" className={classes.center}>
                                                                <Chip
                                                                    icon={<MonetizationOnIcon />}
                                                                    color="secondary"
                                                                    label={value.fname + " " + value.lname} />
                                                            </Typography>
                                                        </ListItem>)
                                            })}
                                            {(this.state.selectedCar.lounge.length === 0) &&
                                                <ListItem>
                                                    <Typography variant="h6" className={classes.center}>
                                                        <Chip
                                                            variant="outlined"
                                                            icon={<FaceIcon />}
                                                            label="No Lounge Members Chosen"
                                                        />
                                                    </Typography>
                                                </ListItem>
                                            }
                                        </List>
                                    </Grid>
                                </React.Fragment>
                                }
                            </Grid>
                        }
                        {(this.state.selectedCar?.adminNotes !== null && this.state.selectedCar?.adminNotes !== "")&&
                            <>
                                <Grid item xs={12} className={classes.center}><Typography variant="h6"><strong>Admin Comments</strong></Typography><Divider /></Grid>
                                <Grid item xs={12} style={{backgroundColor: "#c1c1c1" }}><Typography variant="body1" style={{fontWeight: "bold" }}>{this.state.selectedCar?.adminNotes}</Typography></Grid>
                            </>
                        }
                        <Grid item xs={6}>
                            <TextField
                                name="parkingPass1"
                                label="Parking Pass #1"
                                className={classes.textField}
                                value={this.state.parkingPass1}
                                onChange={this.handleChange}
                                margin="normal"
                                fullWidth
                            />  
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                name="holder1"
                                label="Parking Pass 1 Issued To"
                                className={classes.textField}
                                value={ this.state.holder1}
                                onChange={this.handleChange}
                                margin="normal"
                                fullWidth
                            />
                            {/* {this.state.selectedItem?.checkedIn ? <Typography>Checked-In Date</Typography> : <></>} */}
                        </Grid>  
                        <Grid item xs={6}>
                            <TextField
                                name="parkingPass2"
                                label="Parking Pass #2"
                                className={classes.textField}
                                value={this.state.parkingPass2}
                                onChange={this.handleChange}
                                margin="normal"
                                fullWidth
                            />         
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                name="holder2"
                                label="Parking Pass 2 Issued To"
                                className={classes.textField}
                                value={ this.state.holder2}
                                onChange={this.handleChange}
                                margin="normal"
                                fullWidth
                            />
                            {/* {this.state.selectedItem?.checkedIn ? <Typography>Checked-In Date</Typography> : <></>} */}
                        </Grid>  
                    </Grid>
                </DialogContent>
                <DialogActions className={classes.center}>
                    <Button onClick={this.hideCheckInDialog} className={classes.cancel}>
                        Close
                    </Button>
                    <Button onClick={this.checkIn} className={classes.submit}>
                        Check In
                    </Button>
                </DialogActions>
            </Dialog>
            </React.Fragment>
        )
    }
}

const styles = createStyles((theme: Theme) => ({
    paper: {
        padding: theme.spacing(3, 2),
        position: 'relative',
    },
    center:{
        textAlign: 'center',
    },
    submit: {
        backgroundColor: green[500],
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        color: 'white',
        '&:hover': {
            backgroundColor: green[700],
        }
    },
    cancel: {
        backgroundColor: red[500],
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        color: 'white',
        '&:hover': {
            backgroundColor: red[700],
        }
    },
}))

export default withStyles(styles)(CheckInVehicle)