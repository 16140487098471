import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Card from '@material-ui/core/Card';
import Chip from '@material-ui/core/Chip';
import CardContent from '@material-ui/core/CardContent';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import FaceIcon from '@material-ui/icons/Face';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { withStyles, createStyles, Theme } from '@material-ui/core/styles';
import Crew from '../interfaces/ICrew';
import Race from '../interfaces/IRace';
import LoungeMember from '../interfaces/ILoungeMember';

export interface IRegConfirmationProps {
    imperial: boolean;
    handleStep: any;
    classes: any;

    selectedRaces: Race[];
    carYear?: number;
    carMake: string;
    carModel: string;
    carChassis: string;
    carClass: string;
    carColor: string;
    transponderRental: boolean;
    transponderNumber?: number;
    displacement?: number;
    horsepower?: number;
    induction: string;
    lubeSys: string;
    brakeSys: string;
    originalBrakes: boolean;
    modded: boolean;
    modifications: string;
    tireMake: string;
    tireModel: string;
    tireFrontSize: string;
    tireRearSize: string;
    wheelFrontWidth: number | null;
    wheelRearWidth: number | null;
    wheelFrontDia: number | null;
    wheelRearDia: number | null;
    prefCarNum?: string;
    altCarNum?: string;
    altCarNum2?: string;
    carHistory: string;

    sameAsEntrant: boolean
    driverEmail: string;
    driverEmailConfirm: string;
    driverFName: string;
    driverMName?: string;
    driverLName: string;
    driverPhone: string;
    driverDOB: Date | null;
    driverAdd1: string;
    driverAdd2?: string;
    driverAdd3?: string;
    driverCity: string;
    driverState: string;
    driverCountry: string;
    driverPostal: string;
    ICEName: string;
    ICENumber: string;
    driverLicenseIssuer: string;
    driverLicenseExpiry: Date | null;
    driverMedicalIssuer: string;
    driverMedicalExpiry: Date | null;
    driverYears?: number;
    driverNumRaces?: number;
    driverRacesList: string;
    driverIncidents: string;
    driverSuspensions: string;
    blackFlagged: boolean;
    blackFlaggedReason: string;

    crew: Crew[];
    loungeMembers: LoungeMember[];
}

export interface IRegConfirmationState {
}

export class RegConfirmation extends Component<IRegConfirmationProps, IRegConfirmationState> {

    public constructor(props: Readonly<IRegConfirmationProps>) {
        super(props);
        this.state = {
        };
    }

    private renderCrewList(raceCrew: Crew[], isOvercrew: boolean) {
        const { classes } = this.props;
        let list: any[] = [];
        raceCrew.map((value: Crew, index: number) => {
            list.push(<ListItem key={index}>
                <Typography variant="h6" className={classes.center}>
                    <Chip
                        icon={isOvercrew ? <MonetizationOnIcon /> : <CheckCircleIcon /> }
                        color={isOvercrew ? "secondary" : "primary"}
                        label={isOvercrew && (this.props.selectedRaces.length > 1) ? value.fname + " " + value.lname +  "(" + value.race?.name + ")" : value.fname + " " + value.lname}
                        />
                </Typography>
            </ListItem>)
        }
        )
        // if (!isOvercrew) {
        //     // for (let i = raceCrew.length; i < 4; i++) {
        //     //     list.push(<ListItem key={i}>
        //     //         <Typography variant="h6" className={classes.center}>
        //     //             <Chip
        //     //                 variant="outlined"
        //     //                 icon={<FaceIcon />}
        //     //                 label="No Crew Assigned"
        //     //             />
        //     //         </Typography>
        //     //     </ListItem>)
        //     // }
        // } else 
        if(raceCrew.length === 0){
            list.push(<ListItem key={0}>
                    <Typography variant="h6" className={classes.center}>
                        <Chip
                            variant="outlined"
                            icon={<FaceIcon />}
                            label={isOvercrew ? "No Overcrew" : "No Crew"}
                        />
                    </Typography>
                </ListItem>)
        }
        return (list)
    }

    public render(): React.ReactElement {
        const { classes } = this.props;
        return (
            <React.Fragment>
                <Typography className={classes.title}><b>Car Information</b></Typography>
                <Card><CardContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12} >
                            <TextField
                                name="Races"
                                fullWidth
                                label="Participating Races"
                                className={classes.textField}
                                defaultValue={
                                    this.props.selectedRaces.map((value, index) => {
                                        return (value.name)
                                    })
                                }
                                disabled
                                InputProps={{
                                    readOnly: true,
                                }}
                                margin="normal"
                            >
                            </TextField>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <TextField
                                label="Car"
                                defaultValue={this.props.carYear + " " + this.props.carMake + " " + this.props.carModel}
                                className={classes.textField}
                                disabled
                                fullWidth
                                margin="normal"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                label="Chassis"
                                defaultValue={this.props.carChassis}
                                className={classes.textField}
                                disabled
                                margin="normal"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                label="Color"
                                defaultValue={this.props.carColor}
                                className={classes.textField}
                                disabled
                                margin="normal"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                label="Transponder Rental Required?"
                                defaultValue={(this.props.transponderRental) ? "Yes" : "No"}
                                className={classes.textField}
                                disabled
                                margin="normal"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        {!this.props.transponderRental &&
                            <Grid item xs={12} md={3}>
                                <TextField
                                    label="Transponder Number"
                                    defaultValue={this.props.transponderNumber}
                                    className={classes.textField}
                                    disabled
                                    margin="normal"
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />
                            </Grid>}
                        <Grid item xs={12} md={3}>
                            <TextField
                                label="Displacement"
                                defaultValue={this.props.displacement + "cc"}
                                className={classes.textField}
                                disabled
                                margin="normal"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                label="Horsepower"
                                defaultValue={this.props.horsepower}
                                className={classes.textField}
                                disabled
                                margin="normal"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                label="Induction Type"
                                defaultValue={this.props.induction}
                                className={classes.textField}
                                disabled
                                margin="normal"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                label="Lubrication System"
                                defaultValue={this.props.lubeSys}
                                className={classes.textField}
                                disabled
                                margin="normal"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                label="Brake System"
                                defaultValue={this.props.brakeSys}
                                className={classes.textField}
                                disabled
                                margin="normal"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                label="Original Brake?"
                                defaultValue={(this.props.originalBrakes) ? "Yes" : "No"}
                                className={classes.textField}
                                disabled
                                margin="normal"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                label="Modded?"
                                defaultValue={(this.props.modded) ? "Yes" : "No"}
                                className={classes.textField}
                                disabled
                                margin="normal"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        {this.props.modded && <Grid xs={12} md={12}>
                            <TextField
                                label="Modifications"
                                defaultValue={this.props.modifications}
                                className={classes.textField}
                                disabled
                                fullWidth
                                multiline
                                margin="normal"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>}
                        <Grid item xs={12} md={3}>
                            <TextField
                                label="Tires"
                                defaultValue={this.props.tireMake + " " + this.props.tireModel}
                                className={classes.textField}
                                disabled
                                margin="normal"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                label="Front Tire Size"
                                defaultValue={this.props.tireFrontSize + ((this.props.imperial) ? "  in." : " mm")}
                                className={classes.textField}
                                disabled
                                margin="normal"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                label="Rear Tire Size"
                                defaultValue={this.props.tireRearSize + ((this.props.imperial) ? "  in." : " mm")}
                                className={classes.textField}
                                disabled
                                margin="normal"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                label="Front Wheel Width"
                                defaultValue={this.props.wheelFrontWidth + ((this.props.imperial) ? "  in." : " mm")}
                                className={classes.textField}
                                disabled
                                margin="normal"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                label="Rear Wheel Width"
                                defaultValue={this.props.wheelRearWidth + ((this.props.imperial) ? "  in." : " mm")}
                                className={classes.textField}
                                disabled
                                margin="normal"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                label="Front Wheel Diameter"
                                defaultValue={this.props.wheelFrontDia + ((this.props.imperial) ? "  in." : " mm")}
                                className={classes.textField}
                                disabled
                                margin="normal"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                label="Rear Wheel Diameter"
                                defaultValue={this.props.wheelRearDia + ((this.props.imperial) ? "  in." : " mm")}
                                className={classes.textField}
                                disabled
                                margin="normal"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                label="Preferred Car Number"
                                defaultValue={this.props.prefCarNum}
                                className={classes.textField}
                                disabled
                                margin="normal"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                label="Alt Car Number"
                                defaultValue={this.props.altCarNum}
                                className={classes.textField}
                                disabled
                                margin="normal"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                label="Alt Car Number"
                                defaultValue={this.props.altCarNum2}
                                className={classes.textField}
                                disabled
                                margin="normal"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Car History"
                                defaultValue={this.props.carHistory}
                                className={classes.textField}
                                disabled
                                multiline
                                fullWidth
                                margin="normal"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid sm={12} className={classes.center}>
                            <Button variant="contained" className={classes.button} onClick={() => this.props.handleStep(0)}>
                                Edit
                        </Button>
                        </Grid>
                    </Grid>
                </CardContent>
                </Card>
                <Typography className={classes.title}><b>Driver Information</b></Typography>
                <Card><CardContent>
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <TextField
                                label="Full Name"
                                defaultValue={this.props.driverLName + ", " + this.props.driverFName}
                                className={classes.textField}
                                disabled
                                margin="normal"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            clearable
                            disableToolbar
                            variant="inline"
                            format="MM/dd/yyyy"
                            disabled
                            margin="normal"
                            name="driverDOB"
                            id="driverDOB"
                            label="Date of Birth"
                            placeholder="MM/dd/yyyy"
                            onChange={()=>{return;}}
                            className={classes.textField}
                            value={this.props.driverDOB}
                            InputProps={{
                                readOnly: true,
                            }}
                            />
                        </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                label="Phone Number"
                                defaultValue={this.props.driverPhone}
                                className={classes.textField}
                                disabled
                                margin="normal"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                label="Address"
                                defaultValue={
                                    this.props.driverAdd1 + "\n" +
                                    this.props.driverAdd2 + "\n" +
                                    this.props.driverCity + ", " + this.props.driverState + " " + this.props.driverPostal + ", " + this.props.driverCountry
                                }
                                className={classes.textField}
                                disabled
                                fullWidth
                                margin="normal"
                                multiline
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                label="Emergency Contact Name"
                                defaultValue={this.props.ICEName}
                                className={classes.textField}
                                disabled
                                margin="normal"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                label="Emergency Contact Number"
                                defaultValue={this.props.ICENumber}
                                className={classes.textField}
                                disabled
                                margin="normal"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                label="Competition License Issuer"
                                defaultValue={this.props.driverLicenseIssuer}
                                className={classes.textField}
                                disabled
                                margin="normal"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            clearable
                            disableToolbar
                            variant="inline"
                            format="MM/dd/yyyy"
                            disabled
                            margin="normal"
                            name="driverLicenseExpiry"
                            id="driverLicenseExpiry"
                            label="Driver License Expiry"
                            placeholder="MM/dd/yyyy"
                            onChange={()=>{return;}}
                            className={classes.textField}
                            value={this.props.driverLicenseExpiry}
                            InputProps={{
                                readOnly: true,
                            }}
                            />
                        </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                label="Medical License Issuer"
                                defaultValue={this.props.driverMedicalIssuer}
                                className={classes.textField}
                                disabled
                                margin="normal"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            clearable
                            disableToolbar
                            variant="inline"
                            format="MM/dd/yyyy"
                            disabled
                            margin="normal"
                            name="driverMedicalExpiry"
                            id="driverMedicalExpiry"
                            label="Medical License Expiry"
                            placeholder="MM/dd/yyyy"
                            onChange={()=>{return;}}
                            className={classes.textField}
                            value={this.props.driverMedicalExpiry}
                            InputProps={{
                                readOnly: true,
                            }}
                            />
                        </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                label="Years Driving"
                                defaultValue={this.props.driverYears}
                                className={classes.textField}
                                disabled
                                margin="normal"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                label="Number of Vintage Events"
                                defaultValue={this.props.driverNumRaces}
                                className={classes.textField}
                                disabled
                                margin="normal"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <TextField
                                label="Vintage Events"
                                defaultValue={this.props.driverRacesList}
                                className={classes.textField}
                                disabled
                                multiline
                                fullWidth
                                margin="normal"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <TextField
                                label="Vintage Racing Incidents"
                                defaultValue={this.props.driverIncidents}
                                className={classes.textField}
                                disabled
                                multiline
                                fullWidth
                                margin="normal"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <TextField
                                label="Suspensions"
                                defaultValue={this.props.driverSuspensions}
                                className={classes.textField}
                                disabled
                                multiline
                                fullWidth
                                margin="normal"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <TextField
                                label="Black Flagged?"
                                defaultValue={(this.props.blackFlagged) ? "Yes" : "No"}
                                className={classes.textField}
                                disabled
                                margin="normal"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        {this.props.modded &&
                            <Grid item xs={12} md={12}>
                                <TextField
                                    label="Reason for Black Flags"
                                    defaultValue={this.props.blackFlaggedReason}
                                    className={classes.textField}
                                    disabled
                                    fullWidth
                                    multiline
                                    margin="normal"
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />
                            </Grid>}
                        <Grid xs={12} md={12} className={classes.center}>
                            <Button variant="contained" className={classes.button} onClick={() => this.props.handleStep(1)}>
                                Edit
                        </Button>
                        </Grid>
                    </Grid>
                </CardContent>
                </Card>
                <Typography className={classes.title}><b>Lounge &amp; Crew Information</b></Typography>
                <Card>
                    <CardContent>
                <Grid container spacing={2} className={classes.center}>
                    {this.props.selectedRaces.map((value, index) => {
                        let raceCrew: Crew[] = this.props.crew.filter(x => x.race?.id === value.id && !x.isOvercrew);
                        return (
                            <Grid item xs={12} md key={index}>
                                <Card>
                                    <CardContent>
                                        <Typography variant="h6" className={classes.center}>
                                            {value.name + " Crew"}
                                        </Typography>
                                        <List dense>
                                            {this.renderCrewList(raceCrew, false)}
                                        </List>
                                    </CardContent>
                                </Card>
                            </Grid>
                        )
                    })
                    }
                    <Grid item xs={12} md>
                        <Card>
                            <CardContent>
                                <Typography variant="h6" className={classes.center}>
                                    Overcrew
                                    </Typography>
                                <List dense>
                                    {this.renderCrewList(this.props.crew.filter(x =>x.isOvercrew), true)}
                                </List>
                            </CardContent>
                        </Card>
                    </Grid>
                    </Grid>
                    <Grid container spacing={2} className={classes.center}>
                        <Grid item xs={12} md={4}></Grid>
                        <br></br>
                        <Grid item xs={12} md={4}>
                            {this.props.selectedRaces.some(e => e.hasLounge) &&
                                <Card>
                                    <CardContent>
                                        <Typography variant="h6" className={classes.center}>
                                            Lounge Members
                                        </Typography>
                                        <List dense>
                                            {(this.props.loungeMembers[0] !== undefined) && this.props.loungeMembers.map((value, index) => {
                                                if (index === 0)
                                                    return (
                                                        <React.Fragment>
                                                            <ListItem>
                                                                <Typography variant="h6" className={classes.center}>
                                                                    <Chip
                                                                        icon={<CheckCircleIcon />}
                                                                        color="primary"
                                                                        label={value.fname + " " + value.lname} />
                                                                </Typography>
                                                            </ListItem>
                                                            <Divider />
                                                        </React.Fragment>
                                                    )
                                                else
                                                    return (
                                                        <ListItem>
                                                            <Typography variant="h6" className={classes.center}>
                                                                <Chip
                                                                    icon={<MonetizationOnIcon />}
                                                                    color="secondary"
                                                                    label={value.fname + " " + value.lname} />
                                                            </Typography>
                                                        </ListItem>)
                                            })}
                                            {(this.props.loungeMembers.length === 0) &&
                                                <ListItem>
                                                    <Typography variant="h6" className={classes.center}>
                                                        <Chip
                                                            variant="outlined"
                                                            icon={<FaceIcon />}
                                                            label="No Lounge Members Chosen"
                                                        />
                                                    </Typography>
                                                </ListItem>
                                            }
                                        </List>
                                    </CardContent>
                                </Card>
                            }
                            </Grid>
                            <Grid item xs={12} md={4}></Grid>
                            <Grid xs={12} className={classes.center}>
                                <br></br>
                                <Button variant="contained" className={classes.button} onClick={() => this.props.handleStep(2)}>
                                    Edit
                                </Button>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </React.Fragment>
        );
    }
}


const styles = createStyles((theme: Theme) => ({
    button: {
        margin: theme.spacing(1),
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1)
    },
    center: {
        textAlign: 'center',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
}))

export default withStyles(styles)(RegConfirmation);