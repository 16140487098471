import React, { Component } from 'react';
import { withStyles, createStyles, Theme, withTheme } from '@material-ui/core/styles';
import APIService from '../services/APIService';
import { Paper, Grid, Typography, Tabs, Tab, Button, Card, CardActions, CardContent, Divider, Dialog, DialogActions, DialogContent, DialogTitle, withWidth, TextField, MenuItem, Link, FormControlLabel, Switch, Checkbox, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText} from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import DeleteIcon from '@material-ui/icons/Delete';
import BlockIcon from '@material-ui/icons/Block';
import CheckIcon from '@material-ui/icons/Check';
import 'react-day-picker/lib/style.css';
import ITrackService from '../interfaces/ITrackService';
import ITrackSoundLevel from '../interfaces/ITrackSoundLevel';
import ITrackCaterer from '../interfaces/ITrackCaterer';

export interface ITrackRentalAdminPriceSheetProps {
    classes: any;
    width: any;
    setMessage: any;
    setLoading: any;
    loading: boolean;
}

export interface ITrackRentalAdminPriceSheetState {
    _isMounted: boolean;
    showDialog: boolean;
    caterer: string;
    trackServices: ITrackService[];
    soundLevels: ITrackSoundLevel[];
    DB90: number;
    DB92: number;
    DB103: number;
    DB105: number;
    DBUnlimited: number;
    ALSPrice: number;
    TowTruckPrice : number;
    RescueTruck2Price : number;
    RescueTruck3Price : number;
    FireEngine3Price : number;
    TrackRentalSupervisorPrice : number;
    StarterPrice : number;
    MarshalPrice : number;
    SoundTesterPrice : number;
    GridWorkerPrice : number;
    GasAttendantPrice : number;
    DaytimeGateAttendantPrice : number;
    NighttimeGateAttendantPrice : number;
    EarlyLoadInLateLoadOutPrice : number;
    PaddockCampingPrice : number;
    COVID19AdminFeePrice : number;
    ALSMandatory : boolean;
    TowTruckMandatory : boolean;
    RescueTruck2Mandatory : boolean;
    RescueTruck3Mandatory : boolean;
    FireEngine3Mandatory : boolean;
    TrackRentalSupervisorMandatory : boolean;
    StarterMandatory : boolean;
    MarshalMandatory : boolean;
    SoundTesterMandatory : boolean;
    GridWorkerMandatory : boolean;
    GasAttendantMandatory : boolean;
    DaytimeGateAttendantMandatory : boolean;
    NighttimeGateAttendantMandatory : boolean;
    EarlyLoadInLateLoadOutMandatory : boolean;
    PaddockCampingMandatory : boolean;
    COVID19AdminFeeMandatory : boolean;
    ALSEnabled : boolean;
    TowTruckEnabled : boolean;
    RescueTruck2Enabled : boolean;
    RescueTruck3Enabled : boolean;
    FireEngine3Enabled : boolean;
    TrackRentalSupervisorEnabled : boolean;
    StarterEnabled : boolean;
    MarshalEnabled : boolean;
    SoundTesterEnabled : boolean;
    GridWorkerEnabled : boolean;
    GasAttendantEnabled : boolean;
    DaytimeGateAttendantEnabled : boolean;
    NighttimeGateAttendantEnabled : boolean;
    EarlyLoadInLateLoadOutEnabled : boolean;
    PaddockCampingEnabled : boolean;
    COVID19AdminFeeEnabled : boolean;
    caterers : ITrackCaterer[];
    deletedCaterers : ITrackCaterer[];
}

export class TrackRentalAdminPriceSheet extends Component<ITrackRentalAdminPriceSheetProps, ITrackRentalAdminPriceSheetState> {
    static displayName = TrackRentalAdminPriceSheet.name;
    private apiService: APIService = new APIService;
    public constructor(props: Readonly<ITrackRentalAdminPriceSheetProps>) {
        super(props);
        this.state = {
            _isMounted: false,
            showDialog: false,
            caterer: "",
            soundLevels: [],
            trackServices: [],
            DB90: 0,
            DB92: 0,
            DB103: 0,
            DB105: 0,
            DBUnlimited: 0,
            ALSPrice : 0,
            TowTruckPrice : 0,
            RescueTruck2Price : 0,
            RescueTruck3Price : 0,
            FireEngine3Price : 0,
            TrackRentalSupervisorPrice : 0,
            StarterPrice : 0,
            MarshalPrice : 0,
            SoundTesterPrice : 0,
            GridWorkerPrice : 0,
            GasAttendantPrice : 0,
            DaytimeGateAttendantPrice : 0,
            NighttimeGateAttendantPrice : 0,
            EarlyLoadInLateLoadOutPrice : 0,
            PaddockCampingPrice : 0,
            COVID19AdminFeePrice : 0,
            ALSMandatory : true,
            TowTruckMandatory : false,
            RescueTruck2Mandatory : false,
            RescueTruck3Mandatory : false,
            FireEngine3Mandatory : false,
            TrackRentalSupervisorMandatory : false,
            StarterMandatory : false,
            MarshalMandatory : true,
            SoundTesterMandatory : false,
            GridWorkerMandatory : false,
            GasAttendantMandatory : false,
            DaytimeGateAttendantMandatory : false,
            NighttimeGateAttendantMandatory : false,
            EarlyLoadInLateLoadOutMandatory : false,
            PaddockCampingMandatory : false,
            COVID19AdminFeeMandatory : true,
            ALSEnabled : true,
            TowTruckEnabled : true,
            RescueTruck2Enabled : true,
            RescueTruck3Enabled : true,
            FireEngine3Enabled : true,
            TrackRentalSupervisorEnabled : true,
            StarterEnabled : true,
            MarshalEnabled : true,
            SoundTesterEnabled : true,
            GridWorkerEnabled : true,
            GasAttendantEnabled : true,
            DaytimeGateAttendantEnabled : true,
            NighttimeGateAttendantEnabled : true,
            EarlyLoadInLateLoadOutEnabled : true,
            PaddockCampingEnabled : true,
            COVID19AdminFeeEnabled : true,
            caterers: [],
            deletedCaterers: []
        };
        this.apiService = new APIService;
    }

    private openDialog = () => {
        this.setState({ showDialog: true});
    };

    private closeDialog = () => {
        this.setState({ showDialog: false, caterer: "" });
    };
    
    private addCaterer = () => {
        let caterers : ITrackCaterer[] = this.state.caterers;
        caterers.push({id : "00000000-0000-0000-0000-000000000000", active : true, name : this.state.caterer});
        this.setState({caterers, showDialog: false, caterer: "" });
    };

    private removeCaterer = (index : number) => {
        let deletedCaterers = this.state.deletedCaterers;
        let caterers : ITrackCaterer[] = this.state.caterers;
        deletedCaterers.push(caterers.splice(index, 1)[0]);        
        this.setState({caterers});
    };

    private toggleCaterer = (index : number) => {
        let caterers : ITrackCaterer[] = this.state.caterers;
        caterers[index].active = !caterers[index].active;
        this.setState({caterers});
    };

    private getSoundLevels = async () => {
        await this.apiService.getSoundLevels().then((result : any) => {
            this.setState({soundLevels : result});
            result.forEach((soundLevel : ITrackSoundLevel) => {
                if(soundLevel.name === "90dB"){
                    this.setState({DB90 : soundLevel.price});
                }
                if(soundLevel.name === "92dB"){
                    this.setState({DB92 : soundLevel.price});
                }
                if(soundLevel.name === "103dB"){
                    this.setState({DB103 : soundLevel.price});
                }
                if(soundLevel.name === "105dB"){
                    this.setState({DB105 : soundLevel.price});
                }
                if(soundLevel.name === "Unlimited"){
                    this.setState({DBUnlimited : soundLevel.price});
                }
            });
        });
    }

    private getCaterers = async () => {
        await this.apiService.getAllCaterers().then((result : any) => {
            this.setState({caterers: result});
        });
    }

    private getServices = async () => {
        await this.apiService.getAllTrackServices().then((result : any) => {
            this.setState({trackServices : result});
            result.forEach((service : ITrackService) => {
                const newState = { [`${service.name}Price`]: service.price, [`${service.name}Mandatory`]: service.mandatory , [`${service.name}Enabled`]: !service.disabled} as any;
                this.setState(newState);
            });
        });
    }

    private submitChanges = async () =>{
        this.props.setLoading(true);
        let soundLevels : ITrackSoundLevel[] = [];
        soundLevels = this.state.soundLevels.map((soundLevel : ITrackSoundLevel) =>{
                if(soundLevel.name === "90dB"){
                    soundLevel.price = this.state.DB90;
                }
                if(soundLevel.name === "92dB"){
                    soundLevel.price = this.state.DB92;
                }
                if(soundLevel.name === "103dB"){
                    soundLevel.price = this.state.DB103;
                }
                if(soundLevel.name === "105dB"){
                    soundLevel.price = this.state.DB105;
                }
                if(soundLevel.name === "Unlimited"){
                    soundLevel.price = this.state.DBUnlimited;
                }
                return(soundLevel);
            });
        let trackServices : ITrackService[] = [];
        trackServices = this.state.trackServices.map((trackService : ITrackService) =>{
                let price : number = Number(this.state[`${trackService.name}Price` as keyof ITrackRentalAdminPriceSheetState]);
                let mandatory : boolean = Boolean(this.state[`${trackService.name}Mandatory` as keyof ITrackRentalAdminPriceSheetState]);
                let disabled : boolean = !Boolean(this.state[`${trackService.name}Enabled` as keyof ITrackRentalAdminPriceSheetState]);
                let id :string = String( trackService.id);
                let name : string = trackService.name;
                return({ price, mandatory, disabled, name, id});
            });
        await this.apiService.updateSoundLevels(soundLevels);
        await this.apiService.updateTrackServices(trackServices);
        await this.apiService.updateCaterers(this.state.caterers);
        await this.apiService.removeCaterers(this.state.deletedCaterers);
        this.setState({deletedCaterers : []})
        this.props.setMessage("Sheet Updated", "success");
        this.props.setLoading(false);
    }

    public componentDidMount() {
        this.props.setLoading(true);
        this.getServices().then(() => this.getCaterers()).then(() => this.getSoundLevels()).then(() => {
            this.setState({ _isMounted: true }); 
            this.props.setLoading(false);
        });
    }

    private handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newState = { [event.target.name]: event.target.value } as any; 
        this.setState(newState);
    };
    
    private handleCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newState = { [event.target.name]: !this.state[event.target.name as keyof ITrackRentalAdminPriceSheetState] } as any;
        this.setState(newState);
    }
    public async componentDidUpdate(prevProps: ITrackRentalAdminPriceSheetProps) {
        if (prevProps.loading !== this.props.loading){
            this.getServices();
            this.getCaterers();
            this.getSoundLevels();
        }
    }

    public render(): React.ReactElement<ITrackRentalAdminPriceSheetProps> {
        const { classes, width } = this.props;
        return (
            <React.Fragment>
            {(this.state._isMounted && !this.props.loading) && 
            <Paper className={classes.paper}>
                <Grid container spacing={2}>
                    <Grid item sm={12}>
                        <Typography variant="h5">Modify Sound Levels</Typography>
                    </Grid>
                    <Grid item sm={6}>
                        Sound Level
                    </Grid>
                    <Grid item sm={6}>
                        Price
                    </Grid>
                    <Grid item sm={6}>
                        90dB
                    </Grid>
                    <Grid item sm={6}>
                        <TextField
                            name="DB90"
                            className={classes.textField}
                            value={this.state.DB90}
                            onChange={this.handleChange}
                        />
                    </Grid>
                    <Grid item sm={6}>
                        92dB
                    </Grid>
                    <Grid item sm={6}>
                        <TextField
                            name="DB92"
                            className={classes.textField}
                            value={this.state.DB92}
                            onChange={this.handleChange}
                        />
                    </Grid>
                    <Grid item sm={6}>
                        103dB
                    </Grid>
                    <Grid item sm={6}>
                        <TextField
                            name="DB103"
                            className={classes.textField}
                            value={this.state.DB103}
                            onChange={this.handleChange}
                        />
                    </Grid>
                    <Grid item sm={6}>
                        105dB
                    </Grid>
                    <Grid item sm={6}>
                        <TextField
                            name="DB105"
                            className={classes.textField}
                            value={this.state.DB105}
                            onChange={this.handleChange}
                        />
                    </Grid>
                    <Grid item sm={6}>
                        Unlimited
                    </Grid>
                    <Grid item sm={6}>
                        <TextField
                            name="DBUnlimited"
                            className={classes.textField}
                            value={this.state.DBUnlimited}
                            onChange={this.handleChange}
                        />
                    </Grid>
                    <Grid item sm={12} className={classes.center}>
                        <Divider/>
                    </Grid>
                    <Grid item sm={12}>
                        <Typography variant="h5">Modify Caterers</Typography>
                    </Grid>
                    <Grid item sm={12}>
                        <List dense>
                        {this.state.caterers.map((item : ITrackCaterer, index: number) => {
                            return(
                                <ListItem key={index}>
                                    <ListItemText
                                        primary={item.name}
                                        secondary={item.active ? "Enabled" : "Disabled"}
                                    />
                                    <ListItemSecondaryAction>
                                        <IconButton onClick={() => this.toggleCaterer(index)} edge="end" aria-label="enable/disable">
                                            {item.active ? <BlockIcon /> : <CheckIcon />}
                                        </IconButton>
                                        <IconButton onClick={() => this.removeCaterer(index)} edge="end" aria-label="delete">
                                            <DeleteIcon />
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            );
                        })}
                        </List>
                    </Grid>
                    <Grid item sm={12} className={classes.center}>
                        <Button onClick={this.openDialog}variant="contained" size={"small"}>Add Caterer</Button>
                    </Grid>
                    <Grid item sm={12} className={classes.center}>
                        <Divider/>
                    </Grid>
                    <Grid item sm={12}>
                        <Typography variant="h5">Modify Services</Typography>
                    </Grid>
                    <Grid item sm={3}>
                        Service Name
                    </Grid>
                    <Grid item sm={3}>
                        Service Price
                    </Grid>
                    <Grid item sm={3}>
                        Mandatory
                    </Grid>
                    <Grid item sm={3}>
                        Enabled
                    </Grid>
                    <Grid item sm={3}>
                        ALS (Paramedic) Ambulance Service
                    </Grid>
                    <Grid item sm={3}>
                        <TextField
                            name="ALSPrice"
                            className={classes.textField}
                            value={this.state.ALSPrice}
                            onChange={this.handleChange}
                        />
                    </Grid>
                    <Grid item sm={3}>
                        <Checkbox
                            name={"ALSMandatory"}
                            checked={this.state.ALSMandatory}
                            onChange={this.handleCheck}
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                    </Grid>
                    <Grid item sm={3}>
                        <Checkbox
                            name={"ALSEnabled"}
                            checked={this.state.ALSEnabled}
                            onChange={this.handleCheck}
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                    </Grid>
                    <Grid item sm={3}>
                        Tow Truck (Flatbed)
                    </Grid>
                    <Grid item sm={3}>
                        <TextField
                            name="TowTruckPrice"
                            className={classes.textField}
                            value={this.state.TowTruckPrice}
                            onChange={this.handleChange}
                        />
                    </Grid>
                    <Grid item sm={3}>
                        <Checkbox
                            name={"TowTruckMandatory"}
                            checked={this.state.TowTruckMandatory}
                            onChange={this.handleCheck}
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                    </Grid>
                    <Grid item sm={3}>
                        <Checkbox
                            name={"TowTruckEnabled"}
                            checked={this.state.TowTruckEnabled}
                            onChange={this.handleCheck}
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                    </Grid>
                    <Grid item sm={3}>
                        Rescue Truck with 2-man crew
                    </Grid>
                    <Grid item sm={3}>
                        <TextField
                            name="RescueTruck2Price"
                            className={classes.textField}
                            value={this.state.RescueTruck2Price}
                            onChange={this.handleChange}
                        />
                    </Grid>
                    <Grid item sm={3}>
                        <Checkbox
                            name={"RescueTruck2Mandatory"}
                            checked={this.state.RescueTruck2Mandatory}
                            onChange={this.handleCheck}
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                    </Grid>
                    <Grid item sm={3}>
                        <Checkbox
                            name={"RescueTruck2Enabled"}
                            checked={this.state.RescueTruck2Enabled}
                            onChange={this.handleCheck}
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                    </Grid>
                    <Grid item sm={3}>
                        Rescue Truck with 3-man crew
                    </Grid>
                    <Grid item sm={3}>
                        <TextField
                            name="RescueTruck3Price"
                            className={classes.textField}
                            value={this.state.RescueTruck3Price}
                            onChange={this.handleChange}
                        />
                    </Grid>
                    <Grid item sm={3}>
                        <Checkbox
                            name={"RescueTruck3Mandatory"}
                            checked={this.state.RescueTruck3Mandatory}
                            onChange={this.handleCheck}
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                    </Grid>
                    <Grid item sm={3}>
                        <Checkbox
                            name={"RescueTruck3Enabled"}
                            checked={this.state.RescueTruck3Enabled}
                            onChange={this.handleCheck}
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                    </Grid>
                    <Grid item sm={3}>
                        Fire Engine with 3-man crew
                    </Grid>
                    <Grid item sm={3}>
                        <TextField
                            name="FireEngine3Price"
                            className={classes.textField}
                            value={this.state.FireEngine3Price}
                            onChange={this.handleChange}
                        />
                    </Grid>
                    <Grid item sm={3}>
                        <Checkbox
                            name={"FireEngine3Mandatory"}
                            checked={this.state.FireEngine3Mandatory}
                            onChange={this.handleCheck}
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                    </Grid>
                    <Grid item sm={3}>
                        <Checkbox
                            name={"FireEngine3Enabled"}
                            checked={this.state.FireEngine3Enabled}
                            onChange={this.handleCheck}
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                    </Grid>
                    <Grid item sm={3}>                        
                        Track Rental Supervisor
                    </Grid>
                    <Grid item sm={3}>
                        <TextField
                            name="TrackRentalSupervisorPrice"
                            className={classes.textField}
                            value={this.state.TrackRentalSupervisorPrice}
                            onChange={this.handleChange}
                        />
                    </Grid>
                    <Grid item sm={3}>
                        <Checkbox
                            name={"TrackRentalSupervisorMandatory"}
                            checked={this.state.TrackRentalSupervisorMandatory}
                            onChange={this.handleCheck}
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                    </Grid>
                    <Grid item sm={3}>
                        <Checkbox
                            name={"TrackRentalSupervisorEnabled"}
                            checked={this.state.TrackRentalSupervisorEnabled}
                            onChange={this.handleCheck}
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                    </Grid>
                    <Grid item sm={3}>
                        Starter
                    </Grid>
                    <Grid item sm={3}>
                        <TextField
                            name="StarterPrice"
                            className={classes.textField}
                            value={this.state.StarterPrice}
                            onChange={this.handleChange}
                        />
                    </Grid>
                    <Grid item sm={3}>
                        <Checkbox
                            name={"StarterMandatory"}
                            checked={this.state.StarterMandatory}
                            onChange={this.handleCheck}
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                    </Grid>
                    <Grid item sm={3}>
                        <Checkbox
                            name={"StarterEnabled"}
                            checked={this.state.StarterEnabled}
                            onChange={this.handleCheck}
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                    </Grid>
                    <Grid item sm={3}>
                        Course Marshal
                    </Grid>
                    <Grid item sm={3}>
                        <TextField
                            name="MarshalPrice"
                            className={classes.textField}
                            value={this.state.MarshalPrice}
                            onChange={this.handleChange}
                        />
                    </Grid>
                    <Grid item sm={3}>
                        <Checkbox
                            name={"MarshalMandatory"}
                            checked={this.state.MarshalMandatory}
                            onChange={this.handleCheck}
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                    </Grid>
                    <Grid item sm={3}>
                        <Checkbox
                            name={"MarshalEnabled"}
                            checked={this.state.MarshalEnabled}
                            onChange={this.handleCheck}
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                    </Grid>
                    <Grid item sm={3}>
                        Sound Tester
                    </Grid>
                    <Grid item sm={3}>
                        <TextField
                            name="SoundTesterPrice"
                            className={classes.textField}
                            value={this.state.SoundTesterPrice}
                            onChange={this.handleChange}
                        />
                    </Grid>
                    <Grid item sm={3}>
                        <Checkbox
                            name={"SoundTesterMandatory"}
                            checked={this.state.SoundTesterMandatory}
                            onChange={this.handleCheck}
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                    </Grid>
                    <Grid item sm={3}>
                        <Checkbox
                            name={"SoundTesterEnabled"}
                            checked={this.state.SoundTesterEnabled}
                            onChange={this.handleCheck}
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                    </Grid>
                    <Grid item sm={3}>
                        Grid Worker
                    </Grid>
                    <Grid item sm={3}>
                        <TextField
                            name="GridWorkerPrice"
                            className={classes.textField}
                            value={this.state.GridWorkerPrice}
                            onChange={this.handleChange}
                        />
                    </Grid>
                    <Grid item sm={3}>
                        <Checkbox
                            name={"GridWorkerMandatory"}
                            checked={this.state.GridWorkerMandatory}
                            onChange={this.handleCheck}
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                    </Grid>
                    <Grid item sm={3}>
                        <Checkbox
                            name={"GridWorkerEnabled"}
                            checked={this.state.GridWorkerEnabled}
                            onChange={this.handleCheck}
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                    </Grid>
                    <Grid item sm={3}>
                        Gas Attendant
                    </Grid>
                    <Grid item sm={3}>
                        <TextField
                            name="GasAttendantPrice"
                            className={classes.textField}
                            value={this.state.GasAttendantPrice}
                            onChange={this.handleChange}
                        />
                    </Grid>
                    <Grid item sm={3}>
                        <Checkbox
                            name={"GasAttendantMandatory"}
                            checked={this.state.GasAttendantMandatory}
                            onChange={this.handleCheck}
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                    </Grid>
                    <Grid item sm={3}>
                        <Checkbox
                            name={"GasAttendantEnabled"}
                            checked={this.state.GasAttendantEnabled}
                            onChange={this.handleCheck}
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                    </Grid>
                    <Grid item sm={3}>
                        Daytime Gate Attendant (6:30am-6:00pm)
                    </Grid>
                    <Grid item sm={3}>
                        <TextField
                            name="DaytimeGateAttendantPrice"
                            className={classes.textField}
                            value={this.state.DaytimeGateAttendantPrice}
                            onChange={this.handleChange}
                        />
                    </Grid>
                    <Grid item sm={3}>
                        <Checkbox
                            name={"DaytimeGateAttendantMandatory"}
                            checked={this.state.DaytimeGateAttendantMandatory}
                            onChange={this.handleCheck}
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                    </Grid>
                    <Grid item sm={3}>
                        <Checkbox
                            name={"DaytimeGateAttendantEnabled"}
                            checked={this.state.DaytimeGateAttendantEnabled}
                            onChange={this.handleCheck}
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                    </Grid>
                    <Grid item sm={3}>
                        Nighttime Gate Attendant (6:00pm-6:30am)
                    </Grid>
                    <Grid item sm={3}>
                        <TextField
                            name="NighttimeGateAttendantPrice"
                            className={classes.textField}
                            value={this.state.NighttimeGateAttendantPrice}
                            onChange={this.handleChange}
                        />
                    </Grid>
                    <Grid item sm={3}>
                        <Checkbox
                            name={"NighttimeGateAttendantMandatory"}
                            checked={this.state.NighttimeGateAttendantMandatory}
                            onChange={this.handleCheck}
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                    </Grid>
                    <Grid item sm={3}>
                        <Checkbox
                            name={"NighttimeGateAttendantEnabled"}
                            checked={this.state.NighttimeGateAttendantEnabled}
                            onChange={this.handleCheck}
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                    </Grid>
                    <Grid item sm={3}>
                        Early Load-In and Late Load-Outs
                    </Grid>
                    <Grid item sm={3}>
                        <TextField
                            name="EarlyLoadInLateLoadOutPrice"
                            className={classes.textField}
                            value={this.state.EarlyLoadInLateLoadOutPrice}
                            onChange={this.handleChange}
                        />
                    </Grid>
                    <Grid item sm={3}>
                        <Checkbox
                            name={"EarlyLoadInLateLoadOutMandatory"}
                            checked={this.state.EarlyLoadInLateLoadOutMandatory}
                            onChange={this.handleCheck}
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                    </Grid>
                    <Grid item sm={3}>
                        <Checkbox
                            name={"EarlyLoadInLateLoadOutEnabled"}
                            checked={this.state.EarlyLoadInLateLoadOutEnabled}
                            onChange={this.handleCheck}
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                    </Grid>
                    <Grid item sm={3}>
                        Paddock Camping
                    </Grid>
                    <Grid item sm={3}>
                        <TextField
                            name="PaddockCampingPrice"
                            className={classes.textField}
                            value={this.state.PaddockCampingPrice}
                            onChange={this.handleChange}
                        />
                    </Grid>
                    <Grid item sm={3}>
                        <Checkbox
                            name={"PaddockCampingMandatory"}
                            checked={this.state.PaddockCampingMandatory}
                            onChange={this.handleCheck}
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                    </Grid>
                    <Grid item sm={3}>
                        <Checkbox
                            name={"PaddockCampingEnabled"}
                            checked={this.state.PaddockCampingEnabled}
                            onChange={this.handleCheck}
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                    </Grid>
                    <Grid item sm={3}>
                        COVID-19 Administrative Fee
                    </Grid>
                    <Grid item sm={3}>
                        <TextField
                            name="COVID19AdminFeePrice"
                            className={classes.textField}
                            value={this.state.COVID19AdminFeePrice}
                            onChange={this.handleChange}
                        />
                    </Grid>
                    <Grid item sm={3}>
                        <Checkbox
                            name={"COVID19AdminFeeMandatory"}
                            checked={this.state.COVID19AdminFeeMandatory}
                            onChange={this.handleCheck}
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                    </Grid>
                    <Grid item sm={3}>
                        <Checkbox
                            name={"COVID19AdminFeeEnabled"}
                            checked={this.state.COVID19AdminFeeEnabled}
                            onChange={this.handleCheck}
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                    </Grid>
                    <Grid item sm={12} className={classes.center}>
                        <Divider/>
                    </Grid>
                    <Grid item sm={12} className={classes.center}>
                        <Button onClick={this.submitChanges} className={classes.submit} variant="contained">Save Changes</Button>
                    </Grid>
                </Grid>            
            </Paper>
            }            
            {(this.state._isMounted && !this.props.loading) && 
                <Dialog
                    open={this.state.showDialog}
                    onClose={this.closeDialog}
                    maxWidth={"sm"}
                    fullWidth
                    fullScreen={width !== "lg" && width !== "xl"}
                    scroll="paper"
                >
                    <DialogTitle>Add Caterer</DialogTitle>
                    <DialogContent dividers>
                    <Grid container>
                        <Grid item xs={12}>
                            <TextField
                                name="caterer"
                                label="Caterer Name"
                                required
                                className={classes.textField}
                                value={this.state.caterer}
                                onChange={this.handleChange}
                                margin="normal"
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.closeDialog} variant="contained">Close</Button>
                        <Button onClick={this.addCaterer} className={classes.submit} variant="contained">Add Caterer</Button>
                    </DialogActions>
                </Dialog>
            }
            </React.Fragment>
        )
    }
}

const styles = createStyles((theme: Theme) => ({
    paper: {
        padding: theme.spacing(3, 2),
        position: 'relative',
    },
    center:{
        textAlign: 'center',
    },
    submit: {
        backgroundColor: green[500],
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        color: 'white',
        '&:hover': {
            backgroundColor: green[700],
        }
    },
}))

export default withWidth()(withStyles(styles, { withTheme: true })(TrackRentalAdminPriceSheet));