import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import DateFnsUtils from '@date-io/date-fns';
import {MuiPickersUtilsProvider, KeyboardDatePicker, DatePicker } from '@material-ui/pickers';
import { withStyles, createStyles, Theme } from '@material-ui/core/styles';
import {countries} from '../lists/CountryList';
import Entrant from '../interfaces/IEntrant';
import { MenuItem } from '@material-ui/core';
// @ts-ignore
import MuiPhoneNumber from "material-ui-phone-number";


export interface IDriverFormProps {
    handleChange:any;
    handleDateChange:any;
    handleToggle:any;
    handleAutoSuggestChange: any;
    handlePhoneChange:any;
    validationAttempt?: boolean;
    errorMessage?: string;
    sameAsEntrant: boolean
    driverEmail : string;
    driverEmailConfirm : string;
    driverFName: string;
    driverMName?: string;
    driverLName: string;
    driverPhone: string;
    driverDOB: Date | null;
    driverAdd1: string;
    driverAdd2?: string;
    driverAdd3?: string;
    driverCity: string;
    driverState: string;
    driverCountry: string;
    driverPostal: string;
    ICEName: string;
    ICENumber: string;
    driverLicenseIssuer: string;
    driverLicenseExpiry: Date | null;
    driverMedicalIssuer: string;
    driverMedicalExpiry: Date | null;
    driverYears?: number;
    driverNumRaces?: number;
    driverRacesList: string;
    driverIncidents: string;
    driverSuspensions: string;
    blackFlagged: boolean;
    blackFlaggedReason: string;
    classes:any;
}

export interface IDriverFormState {
    medicalDateValid:boolean;
    driverDateValid:boolean;
    DOBDateValid:boolean;
}

export class DriverForm extends Component<IDriverFormProps, IDriverFormState> {
    
    public constructor(props: Readonly<IDriverFormProps>) {
        super(props);
        this.state = {
            medicalDateValid:true,
            driverDateValid:true,
            DOBDateValid:true
        };
    }   
    private validateDate(date: Date | null, field: keyof IDriverFormState ) : boolean{
        var newState = { [field]: true } as any;
        this.setState(newState)
        if (date === null || !(date instanceof Date) || isNaN(date.getTime()) || (field === "DOBDateValid" && date > new Date()))
        {
            newState = {[field]: false} as any;
            this.setState(newState)
        }
            if(field === "DOBDateValid"){
                this.props.handleDateChange(date, "driverDOB")
            }
            if(field === "driverDateValid"){
                this.props.handleDateChange(date, "driverLicenseExpiry")
            }
            if(field === "medicalDateValid"){
                this.props.handleDateChange(date, "driverMedicalExpiry")
            }
        return this.state[field];
    }

    public render() : React.ReactElement{
        const {classes} = this.props;
        const raceDate = new Date('August 1, ' + (new Date().getFullYear() + 1).toString());
        return(          
            <Grid container spacing={3}>
            <Grid item xs={12}>    
                <Typography className={classes.subheading}><b>Driver Personal Information</b></Typography>
                    <Divider />  
            </Grid> 
            <Grid item xs={12}>
            <FormControlLabel className={classes.formSwitchLabel}
                control={
                <Switch
                    name="sameAsEntrant"
                    checked={this.props.sameAsEntrant}
                    value={this.props.sameAsEntrant}
                    color="secondary"
                    onChange={this.props.handleToggle}
                />
                }
                label="Same as Entrant"
            /> 
            </Grid>
                <React.Fragment>
                <Grid item xs={12} md={4}>
                    <TextField
                        name="driverFName"
                        label="First Name"
                        className={classes.textField}
                        value={this.props.driverFName}
                        onChange={this.props.handleChange}
                        margin="normal"
                        disabled={this.props.sameAsEntrant}
                        required
                        error={(this.props.driverFName === null || this.props.driverFName === "") && this.props.validationAttempt}
                        helperText={(this.props.validationAttempt && this.props.driverFName === "") ? "Required Field" : ""}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <TextField
                        name="driverMName"
                        label="Middle Name"
                        className={classes.textField}
                        value={this.props.driverMName}
                        onChange={this.props.handleChange}
                        margin="normal"
                        disabled={this.props.sameAsEntrant}
                    />
                </Grid> 
                <Grid item xs={12} md={4}>              
                    <TextField
                        name="driverLName"
                        label="Last Name"
                        className={classes.textField}
                        value={this.props.driverLName}
                        onChange={this.props.handleChange}
                        margin="normal"
                        disabled={this.props.sameAsEntrant}
                        required
                        error={(this.props.driverLName === null || this.props.driverLName === "") && this.props.validationAttempt}
                        helperText={(this.props.validationAttempt && this.props.driverLName === "") ? "Required Field" : ""}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        name="driverEmail"
                        label="Email"
                        type="email"
                        className={classes.textField}
                        value={this.props.driverEmail}
                        onChange={this.props.handleChange}
                        margin="normal"
                        disabled={this.props.sameAsEntrant}
                        required
                        error={(this.props.driverEmail === null || this.props.driverEmail === "") && this.props.validationAttempt}
                        helperText={(this.props.validationAttempt && this.props.driverEmail === "") ? "Required Field" : ""}
                    />
                </Grid>
                <Grid item xs={12} md={6}>             
                    <TextField
                        name="driverEmailConfirm"
                        label="Confirm Email"
                        className={classes.textField}
                        value={this.props.driverEmailConfirm}
                        onChange={this.props.handleChange}
                        margin="normal"
                        disabled={this.props.sameAsEntrant}                        
                        required
                        error={((this.props.driverEmailConfirm === null || this.props.driverEmailConfirm === "") && this.props.validationAttempt) || (this.props.driverEmailConfirm.toLowerCase() !== this.props.driverEmail.toLowerCase()  && this.props.validationAttempt)}
                        helperText={(this.props.validationAttempt && this.props.driverEmailConfirm === "") ? "Required Field" : 
                        (this.props.validationAttempt && this.props.driverEmailConfirm.toLowerCase() !== this.props.driverEmail.toLowerCase()) ? "Emails do not match." : ""}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <MuiPhoneNumber 
                        defaultCountry={'us'}
                        name="driverPhone"
                        label="Phone Number"
                        className={classes.textField}
                        onChange={(val:string) => this.props.handlePhoneChange(val, "driverPhone")}
                        value={this.props.driverPhone}
                        disabled={this.props.sameAsEntrant}
                        margin="normal"                    
                        required
                        error={(this.props.driverPhone === null || this.props.driverPhone === "") && this.props.validationAttempt}
                        helperText={(this.props.validationAttempt && this.props.driverPhone === "") ? "Required Field" : ""}
                    />
                </Grid>
                <Grid item xs={12} md={6}>    
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="MM/dd/yyyy"
                        margin="normal"
                        name="driverDOB"
                        id="driverDOB"
                        label="Date of Birth"
                        placeholder="MM/dd/yyyy"
                        autoOk
                        className={classes.textField}
                        maxDate={new Date()}
                        value={this.props.driverDOB}
                        disabled={this.props.sameAsEntrant}
                        onChange={date => {this.validateDate(date, "DOBDateValid");}
                        }
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                        required
                        error={(this.props.driverDOB === null || !this.state.DOBDateValid) && this.props.validationAttempt}
                        helperText={(this.props.validationAttempt && this.props.driverDOB === null) ? "Required Field" : (!this.state.DOBDateValid) ? "Invalid Date" : ""}
                    />
                </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={12}>              
                    <TextField
                        name="driverAdd1"
                        label="Street Address 1"
                        className={classes.textField}
                        value={this.props.driverAdd1}
                        onChange={this.props.handleChange}
                        margin="normal"
                        disabled={this.props.sameAsEntrant}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        name="driverAdd2"
                        label="Street Address 2 (Optional)"
                        className={classes.textField}
                        value={this.props.driverAdd2}
                        onChange={this.props.handleChange}
                        margin="normal"
                        disabled={this.props.sameAsEntrant}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        name="driverAdd3"
                        label="Street Address 3 (Optional)"
                        className={classes.textField}
                        value={this.props.driverAdd3}
                        onChange={this.props.handleChange}
                        margin="normal"
                        disabled={this.props.sameAsEntrant}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                        <TextField
                        inputProps={{
                            readOnly: this.props.sameAsEntrant
                            }
                        }
                        disabled={this.props.sameAsEntrant}
                        select
                        required
                        label="Country"
                        name="driverCountry"
                        onChange={this.props.handleChange}
                        value={this.props.driverCountry}
                        className={classes.textField}
                        SelectProps={{
                            MenuProps: {
                                className: classes.menu,
                            },
                        }}
                        margin="normal"
                        fullWidth                        
                        error={this.props.validationAttempt && this.props.driverCountry === ""}
                        helperText={(this.props.validationAttempt && this.props.driverCountry === "") ? "Required Field" : ""}
                        >
                        {countries.map((option, index) => (
                            <MenuItem key={index} value={option.label}>
                                {option.label}
                            </MenuItem>
                        ))}
                        </TextField>
                    </Grid>
                    {(this.props.driverCountry === "United States" || this.props.driverCountry === "Canada") &&
                    <Grid item xs={12} md={3}>              
                        <TextField
                            inputProps={{
                                readOnly: this.props.sameAsEntrant,
                                maxLength: 2
                                }
                            }
                            name="driverState"
                            label="State/Province"
                            className={classes.textField}
                            onChange={this.props.handleChange}
                            value={this.props.driverState.toUpperCase()}
                            disabled={(this.props.sameAsEntrant) || (this.props.driverCountry !== "United States" && this.props.driverCountry !== "Canada")}
                            margin="normal"
                            required
                            error={(this.props.driverCountry === "United States" || this.props.driverCountry === "Canada") &&this.props.validationAttempt && this.props.driverState === ""}
                            helperText={(this.props.driverCountry === "United States" || this.props.driverCountry === "Canada") && (this.props.validationAttempt && this.props.driverState === "") ? "Required Field" : ""}
                        />
                    </Grid>}
                    <Grid item xs={12} md={3}>              
                        <TextField
                            inputProps={{
                                readOnly: this.props.sameAsEntrant
                                }
                            }
                            disabled={this.props.sameAsEntrant}
                            name="driverCity"
                            label="City"
                            className={classes.textField}
                            onChange={this.props.handleChange}
                            value={this.props.driverCity}
                            margin="normal"
                            required
                            error={this.props.validationAttempt && this.props.driverCity === ""}
                            helperText={(this.props.validationAttempt && this.props.driverCity === "") ? "Required Field" : ""}
                        />
                    </Grid>                 
                    <Grid item xs={12} md={3}>              
                        <TextField
                            inputProps={{
                                readOnly: this.props.sameAsEntrant
                                }
                            }
                            disabled={this.props.sameAsEntrant}
                            name="driverPostal"
                            label="Postal Code"
                            className={classes.textField}
                            value={this.props.driverPostal}
                            onChange={this.props.handleChange}
                            margin="normal"
                            required
                            error={this.props.validationAttempt && this.props.driverPostal === ""}
                            helperText={(this.props.validationAttempt && this.props.driverPostal === "") ? "Required Field" : ""}
                        />
                    </Grid>
                </React.Fragment>
                <Grid item xs={12} md={6}>              
                    <TextField
                        name="ICEName"
                        label="Emergency Contact Name"
                        className={classes.textField}
                        value={this.props.ICEName}
                        onChange={this.props.handleChange}
                        margin="normal"
                        fullWidth
                        required
                        error={(this.props.ICEName === null || this.props.ICEName === "") && this.props.validationAttempt}
                        helperText={(this.props.validationAttempt && this.props.ICEName === "") ? "Required Field" : ""}
                    />
                </Grid>
                <Grid item xs={12} md={6}>   
                <MuiPhoneNumber 
                    defaultCountry={'us'}
                    name="ICENumber"
                    label="Emergency Contact Number"
                    className={classes.textField}
                    onChange={(val: string) => this.props.handlePhoneChange(val, "ICENumber")}
                    value={this.props.ICENumber}
                    fullWidth
                    margin="normal"       
                    required
                    error={(this.props.ICENumber === null || this.props.ICENumber === "") && this.props.validationAttempt}
                    helperText={(this.props.validationAttempt && this.props.ICENumber === "") ? "Required Field" : ""}                 
                />
                </Grid>
                <Grid item xs={12} md={3}>              
                    <TextField
                        name="driverLicenseIssuer"
                        label="Competition License Issuer"
                        className={classes.textField}
                        value={this.props.driverLicenseIssuer}
                        onChange={this.props.handleChange}
                        margin="normal"
                        fullWidth
                        required
                        error={(this.props.driverLicenseIssuer === null || this.props.driverLicenseIssuer === "") && this.props.validationAttempt}
                        helperText={(this.props.validationAttempt && this.props.driverLicenseIssuer === "") ? "Required Field" : ""}
                    />
                </Grid>
                <Grid item xs={12} md={3}>  
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            format="MM/dd/yyyy"
                            margin="normal"
                            id="driverLicenseExpiry"
                            name="driverLicenseExpiry"
                            label="Competition License Expiry"
                            placeholder="MM/dd/yyyy"
                            className={classes.textField}
                            value={this.props.driverLicenseExpiry}
                            fullWidth
                            autoOk
                            onChange={date => {this.validateDate(date, "driverDateValid")}}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            required
                            error={(this.props.driverLicenseExpiry === null || !this.state.driverDateValid) && this.props.validationAttempt}
                            helperText={(this.props.validationAttempt && this.props.driverLicenseExpiry === null) ? "Required Field" : 
                                        (this.props.driverLicenseExpiry !== null && this.props.driverLicenseExpiry < new Date()) ? "License is expired." :
                                        (this.props.driverLicenseExpiry !== null && this.props.driverLicenseExpiry < raceDate) ? "License is set to expire soon." :
                                        (!this.state.driverDateValid) ? "Invalid Date" : ""}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={12} md={3}>              
                 <TextField
                        name="driverMedicalIssuer"
                        label="Medical License Issuer"
                        className={classes.textField}
                        value={this.props.driverMedicalIssuer}
                        onChange={this.props.handleChange}
                        margin="normal"
                        fullWidth
                        required
                        error={(this.props.driverMedicalIssuer === null || this.props.driverMedicalIssuer === "") && this.props.validationAttempt}
                        helperText={(this.props.validationAttempt && this.props.driverMedicalIssuer === "") ? "Required Field" : ""}
                    />
                </Grid>
                <Grid item xs={12} md={3}>   
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            format="MM/dd/yyyy"
                            margin="normal"
                            id="driverMedicalExpiry"
                            name="driverMedicalExpiry"
                            label="Medical License Expiry"
                            placeholder="MM/dd/yyyy"
                            className={classes.textField}
                            value={this.props.driverMedicalExpiry}
                            fullWidth
                            autoOk
                            onChange={date => {this.validateDate(date, "medicalDateValid")}}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            required
                            error={(this.props.driverMedicalExpiry === null || !this.state.medicalDateValid) && this.props.validationAttempt}
                            helperText={(this.props.validationAttempt && this.props.driverMedicalExpiry === null) ? "Required Field" : 
                                        (this.props.driverMedicalExpiry !== null && this.props.driverMedicalExpiry < new Date()) ? "License is expired." :
                                        (this.props.driverMedicalExpiry !== null && this.props.driverMedicalExpiry < raceDate) ? "License is set to expire soon." :
                                        (!this.state.medicalDateValid) ? "Invalid Date" : ""}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={12}>    
                    <Typography className={classes.subheading}><b>Driver History</b></Typography>
                     <Divider />  
                </Grid> 
                <Grid item xs={12} md={3}>              
                    <TextField
                        name="driverYears"
                        type="number"
                        label="Years Driving"
                        className={classes.textField}
                        value={(this.props.driverYears === undefined) ? "" :this.props.driverYears}
                        onChange={this.props.handleChange}
                        margin="normal"
                        inputProps={{
                            max: 999,
                            min:0
                        }}
                        fullWidth
                        required 
                        error={(this.props.driverYears === null || this.props.driverYears === undefined || isNaN(this.props.driverYears) || this.props.driverYears as any === "") && this.props.validationAttempt}
                        helperText={(this.props.validationAttempt && this.props.driverYears === undefined) ? "Required Field" : (this.props.validationAttempt && this.props.driverYears !== undefined && (isNaN(this.props.driverYears) || this.props.driverYears as any === "")) ? "Must be a number." :  ""}
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
                <Grid item xs={12} md={3}>              
                    <TextField
                        name="driverNumRaces"
                        type="number"
                        label="Number of Vintage Events"
                        className={classes.textField}
                        value={(this.props.driverNumRaces === undefined) ? "" : this.props.driverNumRaces}
                        onChange={this.props.handleChange}
                        margin="normal"
                        inputProps={{
                            max: 999,
                            min:0
                        }}
                        fullWidth
                        required
                        error={(this.props.driverNumRaces === null || this.props.driverNumRaces === undefined || isNaN(this.props.driverNumRaces) || this.props.driverNumRaces as any === "") && this.props.validationAttempt}
                        helperText={(this.props.validationAttempt && this.props.driverNumRaces === undefined) ? "Required Field" : (this.props.validationAttempt && this.props.driverNumRaces !== undefined && (isNaN(this.props.driverNumRaces) || this.props.driverNumRaces as any === "")) ? "Must be a number." :  ""}
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
                <Grid item xs={12}>              
                    <TextField
                        name="driverRacesList"
                        label={"Vintage Events for " + new Date().getFullYear() + "/" + (new Date().getFullYear() + 1)}
                        className={classes.textField}
                        value={this.props.driverRacesList}
                        onChange={this.props.handleChange}
                        margin="normal"
                        fullWidth
                        multiline
                        rowsMax="10"
                        required
                        error={(this.props.driverRacesList === null || this.props.driverRacesList === "") && this.props.validationAttempt}
                        helperText={(this.props.validationAttempt && this.props.driverRacesList === "") ? "Required Field" : ""}
                    />
                </Grid>                    
                <Grid item xs={12}>
                    <TextField
                        name="driverIncidents"
                        label="List Any Vintage Racing Incidents"
                        multiline
                        rowsMax="10"
                        className={classes.textField}
                        value={this.props.driverIncidents}
                        onChange={this.props.handleChange}
                        margin="normal"
                        fullWidth
                        required
                        error={(this.props.driverIncidents === null || this.props.driverIncidents === "") && this.props.validationAttempt}
                        helperText={(this.props.validationAttempt && this.props.driverIncidents === "") ? "Required Field" : ""}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        name="driverSuspensions"
                        label="List Any Suspensions"
                        multiline
                        rowsMax="10"
                        className={classes.textField}
                        value={this.props.driverSuspensions}
                        onChange={this.props.handleChange}
                        margin="normal"
                        fullWidth
                        required
                        error={(this.props.driverSuspensions === null || this.props.driverSuspensions === "") && this.props.validationAttempt}
                        helperText={(this.props.validationAttempt && this.props.driverSuspensions === "") ? "Required Field" : ""}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel 
                        className={classes.formSwitchLabel}
                        control={
                            <Switch
                                name="blackFlagged"
                                checked={this.props.blackFlagged}
                                value={this.props.blackFlagged}
                                color="secondary"
                                onChange={this.props.handleToggle}
                            />
                        }
                        label="Black Flagged in Last 12 Months"
                    />
                </Grid>
                {this.props.blackFlagged &&
                <Grid item xs={12}>
                    <TextField
                        name="blackFlaggedReason"
                        label="Reason For Black Flags"
                        multiline
                        rowsMax="5"
                        className={classes.textField}
                        value={this.props.blackFlaggedReason}
                        onChange={this.props.handleChange}
                        margin="normal"
                        fullWidth
                        required
                        error={(this.props.blackFlagged && this.props.blackFlaggedReason === "") && this.props.validationAttempt}
                        helperText={(this.props.blackFlagged && this.props.blackFlaggedReason === "") ? "Required Field" : ""}
                    />
                </Grid>}
            </Grid>             
        );
    }
}

const styles = createStyles((theme: Theme) => ({
    button: {
      margin: theme.spacing(1),
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1)
    },
    paper:{    
      padding: theme.spacing(2, 2),
      marginLeft: '25%',
      marginRight: '25%',
    },  
    center:{
        textAlign: 'center', 
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    root: {
      width: '90%',
    },
    backButton: {
      marginRight: theme.spacing(1),
    },
    completed: {
      display: 'inline-block',
    },
    wizardControl:{
        marginTop: 15,
        textAlign: 'center', 
    },
    regStep:{
        textAlign: 'center', 
    },    
    phoneCode:{
        width: 50,
    }
  }))
  
  export default withStyles(styles)(DriverForm);