import React, { Component } from 'react';
import { withStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Paper, FormControlLabel, Switch, Button, Grid, Typography } from '@material-ui/core';
import AccountForm from './AccountForm';
import { PhoneNumberUtil, PhoneNumber } from 'google-libphonenumber';
import { green, red } from '@material-ui/core/colors';
import Entrant from '../interfaces/IEntrant';
import APIService from '../services/APIService';

export interface IAccountInfoProps {
    classes: any;
    setMessage: any;
    setLoading: any;
    loading: boolean;
    currentEntrant?: Entrant;
}

export interface IAccountInfoState {
    handleChange: any;
    handleDateChange: any;
    handlePhoneChange: any;
    entrantFName: string;
    entrantMName: string;
    entrantLName: string;
    entrantPhone: string;
    entrantDOB: Date | null;
    entrantAdd1: string;
    entrantAdd2: string;
    entrantAdd3: string;
    entrantCity: string;
    entrantState: string;
    entrantCountry: string;
    entrantPostal: string;
    entrantEmail: string;
    setMessage: any;
    entrantBusinessName: string;
    handleAutoSuggestChange: any;
    validationAttempt: boolean;
    editMode: boolean;
    entrantId: string;
}

export class AccountInfo extends Component<IAccountInfoProps, IAccountInfoState> {
    static displayName = AccountInfo.name;
    private apiService: APIService = new APIService;
    public constructor(props: Readonly<IAccountInfoProps>) {
        super(props);
        this.state = {
            handleChange: this.handleChange,
            handleDateChange: this.handleDateChange,
            handleAutoSuggestChange: this.handleAutoSuggestChange,
            handlePhoneChange: this.handlePhoneChange,
            entrantFName: "",
            entrantLName: "",
            entrantMName: "",
            entrantPhone: "",
            entrantDOB: null,
            entrantAdd1: "",
            entrantAdd2: "",
            entrantAdd3: "",
            entrantCity: "",
            entrantState: "",
            entrantCountry: "",
            entrantPostal: "",
            entrantEmail: "",
            entrantId: "",
            entrantBusinessName: "",
            setMessage: this.props.setMessage,
            validationAttempt: false,
            editMode: false,
        };
        this.apiService = new APIService;
    }

    private handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newState = { [event.target.name]: event.target.value } as any;
        this.setState(newState);
    }
    
    private handleAutoSuggestChange = (field: string, value: string) => {
        const newState = { [field]: value } as any;
        this.setState(newState);
    }

    private handlePhoneChange = (phone: string) => {
        this.setState({ entrantPhone: phone });
    }

    private handleDateChange = (date: Date | null, field: string) => {
        const newState = { [field]: date } as any;
        this.setState(newState);
    }

    private handleToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newState = { [event.target.name]: !this.state[event.target.name as keyof IAccountInfoState] } as any;
        this.setState(newState);
    }
    
    private getEntrant = async () => {
        this.props.setLoading(true);
        await this.apiService.getEntrant().then(result => {
                this.setState({entrantFName: result.data.fname,
                entrantMName: result.data.mname,
                entrantLName: result.data.lname,
                entrantDOB: result.data.dob,
                entrantAdd1: result.data.address1,
                entrantAdd2: result.data.address2,
                entrantAdd3: result.data.address,
                entrantCity: result.data.city,
                entrantState: result.data.state,
                entrantCountry: result.data.country,
                entrantPostal: result.data.zipCode,
                entrantId: result.data.id,
                entrantPhone: result.data.phoneNumber,
                entrantBusinessName: result.data.businessName});
            this.props.setLoading(false);
        })
    }

    private updateEntrant = async () => {
        this.setState({ validationAttempt: true });
        try {
            // const phoneUtil: PhoneNumberUtil = PhoneNumberUtil.getInstance();
            // const phoneNumberProto: PhoneNumber = phoneUtil.parse(this.state.entrantPhone);

            if ((this.state.entrantFName === "" || this.state.entrantFName === null) || (this.state.entrantLName === "" || this.state.entrantLName === null) ||
                (this.state.entrantPhone === "" || this.state.entrantPhone === null) || (this.state.entrantDOB === null) || (this.state.entrantAdd1 === "" || this.state.entrantAdd1 === null) ||
                (this.state.entrantCity === "" || this.state.entrantCity === null) || ((this.state.entrantCountry === "United States" || this.state.entrantCountry === "Canada") && (this.state.entrantState === "" || this.state.entrantState === null)) ||
                (this.state.entrantCountry === "" || this.state.entrantCountry === null) || (this.state.entrantPostal === "" || this.state.entrantPostal === null) || this.state.entrantDOB > new Date()) {
                this.props.setMessage("Missing Required Fields", "error");
                return;
            }
            // if (!phoneUtil.isValidNumber(phoneNumberProto)) {
            //     this.props.setMessage("Invalid Phone Format", "error");
            //     return;
            // }
        } catch (e) {
            this.props.setMessage("Not A Valid Phone Number", "error");
            return;
        }
        let entrant : Entrant = {
            id: this.state.entrantId,
            email: "",
            usesMetricSystem: false,
            fname: this.state.entrantFName,
            lname: this.state.entrantLName,
            mname: this.state.entrantMName,
            address1: this.state.entrantAdd1,
            address2: this.state.entrantAdd2,
            address3: this.state.entrantAdd3,
            city: this.state.entrantCity,
            state: (this.state.entrantCountry === "United States" || this.state.entrantCountry === "Canada") ? this.state.entrantState.toUpperCase() : "",
            dob: this.state.entrantDOB,
            zipCode: this.state.entrantPostal,
            country: this.state.entrantCountry,
            phoneNumber: this.state.entrantPhone,
            businessName: this.state.entrantBusinessName
        }
        this.props.setLoading(true);
        await this.apiService.updateEntrant(entrant).then(result => {
            if(result.ok){
                this.setState({ editMode: !result.ok });
                this.props.setMessage("Information Successfully Updated.","success")
            }else{
                this.props.setMessage("Error completing operation. Please try again. (" + result.status + ")", "error");
            }
            this.props.setLoading(false);
        })

        this.setState({ validationAttempt: false });
    };

    public componentDidMount() {
        this.getEntrant();
    }

    public componentDidUpdate(prevProps: IAccountInfoProps) {
        if (this.props.currentEntrant !== prevProps.currentEntrant) {
            this.getEntrant();
        }
    }
    public render(): React.ReactElement<IAccountInfoProps> {
        const { classes } = this.props;
        return (
            <React.Fragment>
                <Paper className={classes.paper}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={10}>
                            <Typography variant="h6">Account Info</Typography>
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <FormControlLabel className={classes.formSwitchLabel}
                                control={
                                    <Switch
                                        name="editMode"
                                        checked={this.state.editMode}
                                        value={this.state.editMode}
                                        color="secondary"
                                        onChange={this.handleToggle}
                                        disabled={this.props.loading}
                                    />
                                }
                                label="Edit Information"
                            />
                        </Grid>
                    </Grid>
                    {(this.state.editMode) ?
                        <React.Fragment>
                            <AccountForm
                                {...this.state}
                            />
                            <div className={classes.center}>
                                <Button className={classes.submit} onClick={this.updateEntrant} autoFocus disabled={this.props.loading}>
                                    Save
                    </Button>
                            </div>
                        </React.Fragment> :
                        <AccountForm
                            {...this.state}
                        />
                    }
                </Paper>
            </React.Fragment>
        )
    }
}

const styles = createStyles((theme: Theme) => ({
    paper: {
        padding: theme.spacing(3, 2),
        position: 'relative'
    },
    root: {
        marginTop: theme.spacing(10),
        [theme.breakpoints.up('sm')]: {
            marginTop: theme.spacing(12),
        }
    },
    submit: {
        backgroundColor: green[500],
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        color: 'white',
        '&:hover': {
            backgroundColor: green[700],
        }
    },
    cancel: {
        backgroundColor: red[500],
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        color: 'white',
        '&:hover': {
            backgroundColor: red[700],
        }
    },
    center: {
        textAlign: 'center'
    }
}))

export default withStyles(styles)(AccountInfo)