import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Card from '@material-ui/core/Card';
import Chip from '@material-ui/core/Chip';
import CardContent from '@material-ui/core/CardContent';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import FaceIcon from '@material-ui/icons/Face';
import { withStyles, createStyles, Theme } from '@material-ui/core/styles';
import Crew from '../interfaces/ICrew';
import Race from '../interfaces/IRace';
import RegistrationEvent from '../interfaces/IRegistrationEvent';
import CarRace from '../interfaces/ICarRace';

export interface ICrewFormProps {
    updateCrew: any;
    setMessage: any;
    selectedRaces: Race[];
    event: RegistrationEvent;
    crew: Crew[];
    classes: any;
    maxCrew: number;
}

export interface ICrewFormState {
    raceCheckboxes: IRaceCheck[];
    crewFName: string;
    crewLName: string;
    dateValid: boolean;
    validationAttempt: boolean;
}

interface IRaceCheck {
    race: Race;
    checked: boolean;
}

export class CrewForm extends Component<ICrewFormProps, ICrewFormState> {

    public constructor(props: Readonly<ICrewFormProps>) {
        super(props);
        this.state = {
            dateValid: true,
            validationAttempt: false,
            crewFName: "",
            crewLName: "",
            raceCheckboxes: [],
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleCheck = this.handleCheck.bind(this);
        this.addCrewMember = this.addCrewMember.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
    }

    private handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newState = { [event.target.name]: event.target.value } as any;
        this.setState(newState);
    }

    private handleDateChange = (date: Date | null, field: string) => {
        const newState = { [field]: date } as any;
        this.setState(newState);
    }

    private handleCheck(id: string): void {
        let raceCheckboxes = this.state.raceCheckboxes;
        let race = raceCheckboxes.filter(x => x.race.id === id)[0];
        race.checked = !race.checked;
        this.setState({ raceCheckboxes });
    }
    private checkBoxChecked() {
        return this.state.raceCheckboxes.filter(x => x.checked === true);
    }
    // private inCrewArray(array : Crew[], crewMember : Crew) : boolean { 
    //     return array.filter(crew => (crew.fname === crewMember.fname && crew.lname === crewMember.lname)).length > 0;
    // }

    private addCrewMember(): void {
        this.setState({ validationAttempt: true });
        let checkedOptions: IRaceCheck[] = this.checkBoxChecked();
        let currentCrew: Crew[] = this.props.crew;
        if (checkedOptions.length === 0) {
            this.props.setMessage("Please Select a Race to Assign a Crew Member to.", "error")
            return;
        }
        if (this.state.crewFName === null || this.state.crewFName === "") {
            this.props.setMessage("Missing First Name", "error");
            return;
        }
        if (this.state.crewLName === null || this.state.crewLName === "") {
            this.props.setMessage("Missing Last Name", "error");
            return;
        }

        checkedOptions.forEach((value) => {
            let newCrew: Crew = {
                fname: this.state.crewFName[0].toUpperCase() + this.state.crewFName.slice(1).toLowerCase(),
                lname: this.state.crewLName[0].toUpperCase() + this.state.crewLName.slice(1).toLowerCase(),
                isOvercrew: false,
                race: value.race
            }
            //Get count of crew
            let count = this.props.crew.filter(x => x.race?.id == value.race.id && !x.isOvercrew).length;
            //crew is full
            if (count > this.props.maxCrew - 1) {
                newCrew.isOvercrew = true;
            }
            //check if participant is already on crew
            if (this.props.crew.some(x => newCrew.fname === x.fname && newCrew.lname === x.lname && newCrew.race?.id === x.race?.id)) {
                this.props.setMessage("Participant is already on the Selected Crew", "info");
            }
            else {
                if (newCrew.isOvercrew) {
                    this.props.setMessage("Pre-Reunion Crew Full. Added to Overcrew", "info");
                }
                currentCrew.push(newCrew);
            }
        });
        this.props.updateCrew(currentCrew);
        this.setState({ crewFName: "", crewLName: "", validationAttempt: false });
        return;
    }

    private removeCrew(value: Crew): void {
        let currentCrew: Crew[] = this.props.crew;
        currentCrew.splice(currentCrew.indexOf(value), 1);
        if(!value.isOvercrew){
            if (currentCrew.some(x => x.race?.id === value.race?.id && x.isOvercrew)) {
                currentCrew.filter(x => x.race?.id === value.race?.id && x.isOvercrew)[0].isOvercrew = false;
            }
        }
        this.props.updateCrew(currentCrew);
        return;
    }

    private setCheckBoxes() {
        let raceCheckboxes: IRaceCheck[] = [];
        this.props.selectedRaces.forEach((value) => {
            raceCheckboxes.push({ race: value, checked: this.props.selectedRaces.length < 2 });
        });
        this.setState({ raceCheckboxes })
    }

    private renderCrewList(raceCrew: Crew[], isOvercrew: boolean) {
        const { classes } = this.props;
        let list: any[] = [];
        raceCrew.map((value: Crew, index: number) => {
            list.push(<ListItem key={index}>
                <Typography variant="h6" className={classes.center}>
                    <Chip
                        icon={isOvercrew ? <MonetizationOnIcon /> : <CheckCircleIcon /> }
                        color={isOvercrew ? "secondary" : "primary"}
                        label={isOvercrew && (this.props.selectedRaces.length > 1) ? value.fname + " " + value.lname +  "(" + value.race?.name + ")" : value.fname + " " + value.lname}
                        onDelete={() => this.removeCrew(value)} />
                </Typography>
            </ListItem>)
        }
        )
        if (!isOvercrew) {
            for (let i = raceCrew.length; i < this.props.maxCrew; i++) {
                list.push(<ListItem key={i}>
                    <Typography variant="h6" className={classes.center}>
                        <Chip
                            variant="outlined"
                            icon={<FaceIcon />}
                            label="No Crew Assigned"
                        />
                    </Typography>
                </ListItem>)
            }
        } else if(raceCrew.length === 0){
            list.push(<ListItem key={0}>
                    <Typography variant="h6" className={classes.center}>
                        <Chip
                            variant="outlined"
                            icon={<FaceIcon />}
                            label="No Overcrew"
                        />
                    </Typography>
                </ListItem>)
        }
        return (list)
    }

    public componentDidMount() {
        this.setCheckBoxes();
    }
    public render(): React.ReactElement {
        const { classes } = this.props;
        return (<Grid container spacing={2}>
            <Grid item xs={12} md={12}>
                <Grid container spacing={2} className={classes.center}>
                    <Grid item xs={12} md={4} className={classes.center}>
                        <TextField
                            name="crewFName"
                            label="First Name"
                            className={classes.textField}
                            margin="normal"
                            value={this.state.crewFName}
                            onChange={this.handleChange}
                            error={this.state.validationAttempt && (this.state.crewFName === null || this.state.crewFName === "")}
                            helperText={(this.state.validationAttempt && (this.state.crewFName === null || this.state.crewFName === "")) ? "Required Field" : ""}
                        />
                    </Grid>
                    <Grid item xs={12} md={4} className={classes.center}>
                        <TextField
                            name="crewLName"
                            label="Last Name"
                            className={classes.textField}
                            margin="normal"
                            value={this.state.crewLName}
                            onChange={this.handleChange}
                            error={this.state.validationAttempt && (this.state.crewLName === null || this.state.crewLName === "")}
                            helperText={(this.state.validationAttempt && (this.state.crewLName === null || this.state.crewLName === "")) ? "Required Field" : ""}
                        />
                    </Grid>
                    {this.props.selectedRaces.length > 1 && (
                        <Grid item xs={12}>
                            {this.props.selectedRaces.map((value, index) => {
                                return (
                                    <FormControlLabel
                                        key={index}
                                        control={
                                            <Checkbox
                                                name={value.name + "check"}
                                                value={false}
                                                color="secondary"
                                                onChange={() => this.handleCheck(value.id)}
                                            />
                                        }
                                        label={value.name}
                                    />
                                )
                            })}
                        </Grid>
                    )}
                    <Grid item xs={12} className={classes.formButton}>
                        <Button variant="contained" className={classes.button}
                            onClick={this.addCrewMember}>
                            Add
                        </Button>
                        <Typography variant="body2" gutterBottom>
                            Race crew for this entry is limited to {this.props.maxCrew} members. Additional spots (Overcrew) are available for an additional
                            {this.props.selectedRaces.map((value, index) =>{
                                return(" $" + value.overcrewPrice + ".00 (" + value.name + ")")
                            })}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            {this.props.selectedRaces.map((value, index) => {
                let raceCrew: Crew[] = this.props.crew.filter(x => x.race?.id === value.id && !x.isOvercrew);
                return (
                    <Grid item xs={12} md key={index}>
                        <Card>
                            <CardContent>
                                <Typography variant="h6" className={classes.center}>
                                    {value.name + " Crew"}
                                </Typography>
                                <List dense>
                                    {this.renderCrewList(raceCrew, false)}
                                </List>
                            </CardContent>
                        </Card>
                    </Grid>
                )
            })
            }
            <Grid item xs={12} md>
                <Card>
                    <CardContent>
                        <Typography variant="h6" className={classes.center}>
                            Overcrew
                            </Typography>
                        <List dense>
                            {this.renderCrewList(this.props.crew.filter(x =>x.isOvercrew), true)}
                        </List>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} className={classes.center}>4 crew members for first car</Grid>
            <Grid item xs={12} className={classes.center}>3 crew members for second car</Grid>
            <Grid item xs={12} className={classes.center}>2 crew members for third and subsequent cars</Grid>
        </Grid>);
    }
}

const styles = createStyles((theme: Theme) => ({
    button: {
        margin: theme.spacing(1),
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1)
    },
    paper: {
        padding: theme.spacing(2, 2),
        marginLeft: '25%',
        marginRight: '25%',
    },
    center: {
        textAlign: 'center',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    root: {
        width: '90%',
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
    completed: {
        display: 'inline-block',
    },
    wizardControl: {
        marginTop: 15,
        textAlign: 'center',
    },
    regStep: {
        textAlign: 'center',
    },
}))

export default withStyles(styles)(CrewForm);