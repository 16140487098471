import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import Fab from '@material-ui/core/Fab';
import { green, red } from '@material-ui/core/colors';
import Paper from '@material-ui/core/Paper';
import CarCard from './CarCard';
import AddIcon from '@material-ui/icons/Add';
import Tooltip from '@material-ui/core/Tooltip';
import { Grid, Divider, FormControlLabel, Switch, Button } from '@material-ui/core';
import { withStyles, createStyles, Theme } from '@material-ui/core/styles';
import Entry from '../interfaces/IEntry';
import PrepShop from '../interfaces/IPrepShop';
import PaddockInfo from './PaddockInfo';
import CarWizard from './CarWizard';
import Box from '@material-ui/core/Box';
import APIService from '../services/APIService';
import convert from '../util/UnitConversion';
import ShoppingCart from './ShoppingCart';
import CartItem from '../interfaces/ICartItem';
import RegistrationEvent from '../interfaces/IRegistrationEvent';
import { start } from 'repl';
import Race from '../interfaces/IRace';

export interface IEntryCardProps {
    classes: any;
    setMessage: any;
    setLoading: any;
    getEntryForms: any;
    entry: Entry;
    loading: boolean;
    regOpen: boolean;
}

export interface IEntryCardState {
    event: RegistrationEvent;
    handleChange: any;
    handleToggle: any;
    handleChangeUnit: any;
    handlePrepShopSelect: any;
    handlePhoneChange: any;
    removableTrailer: boolean;
    removableTrailerLength: number | null;
    transporterType: string;
    transporterLength: number | null;
    transporterWidth: number | null;
    awning: boolean;
    awningWidth: number | null;
    awningLength: number | null;
    sharePitWith: string;
    pitNextTo: string;
    prepShop: PrepShop | null;
    customPrepShopTeam: string;
    customPrepShopNumber: string;
    customPrepShopEmail: string;
    awningOrientation: string;
    imperial: boolean;
    showCarDialog: boolean;
    isInterestedInTOP: boolean;
    editMode: boolean;
    customPrepShop: boolean;
    validationAttempt:boolean;
    entry: Entry;
    cartItems:CartItem[];
    shoppingCartBalance:number;
    startDate: Date;
    _isMounted: boolean;
}

export class EntryCard extends Component<IEntryCardProps, IEntryCardState> {
    static displayName = EntryCard.name;
    private apiService: APIService = new APIService;
    public constructor(props: Readonly<IEntryCardProps>) {
        super(props);
        this.state = {
            showCarDialog: false,
            handleChange: this.handleChange,
            handleToggle: this.handleToggle,
            handlePhoneChange: this.handlePhoneChange,
            handleChangeUnit: this.handleChangeUnit,
            handlePrepShopSelect: this.handlePrepShopSelect,
            removableTrailer: this.props.entry.isRemovableTrailer,
            transporterType: this.props.entry.transporterType,
            awning: this.props.entry.hasTrasporterAwning,
            transporterLength: this.props.entry.transporterLength,
            transporterWidth: this.props.entry.transporterWidth,
            removableTrailerLength: this.props.entry.trailerLength,
            sharePitWith: this.props.entry.sharedSpaceParticipants,
            pitNextTo: this.props.entry.adjacentParticipants,
            customPrepShopTeam: this.props.entry.prepShopName,
            customPrepShopNumber: this.props.entry.prepShopContactNumber,
            customPrepShopEmail: this.props.entry.prepShopEmailAddress,
            prepShop: this.props.entry.prepShop,
            awningLength: this.props.entry.awningLength,
            awningWidth: this.props.entry.awningWidth,
            isInterestedInTOP: this.props.entry.isInterestedInTop,
            awningOrientation: this.props.entry.awningOrientation,
            imperial: false,
            editMode: false,
            customPrepShop: false,
            validationAttempt:false,
            entry: this.props.entry,
            cartItems: [],
            shoppingCartBalance: 0,
            event: {name: "", id : "", year: "", registrationOpenDate : new Date(), registrationCloseDate : new Date(), race : []},
            startDate: new Date("1990-17-12"),
            _isMounted: false
        };
        this.AddCarDialog = this.AddCarDialog.bind(this);
        this.handlePhoneChange = this.handlePhoneChange.bind(this);
        this.apiService = new APIService;
    }
    private updateEntry = () => {
        this.setState({
            entry: this.props.entry,
            removableTrailer: this.props.entry.isRemovableTrailer,
            transporterType: this.props.entry.transporterType,
            awning: this.props.entry.hasTrasporterAwning,
            transporterLength: this.props.entry.transporterLength,
            transporterWidth: this.props.entry.transporterWidth,
            removableTrailerLength: this.props.entry.trailerLength,
            sharePitWith: this.props.entry.sharedSpaceParticipants,
            pitNextTo: this.props.entry.adjacentParticipants,
            customPrepShopTeam: this.props.entry.prepShopName,
            customPrepShopNumber: this.props.entry.prepShopContactNumber,
            customPrepShopEmail: this.props.entry.prepShopEmailAddress,
            prepShop: this.props.entry.prepShop,
            awningLength: this.props.entry.awningLength,
            awningWidth: this.props.entry.awningWidth,
            isInterestedInTOP: this.props.entry.isInterestedInTop,
            cartItems: [],
            shoppingCartBalance: 0
        });        
        this.calculateCart();
    }
    private upcomingRaceYear(): string {
        const currentDate = new Date();
        const raceDate = new Date('August 1, ' + currentDate.getFullYear().toString());
        if (currentDate >= raceDate) {
            return (currentDate.getFullYear() + 1).toString();
        } else {
            return (currentDate.getFullYear()).toString();
        }
    }
    private openAddCarDialog = () => {
        if (this.props.entry.carModel.length < 10) {
            this.setState({ showCarDialog: true });
        } else {
            this.props.setMessage("Maximum amount of cars met.", "Error")
        }
    }
    private handleCloseCarDialog = () => {
        this.setState({ showCarDialog: false });
        this.props.getEntryForms();
    }

    private handleToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newState = { [event.target.name]: !this.state[event.target.name as keyof IEntryCardState] } as any;
        this.setState(newState);
        if (event.target.name === "customPrepShop") {
            this.setState({ customPrepShopTeam: "", customPrepShopNumber: "", customPrepShopEmail: "" });
        }
    }

    private handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newState = { [event.target.name]: event.target.value } as any;
        this.setState(newState);
    }

    private handlePrepShopSelect = (customPrepShop: PrepShop) => {
        this.setState({ prepShop: customPrepShop });
    }

    private handlePhoneChange = (phone: string) => {
        this.setState({ customPrepShopNumber: phone });
    }


    private handleChangeUnit = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
        if (event.target.value === "imperial" && this.state.imperial === false) {
            this.setState({
                awningLength: convert(this.state.awningLength, "imperial", "feet"),
                awningWidth: convert(this.state.awningWidth, "imperial", "feet"),
                transporterLength: convert(this.state.transporterLength, "imperial", "feet"),
                transporterWidth: convert(this.state.transporterWidth, "imperial", "feet"),
                removableTrailerLength: convert(this.state.removableTrailerLength, "imperial", "feet"),
                imperial: true
            });
        }
        if (event.target.value === "metric" && this.state.imperial === true) {
            this.setState({
                awningLength: convert(this.state.awningLength, "metric", "meters"),
                awningWidth: convert(this.state.awningWidth, "metric", "meters"),
                transporterLength: convert(this.state.transporterLength, "metric", "meters"),
                transporterWidth: convert(this.state.transporterWidth, "metric", "meters"),
                removableTrailerLength: convert(this.state.removableTrailerLength, "metric", "meters"),
                imperial: false
            });
        }
    }

    private updateReg = async () => {
        this.props.setLoading(true);
        let customPrepShop;
        if (this.state.customPrepShop) {
            customPrepShop = { id: "00000000-0000-0000-0000-000000000000", name: this.state.customPrepShopTeam, phone: (this.state.customPrepShopNumber), email: this.state.customPrepShopEmail }
        }
        let entry = {
            id: this.props.entry.id,
            entrantId: this.props.entry.entrantId,
            isRemovableTrailer: this.state.removableTrailer,
            trailerLength: this.state.imperial ? this.state.removableTrailerLength : convert(this.state.removableTrailerLength, "imperial", "feet"),
            transporterLength: this.state.imperial ? this.state.transporterLength : convert(this.state.transporterLength, "imperial", "feet"),
            transporterWidth: this.state.imperial ? this.state.transporterWidth : convert(this.state.transporterWidth, "imperial", "feet"),
            transporterType: this.state.transporterType,
            hasTrasporterAwning: this.state.awning,
            awningWidth: this.state.imperial ? this.state.awningWidth : convert(this.state.awningWidth, "imperial", "feet"),
            awningLength: this.state.imperial ? this.state.awningLength : convert(this.state.awningLength, "imperial", "feet"),
            hasSharedSpace: (this.state.sharePitWith === "" || this.state.sharePitWith === null) ? false : true,
            sharedSpaceParticipants: this.state.sharePitWith,
            adjacentParticipants: this.state.pitNextTo,
            participatingYear: this.props.entry.participatingYear,
            event: this.props.entry.event,
            isInterestedInTop: this.state.isInterestedInTOP,
            awningOrientation: this.state.awningOrientation,
            prepShop: (this.state.customPrepShop) ? customPrepShop : this.state.prepShop
        }

        await this.apiService.udpateEntry(entry as Entry, this.state.imperial).then(result => {
            if(result.ok){
                this.props.setMessage("Information Successfully Updated.","success")
                this.setState({ editMode: !result });
                this.props.setLoading(false);
            }else{
                this.props.setMessage("Error completing operation. Please try again. (" + result.status + ")", "error");
                this.props.setLoading(false);
            }
        })
    };

    private getEntrant = async () => {
        this.props.setLoading(true);
        await this.apiService.getEntrant().then(result => {
            this.setState({ imperial: !result.usesMetricSystem });
            if (result.usesMetricSystem) {
                this.setState({
                    awningLength: convert(this.state.awningLength, "metric", "meters"),
                    awningWidth: convert(this.state.awningWidth, "metric", "meters"),
                    transporterLength: convert(this.state.transporterLength, "metric", "meters"),
                    transporterWidth: convert(this.state.transporterWidth, "metric", "meters"),
                    removableTrailerLength: convert(this.state.removableTrailerLength, "metric", "meters")
                })
            }
            this.props.setLoading(false);
        })
    }

    private AddCarDialog(): React.ReactElement {
        return (
            <CarWizard
                {...this.state}
                upcomingYear={this.upcomingRaceYear()}
                entry={this.props.entry}
                setMessage={this.props.setMessage}
                setLoading={this.props.setLoading}
                loading={this.props.loading}
                handleCancel={this.handleCloseCarDialog}
                editMode={false}
            />
        )
    }
    private calculateCart = () => {
        let cartItems : CartItem[] = [];
        this.props.entry.carModel.map((car, index) => {
            let cartItem : CartItem = {name : "", price: -1, quantity: -1}
            let shoppingCartBalance: number = 0;   
            if(car.status === "Draft" || car.status === "Pending" || car.status === "Accepted")
            {
                if(car.carRace.length === 1){
                    cartItem = {name : car.carRace[0].race.name + " Entry", quantity : 1, price : car.carRace[0].race.racePrice}
                    if ((car.carRace[0].race.discountRacePrice !== null) && (cartItems.findIndex(item => (car.carRace[0].race.name === car.carRace[0].race.name)) !== -1)){
                           cartItem.price = car.carRace[0].race.discountRacePrice;
                    }
                }else{
                    let price = 0;
                    let name = "";
                    let carRaces = car.carRace.sort((a,b) => {  
                    if (a.race.name < b.race.name) {
                        return -1;
                    }
                    else{
                        return 1;
                    }})
                    carRaces.map((carRace, index) => {                    
                        if ((carRace.race.discountRacePrice !== null) && (cartItems.findIndex(item => (carRace.race.name === carRace.race.name)) !== -1)){
                            price += carRace.race.discountRacePrice;
                        }else{
                            price += carRace.race.racePrice;
                        }
                        name = name + carRace.race.name;
                        if(index < car.carRace.length - 1){
                            name += "/";
                        }
                    })
                    cartItem = {name : name + " Entry", quantity : 1, price : price}
                    
                }
                if (cartItems.findIndex(item => (item.name === cartItem.name && item.price === cartItem.price)) !== -1){
                    cartItems[cartItems.findIndex(item => (item.name === cartItem.name && item.price === cartItem.price))].quantity++;
                    shoppingCartBalance += cartItem.price;
                }else{
                    cartItems.push(cartItem);
                }
                car.crew.map((crewMember, index) => {
                    if(crewMember.isOvercrew === true){
                        if(crewMember.race !== undefined){
                            cartItem = {name : crewMember.race.name + " Overcrew", quantity : 1, price : crewMember.race.overcrewPrice}
                        }
                        if (cartItems.findIndex(item => (item.name === cartItem.name && item.price === cartItem.price)) !== -1){
                            cartItems[cartItems.findIndex(item => (item.name === cartItem.name && item.price === cartItem.price))].quantity++;
                        }else{
                            cartItems.push(cartItem);
                        }
                    }
                });
                car.lounge.map((loungeMember, index) => {
                    if(index > 0){
                        let loungeRace = this.props.entry.event.race.filter(x => x.hasLounge)[0];
                        cartItem = {name : "Additional Lounge Member", quantity : 1, price : loungeRace.loungePrice}
                        if (cartItems.findIndex(item => (item.name === cartItem.name && item.price === cartItem.price)) !== -1){
                            cartItems[cartItems.findIndex(item => (item.name === cartItem.name && item.price === cartItem.price))].quantity++;
                        }else{
                            cartItems.push(cartItem);
                        }
                    }
                });
            }
            cartItems.map((item, index) => {
                shoppingCartBalance += item.price * item.quantity
            })

            this.setState({shoppingCartBalance});
            this.setState({cartItems});
        })
    }
    private getRegistrationInfo = async () =>{
        await this.apiService.getEvent(this.props.entry.event.id).then(result => {
            let startDate : Date = result.race.sort((a:  Race,b : Race) => {return (a.startDate > b.startDate)})[0].startDate;
            this.setState({event : result, 
                startDate: startDate});
            
        });
    }

    public componentDidUpdate(prevProps : IEntryCardProps){
        if (this.props.entry !== prevProps.entry) {
            this.updateEntry();
            this.calculateCart();
        }
    }
    public componentDidMount() {
        this.getEntrant();
        this.calculateCart();
        this.getRegistrationInfo().then(()=> {
            this.setState({
                _isMounted: true
            })
        });
        if (this.state.customPrepShopEmail !== null && this.state.prepShop === null) {
            this.setState({ customPrepShop: true })
        }

    }
    public render(): React.ReactElement<IEntryCardProps> {
        const { classes } = this.props;
        return (
            <React.Fragment>
            {this.state._isMounted && 
            <Paper className={classes.paper}>
                <Typography variant="h5" className={classes.header}>{this.props.entry.participatingYear}  Reunion Entry</Typography>
                {!this.props.regOpen && <Typography variant="subtitle1" className={classes.notice}>Registration is closed for this year. Modifications to crew can be made until {new Date(this.state.startDate).toDateString()}</Typography>}

                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h6">Car Information</Typography>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Divider />
                        <br></br>
                        <Grid container spacing={2}>
                            {this.props.entry.carModel.length < 1 && <Typography variant="subtitle1" className={classes.notice}>There are no cars for entry.</Typography>}
                            {this.props.entry.carModel.map((carValue, index) => {
                                return (
                                    <Grid key={index} item xs={12} sm={6} md={4}>
                                        <CarCard
                                            getEntryForms={this.props.getEntryForms}
                                            entry={this.props.entry}
                                            setMessage={this.props.setMessage}
                                            setLoading={this.props.setLoading}
                                            loading={this.props.loading}
                                            car={carValue}
                                            editEnabled={this.props.entry.participatingYear === this.upcomingRaceYear() && this.props.regOpen}
                                            regOpen={this.props.regOpen}
                                            event={this.state.event}
                                        />
                                    </Grid>)
                            })}
                                <React.Fragment>
                                    <Grid item xs={12} className={classes.fabGutter}>
                                    </Grid>                                    
                                    {(this.props.entry.participatingYear === this.upcomingRaceYear() && this.props.regOpen) &&
                                    <Tooltip title="Add Car" placement="top" arrow>
                                        <Fab variant="extended" color="secondary" aria-label="add" className={clsx(classes.fab, classes.fabGreen)} disabled={this.props.entry.carModel.length >= 10 || this.props.loading} onClick={this.openAddCarDialog}>
                                            <AddIcon />
                                            Add Car
                                         </Fab>
                                    </Tooltip>}
                                </React.Fragment>
                        </Grid>
                        <Divider />
                    </Grid>
                    <Grid item xs={10}>
                        <Typography variant="h6">Paddock &amp; Registration Info</Typography>
                        <Box m={1}>
                            <Typography variant="body2"><i>25' x 25' Maximum space for 1 car entry <b>|</b> 35' x 25' Maximum space for 2 car entry <b>|</b> 50' x 25' Maximum space for minimum of 4 accepted race cars</i></Typography>
                        </Box>
                        <Box m={1}>
                            <Typography variant="body2"><i>Awning width cannot exceed 22'</i></Typography>
                        </Box>
                        <Box m={1}>
                            <Typography variant="body2"><i>Trailers over 25' overall length will not be allowed to remain in the paddock</i></Typography>
                        </Box>
                    </Grid>
                    {(this.props.entry.participatingYear === this.upcomingRaceYear() && this.props.regOpen) &&
                        <Grid item xs={12} md={2}>
                            <FormControlLabel className={classes.formSwitchLabel}
                                control={
                                    <Switch
                                        name="editMode"
                                        checked={this.state.editMode}
                                        value={this.state.editMode}
                                        color="secondary"
                                        onChange={this.handleToggle}
                                    />
                                }
                                label="Edit Information"
                            />
                        </Grid>}
                    {(this.state.editMode) ?
                        <React.Fragment>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                            <PaddockInfo {...this.state} />
                            <Grid item xs={12} className={classes.center}>
                                <Button className={classes.submit} onClick={this.updateReg} autoFocus disabled={this.props.loading}>
                                    Save Changes
                                </Button>
                            </Grid>
                        </React.Fragment> :
                        <React.Fragment>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                            <PaddockInfo {...this.state} />
                        </React.Fragment>
                    }

                </Grid>
                {this.state.showCarDialog && <this.AddCarDialog />}
                {this.props.entry.participatingYear === this.upcomingRaceYear() && <ShoppingCart loading={this.props.loading} {...this.state}/>}
            </Paper>
            }            
            </React.Fragment>
        );
    }
}
const styles = createStyles((theme: Theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        position: 'relative',
    },
    paper: {
        padding: theme.spacing(3, 2),
        position: 'relative',
    },
    fab: {
        position: 'relative',
        marginRight: theme.spacing(4),
        marginLeft: 'auto',
        marginBottom: theme.spacing(4),
    },
    fabGreen: {
        color: theme.palette.common.white,
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[600],
        },
    },
    header: {
        textAlign: "center"
    },
    notice:{
        textAlign: "center",
        fontStyle: "italic"
    },
    submit: {
        backgroundColor: green[500],
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        color: 'white',
        '&:hover': {
            backgroundColor: green[700],
        }
    },
    cancel: {
        backgroundColor: red[500],
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        color: 'white',
        '&:hover': {
            backgroundColor: red[700],
        }
    },
    center: {
        textAlign: 'center'
    },
    fabGutter: {
        height: theme.spacing(6)
    }
}))

export default withStyles(styles)(EntryCard)